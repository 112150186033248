<template>
<div class="NouveauMdpObligatoire" style="width: 100%; max-height: 100%">
	<b-alert
		:show="alertSuccessCountDown"
		variant="success"
		@dismissed="alertSuccessCountDown=0"
		@dismiss-count-down="alertSuccessCountDownChanged"
		>
			<p>{{ info }}</p>
		<b-progress
			variant="success"
			:max="dismissSecs"
			:value="alertSuccessCountDown"
			height="4px"
			></b-progress>
	</b-alert>
	<b-alert
		:show="alertWarningCountDown"
		variant="warning"
		@dismissed="alertWarningCountDown=0"
		@dismiss-count-down="alertWarningCountDownChanged"
		>
			<p>{{ error }}</p>
		<b-progress
			variant="warning"
			:max="dismissSecs"
			:value="alertWarningCountDown"
			height="4px"
			></b-progress>
	</b-alert>

	<div class="container-fluid">

		<div class="col-md-6 offset-md-3 p-5 ml-auto mr-auto ">
			<div class="app-card app-card-notification shadow-sm mb-4">
				<div class="app-card-header px-4 py-3">
					<div class="row g-3 align-items-center">
						<div class="col-12 col-lg-auto text-center text-lg-left">
							<div class="app-icon-holder" style="background-color: rgb(42, 87, 136)">
								<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-shield-check" fill="white" xmlns="http://www.w3.org/2000/svg">
									<path fill-rule="evenodd" d="M5.443 1.991a60.17 60.17 0 0 0-2.725.802.454.454 0 0 0-.315.366C1.87 7.056 3.1 9.9 4.567 11.773c.736.94 1.533 1.636 2.197 2.093.333.228.626.394.857.5.116.053.21.089.282.11A.73.73 0 0 0 8 14.5c.007-.001.038-.005.097-.023.072-.022.166-.058.282-.111.23-.106.525-.272.857-.5a10.197 10.197 0 0 0 2.197-2.093C12.9 9.9 14.13 7.056 13.597 3.159a.454.454 0 0 0-.315-.366c-.626-.2-1.682-.526-2.725-.802C9.491 1.71 8.51 1.5 8 1.5c-.51 0-1.49.21-2.557.491zm-.256-.966C6.23.749 7.337.5 8 .5c.662 0 1.77.249 2.813.525a61.09 61.09 0 0 1 2.772.815c.528.168.926.623 1.003 1.184.573 4.197-.756 7.307-2.367 9.365a11.191 11.191 0 0 1-2.418 2.3 6.942 6.942 0 0 1-1.007.586c-.27.124-.558.225-.796.225s-.526-.101-.796-.225a6.908 6.908 0 0 1-1.007-.586 11.192 11.192 0 0 1-2.417-2.3C2.167 10.331.839 7.221 1.412 3.024A1.454 1.454 0 0 1 2.415 1.84a61.11 61.11 0 0 1 2.772-.815z" />
									<path fill-rule="evenodd" d="M10.854 6.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 8.793l2.646-2.647a.5.5 0 0 1 .708 0z" />
								</svg>
							</div>
						</div><!--//col-->
						<div class="col-12 col-lg-auto text-center text-lg-left">
							<h4 class="notification-title mb-1">
								<label style="font-weight:bold;">Changement du mot de passe</label>
							</h4>

						</div><!--//col-->
					</div><!--//row-->
				</div><!--//app-card-header-->
				<div class="app-card-body p-4">
					<div class="notification-content">
						<!-- <div class="text-center col-md-8 offset-md-2">
							<div class="item-label"><strong>Ancien mot de passe</strong></div>
							<b-form-input class="form-select-sm ml-auto d-inline-flex text-center" style="margin: 0.5em; height: 30px; border: 1px solid #ced4da; border-radius: .25rem;" :state="checkAncienMdpStatut" type="password" name="ancienMdpInput" v-model="ancienMdpInput" aria-describedby="password-help-block"></b-form-input>
						</div> -->
						<div class="text-center col-md-8 offset-md-2">
							<div class="item-label"><strong>Nouveau mot de passe</strong></div>
							<b-form-input class="form-select-sm ml-auto d-inline-flex text-center" style="margin: 0.5em; height: 30px; border: 1px solid #ced4da; border-radius: .25rem;" :state="checkPwdState" type="password" name="newPwdInput" v-model="newPwdInput" aria-describedby="password-help-block"></b-form-input>
						</div>
						<b-form-checkbox-group v-model="testPwd" :options="options" :state="checkPwdState" disabled name="checkbox-validation" style="margin: 0.75em">
							<b-form-invalid-feedback :state="checkPwdState" style="margin: 0.5em">Merci de respecter au moins 4 critères sur 5</b-form-invalid-feedback>
							<b-form-valid-feedback :state="checkPwdState" style="margin: 0.5em">Le mot de passe satisfait aux conditions de sécurité</b-form-valid-feedback>
						</b-form-checkbox-group>
						<div class="text-center col-md-8 offset-md-2">
							<div class="item-label"><strong>Confirmation du nouveau mot de passe</strong></div>
							<b-form-input class="form-select-sm ml-auto d-inline-flex text-center" style="margin: 0.5em; height: 30px; border: 1px solid #ced4da; border-radius: .25rem;" :state="checkNewPwdState" type="password" name="newPwdConfInput" v-model="newPwdConfInput" aria-describedby="password-help-block"></b-form-input>
						</div>
						<div class="text-center" style="margin-top: 0.5em; margin-bottom: -1em">
							<b-button v-on:click="saveNewPwd()" class="btn app-btn-primary" style="background: rgb(42, 87, 136);color: white; width: 200px; margin: 0.5em;">Valider</b-button>
						</div>
					</div>
				</div><!--//app-card-body-->
			</div>


		</div>
	</div>
</div>

</template>

<style>
	.custom-control-input {
		margin: 0.5em !important;
	}
</style>

<script>
import jwt_decode from "jwt-decode";

export default {
	name: 'NouveauMdpObligatoire',
	computed:
	{
		// checkAncienMdpStatut: function()
		// {
		// 	return (this.ancienMdpInput.length >=1)
		// },
		checkPwdState: function()
		{
			var reMdp = /^.{8,32}$/;
			var reInt = /[0-9]+/;
			var reMin = /[a-z]+/;
			var reMaj = /[A-Z]+/;
			var reSym = /[,.;:?!£$@#%*()=+-]/;

			if (reMdp.test(this.newPwdInput) && this.testPwd.indexOf(0) === -1)
			{
				// eslint-disable-next-line vue/no-side-effects-in-computed-properties
				this.testPwd.push(0);
			}
			else if (!reMdp.test(this.newPwdInput))
			{
				const index = this.testPwd.indexOf(0);
				if (index > -1)
				{
					// eslint-disable-next-line vue/no-side-effects-in-computed-properties
					this.testPwd.splice(index, 1);
				}
			}
			
			if (reInt.test(this.newPwdInput) && this.testPwd.indexOf(1) === -1)
			{
				// eslint-disable-next-line vue/no-side-effects-in-computed-properties
				this.testPwd.push(1);
			}
			else if (!reInt.test(this.newPwdInput))
			{
				const index = this.testPwd.indexOf(1);
				if (index > -1)
				{
					// eslint-disable-next-line vue/no-side-effects-in-computed-properties
					this.testPwd.splice(index, 1);
				}
			}

			if (reMin.test(this.newPwdInput) && this.testPwd.indexOf(2) === -1)
			{
				// eslint-disable-next-line vue/no-side-effects-in-computed-properties
				this.testPwd.push(2);
			}
			else if (!reMin.test(this.newPwdInput))
			{
				const index = this.testPwd.indexOf(2);
				if (index > -1)
				{
					// eslint-disable-next-line vue/no-side-effects-in-computed-properties
					this.testPwd.splice(index, 1);
				}
			}

			if (reMaj.test(this.newPwdInput) && this.testPwd.indexOf(3) === -1)
			{
				// eslint-disable-next-line vue/no-side-effects-in-computed-properties
				this.testPwd.push(3);
			}
			else if (!reMaj.test(this.newPwdInput))
			{
				const index = this.testPwd.indexOf(3);
				if (index > -1)
				{
					// eslint-disable-next-line vue/no-side-effects-in-computed-properties
					this.testPwd.splice(index, 1);
				}
			}

			if (reSym.test(this.newPwdInput) && this.testPwd.indexOf(4) === -1)
			{
				// eslint-disable-next-line vue/no-side-effects-in-computed-properties
				this.testPwd.push(4);
			}
			else if (!reSym.test(this.newPwdInput))
			{
				const index = this.testPwd.indexOf(4);
				if (index > -1)
				{
					// eslint-disable-next-line vue/no-side-effects-in-computed-properties
					this.testPwd.splice(index, 1);
				}
			}

			return (this.testPwd.length >= 4 && reMdp.test(this.newPwdInput))
		},
		checkNewPwdState: function()
		{
			var reMdp = /^.{8,32}$/;
			return (this.newPwdInput == this.newPwdConfInput && this.testPwd.length >= 4 && reMdp.test(this.newPwdInput))
		}
	},
	data() {
		return {
			// Variable message alert
			dismissSecs: 10,
			alertSuccessCountDown: 0,
			alertWarningCountDown: 0,
			info:'',
			error:'',

			// ancienMdpInput: '',
			newPwdInput: '',
			newPwdConfInput: '',
			options: [
				{ text: 'Longueur comprise entre 8 et 32 caractères', value: 0 },
				{ text: 'Un nombre', value: 1 },
				{ text: 'Une minuscule', value: 2 },
				{ text: 'Une majuscule', value: 3 },
				{ text: 'Une symbole (@$!#?...)', value: 4 }
			],
			testPwd:[],

			creationAccount:false,
		}
	},

	mounted()
	{
		var rgToken = /^[A-Za-z0-9-_=]+\.[A-Za-z0-9-_=]+\.?[A-Za-z0-9-_.+/=]*$/;

		if (rgToken.test(localStorage.token))
		{
			var jsonToken = jwt_decode(this.$route.query.token);
			if (jsonToken.exp < Math.round(Date.now()/1000))
			{
				localStorage.token = null;
				this.$router.push({ path: '/login'})
			}
		}
		else
		{
			localStorage.token = null;
			this.$router.push({ path: '/login'})
		}
	},

	methods: {
		// Méthodes message alert
		alertSuccessCountDownChanged(alertSuccessCountDown)
		{
			this.alertSuccessCountDown = alertSuccessCountDown
		},
		alertWarningCountDownChanged(alertWarningCountDown)
		{
			this.alertWarningCountDown = alertWarningCountDown
		},
		showAlertSuccess(info)
		{
			this.info = info;
			this.alertSuccessCountDown = this.dismissSecs
		},
		showAlertWarning(error)
		{
			this.error = error;
			this.alertWarningCountDown = this.dismissSecs
		},

		saveNewPwd()
		{
			var remdp = /^.{8,32}$/;
			if ((remdp.test(this.newPwdInput)) && (this.newPwdInput == this.newPwdConfInput))
			{
				let jsonNewPassword = {
					token : localStorage.token, 
					password : this.newPwdInput
				};

				this.$axios.post('https://api.clickandball.fr/authentification/nouveaumdp', jsonNewPassword)
					.then(response => {
						if (response.status == 200 && response.data.error)
						{
							this.showAlertWarning('Erreur: ' + response.data.error);
						}
						else if (response.status == 200)
						{
							localStorage.token = null;
							this.$router.push({ path: '/login'})
						}
						else 
						{
							this.errors.push('Erreur : ' + response.statusText);
						}
					})
					.catch(error => {
						this.showAlertWarning('Erreur : ' + error);
					});
			}
		}
	}
}
</script>
