<template>
	<div class="Division">
		<h1 class="app-page-title align-middle"><img src="https://img.icons8.com/ios-glyphs/50/000000/basketball--v1.png" /> Gestion des groupes et des divisions</h1>

		<b-modal ref="modalGroup" hide-footer hide-header>
			<div class="app-card " style="margin-top: -1em; margin-left: -1em; margin-right: -1em">
				<a style="float: right; z-index: 9999; margin-right: 0.5em; top: 0" href="#" block @click="$refs['modalGroup'].hide()">
					<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
						<path fill-rule="evenodd" d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z" />
						<path fill-rule="evenodd" d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z" />
					</svg>
				</a>
				<div class="app-card-header p-3 border-bottom-0">
					<div class="row align-items-center gx-3">
						<div class="col-auto">
							<div class="app-icon-holder" style="background-color: rgb(42, 87, 136)">
								<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-shield-check" fill="white" xmlns="http://www.w3.org/2000/svg">
									<path fill-rule="evenodd" d="M8 0C3.584 0 0 3.584 0 8s3.584 8 8 8c4.408 0 8-3.584 8-8s-3.592-8-8-8zm5.284 3.688a6.802 6.802 0 0 1 1.545 4.251c-.226-.043-2.482-.503-4.755-.217-.052-.112-.096-.234-.148-.355-.139-.33-.295-.668-.451-.99 2.516-1.023 3.662-2.498 3.81-2.69zM8 1.18c1.735 0 3.323.65 4.53 1.718-.122.174-1.155 1.553-3.584 2.464-1.12-2.056-2.36-3.74-2.551-4A6.95 6.95 0 0 1 8 1.18zm-2.907.642A43.123 43.123 0 0 1 7.627 5.77c-3.193.85-6.013.833-6.317.833a6.865 6.865 0 0 1 3.783-4.78zM1.163 8.01V7.8c.295.01 3.61.053 7.02-.971.199.381.381.772.555 1.162l-.27.078c-3.522 1.137-5.396 4.243-5.553 4.504a6.817 6.817 0 0 1-1.752-4.564zM8 14.837a6.785 6.785 0 0 1-4.19-1.44c.12-.252 1.509-2.924 5.361-4.269.018-.009.026-.009.044-.017a28.246 28.246 0 0 1 1.457 5.18A6.722 6.722 0 0 1 8 14.837zm3.81-1.171c-.07-.417-.435-2.412-1.328-4.868 2.143-.338 4.017.217 4.251.295a6.774 6.774 0 0 1-2.924 4.573z" />
								</svg>
							</div>
						</div>
						<div class="col-auto">
							<h4 class="app-card-title" style="color: rgb(42, 87, 136) !important">
								<div v-if="modalUpdateGroup">Modifier un groupe de niveau</div>
								<div v-else-if="modalDeleteGroup">Supprimer un groupe de niveau</div>
								<div v-else>Ajouter un groupe de niveau</div>
							</h4>
						</div>
					</div>
				</div>
				<div class="app-card-body px-4">

					<div class="intro mb-3" v-if="!modalDeleteGroup">
						<small>
							L'identifiant permet d'ordonnancer l'ordre de désignation des divisions affectées au groupe de niveau
						</small>
					</div>
					<ul class="list-unstyled">
						<div class="row">
							<div class="col-md-5 offset-md-1 pr-1">
								<div class="form-group">
									<svg width="1em" height="1em" style="margin-right: 0.25em" viewBox="0 0 16 16" class="bi bi-check2 text-primary mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
										<path fill-rule="evenodd" d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
									</svg>
									<label class="form-label" style="font-weight:bold;">Identifiant : </label>
									<b-form-input :disabled="modalUpdateGroup || modalDeleteGroup" class="form-select-sm ml-auto d-inline-flex text-center" style="height: 30px; border: 1px solid #ced4da; border-radius: .25rem;" type="number" min="0" v-model="idGroup"></b-form-input>
								</div>
							</div>
							<div class="col-md-5 pr-1">
								<div class="form-group">
									<svg width="1em" height="1em" style="margin-right: 0.25em" viewBox="0 0 16 16" class="bi bi-check2 text-primary mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
										<path fill-rule="evenodd" d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
									</svg>
									<label class="form-label" style="font-weight:bold;">Distance max. : </label>
									<b-form-input :disabled="modalDeleteGroup" class="form-select-sm ml-auto d-inline-flex text-center" style="height: 30px; border: 1px solid #ced4da; border-radius: .25rem;" type="text" v-model="distanceMax"></b-form-input>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-md-10 offset-md-1 pr-1">
								<div class="form-group">
									<svg width="1em" height="1em" style="margin-right: 0.25em" viewBox="0 0 16 16" class="bi bi-check2 text-primary mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
										<path fill-rule="evenodd" d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
									</svg>
									<label class="form-label" style="font-weight:bold;">Libellé : </label>
									<b-form-input :disabled="modalDeleteGroup" class="form-select-sm ml-auto d-inline-flex text-center" style="height: 30px; border: 1px solid #ced4da; border-radius: .25rem;" type="text" v-model="wordingGroup"></b-form-input>
								</div>
							</div>
						</div>
					</ul>
					<div class="text-center">
						<hr>
						<b-button v-if="modalUpdateGroup" class="btn app-btn-primary" style="background: rgb(42, 87, 136);color: white;" v-on:click="updateGroup()">Mettre à jour</b-button>
						<b-button v-else-if="modalDeleteGroup" class="btn app-btn-primary" style="background: rgb(42, 87, 136);color: white;" v-on:click="deleteGroup()">Supprimer</b-button>
						<b-button v-else class="btn app-btn-primary" style="background: rgb(42, 87, 136);color: white;" v-on:click="addGroup()">Enregistrer</b-button>
					</div>
				</div>
			</div>
		</b-modal>

		<b-modal ref="modalDivision" hide-footer hide-header size="lg">
			<template #modal-title>
				<div v-if="modalUpdateDivision">Modifier une division</div>
				<div v-else>Ajouter une division</div>
			</template>
			<div class="app-card " style="margin-top: -1em; margin-left: -1em; margin-right: -1em">
				<a style="float: right; z-index: 9999; margin-right: 0.5em; top: 0" href="#" block @click="$refs['modalDivision'].hide()">
					<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
						<path fill-rule="evenodd" d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z" />
						<path fill-rule="evenodd" d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z" />
					</svg>
				</a>
				<div class="app-card-header p-3 border-bottom-0">
					<div class="row align-items-center gx-3">
						<div class="col-auto">
							<div class="app-icon-holder" style="background-color: rgb(42, 87, 136)">
								<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-shield-check" fill="white" xmlns="http://www.w3.org/2000/svg">
									<path fill-rule="evenodd" d="M8 0C3.584 0 0 3.584 0 8s3.584 8 8 8c4.408 0 8-3.584 8-8s-3.592-8-8-8zm5.284 3.688a6.802 6.802 0 0 1 1.545 4.251c-.226-.043-2.482-.503-4.755-.217-.052-.112-.096-.234-.148-.355-.139-.33-.295-.668-.451-.99 2.516-1.023 3.662-2.498 3.81-2.69zM8 1.18c1.735 0 3.323.65 4.53 1.718-.122.174-1.155 1.553-3.584 2.464-1.12-2.056-2.36-3.74-2.551-4A6.95 6.95 0 0 1 8 1.18zm-2.907.642A43.123 43.123 0 0 1 7.627 5.77c-3.193.85-6.013.833-6.317.833a6.865 6.865 0 0 1 3.783-4.78zM1.163 8.01V7.8c.295.01 3.61.053 7.02-.971.199.381.381.772.555 1.162l-.27.078c-3.522 1.137-5.396 4.243-5.553 4.504a6.817 6.817 0 0 1-1.752-4.564zM8 14.837a6.785 6.785 0 0 1-4.19-1.44c.12-.252 1.509-2.924 5.361-4.269.018-.009.026-.009.044-.017a28.246 28.246 0 0 1 1.457 5.18A6.722 6.722 0 0 1 8 14.837zm3.81-1.171c-.07-.417-.435-2.412-1.328-4.868 2.143-.338 4.017.217 4.251.295a6.774 6.774 0 0 1-2.924 4.573z" />
								</svg>
							</div>
						</div>
						<div class="col-auto">
							<h4 class="app-card-title" style="color: rgb(42, 87, 136) !important">
								<div v-if="modalUpdateDivision">Modifier une division</div>
								<div v-else-if="modalDeleteDivision">Supprimer une division</div>
								<div v-else>Ajouter une division</div>
							</h4>
						</div>
					</div>
				</div>
				<div class="app-card-body px-4">

					<div class="intro mb-3" v-if="!modalDeleteDivision">
						<small>
							Afin de pouvoir importer les matchs depuis FBI, merci de remplir avec exactitude ces champs
						</small>
					</div>
					<ul class="list-unstyled">
						<div class="row">
							<div class="col-md-5 offset-md-1 pr-1">
								<div class="form-group">
									<svg width="1em" height="1em" style="margin-right: 0.25em" viewBox="0 0 16 16" class="bi bi-check2 text-primary mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
										<path fill-rule="evenodd" d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
									</svg>
									<label class="form-label" style="font-weight:bold;">Identifiant : </label>
									<b-form-input :disabled="modalDeleteDivision || modalUpdateDivision" class="form-select-sm ml-auto d-inline-flex text-center" style="height: 30px; border: 1px solid #ced4da; border-radius: .25rem;" type="text" v-model="idDivision"></b-form-input>
								</div>
							</div>
							<div class="col-md-5 pr-1">
								<div class="form-group">
									<svg width="1em" height="1em" style="margin-right: 0.25em" viewBox="0 0 16 16" class="bi bi-check2 text-primary mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
										<path fill-rule="evenodd" d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
									</svg>
									<label class="form-label" style="font-weight:bold;">Entité : </label>
									<b-form-input :disabled="modalDeleteDivision || modalUpdateDivision" class="form-select-sm ml-auto d-inline-flex text-center" style="height: 30px; border: 1px solid #ced4da; border-radius: .25rem;" type="text" v-model="entityIdDivision"></b-form-input>
								</div>
							</div>
						</div>
						<div class="row" style="margin-top: 0.25em">
							<div class="col-md-10 offset-md-1 pr-1">
								<div class="form-group">
									<svg width="1em" height="1em" style="margin-right: 0.25em" viewBox="0 0 16 16" class="bi bi-check2 text-primary mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
										<path fill-rule="evenodd" d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
									</svg>
									<label class="form-label" style="font-weight:bold;">Libellé : </label>
									<b-form-input :disabled="modalDeleteDivision" class="form-select-sm ml-auto d-inline-flex text-center" style="height: 30px; border: 1px solid #ced4da; border-radius: .25rem;" type="text" v-model="nameDivision"></b-form-input>
								</div>
							</div>
						</div>
						<div class="row" style="margin-top: 0.25em">
							<div class="col-md-5 offset-md-1 pr-1">
								<div class="form-group">
									<svg width="1em" height="1em" style="margin-right: 0.25em" viewBox="0 0 16 16" class="bi bi-check2 text-primary mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
										<path fill-rule="evenodd" d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
									</svg>
									<label class="form-label" style="font-weight:bold;">Niveau minimum : </label>
									<select :disabled="modalDeleteDivision" class="form-select form-select-sm ml-auto d-inline-flex text-center" v-model="minimumLevelDivision" required>
										<option disabled value="0">Choisissez un groupe</option>
										<option v-for="item in listInfoGroup" :key="item.id" :value="item.id">
											{{ item.wording }}
										</option>
									</select>
								</div>
							</div>
							<div class="col-md-5 pr-1">
								<div class="form-group">
									<svg width="1em" height="1em" style="margin-right: 0.25em" viewBox="0 0 16 16" class="bi bi-check2 text-primary mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
										<path fill-rule="evenodd" d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
									</svg>
									<label class="form-label" style="font-weight:bold;">Désignation obligatoire ? </label>
									<select :disabled="modalDeleteDivision" class="form-select form-select-sm ml-auto d-inline-flex text-center" v-model="mandatoryAssignmentDivision" required>
										<option v-bind:value="true">Oui</option>
										<option v-bind:value="false">Non</option>
									</select>
								</div>
							</div>
						</div>
						<div class="row" style="margin-top: 0.25em">
							<div class="col-md-5 offset-md-1 pr-1">
								<div class="form-group">
									<svg width="1em" height="1em" style="margin-right: 0.25em" viewBox="0 0 16 16" class="bi bi-check2 text-primary mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
										<path fill-rule="evenodd" d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
									</svg>
									<label class="form-label" style="font-weight:bold;">Type de division : </label>
									<select :disabled="modalDeleteDivision" class="form-select form-select-sm ml-auto d-inline-flex text-center" v-model="typeDivision" required>
										<option value="0">Division</option>
										<option value="1">Coupe</option>
									</select>
								</div>
							</div>
							<div class="col-md-5 pr-1">
								<div class="form-group">
									<span>
										<input :disabled="modalDeleteDivision" class="form-check-input" type="checkbox" v-model="exportFBI" style="margin: 0.4em; width: 40px; height: 20px;"/>
										<label for="referee" style="margin-top: 0.4em; margin-right: 0.75em"><strong>Export en direction d'FBI</strong></label>
									</span>
									<span>
										<input :disabled="modalDeleteDivision" class="form-check-input" type="checkbox" v-model="importFBI" style="margin: 0.4em; width: 40px; height: 20px;"/>
										<label for="referee" style="margin-top: 0.4em; margin-right: 0.75em"><strong>Import depuis FBI</strong></label>
									</span>
								</div>
							</div>
						</div>
						<!--<div class="row" style="margin-top: 0.25em">
				<div class="col-md-5 offset-md-1 pr-1">
					<div class="form-group">
						<svg width="1em" height="1em" style="margin-right: 0.25em" viewBox="0 0 16 16" class="bi bi-check2 text-primary mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
							<path fill-rule="evenodd" d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
						</svg>
						<label class="form-label" style="font-weight:bold;">Age minimum : </label>
						<b-form-input disabled class="form-select-sm ml-auto d-inline-flex text-center" style="height: 30px; border: 1px solid #ced4da; border-radius: .25rem;" type="number" min="14" v-model="minimumAge"></b-form-input>
					</div>
				</div>
				<div class="col-md-5 pr-1">
					<div class="form-group">
						<svg width="1em" height="1em" style="margin-right: 0.25em" viewBox="0 0 16 16" class="bi bi-check2 text-primary mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
							<path fill-rule="evenodd" d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
						</svg>
						<label class="form-label" style="font-weight:bold;">Indemnité de match : </label>
						<b-form-input disabled class="form-select-sm ml-auto d-inline-flex text-center" style="height: 30px; border: 1px solid #ced4da; border-radius: .25rem;" type="number" min="0" v-model="matchIndemnity"></b-form-input>
					</div>
				</div>
			</div>-->
					</ul>
					<div class="text-center">
						<hr>
						<b-button v-if="modalUpdateDivision" class="btn app-btn-primary" style="background: rgb(42, 87, 136);color: white;" v-on:click="updateDivision()">Mettre à jour</b-button>
						<b-button v-else-if="modalDeleteDivision" class="btn app-btn-primary" style="background: rgb(42, 87, 136);color: white;" v-on:click="deleteDivision()">Supprimer</b-button>
						<b-button v-else class="btn app-btn-primary" style="background: rgb(42, 87, 136);color: white;" v-on:click="addDivision()">Enregistrer</b-button>
					</div>
				</div>
			</div>
		</b-modal>

		<b-alert :show="alertSuccessCountDown"
				variant="success"
				@dismissed="alertSuccessCountDown=0"
				@dismiss-count-down="alertSuccessCountDownChanged">
			<p>{{ info }}</p>
			<b-progress variant="success"
						:max="dismissSecs"
						:value="alertSuccessCountDown"
						height="4px"></b-progress>
		</b-alert>
		<b-alert :show="alertWarningCountDown"
				variant="warning"
				@dismissed="alertWarningCountDown=0"
				@dismiss-count-down="alertWarningCountDownChanged">
			<p>{{ error }}</p>
			<b-progress variant="warning"
						:max="dismissSecs"
						:value="alertWarningCountDown"
						height="4px"></b-progress>
		</b-alert>
		<div class="row">
			<div class="col-12 col-sm-12 col-md-12 col-lg-4">
				
				<div class="app-card  d-flex flex-column  shadow-sm" style="width: auto !important;">
					<div class="app-card-header p-3 " style="background-color: rgba(0,0,0,.05) !important; border-bottom: solid 2px #fa7507">
						<div class="row align-items-center gx-3">
							<div class="col-auto">
								<div class="app-icon-holder" style="background-color: #fa7507 !important">
									<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-code-square" fill="white" xmlns="http://www.w3.org/2000/svg">
										<path fill-rule="evenodd" d="M6 3.5A1.5 1.5 0 0 1 7.5 2h1A1.5 1.5 0 0 1 10 3.5v1A1.5 1.5 0 0 1 8.5 6v1H14a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-1 0V8h-5v.5a.5.5 0 0 1-1 0V8h-5v.5a.5.5 0 0 1-1 0v-1A.5.5 0 0 1 2 7h5.5V6A1.5 1.5 0 0 1 6 4.5v-1zM8.5 5a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1zM0 11.5A1.5 1.5 0 0 1 1.5 10h1A1.5 1.5 0 0 1 4 11.5v1A1.5 1.5 0 0 1 2.5 14h-1A1.5 1.5 0 0 1 0 12.5v-1zm1.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1zm4.5.5A1.5 1.5 0 0 1 7.5 10h1a1.5 1.5 0 0 1 1.5 1.5v1A1.5 1.5 0 0 1 8.5 14h-1A1.5 1.5 0 0 1 6 12.5v-1zm1.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1zm4.5.5a1.5 1.5 0 0 1 1.5-1.5h1a1.5 1.5 0 0 1 1.5 1.5v1a1.5 1.5 0 0 1-1.5 1.5h-1a1.5 1.5 0 0 1-1.5-1.5v-1zm1.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1z" />
									</svg>
								</div>
							</div>
							<div class="col-auto">
								<h4 class="app-card-title">Groupe de niveaux</h4>
							</div>
						</div>
					</div>
					<div class="app-card-body px-4" style="margin-bottom: -2em; margin-top: 0.75em">
						<div class="text-center">
							<b-button class="btn app-btn-primary" style="background: rgb(42, 87, 136);color: white;width: 200px" v-on:click="addGroupModal()">
								Ajouter un groupe
							</b-button>
						</div>
						
						<div class="tab-content" id="orders-table-tab-content" style="margin-top: 0.75em">
							<div class="tab-pane fade show active" id="orders-all" role="tabpanel" aria-labelledby="orders-all-tab">
								<div class="app-card app-card-orders-table shadow-sm mb-5">
									<div class="app-card-body">
										<div class="table-responsive table-stiped">
											<table class="table table-striped table-hover mb-0 text-center">
												<thead>
													<tr>
														<th class="mb-2 text-primary">Ordre</th>
														<th class="mb-2 text-primary">Libellé</th>
														<th class="mb-2 text-primary">Distance max.</th>
														<th class="mb-2 text-primary">Actions</th>
													</tr>
												</thead>
												<tbody>
													<tr v-for="item in listInfoGroup" :key="item.id" class="align-middle" style="text-align: center;">
														<td class="cell">{{ item.id }}</td>
														<td class="cell">{{ item.wording }}</td>
														<td class="cell">{{ item.distanceMax }}</td>
														<td class="cell">
															<b-button class="btn app-btn-secondary" style="margin: 3px" v-on:click="updateGroupModal(item)">
																<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil" viewBox="0 0 16 16">
																	<path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
																</svg>
															</b-button>
															<b-button class="btn app-btn-secondary" style="margin: 3px" v-on:click="deleteGroupModal(item)">
																<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
																	<path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
																	<path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
																</svg>
															</b-button>
														</td>
													</tr>
												</tbody>
												<tbody v-if="noResultGroup">
													<tr>
														<td colspan="6">Aucun résultat</td>
													</tr>
												</tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-12 col-sm-12 col-md-12 col-lg-8">
				<div class="app-card  d-flex flex-column  shadow-sm" style="width: auto !important;">
					<div class="app-card-header p-3 " style="background-color: rgba(0,0,0,.05) !important; border-bottom: solid 2px #fa7507">
						<div class="row align-items-center gx-3">
							<div class="col-auto">
								<div class="app-icon-holder" style="background-color: #fa7507 !important">
									<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-code-square" fill="white" xmlns="http://www.w3.org/2000/svg">
										<path fill-rule="evenodd" d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3.854 2.146a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708L2 3.293l1.146-1.147a.5.5 0 0 1 .708 0zm0 4a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708L2 7.293l1.146-1.147a.5.5 0 0 1 .708 0zm0 4a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 0 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0z" />
									</svg>
								</div>
							</div>
							<div class="col-auto">
								<h4 class="app-card-title">Divisions à désigner</h4>
							</div>
						</div>
					</div>
					<div class="app-card-body px-4" style="margin-bottom: -2em; margin-top: 0.75em">
						<div class="text-center">
							<b-button class="btn app-btn-primary" style="background: rgb(42, 87, 136);color: white;width: 200px" v-on:click="addDivisionModal()">
								Ajouter une division
							</b-button>
						</div>
						<div class="tab-content" id="orders-table-tab-content" style="margin-top: 0.75em">
							<div class="tab-pane fade show active" id="orders-all" role="tabpanel" aria-labelledby="orders-all-tab">
								<div class="app-card app-card-orders-table shadow-sm mb-5">
									<div class="app-card-body">
										<div class="table-responsive table-stiped">
											<table id="myTable2" class="table table-striped table-hover mb-0 text-center">
												<thead>
													<tr>
														<th class="mb-2 text-primary">Entité</th>
														<th class="mb-2 text-primary">ID</th>
														<th class="mb-2 text-primary">Libellé</th>
														<th class="mb-2 text-primary">Type</th>
														<th class="mb-2 text-primary">Niveau minimum</th>
														<th class="mb-2 text-primary">Obligatoire</th>
														<th class="mb-2 text-primary">FBI <br>(Import / Export)</th>
														<th class="mb-2 text-primary">Actions</th>
													</tr>
												</thead>
												<tbody>
													<tr v-for="item in listInfoDivision" :key="item.id" class="align-middle" style="text-align: center;">
														<td class="cell">{{ item.entityId }}</td>
														<td class="cell">{{ item.id }}</td>
														<td class="cell">{{ item.name }}</td>
														<td class="cell" v-if="item.type == 0">Division</td>
														<td class="cell" v-if="item.type == 1">Coupe</td>
														<td class="cell">{{ item.minimumLevel }}</td>
														<td class="cell" v-if="item.mandatoryAssignment">Oui</td>
														<td class="cell" v-if="!item.mandatoryAssignment">Non</td>
														<td class="cell">
															<div v-if="item.importFBI">Oui</div>
															<div v-if="!item.importFBI">Non</div> /
															<div v-if="item.exportFBI">Oui</div>
															<div v-if="!item.exportFBI">Non</div>
														</td>
														<td class="cell">
															<b-button class="btn app-btn-secondary" style="margin: 3px" v-on:click="updateDivisionModal(item)">
																<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil" viewBox="0 0 16 16">
																	<path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
																</svg>
															</b-button>
															<b-button class="btn app-btn-secondary" style="margin: 3px" v-on:click="deleteDivisionModal(item)">
																<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
																	<path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
																	<path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
																</svg>
															</b-button>
														</td>
													</tr>
												</tbody>
												<tbody v-if="noResultDivision">
													<tr>
														<td colspan="6">Aucun résultat</td>
													</tr>
												</tbody>
											</table>
										</div>
									</div>
									<form class="app-search-form">
										<input name="search" type="text" id="myInput" @keyup="myFunction()" placeholder="Recherchez un nom, un prénom, un club" title="Recherche textuelle" class="form-control search-input" style="height: 50px; margin-bottom: 1em">
										<button disabled class="btn search-btn btn-primary" value="Search" style="padding-top: 1em">
											<svg class="svg-inline--fa fa-search fa-w-16" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="search" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
												<path fill="currentColor" d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"></path>
											</svg><!-- <i class="fas fa-search"></i> -->
										</button>
									</form>
								</div>

							</div>

						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>


<script>
	import jwt_decode from "jwt-decode";

export default {
	name: 'Division',
	data() {
		return {
			// Variable message alert
			dismissSecs: 4,
			alertSuccessCountDown: 0,
			alertWarningCountDown: 0,
			info:'',
			error:'',

			listInfoGroup: [],
			listInfoDivision: [],
			noResultGroup: false,
			noResultDivision: false,

			modalUpdateDivision: true,
			modalUpdateGroup: true,
			modalDeleteGroup: true,
            modalDeleteDivision: true,

			groupSelected: {},
			divisionSelected: {},

			idGroup:0,
			wordingGroup:"",
			distanceMax:0,

			// Info sur les modals
			idDivision:"",
			entityIdDivision:"",
			nameDivision:"",
			minimumLevelDivision:0,
			typeDivision:0,
			mandatoryAssignmentDivision: false,
			minimumAge: 0,
			matchIndemnity: 0,
			exportFBI: false,
			importFBI: false,
		}
	},

	mounted()
	{
		this.refresh();
    },
	

	methods: {
        refresh() {
            this.checkToken();
            let config = {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.token
                }
            };

            this.$axios.get('https://api.clickandball.fr/groupe', config)
                .then(response => {
                    if (response.status == 200 && response.data.error) {
                        this.showAlertWarning('Erreur : ' + response.data.error);
                    }
                    else if (response.status == 200) {
                        this.listInfoGroup = response.data;

                        if (this.listInfoGroup.length === 0) {
                            this.noResultGroup = true;
                        }
                    }
                    else {
                        this.showAlertWarning('Code erreur : ' + response.status);
                    }
                })

            this.$axios.get('https://api.clickandball.fr/division', config)
                .then(response => {
                    if (response.status == 200 && response.data.error) {
                        this.showAlertWarning('Erreur : ' + response.data.error);
                    }
                    else if (response.status == 200) {
                        this.listInfoDivision = response.data;

                        if (this.listInfoDivision.length === 0) {
                            this.noResultDivision = true;
                        }
                    }
                    else {
                        this.showAlertWarning('Code erreur : ' + response.status);
                    }
                })
        },

		// Méthodes message alert
		alertSuccessCountDownChanged(alertSuccessCountDown)
		{
			this.alertSuccessCountDown = alertSuccessCountDown
		},
		alertWarningCountDownChanged(alertWarningCountDown)
		{
			this.alertWarningCountDown = alertWarningCountDown
		},
		showAlertSuccess(info)
		{
			this.info = info;
			this.alertSuccessCountDown = this.dismissSecs
		},
		showAlertWarning(error)
		{
			this.error = error;
			this.alertWarningCountDown = this.dismissSecs
		},
		checkToken()
		{
			if (localStorage.token)
			{
				var jsonToken = jwt_decode(localStorage.token);
				if ((jsonToken.exp - 60) < Math.round(Date.now()/1000))
				{
					localStorage.token = null;
					this.connected = false;
					this.$router.push({ path: '/login'})
				}
			}
		},

		// Méthodes liées aux groupes
		addGroupModal()
		{
			this.$refs['modalGroup'].show();
			this.modalUpdateGroup = false;
            this.modalDeleteGroup = false;
		},
		addGroup()
		{
			this.checkToken();
			this.$refs['modalGroup'].hide();

			let json = {
				id:parseInt(this.idGroup),
				wording:this.wordingGroup,
				distanceMax:parseInt(this.distanceMax)
			};
			let config = {
				headers: {
					'Authorization': 'Bearer ' + localStorage.token,
				}
			};

			this.$axios.post('https://api.clickandball.fr/groupe', json, config)
				.then(response => {
					if (response.status == 200 && response.data.error)
					{
						this.showAlertWarning('Erreur : ' + response.data.error);
						
					}
					else if (response.status == 200)
					{
						this.showAlertSuccess("Groupe ajouté");
						this.apiGetInfoIsUpdate = false;
                        this.refresh();
					}
					else
					{
						this.showAlertWarning('Erreur : ' + response.statusText);
					}
				})
				.catch(error => {
					this.showAlertWarning('Erreur : ' + error);
				});
		},
        updateGroupModal(info) {
			this.checkToken();

            this.GroupSelected = info;
            this.$refs['modalGroup'].show();
			this.modalUpdateGroup = true;
            this.modalDeleteGroup = false;

            this.idGroup = info.id;
            this.wordingGroup = info.wording;
			this.distanceMax = info.distanceMax;
		},
        updateGroup() {
            this.checkToken();
			this.$refs['modalGroup'].hide();

			let newId = parseInt(this.idGroup)

            let json = {
				id: newId,
				wording: this.wordingGroup,
				distanceMax:this.distanceMax
			};
            let config = {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.token,
                }
            };

            this.$axios.put('https://api.clickandball.fr/groupe', json, config)
                .then(response => {
                    if (response.status == 200 && response.data.error) {
                        this.showAlertWarning('Erreur : ' + response.data.error);
                    }
                    else if (response.status == 200) {
                        this.showAlertSuccess("Groupe mis à jour");
						this.apiGetInfoIsUpdate = false;
                        this.refresh();
                    }
                    else {
                        this.showAlertWarning('Erreur : ' + response.statusText);
                    }
                })
                .catch(error => {
                    this.showAlertWarning('Erreur : ' + error);
                });
        },
        deleteGroupModal(info) {
            this.checkToken();

            this.GroupSelected = info;
			this.$refs['modalGroup'].show();
            this.modalUpdateGroup = false;
            this.modalDeleteGroup = true;

            this.idGroup = info.id;
            this.wordingGroup = info.wording;
			this.distanceMax = info.distanceMax;
        },
        deleteGroup() {
            this.checkToken();
            this.$refs['modalGroup'].hide();
            let config = {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.token,
                },
                data: {
                    "id": this.idGroup,
                }
            };

            this.$axios.delete('https://api.clickandball.fr/groupe', config)
                .then(response => {
                    if (response.status == 200 && response.data.error) {
                        this.showAlertWarning('Erreur : ' + response.data.error);
                    }
                    else if (response.status == 200) {
						this.showAlertSuccess("Groupe supprimé");
                        this.refresh();
                    }
                    else {
                        this.showAlertWarning('Code erreur : ' + response.status);
                    }
                })
                .catch(error => {
                    this.showAlertWarning('Erreur : ' + error);
                });
        },

		// Méthodes liées aux divisions/coupes
		addDivisionModal()
		{
			this.$refs['modalDivision'].show();
			this.modalUpdateDivision = false;
			this.modalDeleteDivision = false;

			this.idDivision = '';
			this.entityIdDivision = '';
			this.nameDivision = '';
			this.minimumLevelDivision = '',
			this.mandatoryAssignmentDivision = '',
			this.minimumAge = '';
			this.matchIndemnity = '';
			this.exportFBI = false;
			this.importFBI = false;
			this.typeDivision = 0;

		},
		addDivision()
		{
			this.checkToken();
			this.$refs['modalDivision'].hide();

			let json = {
				id:this.idDivision,
				entityId:this.entityIdDivision,
				name:this.nameDivision,
				minimumLevel:this.minimumLevelDivision,
				mandatoryAssignment: this.mandatoryAssignmentDivision,
				exportFBI: this.exportFBI,
				importFBI: this.importFBI,
				type:this.typeDivision
			};
			let config = {
				headers: {
					'Authorization': 'Bearer ' + localStorage.token,
				}
			};

			this.$axios.post('https://api.clickandball.fr/division', json, config)
				.then(response => {
					if (response.status == 200 && response.data.error)
					{
						this.showAlertWarning('Erreur : ' + response.data.error);
					}
					else if (response.status == 200)
					{
						this.showAlertSuccess("Division ajoutée");
						this.apiGetInfoIsUpdate = false;
                        this.refresh();
					}
					else
					{
						this.showAlertWarning('Erreur : ' + response.statusText);
					}
				})
				.catch(error => {
					this.showAlertWarning('Erreur : ' + error);
				});

		},
		updateDivisionModal(info) {
			this.checkToken();

			this.divisionSelected = info;
			this.$refs['modalDivision'].show();
			this.modalUpdateDivision = true;
			this.modalDeleteDivision = false;

			this.idDivision = info.id;
			this.entityIdDivision = info.entityId;
			this.nameDivision = info.name;
			this.minimumLevelDivision = info.minimumLevel;
			this.mandatoryAssignmentDivision = info.mandatoryAssignment;
			this.minimumAge = info.minimumAge;
			this.matchIndemnity = info.matchIndemnity;
			this.exportFBI = info.exportFBI;
			this.importFBI = info.importFBI;
			this.typeDivision = info.type;
		},
        updateDivision() {
            this.checkToken();
            this.$refs['modalDivision'].hide();

            let json = {
                id: this.idDivision,
                entityId: this.entityIdDivision,
                name: this.nameDivision,
                minimumLevel: this.minimumLevelDivision,
                mandatoryAssignment: this.mandatoryAssignmentDivision,
				exportFBI: this.exportFBI,
				importFBI: this.importFBI,
				type: this.type
            };
            let config = {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.token,
                }
            };

            this.$axios.put('https://api.clickandball.fr/division', json, config)
                .then(response => {
                    if (response.status == 200 && response.data.error) {
                        this.showAlertWarning('Erreur : ' + response.data.error);
                    }
                    else if (response.status == 200) {
                        this.showAlertSuccess("Division mise à jour");
						this.apiGetInfoIsUpdate = false;
                        this.refresh();
                    }
                    else {
                        this.showAlertWarning('Erreur : ' + response.statusText);
                    }
                })
                .catch(error => {
                    this.showAlertWarning('Erreur : ' + error);
                });
        },
		deleteDivisionModal(info) {
			this.checkToken();
			this.divisionSelected = info;
			this.$refs['modalDivision'].show();
            this.modalUpdateDivision = false;
			this.modalDeleteDivision = true;

            this.idDivision = info.id;
            this.entityIdDivision = info.entityId;
            this.nameDivision = info.name;
			this.minimumLevelDivision = info.minimumLevel;
			this.mandatoryAssignmentDivision = info.mandatoryAssignment;
            this.minimumAge = info.minimumAge;
            this.matchIndemnity = info.matchIndemnity;
        },
        deleteDivision() {
            this.checkToken();
            this.$refs['modalDivision'].hide();
            let config = {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.token,
                },
				data: {
					"id": this.divisionSelected.id,
					"entityId": this.divisionSelected.entityId
				}
            };

            this.$axios.delete('https://api.clickandball.fr/division', config)
                .then(response => {
                    if (response.status == 200 && response.data.error) {
                        this.showAlertWarning('Erreur : ' + response.data.error);
                    }
                    else if (response.status == 200) {
						this.showAlertSuccess("Division supprimée");
                        this.refresh();
                    }
                    else {
                        this.showAlertWarning('Code erreur : ' + response.status);
                    }
                })
                .catch(error => {
                    this.showAlertWarning('Erreur : ' + error);
                });
		},

		// Méthodes 
        myFunction: function () {
            var input, filter, table, tr;
            input = document.getElementById("myInput");
            filter = input.value.toUpperCase();
            table = document.getElementById("myTable2");
            tr = table.getElementsByTagName("tr");
            for (var i = 1; i < tr.length; i++) {
                var tds = tr[i].getElementsByTagName("td");
                var flag = false;
                for (var j = 0; j < tds.length; j++) {
                    var td = tds[j];
                    if (td.innerHTML.toUpperCase().indexOf(filter) > -1) {
                        flag = true;
                    }
                }
                if (flag) {
                    tr[i].style.display = "";
                }
                else {
                    tr[i].style.display = "none";
                }
            }
        }
		
	}
}
</script>

