<template>
	<div class="Home">
		<h1 class="app-page-title"><img src="https://img.icons8.com/ios-glyphs/50/000000/basketball--v1.png"/> Accueil</h1>

		<div class="row">
			<div class="col-12 col-sm-12 col-md-12 col-lg-8" style="margin-bottom: 0.75em">

				<div class="accordion3" role="tablist">
					<div class="app-card app-card-notification shadow-sm mb-4">
						<div block v-b-toggle.welcome type="button" data-toggle="" class="app-card-header px-4 py-3" header-tag="header" role="tab" style="background-color: rgba(0,0,0,.05) !important; border-bottom: 2px solid #fa7507">
							<div class="row g-3 align-items-center">
								<div class="col-12 col-lg-auto text-center text-lg-left">
									<div class="app-icon-holder" style="background-color: #fa7507">
										<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" class="bi bi-pc-display" viewBox="0 0 16 16">
											<path d="M8 1a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H9a1 1 0 0 1-1-1V1Zm1 13.5a.5.5 0 1 0 1 0 .5.5 0 0 0-1 0Zm2 0a.5.5 0 1 0 1 0 .5.5 0 0 0-1 0ZM9.5 1a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5ZM9 3.5a.5.5 0 0 0 .5.5h5a.5.5 0 0 0 0-1h-5a.5.5 0 0 0-.5.5ZM1.5 2A1.5 1.5 0 0 0 0 3.5v7A1.5 1.5 0 0 0 1.5 12H6v2h-.5a.5.5 0 0 0 0 1H7v-4H1.5a.5.5 0 0 1-.5-.5v-7a.5.5 0 0 1 .5-.5H7V2H1.5Z" />
										</svg>
									</div>

								</div>
								<div class="col-12 col-lg-auto text-center text-lg-left">
									<h4 class="notification-title mb-1">Bienvenue sur Click&Ball</h4>
								</div>
							</div>
						</div>
						<b-collapse visible id="welcome" accordion="my-accordion3" role="tabpanel">
							<div class="app-card-body p-4">
								<div class="notification-content" style="margin-bottom: -2em">
									<small>
										<strong>Depuis cette saison la commission Désignation des Officiels assure la gestion des désignations des officiels sur le territoire au niveau départemental.</strong><br><br>

										<p>Pour la saison 2024/2025, la commission Désignation des Officiels continue l'utilisation de la solution Click&Ball pour faciliter la gestion de ses désignations.</p>

										<p>Celui-ci vous permettra de gérer vos indisponibilités tout au long de la saison, ainsi que vos retours. Un calendrier vous permettra de centraliser l'ensemble de ces éléments</p>

										<div class="text-right" style="text-align:right;">
											<!--<img src="https://zupimages.net/up/21/42/yws7.jpg" style="margin-right: 1em; margin-bottom: 1em; height: 120px;">-->
											<h5 class="mb-2 text-primary">Romain LANGLET</h5>
											<small class="text-primary">Président Commission Désignation des Officiels</small>
										</div>
									</small>
								</div>
							</div>
							<hr />
							<div class="app-card-footer px-4 py-3" style="margin-top: -1em">
								<h5 class="mb-2 text-primary">Nous contacter :</h5>
								<small>Pour toute question, demande d'assistance, idée ou suggestion sur l'outil de designation veuillez nous-contacter de la façon suivante :</small><br><br />
								<small>Par courriel : <a href="mailto:clickandball@gmail.com">clickandball@gmail.com</a></small><br>
								<small class="text-primary">L'équipe Outil de Désignation</small>
							</div>
						</b-collapse>
					</div>
				</div>

				<div class="accordion4 col-md-10 offset-md-1" role="tablist">
					<div class="app-card app-card-notification shadow-sm mb-4" style="border: 1px solid rgb(47 87 136); border-radius: 0.25rem">
						<div block v-b-toggle.bug type="button" data-toggle="" class="app-card-header px-4 py-3" header-tag="header" role="tab" style="background-color: rgba(0,0,0,.05) !important;border-bottom: 2px solid rgb(47 87 136)">
							<div class="row g-3 align-items-center">
								<div class="col-12 col-lg-auto text-center text-lg-left">
									<div class="app-icon-holder" style="background-color: rgb(42, 87, 136)">
										<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" class="bi bi-bug" viewBox="0 0 16 16">
											<path d="M4.355.522a.5.5 0 0 1 .623.333l.291.956A4.979 4.979 0 0 1 8 1c1.007 0 1.946.298 2.731.811l.29-.956a.5.5 0 1 1 .957.29l-.41 1.352A4.985 4.985 0 0 1 13 6h.5a.5.5 0 0 0 .5-.5V5a.5.5 0 0 1 1 0v.5A1.5 1.5 0 0 1 13.5 7H13v1h1.5a.5.5 0 0 1 0 1H13v1h.5a1.5 1.5 0 0 1 1.5 1.5v.5a.5.5 0 1 1-1 0v-.5a.5.5 0 0 0-.5-.5H13a5 5 0 0 1-10 0h-.5a.5.5 0 0 0-.5.5v.5a.5.5 0 1 1-1 0v-.5A1.5 1.5 0 0 1 2.5 10H3V9H1.5a.5.5 0 0 1 0-1H3V7h-.5A1.5 1.5 0 0 1 1 5.5V5a.5.5 0 0 1 1 0v.5a.5.5 0 0 0 .5.5H3c0-1.364.547-2.601 1.432-3.503l-.41-1.352a.5.5 0 0 1 .333-.623zM4 7v4a4 4 0 0 0 3.5 3.97V7H4zm4.5 0v7.97A4 4 0 0 0 12 11V7H8.5zM12 6a3.989 3.989 0 0 0-1.334-2.982A3.983 3.983 0 0 0 8 2a3.983 3.983 0 0 0-2.667 1.018A3.989 3.989 0 0 0 4 6h8z" />
										</svg>
									</div>

								</div>
								<div class="col-12 col-lg-auto text-center text-lg-left">
									<div class="notification-type mb-2"><span class="badge bg-danger">Important</span></div>
									<h4 class="notification-title mb-1">Remontée d'anomalie(s)</h4>
								</div>
							</div>
						</div>
						<b-collapse visible id="bug" accordion="my-accordion4" role="tabpanel" class="app-card-body p-4">
							<div class="notification-content" style="margin-bottom: -2em">
								Si vous rencontrez des bugs, fautes de frappe, ou tout autre genre d'anomalie lors de l'utilisation de l'outil merci d'utiliser le formulaire suivant pour nous remonter directement les informations.
							</div>
						</b-collapse>
						<div class="app-card-footer px-4 py-3">
							<div style="text-align: center !important">
								<a class="action-link" href="">
									<a href="https://forms.gle/QLD6C5vDCVrnceoH7" class="btn app-btn-secondary">Lien du formulaire</a>
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
			
			<div class="col-12 col-sm-12 col-md-12 col-lg-4">
				<div class="accordion1" role="tablist">
					<div class="app-card app-card-notification shadow-sm mb-4">
						<div block v-b-toggle.important type="button" data-toggle="" class="app-card-header px-4 py-3" header-tag="header" role="tab" style="background-color: rgba(0,0,0,.05) !important;border-bottom: 2px solid rgb(47 87 136)">
							<div class="row g-3 align-items-center">
								<div class="col-12 col-lg-auto text-center text-lg-left">
									<div class="app-icon-holder" style="background-color: rgb(42, 87, 136)">
										<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" class="bi bi-bell" viewBox="0 0 16 16">
											<path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zM8 1.918l-.797.161A4.002 4.002 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4.002 4.002 0 0 0-3.203-3.92L8 1.917zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5.002 5.002 0 0 1 13 6c0 .88.32 4.2 1.22 6z" />
										</svg>
									</div>
								</div>
								<div class="col-12 col-lg-auto text-center text-lg-left">
									<h4 class="notification-title mb-1">Informations importantes</h4>
								</div>
							</div>
						</div>
						<b-collapse visible id="important" accordion="my-accordion" role="tabpanel" class="app-card-body p-4">
							<div class="notification-content" style="margin-bottom: -2em">
								<p>
									<small>
										N'oubliez pas de saisir vos indisponibilités 30 jours à l'avance. Mieux vaut saisir trop d'indisponibilités et les supprimer plus tard, que de ne rien saisir !
									</small>
								</p>
								<hr />
								<p>
									<small>
										Seuls les retours saisis sur Click&Ball seront pris en compte !
									</small>
								</p>
								<p>
									<small style="font-weight: bold">
										En cas de doute, seul <a href="https://extranet.ffbb.com/fbi/identification.do">FBI</a> fait foi.
									</small>
								</p>
							</div>
						</b-collapse>
					</div>
				</div>
				<div class="accordion2" role="tablist">
					<div class="app-card app-card-notification shadow-sm mb-4">
						<div block v-b-toggle.new type="button" data-toggle="" class="app-card-header px-4 py-3" header-tag="header" role="tab" style="background-color: rgba(0,0,0,.05) !important;border-bottom: 2px solid rgb(47 87 136)">
							<div class="row g-3 align-items-center">
								<div class="col-12 col-lg-auto text-center text-lg-left">
									<div class="app-icon-holder" style="background-color: rgb(42, 87, 136)">
										<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" class="bi bi-activity" viewBox="0 0 16 16">
											<path fill-rule="evenodd" d="M6 2a.5.5 0 0 1 .47.33L10 12.036l1.53-4.208A.5.5 0 0 1 12 7.5h3.5a.5.5 0 0 1 0 1h-3.15l-1.88 5.17a.5.5 0 0 1-.94 0L6 3.964 4.47 8.171A.5.5 0 0 1 4 8.5H.5a.5.5 0 0 1 0-1h3.15l1.88-5.17A.5.5 0 0 1 6 2Z" />
										</svg>
									</div>

								</div>
								<div class="col-12 col-lg-auto  text-center  text-lg-left">
									<div class="notification-type mb-2"><span class="badge bg-success">Nouveautés</span></div>
									<h4 class="notification-title mb-1">Version 1.4.0</h4>

									<ul class="notification-meta list-inline mb-0">
										<li class="list-inline-item">Version du 02/10/2022</li>
									</ul>

								</div>
							</div>
						</div>
						<b-collapse visible id="new" accordion="my-accordion2" role="tabpanel" class="app-card-body p-4">
							<div class="notification-content" style="margin-bottom: -2em">
								<ul style="list-style-type:square;">
									<li>
										Ajout de nofitications par mail
									</li>
								</ul>

							</div>
						</b-collapse>
						<!--<div class="app-card-footer px-4 py-3">
							<a class="action-link" href="">
								Voir le Changelog
								<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-arrow-right ml-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
									<path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
								</svg>
							</a>
						</div>-->
					</div>
				</div>
			</div>

		</div>
	</div>
</template>

<style>
    a:hover {
        color: #204065 !important;
    }

</style>

<script>
export default {
	name: 'Home',
	data() {
		return {

			}
	},
}
</script>