<template>
	<div class="Calendar">
		<h1 class="app-page-title"><img src="https://img.icons8.com/ios-glyphs/50/000000/basketball--v1.png"/> Mon calendrier</h1>
		<b-alert
				:show="alertSuccessCountDown"
				variant="success"
				@dismissed="alertSuccessCountDown=0"
				@dismiss-count-down="alertSuccessCountDownChanged"
				>
					<p>{{ info }}</p>
				<b-progress
					variant="success"
					:max="dismissSecs"
					:value="alertSuccessCountDown"
					height="4px"
					></b-progress>
			</b-alert>
			<b-alert
				:show="alertWarningCountDown"
				variant="warning"
				@dismissed="alertWarningCountDown=0"
				@dismiss-count-down="alertWarningCountDownChanged">
					<p>{{ error }}</p>
				<b-progress
					variant="warning"
					:max="dismissSecs"
					:value="alertWarningCountDown"
					height="4px"></b-progress>
			</b-alert>

			<!-- Modal indisponibilité -->
			<b-modal ref="modalUnavailabilities" hide-footer hide-header size="xl">
				<template #modal-title>
					<div v-if="unavailabilitiesUpdate">Modifier une indisponibilité</div>
					<div v-else>Ajouter une indisponibilité</div>
				</template>
				<div class="row" style="text-align: right; margin-top: -1em">
					<a style="float: right; z-index: 9999; margin-right: 0em; top: 0" href="#" block @click="$refs['modalUnavailabilities'].hide()">
						<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
							<path fill-rule="evenodd" d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z" />
							<path fill-rule="evenodd" d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z" />
						</svg>
					</a>
				</div>
				
				<div class="row align-middle" style="margin-bottom: -1.5em">
					
					<div class="col-lg-6 col-md-12 align-middle">
						
						<div class="app-card " style="margin-top: -1em; margin-left: -1em; margin-right: -1em; margin-bottom: 1em">
							<div class="app-card-header p-3 border-bottom-0">
								<div class="row align-items-center gx-3">
									<div class="col-auto">
										<div class="app-icon-holder" style="background-color: rgb(42, 87, 136)">
											<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-shield-check" fill="white" xmlns="http://www.w3.org/2000/svg">
												<path d="M8 7a.5.5 0 0 1 .5.5V9H10a.5.5 0 0 1 0 1H8.5v1.5a.5.5 0 0 1-1 0V10H6a.5.5 0 0 1 0-1h1.5V7.5A.5.5 0 0 1 8 7z" />
												<path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z" />
											</svg>
										</div>
									</div>
									<div class="col-auto">
										<h4 class="app-card-title" style="color: rgb(42, 87, 136) !important" v-if="unavailabilitiesUpdate">Modifier l'indisponibilité</h4>
										<h4 class="app-card-title" style="color: rgb(42, 87, 136) !important" v-else>Ajouter une indisponibilité</h4>
									</div>
								</div>
							</div>
						</div>
						<div class="app-card app-card-notification shadow-sm mb-4 align-middle" style="margin-top: -1em; margin-bottom: -1em">
							<div class="app-card-body p-4">
								<div class="notification-content">
									<div class="">
										<input type="checkbox" class="form-check-input" id="allDay" name="allDay" :state="allDay" v-model="allDay" style="margin: 0.4em;">
										<label for="allDay">Je suis indisponible toute la journée ?</label>
									</div>
									<hr />
									<div class="row">
										<div v-if="allDay == true" class="col-md-3">
										</div>
										<div class="col-md-6">
											<div class="form-group">
												<label for="dateStartInput" style="font-weight: bold;" class="form-label">Date<span v-if="allDay == false"> de début : </span></label>
												<b-form-input class="form-select-sm ml-auto d-inline-flex text-center" style="padding: .375rem 2.25rem .375rem .75rem; font-weight: 400; line-height: 1.5; height: 30px; border: 1px solid #ced4da; border-radius: .25rem; " :state="dateStartInputState" name="dateStartInput" v-model="dateStartInput" type="date" required></b-form-input>
											</div>
										</div>
										<div class="col-md-6" v-if="allDay == false">
											<div class="form-group">
												<label for="hourStartInput" style="font-weight: bold;" class="form-label">Heure de début : </label>
												<b-form-input class="form-select-sm ml-auto d-inline-flex text-center" style="padding: .375rem 2.25rem .375rem .75rem; font-weight: 400; line-height: 1.5; height: 30px; border: 1px solid #ced4da; border-radius: .25rem; " :state="hourStartInputState" name="hourStartInput" v-model="hourStartInput" type="time" required></b-form-input>
											</div>
										</div>
									</div>
									<div class="row" style="margin-top: 0.5em">
										<div class="col-md-6" v-if="allDay == false">
											<div class="form-group">
												<label for="dateEndInput" style="font-weight: bold;" class="form-label">Date de fin : </label>
												<b-form-input class="form-select-sm ml-auto d-inline-flex text-center" style="padding: .375rem 2.25rem .375rem .75rem; font-weight: 400; line-height: 1.5; height: 30px; border: 1px solid #ced4da; border-radius: .25rem; " :state="dateEndInputState" name="dateEndInput" v-model="dateEndInput" type="date" required></b-form-input>
											</div>
										</div>
										<div class="col-md-6" v-if="allDay == false">
											<div class="form-group tex">
												<label for="hourEndInput" style="font-weight: bold;" class="form-label">Heure de fin : </label>
												<b-form-input class="form-select-sm ml-auto d-inline-flex text-center" style="padding: .375rem 2.25rem .375rem .75rem; font-weight: 400; line-height: 1.5; height: 30px; border: 1px solid #ced4da; border-radius: .25rem; " :state="hourEndInputState" name="hourEndInput" v-model="hourEndInput" type="time" required></b-form-input>
											</div>
										</div>
									</div>
									<hr />
									<div v-if="newunavailabilities" style="margin-top: 0.5em">
										<div class="col-lg-12">
											<input type="checkbox" class="form-check-input" id="recursive" name="recursive" :state="recursive" v-model="recursive" style="margin: 0.4em; margin-bottom: 1em">
											<label for="recursive">Souhaitez vous ajouter une indisponibilité récurrente ?</label>
										</div>
									</div>
									<div v-if="unavailabilitiesUpdate == false && recursive == true" class="col-lg-12" style="margin-top: 0.5em">

										<div class="col-12">
											<div class="app-card shadow-sm mb-4 border-left-decoration">
												<div class="inner">
													<div class="app-card-body p-4">
														<div style="margin-bottom: 0.5em;">
															<label class="form-label" for="selectedPeriodicity" style="font-weight: bold; margin-bottom: 0.5em;">
																<small>Répéter l'indisponibilité tous les :</small>
															</label>

															<div class="mb-3 col-12 text-center" style="margin-top: 0.5em">
																<div class="form-check form-check-inline">
																	<ul style="list-style: none; display: block; padding: 0;">
																		<li style="float: left; display: block; margin-right: 0.75em; text-align: center;">
																			<input class="form-check-input" type="radio" name="selectedPeriodicity" v-model="selectedPeriodicity" id="radio-1" :value='1' />
																			<label for="radio-1" style="display: block;">1 jour</label>
																		</li>
																		<li style="float: left; display: block; margin-left: 0.75em; margin-right: 0.75em; text-align: center;">
																			<input class="form-check-input" type="radio" name="selectedPeriodicity" v-model="selectedPeriodicity" id="radio-2" :value='7' />
																			<label for="radio-2" style="display: block;">7 jours</label>
																		</li>
																		<li style="float: left; display: block; margin-left: 0.75em; margin-right: 0.75em; text-align: center;">
																			<input class="form-check-input" type="radio" name="selectedPeriodicity" v-model="selectedPeriodicity" id="radio-3" :value='14' />
																			<label for="radio-3" style="display: block;">14 jours</label>
																		</li>
																		<li style="float: left; display: block; margin-left: 0.75em; margin-right: 0.75em; text-align: center;">
																			<input class="form-check-input" type="radio" name="selectedPeriodicity" v-model="selectedPeriodicity" id="radio-4" :value='21' />
																			<label for="radio-4" style="display: block;">21 jours</label>
																		</li>
																		<li style="float: left; display: block; margin-left: 0.75em; text-align: center;">
																			<input class="form-check-input" type="radio" name="selectedPeriodicity" v-model="selectedPeriodicity" id="radio-5" :value='28' />
																			<label for="radio-5" style="display: block;">28 jours</label>
																		</li>
																	</ul>
																</div>
															</div>

														</div>
														<div class="form-group">
															<label class="form-label" for="endPeriodicity" style="font-weight: bold;">
																<small>Répéter l'indisponibilité jusqu'au :</small>
															</label>
															<b-form-input class="form-select-sm ml-auto d-inline-flex text-center" style="padding: .375rem 2.25rem .375rem .75rem; font-weight: 400; line-height: 1.5; height: 30px; border: 1px solid #ced4da; border-radius: .25rem; " :state="endPeriodicityState" name="endPeriodicity" v-model="endPeriodicity" type="date"></b-form-input>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="text-center" style="margin-top: -0.5em; margin-bottom: -0.5em">
								<b-button v-on:click="SuppressionIndispoModal()" class="btn app-btn-primary" style="background: rgb(42, 87, 136);color: white; width: 250px; margin: 0.5em;" v-if="unavailabilitiesUpdate">Supprimer</b-button>
								<b-button v-on:click="MajIndispo()" class="btn app-btn-primary" style="background: rgb(42, 87, 136);color: white; width: 250px; margin: 0.5em;" v-if="unavailabilitiesUpdate">Mise à jour</b-button>
								<b-button v-on:click="AjouterIndispo()" class="btn app-btn-primary" style="background: rgb(42, 87, 136);color: white; width: 250px; margin: 0.5em;" v-else>Enregistrer</b-button>
							</div>
						</div>
					</div>
					<div class="col-lg-6 align-middle">
						<div class="app-card app-card-notification shadow-sm mb-4" style="margin-top: -1em; margin-bottom: -1em; overflow: auto">
							<div class="app-card-header px-4 py-3">
								<div class="row g-3 align-items-center">
									<div class="col-12 col-lg-auto text-lg-left" style="; word-wrap: break-word;">
										<div class="notification-type mb-2" style="word-wrap: break-word;">
											<span class="badge bg-danger">
												Attention
											</span>
											<ul class="notification-meta list-inline mb-0" style="margin-top: 0.25em;">
												<li class="list-inline-item">
													<ul style="font-weight: bold; list-style-type: none; padding-left: 1em;">
														<li style="margin-top: 0.25em; word-wrap: break-word;">
															<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2 text-primary mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg" style="margin: 0.4em">
																<path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
															</svg>
															Si mon indisponibilité commence à 14h, je suis donc susceptible d’être désigné pour une rencontre dont l’horaire commencerait au plus tard à 13h59.
														</li>
														<li style="margin-top: 0.25em; word-wrap: break-word;">
															<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2 text-primary mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg" style="margin: 0.4em">
																<path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
															</svg>
															Si mon indisponibilité se termine à 16h00, je suis donc susceptible d’être désigné sur une rencontre qui commencerait au plus tôt à 16h01.
														</li>
														<li style="color: #fa7507; margin-top: 0.25em;">
															<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2 text-primary mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg" style="margin: 0.4em">
																<path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
															</svg>
															Il faut donc intégrer ces possibilités dans la saisie de l’heure de début et de fin de votre indisponibilité temporaire.
														</li>
													</ul>
													<span style="margin-top: 2em;" class="badge bg-info">Comment définir les horaires d'indisponibilité si je joue ou coach</span>
													<div style="margin-top: 1em;">
														<strong>
															Début d’indisponibilité
														</strong>
														<ul style="list-style-type: none; padding-left: 1em;">
															<li>
																<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2 text-primary mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg" style="margin: 0.4em">
																	<path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
																</svg>
																Comptez 2h par rencontre où vous seriez désigné.
															</li>
															<li>
																<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2 text-primary mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg" style="margin: 0.4em">
																	<path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
																</svg>
																Comptez 1h de déplacement.
															</li>
															<li>
																<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2 text-primary mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg" style="margin: 0.4em">
																	<path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
																</svg>
																Temps de préparation 30 minutes, avant le début de votre rencontre où vous jouez.
															</li>
														</ul>
														<strong>
															Fin d’indisponibilité
														</strong>
														<ul style="list-style-type: none; padding-left: 1em;">
															<li>
																<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2 text-primary mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg" style="margin: 0.4em">
																	<path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
																</svg>
																Comptez 2h pour la rencontre où vous jouez.
															</li>
															<li>
																<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2 text-primary mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg" style="margin: 0.4em">
																	<path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
																</svg>
																Temps de récupération 30 mn après match où vous jouez
															</li>
															<li>
																<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2 text-primary mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg" style="margin: 0.4em">
																	<path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
																</svg>
																Comptez 1h de déplacement retour.
															</li>
														</ul>
														<strong>
															Exemple
														</strong>
														<ul style="list-style-type: none; padding-left: 1em;">
															<li>
																<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2 text-primary mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg" style="margin: 0.4em">
																	<path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
																</svg>
																Horaire de la rencontre où vous jouez : 15h30
															</li>
															<li>
																<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2 text-primary mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg" style="margin: 0.4em">
																	<path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
																</svg>
																Début de l’indisponibilité : 15h30 – 1h - 2h – 0,5h = 12h00
															</li>
															<li>
																<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2 text-primary mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg" style="margin: 0.4em">
																	<path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
																</svg>
																Fin de l’indisponibilité : 15h30+2h+0,5h+1h = 19h00
															</li>
														</ul>
													</div>
												</li>
											</ul>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</b-modal>
			<!-- Modal validation suppression indispo -->
			<b-modal ref="modalValidationSuppressionIndispo" hide-footer hide-header size="lg">
				<div class="app-card " style="margin-top: -1em; margin-left: -1em; margin-right: -1em">
					<a style="float: right; z-index: 9999; margin-right: 0.5em; top: 0" href="#" block @click="$refs['modalValidationSuppressionIndispo'].hide()">
						<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
							<path fill-rule="evenodd" d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z" />
							<path fill-rule="evenodd" d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z" />
						</svg>
					</a>
					<div class="app-card-header p-3 border-bottom-0">
						<div class="row align-items-center gx-3">
							<div class="col-auto">
								<div class="app-icon-holder" style="background-color: rgb(42, 87, 136)">
									<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
										<path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
										<path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
									</svg>
								</div>
							</div>
							<div class="col-auto">
								<h4 class="app-card-title" style="color: rgb(42, 87, 136) !important">Valider la suppression de l'indisponibilité</h4>
							</div>
						</div>
					</div>
				</div>
				<p>
					Etes-vous sur de vouloir supprimer l'indisponibilité ?
				</p>
				<hr />
				<div class="intro mb-6 text-center" style="margin-top: 0.5em;">
					<b-button v-on:click="SuppressionIndispo()" class="btn app-btn-primary" style="background: rgb(42, 87, 136);color: white; width: 250px; margin: 0.5em;">Confirmer la suppression</b-button>
					<b-button block @click="$refs['modalValidationSuppressionIndispo'].hide()" class="btn app-btn-primary" style="background: rgb(42, 87, 136);color: white; width: 250px; margin: 0.5em;">Annuler</b-button>
				</div>
			</b-modal>

			<b-modal ref="modalAssignment" hide-footer hide-header size="lg">
				<template #modal-title>
					<div>Désignation - {{assignmentReturn.match.divisionId}} - {{assignmentReturn.match.id}}</div>
				</template>
				<div class="app-card " style="margin-top: -1em; margin-left: -1em; margin-right: -1em">
					<div class="app-card-header p-3 border-bottom-0">
						<a style="float: right; z-index: 9999; margin-right: 0.5em; top: 0" href="#" block @click="$refs['modalAssignment'].hide()">
							<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
								<path fill-rule="evenodd" d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z" />
								<path fill-rule="evenodd" d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z" />
							</svg>
						</a>
						<div class="row align-items-center gx-3">
							<div class="col-auto">
								<div class="app-icon-holder" style="background-color: rgb(42, 87, 136)">
									<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-shield-check" fill="white" xmlns="http://www.w3.org/2000/svg">
										<path fill-rule="evenodd" d="M4 2h7a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2zm0 1a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h7a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1H4z"></path>
										<path d="M6 0h7a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2v-1a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H6a1 1 0 0 0-1 1H4a2 2 0 0 1 2-2z"></path>
									</svg>
								</div>
							</div>
							<div class="col-auto">
								<h4 class="app-card-title" style="color: rgb(42, 87, 136) !important">Désignation - {{assignmentReturn.match.divisionId}} - {{assignmentReturn.match.id}}</h4>
							</div>
						</div>
					</div>
				</div>
				<div class="accordion" role="tablist">
					<div class="app-card app-card-notification shadow-sm mb-4">
						<div block v-b-toggle.match  id="title-legende" type="button" data-toggle="" class="app-card-header px-4 py-3" header-tag="header" role="tab" style="background-color: rgba(0,0,0,.05) !important; border-bottom: 2px solid rgb(47 87 136)">
							<div class="row g-3 align-items-center">
								<div class="col-12 col-lg-auto text-lg-left">
									<h4 class="notification-title mb-1" >
										<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="rgb(20 57 96)" class="bi bi-hand-index" viewBox="0 0 16 16">
											<path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
											<path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
										</svg><span style="color: rgb(20 57 96);"> Informations du match</span>
									</h4>
									<ul class="notification-meta list-inline mb-0" style="color:">
										<li class="list-inline-item">
											Informations liées à la rencontre
										</li>
									</ul>
								</div>
							</div>
						</div>
						<b-collapse visible id="match" accordion="my-accordion" role="tabpanel">
							<div class="app-card-body p-4">
								<div class="notification-content">
									<ul class="list-unstyled">
										<li class="listeInfoMatch">
											<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2 text-primary mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
												<path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
											</svg>
											<strong>
												Division :
											</strong>
											{{assignmentReturn.match.divisionId}} - {{assignmentReturn.match.id}}

											<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2 text-primary mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
												<path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
											</svg>
											<strong>
												Horaire :
											</strong>
											{{assignmentReturn.match.date}}
										</li>
									</ul>
									<hr />
									<ul class="list-unstyled">
										<li class="listeInfoMatch">
											<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2 text-primary mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
												<path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
											</svg>
											<strong>
												Salle :
											</strong>
											{{assignmentReturn.match.sportsHall.name}}
										</li>
										<li class="listeInfoMatch">
											<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2 text-primary mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
												<path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
											</svg>
											<strong>
												Adresse :
											</strong>
											{{assignmentReturn.match.sportsHall.address}}
										</li>
									</ul>
									<hr />
									<ul class="list-unstyled">
										<li class="listeInfoMatch">
											<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2 text-primary mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
												<path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
											</svg>
											<strong>
												Equipe A :
											</strong>
											{{assignmentReturn.match.homeClub.name}}
										</li>
										<li class="listeInfoMatch">
											<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2 text-primary mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
												<path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
											</svg>
											<strong>
												Equipe B :
											</strong>
											{{assignmentReturn.match.visitorClub.name}}
										</li>
									</ul>
								</div>
							</div>
						</b-collapse>
					</div>
					<div class="app-card app-card-notification shadow-sm mb-4">
						<div block v-b-toggle.referee type="button" data-toggle=""   class="app-card-header px-4 py-3" header-tag="header" role="tab" style="background-color: rgba(0,0,0,.05) !important; border-bottom: 2px solid rgb(47 87 136)">
							<div class="row g-3 align-items-center">
								<div class="col-12 col-lg-auto text-lg-left">
									<h4 class="notification-title mb-1" id="title-legende" style="color: rgb(20 57 96);">
										<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-hand-index" viewBox="0 0 16 16">
											<path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1h8zm-7.978-1A.261.261 0 0 1 7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002a.274.274 0 0 1-.014.002H7.022zM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0zM6.936 9.28a5.88 5.88 0 0 0-1.23-.247A7.35 7.35 0 0 0 5 9c-4 0-5 3-5 4 0 .667.333 1 1 1h4.216A2.238 2.238 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816zM4.92 10A5.493 5.493 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275zM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0zm3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4z" />
										</svg> Arbitres
									</h4>
									<ul class="notification-meta list-inline mb-0">
										<li class="list-inline-item">
											Informations sur les arbitres de la rencontre
										</li>
									</ul>
								</div>
							</div>
						</div>
						<b-collapse id="referee" accordion="my-accordion" role="tabpanel">
							<div class="app-card app-card-account shadow-sm d-flex flex-column align-items-start col-md-12">
								<div class="app-card-body px-4 w-100">
									<div class="item border-bottom py-3">
										<div class="row justify-content-center align-items-center text-center" style="margin-bottom: 0.5em">
											<strong>Arbitre 1</strong>
										</div>
										<div class="row justify-content-around align-items-center" style="margin-bottom: 0.5em">
											{{assignmentReturn.referee1.firstName}} {{assignmentReturn.referee1.lastName}}
										</div>
										<div class="row justify-content-around align-items-center text-center" style="margin-bottom: 0.5em">
											<div v-if="assignmentReturn.referee1.email" class="col-auto">
												<div class="item-label">
													<strong>Email</strong>
												</div>
												<div class="item-data">
													{{assignmentReturn.referee1.email}}
												</div>
											</div>
											<div v-else class="col-auto">
												<div class="item-label">
													<strong>Email</strong>
												</div>
												<div class="item-data">
													<span style="color: darkred">L'arbitre n'a pas d'email de renseigné</span>
												</div>
											</div>
											<div v-if="assignmentReturn.referee1.phone" class="col-auto">
												<div class="item-label">
													<strong>N° de téléphone</strong>
												</div>
												<div class="item-data">
													{{assignmentReturn.referee1.phone}}
												</div>
											</div>
											<div v-else class="col-auto">
												<div class="item-label">
													<strong>N° de téléphone</strong>
												</div>
												<div class="item-data">
													<span style="color: darkred">L'arbitre n'a pas de numéro de téléphone de renseigné</span>
												</div>
											</div>
											<div v-if="assignmentReturn.referee1.city" class="col-auto">
												<div class="item-label">
													<strong>Ville</strong>
												</div>
												<div class="item-data">
													{{assignmentReturn.referee1.city}}
												</div>
											</div>
											<div v-else class="col-auto">
												<div class="item-label">
													<strong>Ville</strong>
												</div>
												<div class="item-data">
													<span style="color: darkred">L'arbitre n'a pas d'adresse de renseignée</span>
												</div>
											</div>
										</div>
										<hr />
										<div class="row justify-content-center align-items-center text-center" style="margin-bottom: 0.5em">
											<strong>Arbitre 2</strong>
										</div>
										<div class="row justify-content-around align-items-center text-center" style="margin-bottom: 0.5em">
											{{assignmentReturn.referee2.firstName}} {{assignmentReturn.referee2.lastName}}
										</div>
										<div class="row justify-content-around align-items-center text-center">
											<div v-if="assignmentReturn.referee2.email" class="col-auto">
												<div class="item-label">
													<strong>Email</strong>
												</div>
												<div class="item-data">
													{{assignmentReturn.referee2.email}}
												</div>
											</div>
											<div v-else class="col-auto">
												<div class="item-label">
													<strong>Email</strong>
												</div>
												<div class="item-data">
													<span style="color: darkred">L'arbitre n'a pas d'email de renseigné</span>
												</div>
											</div>
											<div v-if="assignmentReturn.referee2.phone" class="col-auto">
												<div class="item-label">
													<strong>N° de téléphone</strong>
												</div>
												<div class="item-data">
													{{assignmentReturn.referee2.phone}}
												</div>
											</div>
											<div v-else class="col-auto">
												<div class="item-label">
													<strong>N° de téléphone</strong>
												</div>
												<div class="item-data">
													<span style="color: darkred">L'arbitre n'a pas de numéro de téléphone de renseigné</span>
												</div>
											</div>
											<div v-if="assignmentReturn.referee2.city" class="col-auto">
												<div class="item-label">
													<strong>Ville</strong>
												</div>
												<div class="item-data">
													{{assignmentReturn.referee2.city}}
												</div>
											</div>
											<div v-else class="col-auto">
												<div class="item-label">
													<strong>Ville</strong>
												</div>
												<div class="item-data">
													<span style="color: darkred">L'arbitre n'a pas d'adresse de renseignée</span>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</b-collapse>
					</div>
				</div>
				<div class="intro mb-6 text-center" style="margin-top: 0.5em;">
					<b-button v-on:click="showAddReturn()" class="btn app-btn-primary" style="background: rgb(42, 87, 136);color: white; width: 250px; margin: 0.5em;">Faire un retour</b-button>
				</div>
			</b-modal>

			<b-modal ref="modalReturn" hide-footer hide-header size="lg">
				<template #modal-title>
					<div v-if="returnUpdate">Mise à jour du retour</div>
					<div v-else>Ajouter un retour</div>
				</template>
				<div class="app-card " style="margin-top: -1em; margin-left: -1em; margin-right: -1em">
					<a style="float: right; z-index: 9999; margin-right: 0.5em; top: 0" href="#" block @click="$refs['modalReturn'].hide()">
						<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
							<path fill-rule="evenodd" d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z" />
							<path fill-rule="evenodd" d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z" />
						</svg>
					</a>
					<div class="app-card-header p-3 border-bottom-0">
						<div class="row align-items-center gx-3">
							<div class="col-auto">
								<div class="app-icon-holder" style="background-color: rgb(42, 87, 136)">
									<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-shield-check" fill="white" xmlns="http://www.w3.org/2000/svg">
										<path d="M9.302 1.256a1.5 1.5 0 0 0-2.604 0l-1.704 2.98a.5.5 0 0 0 .869.497l1.703-2.981a.5.5 0 0 1 .868 0l2.54 4.444-1.256-.337a.5.5 0 1 0-.26.966l2.415.647a.5.5 0 0 0 .613-.353l.647-2.415a.5.5 0 1 0-.966-.259l-.333 1.242-2.532-4.431zM2.973 7.773l-1.255.337a.5.5 0 1 1-.26-.966l2.416-.647a.5.5 0 0 1 .612.353l.647 2.415a.5.5 0 0 1-.966.259l-.333-1.242-2.545 4.454a.5.5 0 0 0 .434.748H5a.5.5 0 0 1 0 1H1.723A1.5 1.5 0 0 1 .421 12.24l2.552-4.467zm10.89 1.463a.5.5 0 1 0-.868.496l1.716 3.004a.5.5 0 0 1-.434.748h-5.57l.647-.646a.5.5 0 1 0-.708-.707l-1.5 1.5a.498.498 0 0 0 0 .707l1.5 1.5a.5.5 0 1 0 .708-.707l-.647-.647h5.57a1.5 1.5 0 0 0 1.302-2.244l-1.716-3.004z" />
									</svg>
								</div>
							</div>
							<div class="col-auto">
								<h4 class="app-card-title" style="color: rgb(42, 87, 136) !important" v-if="returnUpdate">Mise à jour du retour ({{assignmentReturn.id}})</h4>
								<h4 class="app-card-title" style="color: rgb(42, 87, 136) !important" v-else>Ajout d'un retour</h4>
							</div>
						</div>
					</div>
				</div>
				<p v-if="showDropdownReturn">
					<span style="font-weight: bold;margin-right: 0.25em">Match : </span>

					<b-form-select class="form-select form-select-sm ml-auto d-inline-flex w-auto" v-model="selectedMatchReturn" name="selectedMatchReturn">
						<b-form-select-option :value='null'>Choisir un match</b-form-select-option>

						<b-form-select-option v-for="assignment in assignmentList" :key="assignment.id" :value="assignment.id">{{ assignment.match.divisionId }} : {{ assignment.match.date }}</b-form-select-option>
					</b-form-select>
				</p>
				<p v-else>
					<span style="font-weight: bold;">Match : </span>{{assignmentReturn.match.divisionId}} - {{assignmentReturn.match.id}}
				</p>
				<div class="">
					<b-form-textarea name="motifReturnInput" v-model="motifReturnInput" placeholder="Saisir un motif..." :maxlength="500" rows="3" max-rows="3" style="max-height: 175px"></b-form-textarea>
					<div class="d-flex justify-content-end" style="margin-bottom: 0.25em; padding-top: 0.25em; font-style: oblique; font-size: 12px">
						<span v-text="(500 - motifReturnInput.length)"></span><span style="margin-left: 0.2em">caractère(s) restant(s)</span>
					</div>

				</div>
				<hr />
				<!-- Ajout de justificatif -->
				<div class="col-12" v-if="!assignmentReturn.file.fileSave">
				<!-- <div class="col-12"> -->
					<div class="app-card shadow-sm mb-4 border-left-decoration">
						<div class="inner">
							<div class="app-card-body p-4">
								<div class="col-12">
									<div>
										<span style="font-weight: bold">Justificatif</span><br />
										<span><small style="font-size: 12px; color: rgb(234 104 11)">Le justificatif doit être envoyé dans un délai maximal de 24h après la date du match concerné</small></span>
									</div>
									<hr />
									<label>
										<input type="file" id="file" ref="file" accept="image/*,.pdf" v-on:change="handleFileUpload()" />
									</label>
								</div>
								<hr />
								<div class="row align-items-center" style="margin-top: 0.75em">
									<div class="col-1" style="text-align: center">
										<input type="checkbox" class="form-check-input" id="sensible" name="sensible" :state="acceptUploadFile" v-model="acceptUploadFile">
									</div>
									<div class="col-11">
										<small>
											J'accepte que le justificatif fourni soit importé dans le logiciel automatisé Click&Ball
										</small>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="intro mb-6 text-center" style="margin-top: 0.5em;">
					<b-button v-if="returnUpdate" v-on:click="updateReturn()" class="btn app-btn-primary" style="background: rgb(42, 87, 136);color: white; width: 250px; margin: 0.5em;">Mettre à jour</b-button>
					<b-button v-else v-on:click="saveReturn()" class="btn app-btn-primary" style="background: rgb(42, 87, 136);color: white; width: 250px; margin: 0.5em;">Enregistrer</b-button>
				</div>
			</b-modal>

			<!-- Page calendrier -->
			<div class="row">
				<div class="col-md-3">

					<div class="accordion12" role="tablist">
						<div class="app-card app-card-notification shadow-sm mb-4">
							<div class="app-card-header px-4 py-3" header-tag="header" role="tab" style="background-color: rgba(0,0,0,.05); border-bottom: 2px solid rgb(234 104 11)">
								<div class="row g-3 align-items-center">
									<div class="col-12 col-lg-auto text-center text-lg-left">
										<h4 block v-b-toggle.ajouts class="notification-title mb-1" id="title-legende" type="button" data-toggle="" style="color: rgb(234 104 11)">
											<i class='fas fa-basketball-ball fa-spin'></i><span style="color:"> Ajouts</span>
										</h4>
									</div>
								</div>
							</div>
							<b-collapse visible id="ajouts" accordion="my-accordion12" role="tabpanel">
								<div class="app-card-body p-4">
									<div class="notification-content text-center" style="margin-top: -1em;margin-bottom: -1em">
										<b-button type="button" v-on:click="AjouterIndispoModal()" class="btn app-btn-primary col-md-12 col-xs-12 col-12" style="max-width: 225px !important; margin: 0.5em; margin-bottom: 0.5em;">Ajouter une indisponibilité</b-button>
										<b-button type="button" v-on:click="showAddReturnDropdown()" class="btn app-btn-primary col-md-12 col-xs-12 col-12" style="max-width: 225px !important; margin: 0.5em; margin-bottom: 0.5em; ">Ajouter un retour</b-button>
									</div>
								</div>
							</b-collapse>
						</div>
					</div>

					<div class="accordion13" role="tablist">
						<div class="app-card app-card-notification shadow-sm mb-4">
							<div class="app-card-header px-4 py-3" header-tag="header" role="tab" style="background-color: rgba(0,0,0,.05); border-bottom: 2px solid rgb(234 104 11)">
								<div class="row g-3 align-items-center">
									<div class="col-12 col-lg-auto text-center text-lg-left">
										<h4 block v-b-toggle.affichage class="notification-title mb-1" id="title-legende" type="button" data-toggle="" style="color: rgb(234 104 11)">
											<i class='fas fa-basketball-ball fa-spin'></i><span style="color:"> Affichage</span>
										</h4>
									</div>
								</div>
							</div>
							<b-collapse visible id="affichage" accordion="my-accordion13" role="tabpanel">
								<div class="app-card-body p-4">
									<div class="notification-content text-center" style="margin-top: -1em;margin-bottom: -1em">
										<b-button v-on:click="checkShowUnavailabilities()" class="btn app-btn-primary col-md-12 col-xs-12 col-12" style="background: rgb(42, 87, 136); color: white; max-width: 225px !important; margin: 0.5em; margin-bottom: 0.5em; " v-if="showUnavailabilities">Voir mes indisponibilités</b-button>
										<b-button v-on:click="checkShowUnavailabilities()" class="btn app-btn-primary col-md-12 col-xs-12 col-12" style="background: rgb(107 114 114); color: white; max-width: 225px !important; margin: 0.5em; margin-bottom: 0.5em; " v-else>Cacher mes indisponibilités</b-button>
										<b-button v-on:click="checkShowAssignment()" class="btn app-btn-primary col-md-12 col-xs-12 col-12" style="background: rgb(42, 87, 136); color: white; max-width: 225px !important; margin: 0.5em; margin-bottom: 0.5em; " v-if="showAssignment">Voir mes désignations</b-button>
										<b-button v-on:click="checkShowAssignment()" class="btn app-btn-primary col-md-12 col-xs-12 col-12" style="background: rgb(107 114 114); color: white; max-width: 225px !important; margin: 0.5em; margin-bottom: 0.5em; " v-else>Cacher mes désignations</b-button>
										<b-button v-on:click="checkShowReturn()" class="btn app-btn-primary col-md-12 col-xs-12 col-12" style="background: rgb(42, 87, 136); color: white; max-width: 225px !important; margin: 0.5em; margin-bottom: 0.5em; " v-if="showReturn">Voir mes retours</b-button>
										<b-button v-on:click="checkShowReturn()" class="btn app-btn-primary col-md-12 col-xs-12 col-12" style="background: rgb(107 114 114); color: white; max-width: 225px !important; margin: 0.5em; margin-bottom: 0.5em; " v-else>Cacher mes retours</b-button>
									</div>
								</div>
							</b-collapse>
						</div>
					</div>

				</div>
				<div class="col-md-9">
					<div class="app-card app-card-notification shadow-sm mb-4" style="border-radius: 0.25rem">
						<div class="app-card-header px-4 py-3" style="background-color: rgba(0,0,0,.05); border-bottom: 2px solid rgb(47 87 136);">
							<div class="row g-3 align-items-center">
								<div class="col-12 col-lg-auto text-lg-left">
									<h4 class="notification-title mb-1" id="title-legende" style="color: rgb(42, 87, 136)">
										<i class="fa fa-calendar" style="font-size:30px"></i><span style="color:"> Calendrier</span>
									</h4>
									<ul class="notification-meta list-inline mb-0" style="color:">
										<li class="list-inline-item">
											Pour faciliter la vue des informations sur mobile, utiliser le mode "Planning"
										</li>
									</ul>
								</div>
							</div>
						</div>
						<div class="app-card-body p-4">
							<div class="notification-content text-center" style="margin-top: -1em;margin-bottom: -1em">
								<FullCalendar :options="calendarOptions" ref="fullCalendar" />
							</div>
						</div>
					</div>
				</div>
			</div>

	</div>

</template>

<style>
    .fc-toolbar {
        text-transform: capitalize;
    }
    .listeInfoMatch {
        margin: 0.5em
    }
</style>

<script>
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction'
import frLocale from '@fullcalendar/core/locales/fr';
import jwt_decode from "jwt-decode";

export default {
	name: 'Calendar',
	components: {
		FullCalendar
	},
	computed:
	{
		dateStartInputState: function()
		{
			var reDate = /^(20\d\d)-([1-9]|0\d|1[0-2])-([1-9]|[0-2]\d|3[0-1])$/;
			if (reDate.test(this.dateStartInput)) { return true; }
			else { return false; }
		},
		dateEndInputState: function()
		{
			var reDate = /^(20\d\d)-([1-9]|0\d|1[0-2])-([1-9]|[0-2]\d|3[0-1])$/;
			if (reDate.test(this.dateEndInput)) { return true; }
			else { return false; }
		},
		hourStartInputState: function()
		{
			var reHour = /^([0-1]?\d|2[0-3]):([0-9]|[0-5]\d)$/;
			if (reHour.test(this.hourStartInput)) { return true; }
			else { return false; }
		},
		hourEndInputState: function()
		{
			var reHour = /^([0-1]?\d|2[0-3]):([0-9]|[0-5]\d)$/;
			if (reHour.test(this.hourEndInput)) { return true; }
			else { return false; }
		},
        endPeriodicityState: function () {
			var reDate = /^(20\d\d)-([1-9]|0\d|1[0-2])-([1-9]|[0-2]\d|3[0-1])$/;
			if (reDate.test(this.endPeriodicity)) { return true; }
            else { return false; }
        }
	},
	data() {
		return {
			// Variable message alert
			dismissSecs: 4,
			alertSuccessCountDown: 0,
			alertWarningCountDown: 0,
			info:'',
			error:'',

			calendarOptions: {
				plugins: [ dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin ],
				initialView: 'dayGridMonth',
				weekNumbers: true,
				selectable: true,
				select: this.getClickInfo,
				eventClick: this.SelectionModalAProjeter,
				datesSet: this.checkShowEvents,
				events: [],
				locale:frLocale ,
				firstDay:1,
				headerToolbar: {
					left: 'dayGridMonth,listMonth',
					center: 'title',
					right: 'prevYear,prev,today,next,nextYear'
				}
			},
			showAssignment:true,
			showUnavailabilities:true,
			showReturn:true,
			dateStartInput:'',
			hourStartInput:'',
			dateEndInput:'',
			hourEndInput: '',
			allDay: false,
			newunavailabilities: false,
			recursive: false,
			selectedPeriodicity: '',
			endPeriodicity: '',
			unavailabilities:[],
			assignmentList:[],
			assignmentReturn:{
				"id":'',
				"referee1":{},
				"referee2":{},
				"match":{
					"sportsHall":{},
					"homeClub":{},
					"visitorClub":{}
				},
				"file":{
					"fileSave": false
				}
			},
			motifReturnInput:'',
			unavailabilitiesUpdate:false,
			returnUpdate:false,
			unavailabilitiesId:0,
			showDropdownReturn:true,
			selectedMatchReturn:'null',

			listShowAssignment:[],
			listShowUnavailabilities:[],
			listShowReturn: [],

			file: '',
			acceptUploadFile: false,
		}
	},

	methods: {
		// Méthodes message alert
		alertSuccessCountDownChanged(alertSuccessCountDown)
		{
			this.alertSuccessCountDown = alertSuccessCountDown
		},
		alertWarningCountDownChanged(alertWarningCountDown)
		{
			this.alertWarningCountDown = alertWarningCountDown
		},
		showAlertSuccess(info)
		{
			this.info = info;
			this.alertSuccessCountDown = this.dismissSecs
		},
		showAlertWarning(error)
		{
			this.error = error;
			this.alertWarningCountDown = this.dismissSecs
		},
		checkToken()
		{
			if (localStorage.token)
			{
				var jsonToken = jwt_decode(localStorage.token);
				if ((jsonToken.exp - 60) < Math.round(Date.now()/1000))
				{
					localStorage.token = null;
					this.connected = false;
					this.$router.push({ path: '/login'})
				}
			}
		},

		// Méthodes de gestion de date
		getDateHourString(date)
		{
			// prints date & time in YYYY-MM-DD HH:MM:SS format
			return date.getFullYear() + "-" + ("0" + (date.getMonth() + 1)).slice(-2) + "-" + ("0" + date.getDate()).slice(-2) + " " + date.getHours() + ":" + date.getMinutes() + ":" + ("0" + date.getSeconds()).slice(-2);
		},
		getDateString(date)
		{
			// prints date & time in YYYY-MM-DD format
			return date.getFullYear() + "-" + ("0" + (date.getMonth() + 1)).slice(-2) + "-" + ("0" + date.getDate()).slice(-2);
		},
		getFirstDayMonth()
		{
			let date = new Date();
			return date.getFullYear() + "-" + ("0" + (date.getMonth() + 1)).slice(-2) + "-01";
		},


		checkShowAssignment()
		{
			this.checkToken();
			if (this.showAssignment)
			{
				let calendarApi = this.$refs.fullCalendar.getApi();
				let info = {};

				info.start = calendarApi.view.activeStart;
				info.end = calendarApi.view.activeEnd;
				
				this.showAssignment = false;
				this.checkShowEvents(info);
			}
			else
			{
				this.showAssignment = true;
				this.assignmentList = [];
				this.listShowAssignment = [];

				let newEvents = [];
				for (var i = 0; i < this.calendarOptions.events.length; i++)
				{
					if (this.calendarOptions.events[i].type != 1)
					{
						newEvents.push(this.calendarOptions.events[i]);
					}
				}
				this.calendarOptions.events = newEvents; 
			}
		},
		checkShowUnavailabilities()
		{
			this.checkToken();
			if (this.showUnavailabilities)
			{
				let calendarApi = this.$refs.fullCalendar.getApi();
				let info = {};

				info.start = calendarApi.view.activeStart;
				info.end = calendarApi.view.activeEnd;
				
				this.showUnavailabilities = false;
				this.checkShowEvents(info);
			}
			else
			{
				this.showUnavailabilities = true;
				this.listShowUnavailabilities = [];

				let newEvents = [];
				for (var i = 0; i < this.calendarOptions.events.length; i++)
				{
					if (this.calendarOptions.events[i].type != 2)
					{
						newEvents.push(this.calendarOptions.events[i]);
					}
				}
				this.calendarOptions.events = newEvents; 
			}
		},
		checkShowReturn()
		{
			this.checkToken();
			if (this.showReturn)
			{
				let calendarApi = this.$refs.fullCalendar.getApi();
				let info = {};

				info.start = calendarApi.view.activeStart;
				info.end = calendarApi.view.activeEnd;
				
				this.showReturn = false;
				this.checkShowEvents(info);
			}
			else
			{
				this.showReturn = true;
				this.listShowReturn = [];

				let newEvents = [];
				for (var i = 0; i < this.calendarOptions.events.length; i++)
				{
					if (this.calendarOptions.events[i].type != 3)
					{
						newEvents.push(this.calendarOptions.events[i]);
					}
				}
				this.calendarOptions.events = newEvents; 
			}
		},
		
		getClickInfo: function (arg1) {
			var start = arg1.startStr;
			start = new Date(start);
			start = this.getDateString(start);
			var startHour = '00:00';
			var end = arg1.endStr;
			end = new Date(end);
			end.setDate(end.getDate() - 1);
			end = this.getDateString(end)
			var endHour = '23:59';
			this.dateStartInput = start;
			this.hourStartInput = startHour;
			this.dateEndInput = end;
			this.hourEndInput = endHour;
			this.unavailabilitiesUpdate = false;
			this.newunavailabilities = true;
			this.$refs['modalUnavailabilities'].show();
        },

		// Méthode gestion des modals à partir d'un click sur le calendrier
		SelectionModalAProjeter(info)
		{
			this.checkToken();
			if (info.event.extendedProps.type == 1) // désignation - on visualise la désignation
			{
				for (let i = 0; i < this.assignmentList.length; i++)
				{
					if (this.assignmentList[i].id == info.event.id)
					{
						this.assignmentReturn = this.assignmentList[i];
						this.assignmentReturn.file = {};
					}
				}
				this.$refs['modalAssignment'].show();
			}
			else if (info.event.extendedProps.type == 2) // indisponibilité - on affiche la modal update indispo
			{
				this.unavailabilitiesUpdate = true;
				this.newunavailabilities = false;
				
				this.unavailabilitiesId = info.event.id;
				this.dateStartInput = info.event.start.getFullYear() + "-" + ("0" + (info.event.start.getMonth() + 1)).slice(-2) + "-" + ("0" + (info.event.start.getDate())).slice(-2);
				this.hourStartInput = ("0" + (info.event.start.getHours())).slice(-2) + ":" + ("0" + (info.event.start.getMinutes())).slice(-2);
				this.dateEndInput = info.event.end.getFullYear() + "-" + ("0" + (info.event.end.getMonth() + 1)).slice(-2) + "-" + ("0" + (info.event.end.getDate())).slice(-2);
				this.hourEndInput = ("0" + (info.event.end.getHours())).slice(-2) + ":" + ("0" + (info.event.end.getMinutes())).slice(-2);
				if (this.hourStartInput == '00:00' && this.hourEndInput == '23:59' && this.dateStartInput == this.dateEndInput) {
                    this.allDay = true;
                }
				else {
                    this.allDay = false;
                }
				this.$refs['modalUnavailabilities'].show();
			}
			else if (info.event.extendedProps.type == 3) // retour - on affiche la modal update return
			{
				this.returnUpdate = true;
				this.showDropdownReturn = false;
				this.acceptUploadFile = false;
				this.assignmentReturn.id = info.event.id;
				this.assignmentReturn.match.id = info.event.extendedProps.matchId;
				this.assignmentReturn.match.divisionId = info.event.extendedProps.division;
				this.assignmentReturn.file = info.event.extendedProps.file;
				this.motifReturnInput = info.event.extendedProps.comment;

				this.$refs['modalReturn'].show();
			}
		},

		// Méthodes liées aux indispo
		AjouterIndispoModal()
		{
			this.checkToken();
			this.unavailabilitiesUpdate = false;
			this.newunavailabilities = true;
			this.$refs['modalUnavailabilities'].show();
		},
		AjouterIndispo()
		{
			this.checkToken();
			const dateStartInput = document.querySelector('input[name="dateStartInput"]');
			const hourStartInput = document.querySelector('input[name="hourStartInput"]');
			const dateEndInput = document.querySelector('input[name="dateEndInput"]');
			const hourEndInput = document.querySelector('input[name="hourEndInput"]');
			
			var reDate = /^(20\d\d)-([1-9]|0\d|1[0-2])-([1-9]|[0-2]\d|3[0-1])$/;
			var reHour = /^([0-1]?\d|2[0-3]):([0-9]|[0-5]\d)$/;
			
			if (this.allDay == true) {
				this.hourStartInput = '00:00',
				this.dateEndInput = this.dateStartInput,
				this.hourEndInput = '23:59'
            }

			if (!reDate.test(this.dateStartInput))
			{
				dateStartInput.setCustomValidity("Veuillez saisir une date de début");
			}
			if (!reDate.test(this.dateEndInput))
			{
				dateEndInput.setCustomValidity("Veuillez saisir une date de fin");
			}
			if (!reHour.test(this.hourStartInput))
			{
				hourStartInput.setCustomValidity("Veuillez saisir une heure de début");
			}
			if (!reHour.test(this.hourEndInput))
			{
				hourEndInput.setCustomValidity("Veuillez saisir une heure de fin");
			}		
            if (reDate.test(this.dateStartInput) && reDate.test(this.dateEndInput) && reHour.test(this.hourStartInput) && reHour.test(this.hourEndInput)) {
                if (this.recursive) {
                    if (this.endPeriodicity) {
                        if (this.dateStartInput < this.endPeriodicity && this.dateEndInput < this.endPeriodicity) {
                            if (this.selectedPeriodicity != 0) {
                                let n = 0;
                                this.unavailabilities = [];

                                var fullDateStart = this.getDateHourString(new Date(this.dateStartInput + "T" + this.hourStartInput));
                                var fullDateEnd = this.getDateHourString(new Date(this.dateEndInput + "T" + this.hourEndInput));
                                this.unavailabilities.push(n, fullDateStart, fullDateEnd);

                                n++;


                                while (this.dateStartInput < this.endPeriodicity) {
                                    var start = new Date(this.dateStartInput);
                                    var end = new Date(this.dateEndInput);
                                    start.setDate(start.getDate() + this.selectedPeriodicity);
                                    start = this.getDateString(start),
                                        end.setDate(end.getDate() + this.selectedPeriodicity);
                                    end = this.getDateString(end),
                                        this.dateStartInput = start;
                                    this.dateEndInput = end;
                                    fullDateStart = this.getDateHourString(new Date(this.dateStartInput + "T" + this.hourStartInput));
                                    fullDateEnd = this.getDateHourString(new Date(this.dateEndInput + "T" + this.hourEndInput));
                                    this.unavailabilities.push(n, fullDateStart, fullDateEnd);
                                    n++;
                                }

                                this.$refs['modalUnavailabilities'].hide();
                                let data = [];
                                let config = {
                                    headers: {
                                        'Authorization': 'Bearer ' + localStorage.token
                                    }
                                };

                                for (var i = 0; i < this.unavailabilities.length; i += 3) {
                                    var y = i + 1;
                                    var z = i + 2;
                                    data.push({
                                        start: this.unavailabilities[y],
                                        end: this.unavailabilities[z]
                                    });
                                }



                                this.$axios.post('https://api.clickandball.fr/designation/arbitre/indisponibilite', data, config)
                                    .then(response => {
                                        if (response.status == 200 && response.data.error) {
                                            this.showAlertWarning('Erreur: ' + response.data.error);
                                        }
                                        else if (response.status == 200) {
                                            this.showAlertSuccess("Indisponibilité récurrente sauvegardée");
                                            this.unavailabilities = [];
                                            this.checkShowUnavailabilities();
                                            this.checkShowUnavailabilities();
                                        }
                                        else {
                                            this.showAlertWarning('Erreur : ' + response.statusText);
                                        }
                                    })
                                    .catch(error => {
                                        this.showAlertWarning('Erreur : ' + error);
                                    });
                            }
                            else {
                                this.$refs['modalUnavailabilities'].hide();
                                this.showAlertWarning('Erreur: Aucune périodicité n\'a été choisie');
                            }
                        }
                        else {
                            this.$refs['modalUnavailabilities'].hide();
                            this.showAlertWarning('Erreur: La date de début de l\'indisponibilité est inférieure à la date de fin de la périodicité de l\'indisponibilité');
                        }
                    }

                }
                else {
                    this.$refs['modalUnavailabilities'].hide();
                    let data = [];
                    data.push({
                        start: this.getDateHourString(new Date(this.dateStartInput + "T" + this.hourStartInput)),
                        end: this.getDateHourString(new Date(this.dateEndInput + "T" + this.hourEndInput))
                    });
                    let config = {
                        headers: {
                            'Authorization': 'Bearer ' + localStorage.token
                        }
                    };
                    this.$axios.post('https://api.clickandball.fr/designation/arbitre/indisponibilite', data, config)
                        .then(response => {
                            if (response.status == 200 && response.data.error) {
                                this.showAlertWarning('Erreur: ' + response.data.error);
                            }
                            else if (response.status == 200) {
                                this.showAlertSuccess("Indisponibilité sauvegardée");
                                this.checkShowUnavailabilities();
                                this.checkShowUnavailabilities();
                            }
                            else {
                                this.showAlertWarning('Erreur : ' + response.statusText);
                            }
                        })
                        .catch(error => {
                            this.showAlertWarning('Erreur : ' + error);
                        });
                }
            }
			

			
		},
		MajIndispo()
		{
			this.checkToken();
			const dateStartInput = document.querySelector('input[name="dateStartInput"]');
			const hourStartInput = document.querySelector('input[name="hourStartInput"]');
			const dateEndInput = document.querySelector('input[name="dateEndInput"]');
			const hourEndInput = document.querySelector('input[name="hourEndInput"]');
			
			var reDate = /^(20\d\d)-([1-9]|0\d|1[0-2])-([1-9]|[0-2]\d|3[0-1])$/;
			var reHour = /^([0-1]?\d|2[0-3]):([0-9]|[0-5]\d)$/;

            if (this.allDay == true) {
                this.hourStartInput = '00:00',
                    this.dateEndInput = this.dateStartInput,
                    this.hourEndInput = '23:59'
            }

			if (!reDate.test(this.dateStartInput))
			{
				dateStartInput.setCustomValidity("Veuillez saisir une date de début");
			}
			if (!reDate.test(this.dateEndInput))
			{
				dateEndInput.setCustomValidity("Veuillez saisir une date de fin");
			}
			if (!reHour.test(this.hourStartInput))
			{
				hourStartInput.setCustomValidity("Veuillez saisir une heure de début");
			}
			if (!reHour.test(this.hourEndInput))
			{
				hourEndInput.setCustomValidity("Veuillez saisir une heure de fin");
			}

			if(reDate.test(this.dateStartInput) && reDate.test(this.dateEndInput) && reHour.test(this.hourStartInput) && reHour.test(this.hourEndInput))
			{
				this.$refs['modalUnavailabilities'].hide();
				let json = {
					id : parseInt(this.unavailabilitiesId),
					start : this.getDateHourString(new Date(this.dateStartInput + "T" + this.hourStartInput)), 
					end : this.getDateHourString(new Date(this.dateEndInput + "T" + this.hourEndInput))
				};
				let config = {
					headers: {
						'Authorization': 'Bearer ' + localStorage.token,
					}
				};

				this.$axios.put('https://api.clickandball.fr/designation/arbitre/indisponibilite', json, config)
					.then(response => {
						if (response.status == 200 && response.data.error)
						{
							this.showAlertWarning('Erreur : ' + response.data.error);
						}
						else if (response.status == 200)
						{
							this.showAlertSuccess("Indisponibilité mise à jour");
                            this.checkShowUnavailabilities();
                            this.checkShowUnavailabilities();
						}
						else
						{
							this.showAlertWarning('Erreur : ' + response.statusText);
						}
					})
					.catch(error => {
						this.showAlertWarning('Erreur : ' + error); 
					});
			}
		},
		SuppressionIndispoModal()
		{
			this.$refs['modalUnavailabilities'].hide();
			this.$refs['modalValidationSuppressionIndispo'].show();
			console.log(this.unavailabilitiesId);
		},
		SuppressionIndispo()
		{
			this.checkToken();
			this.$refs['modalValidationSuppressionIndispo'].hide();
			let config = {
				headers: {
					'Authorization': 'Bearer ' + localStorage.token,
				},
				data: { id : [parseInt(this.unavailabilitiesId)] }
			};

			this.$axios.delete('https://api.clickandball.fr/designation/arbitre/indisponibilite', config)
				.then(response => {
					if (response.status == 200 && response.data.error)
					{
						this.showAlertWarning('Erreur : ' + response.data.error);
					}
					else if (response.status == 200)
					{
						this.showAlertSuccess("Indisponibilité supprimée");
                        this.checkShowUnavailabilities();
                        this.checkShowUnavailabilities();
					}
					else
					{
						this.showAlertWarning('Erreur : ' + response.statusText);
					}
				})
				.catch(error => {
					this.showAlertWarning('Erreur : ' + error);
				});
		},

		// Méthodes liées aux retours
		showAddReturn()
		{
			this.checkToken();
			this.showDropdownReturn = false;
			this.acceptUploadFile = false;
			this.$refs['modalAssignment'].hide();
			this.$refs['modalReturn'].show();
		},
		showAddReturnDropdown()
		{
			this.checkToken();	
			this.returnUpdate = false;
			this.showDropdownReturn = true;
			this.acceptUploadFile = true;
			this.assignmentList = [];
			let newEvents = [];
			for (var i = 0; i < this.calendarOptions.events.length; i++)
			{
				if (this.calendarOptions.events[i].type != 1)
				{
					newEvents.push(this.calendarOptions.events[i]);
				}
			}
			this.calendarOptions.events = newEvents; 

			let config = {
				headers: {
					'Authorization': 'Bearer ' + localStorage.token
				}
			};

			this.$axios
				.get('https://api.clickandball.fr/designation/arbitre?start=' + this.getFirstDayMonth(), config)
				.then(response => {
					if (response.status == 200)
					{
						for (var i = 0; i < response.data.length; i++)
						{
							if (!this.calendarOptions.events.find(el => el.type === 1 && el.id === response.data[i].id))
							{
								this.assignmentList.push(response.data[i]);
							}
						}
					}
					else
					{
						this.showAlertWarning("Code erreur : " + response.status);
					}
				})
				.catch(error => {
					this.showAlertWarning('Erreur : ' + error);
				});

			this.$refs['modalReturn'].show();
		},
		saveReturn()
		{
			this.checkToken();
			let refereeAssignmentId;
			if (this.showDropdownReturn)
			{
				refereeAssignmentId = this.selectedMatchReturn;
			}
			else
			{
				refereeAssignmentId = this.assignmentReturn.id;
			}

			/*var reTexte = /^[\w\s\n]+$/;*/
			var reNumber = /^\d+$/;

			//if(!reTexte.test(this.motifReturnInput))
			//{
			//	this.$refs['modalReturn'].hide();
			//	this.showAlertWarning('Erreur : Merci d\'utiliser uniquement des caractères sans accents, ni aucune ponctuation pour le motif saisi (Exemple : Je suis malade)');
			//}

			if((/*reTexte.test(this.motifReturnInput) &&*/ !this.showDropdownReturn) || /*(reTexte.test(this.motifReturnInput) && */reNumber.test(this.selectedMatchReturn))
			{
				this.$refs['modalReturn'].hide();
				let json = {
					refereeAssignmentId : refereeAssignmentId, 
					comment : this.motifReturnInput
				};
				let config = {
					headers: {
						'Authorization': 'Bearer ' + localStorage.token
					}
				};

				this.$axios.post('https://api.clickandball.fr/retour/arbitre', json, config)
					.then(response => {
						if (response.status == 200 && response.data.error)
						{
							this.showAlertWarning('Erreur : ' + response.data.error);
						}
						else if (response.status == 200)
						{
							this.showAlertSuccess("Retour sauvegardé");
							this.checkShowReturn();
							this.checkShowReturn();

							//Envoie du fichier sur le serveur
							if (this.file != '')
							{
								let form = new FormData();
								form.append('Ressource', this.file);
								form.append('idRetour', response.data.id);
								form.append('acceptUploadFile', this.acceptUploadFile);
								this.$axios.post('https://api.clickandball.fr/retour/arbitre/importfichier', form, config)
									.then(response => {
										if (response.status == 200 && response.data.error) {
											this.showAlertWarning('Erreur : ' + response.data.error);
										}
										else if (response.status == 200) {
											this.showAlertSuccess("Justificatif sauvegardé");
										}
										else {
											this.showAlertWarning('Erreur : ' + response.statusText);
										}
									})
									.catch(error => {
										this.showAlertWarning('Erreur : ' + error);
									});
							}
						}
						else
						{
							this.showAlertWarning('Erreur : ' + response.statusText);
						}
					})
					.catch(error => {
						this.showAlertWarning('Erreur : ' + error); 
					});
				this.motifReturnInput = '';
			}
		},
		updateReturn()
		{
			this.checkToken();
			/*const motifReturnInput = document.querySelector('textarea[name="motifReturnInput"]');*/
			/*var reTexte = /^[\w\s\nÀ-ÿ]+$/;*/

			//if (!reTexte.test(this.motifReturnInput))
			//{
			//	motifReturnInput.setCustomValidity("Veuillez écrire un commentaire valide (lettre et chiffre seulement)");
			//}
			//else
			//{
				this.$refs['modalReturn'].hide();
				let json = {
					id : parseInt(this.assignmentReturn.id),
					comment : this.motifReturnInput
				};
				let config = {
					headers: {
						'Authorization': 'Bearer ' + localStorage.token
					}
				};

				this.$axios.put('https://api.clickandball.fr/retour/arbitre', json, config)
					.then(response => {
						if (response.status == 200 && response.data.error)
						{
							this.showAlertWarning('Erreur : ' + response.data.error);
						}
						else if (response.status == 200)
						{
							this.showAlertSuccess("Retour mis à jour");
							this.checkShowReturn();
                            this.checkShowReturn();

							//Envoie du fichier sur le serveur
							if (this.file != '')
							{
								let form = new FormData();
								form.append('Ressource', this.file);
								form.append('idRetour', parseInt(this.assignmentReturn.id));
								form.append('acceptUploadFile', this.acceptUploadFile);
								this.$axios.post('https://api.clickandball.fr/retour/arbitre/importfichier', form, config)
									.then(response => {
										if (response.status == 200 && response.data.error) {
											this.showAlertWarning('Erreur : ' + response.data.error);
										}
										else if (response.status == 200) {
											this.showAlertSuccess("Justificatif sauvegardé");
										}
										else {
											this.showAlertWarning('Erreur : ' + response.statusText);
										}
									})
									.catch(error => {
										this.showAlertWarning('Erreur : ' + error);
									});
							}
						}
						else
						{
							this.showAlertWarning('Erreur : ' + response.statusText);
						}
					})
					.catch(error => {
						this.showAlertWarning('Erreur : ' + error);
					});
				this.motifReturnInput = '';
			/*}*/
		},

		checkShowEvents(info)
		{
			this.checkToken();
			Date.prototype.addDays = function(days) {
				var date = new Date(this.valueOf());
				date.setDate(date.getDate() + days);
				return date;
			}

			let start = new Date(this.getDateString(info.start));
			let end = new Date(this.getDateString(info.end));
			let addAssignment = false;
			let addUnavailabilities = false;
			let addReturn = false;

			//Vérifie s'il existe les désignations sont déjà affiché
			for (let i = (((end.getTime() - start.getTime()) / (86400000)) / 7) - 1; i >= 0; i--)
			{
				let mondayDate = start.addDays(7 * i);
				let yearStart = new Date(Date.UTC(mondayDate.getUTCFullYear(), 0, 1));

				if (!this.listShowAssignment.find(el => el.week === Math.ceil(((mondayDate - yearStart) / 86400000) / 7) && el.year === mondayDate.getUTCFullYear()) && !this.showAssignment)
				{
					addAssignment = true;
					this.listShowAssignment.push({
						"week":Math.ceil(((mondayDate - yearStart) / 86400000) / 7),
						"year":mondayDate.getUTCFullYear()
					});
				}
			}

			//Vérifie s'il existe les indisponibilités sont déjà affiché
			for (let i = (((end.getTime() - start.getTime()) / (86400000)) / 7) - 1; i >= 0; i--)
			{
				let mondayDate = start.addDays(7 * i);
				let yearStart = new Date(Date.UTC(mondayDate.getUTCFullYear(), 0, 1));

				if (!this.listShowUnavailabilities.find(el => el.week === Math.ceil(((mondayDate - yearStart) / 86400000) / 7) && el.year === mondayDate.getUTCFullYear()) && !this.showUnavailabilities)
				{
					addUnavailabilities = true;
					this.listShowUnavailabilities.push({
						"week":Math.ceil(((mondayDate - yearStart) / 86400000) / 7),
						"year":mondayDate.getUTCFullYear()
					});
				}
			}

			//Vérifie s'il existe les retours sont déjà affiché
			for (let i = (((end.getTime() - start.getTime()) / (86400000)) / 7) - 1; i >= 0; i--)
			{
				let mondayDate = start.addDays(7 * i);
				let yearStart = new Date(Date.UTC(mondayDate.getUTCFullYear(), 0, 1));

				if (!this.listShowReturn.find(el => el.week === Math.ceil(((mondayDate - yearStart) / 86400000) / 7) && el.year === mondayDate.getUTCFullYear()) && !this.showReturn)
				{
					addReturn = true;
					this.listShowReturn.push({
						"week":Math.ceil(((mondayDate - yearStart) / 86400000) / 7),
						"year":mondayDate.getUTCFullYear()
					});
				}
			}
			let config = {
				headers: {
					'Authorization': 'Bearer ' + localStorage.token
				}
			};

			if (!this.showAssignment && addAssignment)
			{
				this.$axios
					.get('https://api.clickandball.fr/designation/arbitre?start=' + this.getDateString(start) +  "&end=" + this.getDateString(end), config)
					.then(response => {
						if (response.status == 200)
						{
							for (var i = 0; i < response.data.length; i++)
							{
								if (!this.calendarOptions.events.find(el => el.type === 1 && el.id === response.data[i].id))
								{
									this.assignmentList.push(response.data[i]);

									this.calendarOptions.events.push({
										type:1,
										id:response.data[i].id,
										title:"Désignation - " + response.data[i].match.divisionId + " - " + response.data[i].match.homeClub.name,
										date:response.data[i].match.date,
										backgroundColor:'#45b39d'
									});
								}
							}
						}
						else
						{
							this.showAlertWarning("Code erreur : " + response.status);
						}
					})
					.catch(error => {
						this.showAlertWarning('Erreur: ' + error);
					});
			}
			if (!this.showUnavailabilities && addUnavailabilities)
			{
				this.$axios.get('https://api.clickandball.fr/designation/arbitre/indisponibilite?start=' + this.getDateString(info.start) +  "&end=" + this.getDateString(info.end), config)
					.then(response => {
						if (response.status == 200 && response.data.error)
						{
							this.showAlertWarning('Erreur : ' + response.data.error);
						}
						else if (response.status == 200)
						{
							for (var i = 0; i < response.data.length; i++)
							{
								if (!this.calendarOptions.events.find(el => el.type === 2 && el.id === response.data[i].id))
								{
									this.calendarOptions.events.push({
										type:2,
										id:response.data[i].id,
										title:"Indisponibilité",
										start:response.data[i].start,
										end:response.data[i].end,
										backgroundColor:'#ff5733'
									});
								}
							}
						}
						else
						{
							this.showAlertWarning('Erreur : ' + response.statusText);
						}
					})
					.catch(error => {
						this.showAlertWarning('Erreur : ' + error);
					});
			}
			if (!this.showReturn && addReturn)
			{
				this.$axios.get('https://api.clickandball.fr/retour/arbitre?start=' + this.getDateString(info.start) +  "&end=" + this.getDateString(info.end), config)
					.then(response => {
						if (response.status == 200 && response.data.error)
						{
							this.showAlertWarning('Erreur: ' + response.data.error);
						}
						else if (response.status == 200)
						{
							for (var i = 0; i < response.data.length; i++)
							{
								if (!this.calendarOptions.events.find(el => el.type === 3 && el.id === response.data[i].id))
								{
                                    let title = "Retour : " + response.data[i].match.divisionId + " - " + response.data[i].match.id;
									if (response.data[i].validated)
									{
                                        title = "Retour : " + response.data[i].match.divisionId + " - " + response.data[i].match.id;
									}
									this.calendarOptions.events.push({
										type:3,
										id:response.data[i].id,
										division:response.data[i].match.divisionId,
										matchId:response.data[i].match.id,
										comment:response.data[i].comment,
										file:response.data[i].file,
										title:title,
										date:response.data[i].match.date,
										backgroundColor:'#34495e'
									});
								}
							}
						}
						else
						{
							this.showAlertWarning('Erreur : ' + response.statusText);
						}
					})
					.catch(error => {
						this.showAlertWarning('Erreur : ' + error); 
					});
			}
		},
        handleFileUpload()
		{
			this.file = this.$refs.file.files[0];
		}
	}
}
</script>

