<template>
	<div class="Assignment">
		<h1 class="app-page-title"><img src="https://img.icons8.com/ios-glyphs/50/000000/basketball--v1.png" /> Gestion des désignations</h1>

		<b-modal ref="modalInfo" hide-footer hide-header size="lg">
			<template #modal-title>
				<div>Désignation</div>
			</template>
			<div class="app-card " style="margin: -1em; margin-bottom: -0.5em;">
				<a style="float: right; z-index: 9999; margin-right: 0.5em; top: 0" href="#" block @click="$refs['modalInfo'].hide()">
					<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
						<path fill-rule="evenodd" d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z" />
						<path fill-rule="evenodd" d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z" />
					</svg>
				</a>
				<div class="app-card-header p-3 border-bottom-0">
					<div class="row align-items-center gx-3">
						<div class="col-auto">
							<div class="app-icon-holder" style="background-color: rgb(42, 87, 136)">
								<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-shield-check" fill="white" xmlns="http://www.w3.org/2000/svg">
									<path fill-rule="evenodd" d="M4 2h7a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2zm0 1a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h7a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1H4z"></path>
									<path d="M6 0h7a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2v-1a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H6a1 1 0 0 0-1 1H4a2 2 0 0 1 2-2z"></path>
								</svg>
							</div>
						</div>
						<div class="col-auto">
							<h4 class="app-card-title" style="color: rgb(42, 87, 136) !important">Désignation</h4>
						</div>
					</div>
				</div>
			</div>

			<div class="accordion" role="tablist" style="margin-bottom: -1em">
				<div class="app-card app-card-notification shadow-sm mb-4">
					<div block v-b-toggle.match type="button" data-toggle="" class="app-card-header px-4 py-3" header-tag="header" role="tab" style="background-color: rgba(0,0,0,.05) !important; border-bottom: 2px solid rgb(47 87 136)">
						<div class="row g-3 align-items-center">
							<div class="col-12 col-lg-auto text-lg-left">
								<!--<div class="notification-type mb-2">
							<span class="badge bg-info">
								Légende
							</span>
						</div>-->
								<h4 class="notification-title mb-1" id="title-legende">
									<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="rgb(47 87 136)" class="bi bi-hand-index" viewBox="0 0 16 16">
										<path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
										<path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
									</svg><span style="color: rgb(47 87 136);"> Informations du match</span>
								</h4>
								<ul class="notification-meta list-inline mb-0" style="color:">
									<li class="list-inline-item">
										Information liées à la rencontre
									</li>
								</ul>
							</div>
						</div>
					</div>
					<b-collapse visible id="match" accordion="my-accordion" role="tabpanel">
						<div class="app-card-body p-4">
							<div class="notification-content" style="margin-bottom: -2em; margin-top: -1em">
								<ul class="list-unstyled">
									<li class="listeInfoMatch">
										<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2 text-primary mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
											<path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
										</svg>
										<strong>
											Division :
										</strong>
										{{assignmentSelected.match.divisionId}}
									</li>
									<li class="listeInfoMatch">
										<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2 text-primary mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
											<path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
										</svg>
										<strong>
											Numéro de rencontre :
										</strong>
										{{assignmentSelected.match.id}}
									</li>
									<li class="listeInfoMatch">
										<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2 text-primary mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
											<path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
										</svg>
										<strong>
											Poule :
										</strong>
										{{assignmentSelected.match.poule.nomLong}}
									</li>
									<li class="listeInfoMatch">
										<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2 text-primary mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
											<path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
										</svg>
										<strong>
											Horaire :
										</strong>
										{{assignmentSelected.match.date}}
									</li>
								</ul>
								<hr />
								<ul class="list-unstyled">
									<li class="listeInfoMatch">
										<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2 text-primary mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
											<path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
										</svg>
										<strong>
											Salle :
										</strong>
										{{assignmentSelected.match.sportsHall.name}}
									</li>
									<li class="listeInfoMatch">
										<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2 text-primary mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
											<path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
										</svg>
										<strong>
											Adresse :
										</strong>
										{{assignmentSelected.match.sportsHall.address}}
									</li>
								</ul>
								<hr />
								<ul class="list-unstyled">
									<li class="listeInfoMatch">
										<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2 text-primary mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
											<path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
										</svg>
										<strong>
											Equipe A :
										</strong>
										{{assignmentSelected.match.homeClub.name}}
									</li>
									<li class="listeInfoMatch">
										<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2 text-primary mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
											<path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
										</svg>
										<strong>
											Equipe B :
										</strong>
										{{assignmentSelected.match.visitorClub.name}}
									</li>
								</ul>
							</div>
						</div>
					</b-collapse>
				</div>
				<div class="app-card app-card-notification shadow-sm mb-4" v-if="assignmentSelected.historique">
					<div block v-b-toggle.referee id="title-legende" type="button" data-toggle="" class="app-card-header px-4 py-3" header-tag="header" role="tab" style="background-color: rgba(0,0,0,.05) !important; border-bottom: 2px solid rgb(47 87 136)">
						<div class="row g-3 align-items-center">
							<div class="col-12 col-lg-auto text-lg-left">
								<h4 class="notification-title mb-1" style="color: rgb(20 57 96);">
									<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="rgb(47 87 136)" class="bi bi-hand-index" viewBox="0 0 16 16">
										<path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1h8zm-7.978-1A.261.261 0 0 1 7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002a.274.274 0 0 1-.014.002H7.022zM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0zM6.936 9.28a5.88 5.88 0 0 0-1.23-.247A7.35 7.35 0 0 0 5 9c-4 0-5 3-5 4 0 .667.333 1 1 1h4.216A2.238 2.238 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816zM4.92 10A5.493 5.493 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275zM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0zm3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4z" />
									</svg><span style="color: rgb(47 87 136);"> Arbitres</span>
								</h4>
								<ul class="notification-meta list-inline mb-0">
									<li class="list-inline-item">
										Historique des désignations de la rencontre
									</li>
								</ul>
							</div>
						</div>
					</div>
					<b-collapse id="referee" accordion="my-accordion" role="tabpanel">
						<div class="app-card-body p-4">
							<div class="notification-content" style="margin-bottom: -2em; margin-top: -1em">
								<div class="text-center">
									<b-button v-if="assignmentSelected.historique[0].status !== 2" v-on:click="deleteAssignement(1)" class="btn app-btn-secondary" text-center>Annuler la dernière désignation</b-button>
									<hr />
								</div>
								<ul class="list-unstyled" v-for="item in assignmentSelected.historique" :key="item.historique">
									<li>
										<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2 text-primary mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg" style="margin: 0.4em">
											<path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
										</svg>
										<strong>Arbitre n°1 : </strong>{{ item.referee1.firstName }} {{ item.referee1.lastName}}
									</li>
									<li>
										<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2 text-primary mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg" style="margin: 0.4em">
											<path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
										</svg>
										<strong>Arbitre n°2 : </strong>{{ item.referee2.firstName }} {{ item.referee2.lastName}}
									</li>
									<li style="margin-top: 0.25em">
										<small>
											<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2 text-primary mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg" style="margin: 0.4em">
												<path d="M6.445 11.688V6.354h-.633A12.6 12.6 0 0 0 4.5 7.16v.695c.375-.257.969-.62 1.258-.777h.012v4.61h.675zm1.188-1.305c.047.64.594 1.406 1.703 1.406 1.258 0 2-1.066 2-2.871 0-1.934-.781-2.668-1.953-2.668-.926 0-1.797.672-1.797 1.809 0 1.16.824 1.77 1.676 1.77.746 0 1.23-.376 1.383-.79h.027c-.004 1.316-.461 2.164-1.305 2.164-.664 0-1.008-.45-1.05-.82h-.684zm2.953-2.317c0 .696-.559 1.18-1.184 1.18-.601 0-1.144-.383-1.144-1.2 0-.823.582-1.21 1.168-1.21.633 0 1.16.398 1.16 1.23z" />
												<path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z" />
											</svg>
											<strong>Date de désignation : </strong>{{ item.assignmentDate }}<br>
												<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-check-fill text-primary mr-2" style="margin: 0.4em">
													<path fill-rule="evenodd" d="M15.854 5.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L12.5 7.793l2.646-2.647a.5.5 0 0 1 .708 0z"/>
													<path d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
												</svg>
											<strong>Répartiteur : </strong>
											<span v-if="item.dispatcherId == 0">
												Click&Ball Robot
											</span>
											<span v-else>{{ item.dispatcherFirstName }} {{ item.dispatcherLastName }}</span>
											<br>
											<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2 text-primary mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg" style="margin: 0.4em">
												<path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
												<path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
											</svg>
											<strong>Statut : </strong><dfn v-if="item.status == 0">Générée</dfn><dfn v-if="item.status == 1">Validée</dfn><dfn v-if="item.status == 2">Ancienne</dfn>
										</small>
									</li>
									<hr />
								</ul>
							</div>
						</div>
					</b-collapse>
				</div>
			</div>
		</b-modal>

		<b-modal ref="modalAddAssignment" hide-footer hide-header size="xl">
			<template #modal-title>
				<div>Ajouter une désignation</div>
			</template>
			<div class="app-card " style="margin: -1em; margin-bottom: -0.5em">
				<a style="float: right; z-index: 9999; margin-right: 0.5em; top: 0" href="#" block @click="$refs['modalAddAssignment'].hide()">
					<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
						<path fill-rule="evenodd" d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z" />
						<path fill-rule="evenodd" d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z" />
					</svg>
				</a>
				<div class="app-card-header p-3 border-bottom-0">
					<div class="row align-items-center gx-3">
						<div class="col-auto">
							<div class="app-icon-holder" style="background-color: rgb(42, 87, 136)">
								<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-shield-check" fill="white" xmlns="http://www.w3.org/2000/svg">
									<path d="M8 6.5a.5.5 0 0 1 .5.5v1.5H10a.5.5 0 0 1 0 1H8.5V11a.5.5 0 0 1-1 0V9.5H6a.5.5 0 0 1 0-1h1.5V7a.5.5 0 0 1 .5-.5z" />
									<path d="M14 4.5V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5L14 4.5zm-3 0A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5h-2z" />
								</svg>
							</div>
						</div>
						<div class="col-auto">
							<h4 class="app-card-title" style="color: rgb(42, 87, 136) !important">Ajouter une désignation</h4>
						</div>
					</div>
				</div>
			</div>
			<div>

			</div>
			<div class="accordion" role="tablist">
				<div class="app-card app-card-notification shadow-sm mb-4">
					<div block v-b-toggle.match type="button" data-toggle="" class="app-card-header px-4 py-3" header-tag="header" role="tab" style="background-color: rgba(0,0,0,.05) !important; border-bottom: 2px solid rgb(47 87 136)">
						<div class="row g-3 align-items-center">
							<div class="col-12 col-lg-auto text-lg-left">
								<h4 class="notification-title mb-1" id="title-legende" style="color: rgb(20 57 96);">
									<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-hand-index" viewBox="0 0 16 16">
										<path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
										<path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
									</svg> Informations du match
								</h4>
								<ul class="notification-meta list-inline mb-0">
									<li class="list-inline-item">
										Information liées à la rencontre
									</li>
								</ul>
							</div>
						</div>
					</div>
					<b-collapse visible id="match" accordion="my-accordion" role="tabpanel">
						<div class="app-card-body p-4">
							<div class="notification-content" style="margin-bottom: -2em; margin-top: -1em">
								<ul class="list-unstyled">
									<li class="listeInfoMatch">
										<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2 text-primary mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
											<path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
										</svg>
										<strong>
											Division :
										</strong>
										{{assignmentSelected.match.divisionId}}
									</li>
									<li class="listeInfoMatch">
										<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2 text-primary mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
											<path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
										</svg>
										<strong>
											Numéro de rencontre :
										</strong>
										{{assignmentSelected.match.id}}
									</li>
									<li class="listeInfoMatch">
										<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2 text-primary mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
											<path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
										</svg>
										<strong>
											Poule :
										</strong>
										{{assignmentSelected.match.poule.nomLong}}
									</li>
									<li class="listeInfoMatch">
										<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2 text-primary mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
											<path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
										</svg>

										<strong>
											Horaire :
										</strong>
										{{assignmentSelected.match.date}}
									</li>
								</ul>
								<hr />
								<ul class="list-unstyled">
									<li class="listeInfoMatch">
										<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2 text-primary mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
											<path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
										</svg>
										<strong>
											Salle :
										</strong>
										{{assignmentSelected.match.sportsHall.name}}
									</li>
									<li class="listeInfoMatch">
										<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2 text-primary mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
											<path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
										</svg>
										<strong>
											Adresse :
										</strong>
										{{assignmentSelected.match.sportsHall.address}}
									</li>
								</ul>
								<hr />
								<ul class="list-unstyled">
									<li class="listeInfoMatch">
										<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2 text-primary mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
											<path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
										</svg>
										<strong>
											Equipe A :
										</strong>
										{{assignmentSelected.match.homeClub.name}}
									</li>
									<li class="listeInfoMatch">
										<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2 text-primary mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
											<path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
										</svg>
										<strong>
											Equipe B :
										</strong>
										{{assignmentSelected.match.visitorClub.name}}
									</li>
								</ul>
							</div>
						</div>
					</b-collapse>
				</div>
			</div>
			<!--<div class="accordion3" role="tablist">
				<div class="app-card app-card-notification shadow-sm mb-4">
					<div block v-b-toggle.historique type="button" data-toggle="" class="app-card-header px-4 py-3" header-tag="header" role="tab" style="background-color: rgba(0,0,0,.05) !important; border-bottom: 2px solid rgb(47 87 136)">
						<div class="row g-3 align-items-center">
							<div class="col-12 col-lg-auto text-lg-left">
								<h4 class="notification-title mb-1" id="title-legende" style="color: rgb(20 57 96);">
									<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-hand-index" viewBox="0 0 16 16">
										<path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
										<path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
									</svg> Historique de désignation
								</h4>
								<ul class="notification-meta list-inline mb-0">
									<li class="list-inline-item">
										Historique des indisponibilités et des désignations des arbitres
									</li>
								</ul>
							</div>
						</div>
					</div>
					<b-collapse id="historique" accordion="my-accordion3" role="tabpanel">
						<div class="app-card-body p-4">
							<div class="notification-content" style="margin-bottom: -2em; margin-top: -1em">

								<div class="tab-pane fade show active" id="orders-all" role="tabpanel" aria-labelledby="orders-all-tab">
									<div class="app-card app-card-orders-table shadow-sm mb-5">
										<div class="app-card-body">
											<div class="table-responsive table-stiped">
												<table class="table table-striped table-hover mb-0 text-left-center">
													<thead>
														<tr class="align-middle">
															<th class="mb-2 text-primary">Arbitres</th>
															<th v-for="item in previousDate" :key="item.id" class="mb-2 text-primary">{{ item }}</th>
														</tr>
													</thead>
													<tbody>
														<tr class="align-middle" v-for="item in listReferee" :key="item.nationalNumber">
															<td>{{item.firstName}} <strong>{{item.lastName}}</strong></td>
														</tr>
													</tbody>
												</table>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</b-collapse>
				</div>
			</div>-->

			<div class="accordion" role="tablist">
				<div class="app-card app-card-notification shadow-sm mb-4">
					<div block v-b-toggle.legende id="title-legende" type="button" data-toggle="" data-target="#legende1" aria-expanded="false" aria-controls="legende1" class="app-card-header px-4 py-3" header-tag="header" role="tab" style="background-color: rgba(0,0,0,.05) !important; border-bottom: 2px solid rgb(47 87 136)">
						<div class="row g-3 align-items-center">
							<div class="col-12 col-lg-auto text-lg-left">
								<h4 class="notification-title mb-1" style="color: rgb(20 57 96);">
									<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-hand-index" viewBox="0 0 16 16">
										<path d="M6.75 1a.75.75 0 0 1 .75.75V8a.5.5 0 0 0 1 0V5.467l.086-.004c.317-.012.637-.008.816.027.134.027.294.096.448.182.077.042.15.147.15.314V8a.5.5 0 1 0 1 0V6.435a4.9 4.9 0 0 1 .106-.01c.316-.024.584-.01.708.04.118.046.3.207.486.43.081.096.15.19.2.259V8.5a.5.5 0 0 0 1 0v-1h.342a1 1 0 0 1 .995 1.1l-.271 2.715a2.5 2.5 0 0 1-.317.991l-1.395 2.442a.5.5 0 0 1-.434.252H6.035a.5.5 0 0 1-.416-.223l-1.433-2.15a1.5 1.5 0 0 1-.243-.666l-.345-3.105a.5.5 0 0 1 .399-.546L5 8.11V9a.5.5 0 0 0 1 0V1.75A.75.75 0 0 1 6.75 1zM8.5 4.466V1.75a1.75 1.75 0 1 0-3.5 0v5.34l-1.2.24a1.5 1.5 0 0 0-1.196 1.636l.345 3.106a2.5 2.5 0 0 0 .405 1.11l1.433 2.15A1.5 1.5 0 0 0 6.035 16h6.385a1.5 1.5 0 0 0 1.302-.756l1.395-2.441a3.5 3.5 0 0 0 .444-1.389l.271-2.715a2 2 0 0 0-1.99-2.199h-.581a5.114 5.114 0 0 0-.195-.248c-.191-.229-.51-.568-.88-.716-.364-.146-.846-.132-1.158-.108l-.132.012a1.26 1.26 0 0 0-.56-.642 2.632 2.632 0 0 0-.738-.288c-.31-.062-.739-.058-1.05-.046l-.048.002zm2.094 2.025z" />
									</svg> Légende
								</h4>
								<ul class="notification-meta list-inline mb-0">
									<li class="list-inline-item">
										Information concernant l'état de désignation des arbitres dans la liste déroulante
									</li>
								</ul>
							</div>
						</div>
					</div>
					<b-collapse id="legende" accordion="my-accordion" role="tabpanel">
						<div class="app-card-body p-4">
							<div class="notification-content" style="margin-bottom: -2em; margin-top: -1em">
								<ul class="list-unstyled">
									<li>
										🔝 Arbitre région ou championnat de france
									</li>
									<li>
										⛔ Ne peut pas être désigné sur la rencontre
									</li>
									<li>
										⚠ L'arbitre est déjà désigné sur la même semaine
									</li>
									<li>
										🛑 L'arbitre est déjà désigné sur la même journée
									</li>
								</ul>
							</div>
						</div>
					</b-collapse>
				</div>
			</div>

			<div class="row align-middle" style="padding: 0.5em">
				<div class="app-card-body p-4">
							<div class="notification-content" style="margin-bottom: -2em; margin-top: -1em">
								<ul class="list-unstyled">
									<li class="listeInfoMatch">
										<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2 text-primary mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
											<path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
										</svg>
										<strong>
											Filtrer les arbitres selon leur groupe : <br>
										</strong>

										<div class="col-lg-2">
											<div class="align-items-center" style="text-align: center;">
												<b-form-select v-model="groupAssignmentReferee1" @change="FiltreArbitreModalDesignation()" class="form-select form-select-sm ml-auto d-inline-flex">
													<b-form-select-option :value="0">Choisir un groupe</b-form-select-option>
													<b-form-select-option v-for="r in listGroupAvailable" :key="r.id" :value="r.id">{{ r.wording }}</b-form-select-option>
												</b-form-select>
											</div>
										</div>
										
									</li>
								</ul>
							</div>
						</div>
				<div class="col-md-4 col-sm-12" style="background-color: rgba(20,57,96, 0.5) !important; border-right: 2px solid #fa7507; padding: 0.5em;margin-top: 0.5em; border-top-left-radius: 10px; border-bottom-left-radius: 10px; ">
					<div style="font-weight: bold;text-align: center;color: white">Arbitre n°1 :</div>

					<div class="col-md-10 offset-md-1 pl-1" style="margin-top: 0.5em; margin-bottom: 1em">
						<b-form-select v-model="addAssignmentReferee1" @change="GetUnavailabilityReferee1() + GetPreviousAssignmentReferee1() + GetPreviousUnavailabilitiesReferee1()" class="form-select form-select-sm ml-auto d-inline-flex text-center">
							<b-form-select-option :value='0' disabled>Choisir un arbitre</b-form-select-option>
							<b-form-select-option :value='0' disabled>--- Arbitre position 1 ---</b-form-select-option>
							<b-form-select-option v-for="r in filterListReferee1Pos1Available" :key="r.nationalNumber" :value="r.nationalNumber">
								<div v-if="r.distance == 0" style="color: red">
									<div v-if="r.doubleInWeek == true && r.doubleInDay == false">
										{{ r.lastName }} {{ r.firstName }} (hors limite) ⚠<span v-if="r.status == 3"> 🔝</span>
									</div>
									<div v-else-if="r.doubleInWeek == true && r.doubleInDay == true">
										{{ r.lastName }} {{ r.firstName }} (hors limite) 🛑<span v-if="r.status == 3"> 🔝</span>
									</div>
									<div v-else>
										{{ r.lastName }} {{ r.firstName }} (hors limite)<span v-if="r.status == 3"> 🔝</span>
									</div>
								</div>
								<div v-if="r.distance != 0">
									<div v-if="r.doubleInWeek == true && r.doubleInDay == false">
										{{ r.lastName }} {{ r.firstName }} ({{Math.round(r.distance/100)/10}}Km) ⚠<span v-if="r.status == 3"> 🔝</span>
									</div>
									<div v-else-if="r.doubleInWeek == true && r.doubleInDay == true">
										{{ r.lastName }} {{ r.firstName }} ({{Math.round(r.distance/100)/10}}Km) 🛑<span v-if="r.status == 3"> 🔝</span>
									</div>
									<div v-else>
										{{ r.lastName }} {{ r.firstName }} ({{Math.round(r.distance/100)/10}}Km)<span v-if="r.status == 3"> 🔝</span>
									</div>
								</div>
							</b-form-select-option>
							<b-form-select-option :value='0' disabled>--- Arbitre position 2 ---</b-form-select-option>
							<b-form-select-option v-for="r in filterListReferee1Pos2Available" :key="r.nationalNumber" :value="r.nationalNumber">
								<div v-if="r.distance == 0" style="color: red">
									<div v-if="r.doubleInWeek == true && r.doubleInDay == false">
										{{ r.lastName }} {{ r.firstName }} (hors limite) ⚠<span v-if="r.status == 3"> 🔝</span>
									</div>
									<div v-else-if="r.doubleInWeek == true && r.doubleInDay == true">
										{{ r.lastName }} {{ r.firstName }} (hors limite) 🛑<span v-if="r.status == 3"> 🔝</span>
									</div>
									<div v-else>
										{{ r.lastName }} {{ r.firstName }} (hors limite)<span v-if="r.status == 3"> 🔝</span>
									</div>
								</div>
								<div v-if="r.distance != 0">
									<div v-if="r.doubleInWeek == true && r.doubleInDay == false">
										{{ r.lastName }} {{ r.firstName }} ({{Math.round(r.distance/100)/10}}Km) ⚠<span v-if="r.status == 3"> 🔝</span>
									</div>
									<div v-else-if="r.doubleInWeek == true && r.doubleInDay == true">
										{{ r.lastName }} {{ r.firstName }} ({{Math.round(r.distance/100)/10}}Km) 🛑<span v-if="r.status == 3"> 🔝</span>
									</div>
									<div v-else>
										{{ r.lastName }} {{ r.firstName }} ({{Math.round(r.distance/100)/10}}Km)<span v-if="r.status == 3"> 🔝</span>
									</div>
								</div>
							</b-form-select-option>
						</b-form-select>
					</div>

				</div>

				<!-- Informations dispo et historique-->
				<div class="col-md-8 col-sm-12" style="background-color: rgb(207, 207, 207); padding: 0.5em; margin-top: 0.5em; border-top-right-radius: 10px; border-bottom-right-radius: 10px; ">
					<div v-if="!referee1.nationalNumber" class="app-card app-card-account d-flex flex-column align-items-start col-md-12" style="background-color: rgb(207, 207, 207); border-top-right-radius: 10px; border-bottom-right-radius: 10px; ">
						<div class="app-card-body px-4 w-100">
							<div class="item py-3">
								<div class="row justify-content-center align-items-center">
									<div class="col-auto">
										<p>Sélectionnez un arbitre</p>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div v-if="referee1.nationalNumber" class="app-card app-card-account d-flex flex-column align-items-start col-md-12" style="background-color: rgb(207, 207, 207); border-top-right-radius: 10px; border-bottom-right-radius: 10px; ">
						
						<div class="app-card-body px-4 w-100">
							<div class="row justify-content-center align-items-center" style="text-align: center">
								<div class="col-auto" style="text-align: center">
									<h5 style="color: rgb(20 57 96) !important;">{{referee1.firstName}} {{referee1.lastName}}</h5>
								</div>
							</div>
							<div class="item py-3">

								<div class="row justify-content-center align-items-center">
									<div class="col-auto">
										<strong>Groupe : </strong>{{referee1.refereeLevel.id}}
									</div>
									<div class="col-auto">
										<strong>Position : </strong>{{referee1.refereePosition}}
									</div>
									<div v-if="referee1.canDouble" class="col-auto">
										<strong>Peut doubler :</strong>
										Oui
									</div>
									<div v-else class="col-auto">
										<strong>Peut doubler :</strong>
										Non
									</div>
									<div v-if="referee1.tutor" class="col-auto">
										<strong style="color:darkgreen">Tuteur</strong>
									</div>
								</div>
								<div class="row justify-content-center align-items-center" style="margin-bottom: 0.5em">
									<div class="col-auto">
										<strong>Club</strong> : <span>{{ referee1.club.name }}</span>
									</div>
								</div>
								<div class="row justify-content-center align-items-center" style="margin-bottom: 0.5em">
									<div v-if="referee1.colleagueMandatory.nationalNumber != 0" class="col-auto">
										<strong>Doit être désigné avec</strong> : <span style="color: #fa7507">{{ referee1.colleagueMandatory.firstName }} {{ referee1.colleagueMandatory.lastName }}</span>
									</div>
								</div>
								<div class="row justify-content-around align-items-center">
									<div v-if="referee1.phone" class="col-auto">
										<strong>N° de téléphone :</strong>
										{{referee1.phone}}
									</div>
									<div v-else class="col-auto">
										<strong>N'a pas de numéro de téléphone enregistré</strong>
									</div>
									<div v-if="referee1.city" class="col-auto">
										<strong>Ville :</strong>
										{{referee1.city}}
									</div>
									<div v-else class="col-auto">
										<strong>Aucune adresse enregistrée</strong>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>


			<div v-if="referee1.nationalNumber" style="margin-top: 1em">
				<!--Indisponibilités de la semaine-->
				<div v-if="previousUnavailabilitiesReferee1" class="app-card app-card-orders-table shadow-sm" style="border: 1px solid #fa7507; padding: 0.5em; border-radius: 10px; margin-bottom: 0.5em;">
					<div class="row">
						<div class="col-md-3 col-sm-12" style="text-align: center;">
							<strong>
								Indisponibilité(s) sur 7 jours
							</strong>
						</div>
						<div class="col-md-9 col-sm-12">
							<div class="app-card-body">
								<table class="table table-striped table-hover mb-0 text-left-center" style="text-align: center;">
									<thead>
										<tr>
											<th class="mb-2 text-primary">Date de début</th>
											<th class="mb-2 text-primary">Date de fin</th>
										</tr>
									</thead>
									<tbody v-if="!noResult">
										<tr v-for="item in previousUnavailabilitiesReferee1" :key="item.date" class="align-middle">
											<td class="cell">{{ item.unavailability.start }}</td>
											<td class="cell">{{ item.unavailability.end }}</td>
										</tr>
									</tbody>
									<tbody v-if="noResult">
										<tr>
											<td colspan="2">Aucun résultat</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>

					</div>

				</div>

				<span v-if="refereeDoubleInWeek1  && refereeDoubleInDay1" class="badge bg-dark" style="margin: 0.5em"><strong style="color: orangered">Attention :</strong> L'arbitre sélectionné(e) est déjà déssigné(e) le même jour que le match sélectionné</span>
				<span v-if="!refereeDoubleInDay1 && refereeDoubleInWeek1" class="badge bg-dark" style="margin: 0.5em"><strong style="color: orangered">Attention :</strong> L'arbitre sélectionné(e) est déjà déssigné(e) le même week-end que le match sélectionné</span>


				<!--Désignation du mois précédent-->
				<div v-if="previousAssignmentsReferee1" class="app-card app-card-orders-table shadow-sm" style="margin-top: 0.5em; border: 1px solid #fa7507; padding: 0.5em; border-radius: 10px;">
					<div class="row">
						<div class="col-md-3 col-sm-12" style="text-align: center;">
							<strong>
								6 dernières désignations
							</strong>
							<br />
							<small>
								au cours des 30 derniers jours
							</small>
						</div>
						<div class="col-md-9 col-sm-12">
							<div class="app-card-body">
								<div class="app-card-body">
									<table class="table table-striped table-hover mb-0 text-left-center">
										<thead>
											<tr>
												<th class="mb-2 text-primary">Horaire</th>
												<th class="mb-2 text-primary">Division</th>
												<th class="mb-2 text-primary">Poule</th>
												<th class="mb-2 text-primary">Ville</th>
												<th class="mb-2 text-primary">Locaux</th>
												<th class="mb-2 text-primary">Visiteur</th>
											</tr>
										</thead>
										<tbody>
											<tr v-for="item in previousAssignmentsReferee1.slice().reverse()" :key="item.date" class="align-middle">
												<td class="cell">{{ item.date }}</td>
												<td class="cell">{{ item.divisionId }}</td>
												<td class="cell">{{ item.poule.nomLong }}</td>
												<td class="cell">{{ item.sportsHall.city }}</td>
												<td class="cell">{{ item.homeClub.name }}</td>
												<td class="cell">{{ item.visitorClub.name }}</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>

						</div>
						<!--Fin des désignations du mois précédent-->
					</div>
				</div>
			</div>
			<div class="row align-middle" style=" padding: 0.5em">
				<div class="col-md-4 col-sm-12" style="background-color: rgba(20,57,96, 0.5) !important; border-right: 2px solid #fa7507; padding: 0.5em;margin-top: 0.5em; border-top-left-radius: 10px; border-bottom-left-radius: 10px; ">
					<div style="font-weight: bold;text-align: center;color: white">Arbitre n°2 :</div>
					<div class="col-md-10 offset-md-1 pl-1" style="margin-top: 0.5em; margin-bottom: 1em">
						<b-form-select v-model="addAssignmentReferee2" @change="GetUnavailabilityReferee2() + GetPreviousAssignmentReferee2() + GetPreviousUnavailabilitiesReferee2()" class="form-select form-select-sm ml-auto d-inline-flex text-center">
							<b-form-select-option :value='0' disabled>Choisir un arbitre</b-form-select-option>
							<b-form-select-option :value='0' disabled>--- Arbitre position 1 ---</b-form-select-option>
							<b-form-select-option v-for="r in filterListReferee2Pos1Available" :key="r.nationalNumber" :value="r.nationalNumber">
								<div v-if="r.distance == 0" style="color: red">
									<div v-if="r.doubleInWeek == true && r.doubleInDay == false">
										{{ r.lastName }} {{ r.firstName }} (hors limite) ⚠<span v-if="r.status == 3"> 🔝</span>
									</div>
									<div v-else-if="r.doubleInWeek == true && r.doubleInDay == true">
										{{ r.lastName }} {{ r.firstName }} (hors limite) 🛑<span v-if="r.status == 3"> 🔝</span>
									</div>
									<div v-else>
										{{ r.lastName }} {{ r.firstName }} (hors limite)<span v-if="r.status == 3"> 🔝</span>
									</div>
								</div>
								<div v-if="r.distance != 0">
									<div v-if="r.doubleInWeek == true && r.doubleInDay == false">
										{{ r.lastName }} {{ r.firstName }} ({{Math.round(r.distance/100)/10}}Km) ⚠<span v-if="r.status == 3"> 🔝</span>
									</div>
									<div v-else-if="r.doubleInWeek == true && r.doubleInDay == true">
										{{ r.lastName }} {{ r.firstName }} ({{Math.round(r.distance/100)/10}}Km) 🛑<span v-if="r.status == 3"> 🔝</span>
									</div>
									<div v-else>
										{{ r.lastName }} {{ r.firstName }} ({{Math.round(r.distance/100)/10}}Km)<span v-if="r.status == 3"> 🔝</span>
									</div>
								</div>
							</b-form-select-option>
							<b-form-select-option :value='0' disabled>--- Arbitre position 2 ---</b-form-select-option>
							<b-form-select-option v-for="r in filterListReferee2Pos2Available" :key="r.nationalNumber" :value="r.nationalNumber">
								<div v-if="r.distance == 0" style="color: red">
									<div v-if="r.doubleInWeek == true && r.doubleInDay == false">
										{{ r.lastName }} {{ r.firstName }} (hors limite) ⚠<span v-if="r.status == 3"> 🔝</span>
									</div>
									<div v-else-if="r.doubleInWeek == true && r.doubleInDay == true">
										{{ r.lastName }} {{ r.firstName }} (hors limite) 🛑<span v-if="r.status == 3"> 🔝</span>
									</div>
									<div v-else>
										{{ r.lastName }} {{ r.firstName }} (hors limite)<span v-if="r.status == 3"> 🔝</span>
									</div>
								</div>
								<div v-if="r.distance != 0">
									<div v-if="r.doubleInWeek == true && r.doubleInDay == false">
										{{ r.lastName }} {{ r.firstName }} ({{Math.round(r.distance/100)/10}}Km) ⚠<span v-if="r.status == 3"> 🔝</span>
									</div>
									<div v-else-if="r.doubleInWeek == true && r.doubleInDay == true">
										{{ r.lastName }} {{ r.firstName }} ({{Math.round(r.distance/100)/10}}Km) 🛑<span v-if="r.status == 3"> 🔝</span>
									</div>
									<div v-else>
										{{ r.lastName }} {{ r.firstName }} ({{Math.round(r.distance/100)/10}}Km)<span v-if="r.status == 3"> 🔝</span>
									</div>
								</div>
							</b-form-select-option>
						</b-form-select>
					</div>

				</div>

				<!-- Informations dispo et historique ref2-->
				<div class="col-md-8 col-sm-12" style="background-color: rgb(207, 207, 207); padding: 0.5em; margin-top: 0.5em; border-top-right-radius: 10px; border-bottom-right-radius: 10px; ">
					<div v-if="!referee2.nationalNumber" class="app-card app-card-account d-flex flex-column align-items-start col-md-12" style="background-color: rgb(207, 207, 207); border-top-right-radius: 10px; border-bottom-right-radius: 10px; ">
						<div class="app-card-body px-4 w-100">
							<div class="item py-3">
								<div class="row justify-content-center align-items-center">
									<div class="col-auto">
										<p>Sélectionnez un arbitre</p>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div v-if="referee2.nationalNumber" class="app-card app-card-account d-flex flex-column align-items-start col-md-12" style="background-color: rgb(207, 207, 207); border-top-right-radius: 10px; border-bottom-right-radius: 10px; ">
						<div class="app-card-body px-4 w-100">
							<div class="row justify-content-center align-items-center" style="text-align: center">
								<div class="col-auto" style="text-align: center">
									<h5 style="color: rgb(20 57 96) !important;">{{referee2.firstName}} {{referee2.lastName}}</h5>
								</div>
							</div>
							<div class="item py-3">

								<div class="row justify-content-center align-items-center">
									<div class="col-auto">
										<strong>Groupe : </strong>{{referee2.refereeLevel.id}}
									</div>
									<div class="col-auto">
										<strong>Position : </strong>{{referee2.refereePosition}}
									</div>
									<div v-if="referee1.canDouble" class="col-auto">
										<strong>Peut doubler :</strong>
										Oui
									</div>
									<div v-else class="col-auto">
										<strong>Peut doubler :</strong>
										Non
									</div>
									<div v-if="referee2.tutor" class="col-auto">
										<strong style="color:darkgreen">Tuteur</strong>
									</div>
								</div>
								<div class="row justify-content-center align-items-center" style="margin-bottom: 0.5em">
									<div class="col-auto">
										<strong>Club</strong> : <span>{{ referee2.club.name }}</span>
									</div>
								</div>
								<div class="row justify-content-center align-items-center" style="margin-bottom: 0.5em">
									<div v-if="referee2.colleagueMandatory.nationalNumber != 0" class="col-auto">
										<strong>Doit être désigné avec</strong> : <span style="color: #fa7507">{{ referee2.colleagueMandatory.firstName }} {{ referee2.colleagueMandatory.lastName }}</span>
									</div>
								</div>
								<div class="row justify-content-around align-items-center">
									<div v-if="referee2.phone" class="col-auto">
										<strong>N° de téléphone :</strong>
										{{referee2.phone}}
									</div>
									<div v-else class="col-auto">
										<strong>N'a pas de numéro de téléphone enregistré</strong>
									</div>
									<div v-if="referee2.city" class="col-auto">
										<strong>Ville :</strong>
										{{referee2.city}}
									</div>
									<div v-else class="col-auto">
										<strong>Aucune adresse enregistrée</strong>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div v-if="referee2.nationalNumber" style="margin-top: 1em">
				<!--Indisponibilités de la semaine-->
				<div v-if="previousUnavailabilitiesReferee2" class="app-card app-card-orders-table shadow-sm" style="border: 1px solid #fa7507; padding: 0.5em; border-radius: 10px; margin-bottom: 0.5em;">
					<div class="row">
						<div class="col-md-3 col-sm-12" style="text-align: center;">
							<strong>
								Indisponibilité(s) sur 7 jours
							</strong>
						</div>
						<div class="col-md-9 col-sm-12">
							<div class="app-card-body">
								<table class="table table-striped table-hover mb-0 text-left-center" style="text-align: center;">
									<thead>
										<tr>
											<th class="mb-2 text-primary">Date de début</th>
											<th class="mb-2 text-primary">Date de fin</th>
										</tr>
									</thead>
									<tbody v-if="!noResult">
										<tr v-for="item in previousUnavailabilitiesReferee2" :key="item.date" class="align-middle">
											<td class="cell">{{ item.unavailability.start }}</td>
											<td class="cell">{{ item.unavailability.end }}</td>
										</tr>
									</tbody>
									<tbody v-if="noResult">
										<tr>
											<td colspan="2">Aucun résultat</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>

					</div>

				</div>

				<span v-if="refereeDoubleInWeek2  && refereeDoubleInDay2" class="badge bg-dark" style="margin: 0.5em"><strong style="color: orangered">Attention :</strong> L'arbitre sélectionné(e) est déjà déssigné(e) le même jour que le match sélectionné</span>
				<span v-if="!refereeDoubleInDay2 && refereeDoubleInWeek2" class="badge bg-dark" style="margin: 0.5em"><strong style="color: orangered">Attention :</strong> L'arbitre sélectionné(e) est déjà déssigné(e) le même week-end que le match sélectionné</span>


				<!--Désignation du mois précédent-->
				<div v-if="previousAssignmentsReferee2" class="app-card app-card-orders-table shadow-sm" style="margin-top: 0.5em; border: 1px solid #fa7507; padding: 0.5em; border-radius: 10px;">
					<div class="row">
						<div class="col-md-3 col-sm-12" style="text-align: center;">
							<strong>
								6 dernières désignations
							</strong>
							<br />
							<small>
								au cours des 30 derniers jours
							</small>
						</div>
						<div class="col-md-9 col-sm-12">
							<div class="app-card-body">
								<div class="app-card-body">
									<table class="table table-striped table-hover mb-0 text-left-center">
										<thead>
											<tr>
												<th class="mb-2 text-primary">Horaire</th>
												<th class="mb-2 text-primary">Division</th>
												<th class="mb-2 text-primary">Poule</th>
												<th class="mb-2 text-primary">Ville</th>
												<th class="mb-2 text-primary">Locaux</th>
												<th class="mb-2 text-primary">Visiteur</th>
											</tr>
										</thead>
										<tbody>
											<tr v-for="item in previousAssignmentsReferee2.slice().reverse()" :key="item.date" class="align-middle">
												<td class="cell">{{ item.date }}</td>
												<td class="cell">{{ item.divisionId }}</td>
												<td class="cell">{{ item.poule.nomLong }}</td>
												<td class="cell">{{ item.sportsHall.city }}</td>
												<td class="cell">{{ item.homeClub.name }}</td>
												<td class="cell">{{ item.visitorClub.name }}</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>

						</div>
						<!--Fin des désignations du mois précédent-->
					</div>
				</div>
			</div>
			<hr />
			<div class="intro mb-6 text-center" style="margin-top: 0.5em;">
				<div class="form-check form-switch mb-3">
					<input class="form-check-input" type="checkbox" v-model="voirArbitreDejaDesigne" id="voirArbitreDejaDesigne" v-on:click="FiltreArbitreModalDesignation()" style="margin: 0.4em" />
					<label for="allDay">Afficher les arbitres déjà désignés</label>
				</div>
				<div class="form-check form-switch mb-3">
					<input class="form-check-input" type="checkbox" v-model="voirArbitreRegional" id="voirArbitreRegional" v-on:click="FiltreArbitreModalDesignation()" style="margin: 0.4em" />
					<label for="allDay1">Afficher les arbitres régionaux et CF</label>
				</div>
				<div class="form-check form-switch mb-3">
					<input class="form-check-input" type="checkbox" v-model="forceDesignation" id="forceDesignation" v-on:click="FiltreArbitreModalDesignation()" style="margin: 0.4em" />
					<label for="allDay1">Forcer l'enregistrement</label>
				</div>
				<div class="" style="text-align: center">
					<hr>
					<b-button class="btn app-btn-primary" style="background: rgb(42, 87, 136);color: white; width: 250px; margin: 0.5em;" v-on:click="AjouterDesignation()">Ajouter et valider la désignation</b-button>
				</div>
			</div>

		</b-modal>

		<b-modal ref="modalValidedAssignment" hide-header hide-footer>
			<template #modal-title>
				<div>Validation de la désignation</div>
			</template>
			<div class="app-card " style="margin: -1em; margin-bottom: 0">
				<a style="float: right; z-index: 9999; margin-right: 0.5em; top: 0" href="#" block @click="$refs['modalValidedAssignment'].hide()">
					<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
						<path fill-rule="evenodd" d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z" />
						<path fill-rule="evenodd" d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z" />
					</svg>
				</a>
				<div class="app-card-header p-3 border-bottom-0">
					<div class="row align-items-center gx-3">
						<div class="col-auto">
							<div class="app-icon-holder" style="background-color: rgb(42, 87, 136)">
								<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-shield-check" fill="white" xmlns="http://www.w3.org/2000/svg">
									<path d="M10.854 7.854a.5.5 0 0 0-.708-.708L7.5 9.793 6.354 8.646a.5.5 0 1 0-.708.708l1.5 1.5a.5.5 0 0 0 .708 0l3-3z" />
									<path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z" />
								</svg>
							</div>
						</div>
						<div class="col-auto">
							<h4 class="app-card-title" style="color: rgb(42, 87, 136) !important">Valider une désignation</h4>
						</div>
					</div>
				</div>
			</div>
			<div class="app-card app-card-basic d-flex flex-column align-items-center shadow-sm">
				<div class="app-card-body">
					<div class="intro mb-6">
						<span style="font-weight: bold;">Arbitre n°1 : </span>{{ assignmentSelectedBis.historique[0].referee1.firstName }} {{ assignmentSelectedBis.historique[0].referee1.lastName }}<br>
						<span style="font-weight: bold;">Arbitre n°2 : </span>{{ assignmentSelectedBis.historique[0].referee2.firstName }} {{ assignmentSelectedBis.historique[0].referee2.lastName }}<br>
						<div class="" style="text-align: center">
							<hr>
							<b-button class="btn app-btn-primary" style="background: rgb(42, 87, 136);color: white; width: 200px; margin: 0.5em;" v-on:click="ValidationDesignation()">Valider la désignation</b-button>
						</div>
					</div>
				</div>
			</div>
		</b-modal>

		<b-modal ref="modalDeleteAssignment" hide-header hide-footer>
			<template #modal-title>
				<div>Supprimer la désignation</div>
			</template>
			<div class="app-card " style="margin: -1em">
				<a style="float: right; z-index: 9999; margin-right: 0.5em; top: 0" href="#" block @click="$refs['modalDeleteAssignment'].hide()">
					<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
						<path fill-rule="evenodd" d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z" />
						<path fill-rule="evenodd" d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z" />
					</svg>
				</a>
				<div class="app-card-header p-3 border-bottom-0">
					<div class="row align-items-center gx-3">
						<div class="col-auto">
							<div class="app-icon-holder" style="background-color: rgb(42, 87, 136)">
								<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-shield-check" fill="white" xmlns="http://www.w3.org/2000/svg">
									<path d="M6.854 7.146a.5.5 0 1 0-.708.708L7.293 9l-1.147 1.146a.5.5 0 0 0 .708.708L8 9.707l1.146 1.147a.5.5 0 0 0 .708-.708L8.707 9l1.147-1.146a.5.5 0 0 0-.708-.708L8 8.293 6.854 7.146z" />
									<path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z" />
								</svg>
							</div>
						</div>
						<div class="col-auto">
							<h4 class="app-card-title" style="color: rgb(42, 87, 136) !important">Supprimer une désignation</h4>
						</div>
					</div>
				</div>
			</div>
			<div class="app-card app-card-basic d-flex flex-column align-items-center shadow-sm">
				<div class="app-card-body">
					<div class="intro mb-6">
						<span style="font-weight: bold;">Arbitre n°1 : </span>{{ assignmentSelectedBis.historique[0].referee1.firstName }} {{ assignmentSelectedBis.historique[0].referee1.lastName }}<br>
						<span style="font-weight: bold;">Arbitre n°2 : </span>{{ assignmentSelectedBis.historique[0].referee2.firstName }} {{ assignmentSelectedBis.historique[0].referee2.lastName }}<br>
						<div class="" style="text-align: center">
							<hr>
							<b-button class="btn app-btn-primary" style="background: rgb(42, 87, 136);color: white; width: 200px; margin: 0.5em;" v-on:click="deleteAssignement(2)">Supprimer la désignation</b-button>
						</div>
					</div>
				</div>
			</div>

		</b-modal>

		<b-alert :show="alertSuccessCountDown"
				variant="success"
				@dismissed="alertSuccessCountDown=0"
				@dismiss-count-down="alertSuccessCountDownChanged">
			<p>{{ info }}</p>
			<b-progress variant="success"
						:max="dismissSecs"
						:value="alertSuccessCountDown"
						height="4px"></b-progress>
		</b-alert>
		<b-alert :show="alertInfoCountDown"
				variant="info"
				@dismissed="alertInfoCountDown=0"
				@dismiss-count-down="alertInfoCountDownChanged">
			<p>{{ info }}</p>
			<b-progress variant="info"
						:max="dismissSecs"
						:value="alertInfoCountDown"
						height="4px"></b-progress>
		</b-alert>
		<b-alert :show="alertWarningCountDown"
				variant="warning"
				@dismissed="alertWarningCountDown=0"
				@dismiss-count-down="alertWarningCountDownChanged">
			<p>{{ error }}</p>
			<b-progress variant="warning"
						:max="dismissSecs"
						:value="alertWarningCountDown"
						height="4px"></b-progress>
		</b-alert>

		<div class="accordion2" role="tablist">
			<div class="app-card shadow-sm mb-4 border-left-decoration">
				<div class="inner">
					<div block v-b-toggle.actions id="title-legende" type="button" data-toggle="" class="card-header align-middle" style="background-color: rgba(0,0,0,.05) !important; border-bottom: 2px solid rgb(47 87 136)">
						<h5 class="title align-middle" style="color: rgb(47 87 136)">
							<i class="fas fa-search-plus"></i>
							Actions
						</h5>
					</div>
					<b-collapse visible id="actions" accordion="my-accordion2" role="tabpanel" class="app-card-body p-4">
						<div class="row g-2 justify-content-center justify-content-md-end">
							<div class="col-12 col-sm-12 col-lg-10" style="margin-top: -1em; margin-bottom: -1em">
								<div class="col-lg-12">
									<div class="row" style="margin-bottom: 1em">
										<div class="col-lg-2 col-md-3 col-sm-12 col-12">
											<label for="dateStartInput" style="font-weight: bold; margin-top: 0.5em"><small>Date de début</small></label>
											<b-form-input class="form-select-sm ml-auto d-inline-flex text-center" style="padding: .375rem 2.25rem .375rem .75rem; font-weight: 400; line-height: 1.5; height: 30px; border: 1px solid #ced4da; border-radius: .25rem; " :state="dateStartInputState" name="dateStartInput" v-model="dateStartInput" type="date" required></b-form-input>
										</div>
										<div class="col-lg-2 col-md-3 col-sm-12 col-12" style="margin-top: 0.5em">
											<label for="dateEndInput" style="font-weight: bold;"><small>Date de fin</small></label>
											<b-form-input class="form-select-sm ml-auto d-inline-flex text-center" style="padding: .375rem 2.25rem .375rem .75rem; font-weight: 400; line-height: 1.5; height: 30px; border: 1px solid #ced4da; border-radius: .25rem; " :state="dateEndInputState" name="dateEndInput" v-model="dateEndInput" type="date" required></b-form-input>
										</div>
										<div class="row align-items-center">
											<div class="col-lg-2 col-md-3 col-sm-12 col-12" style="margin-top: 0.75em">
												<div class="row">
													<label for="divisionChoice" style="font-weight: bold;"><small>Divisions</small></label>
												</div>
												<v-select class="form-select-sm ml-auto d-inline-flex text-center"
															item-value="last" 
															item-text="name"
															:options="listInfoDivision"
															label="name"
															style="padding: 0" 
															placeholder="Choissisez une division"
															v-model="divisionChoice">
													<template v-slot:option="option">
														{{ option.name }}
													</template>
													<template v-slot:no-options="{ search, searching }">
														<template v-if="searching">
															Pas de résultats pour
															<em>{{ search }}</em>.
														</template>
														<em v-else style="opacity: 0.5">Ecrivez pour rechercher une division</em>
													</template>
												</v-select>
											</div>
											<div class="col-lg-2 col-md-3 col-sm-12 col-12" style="margin-top: 0.75em">
												<div class="row">
													<label for="groupChoice" style="font-weight: bold;"><small>Groupe</small></label>
												</div>

												<v-select :options="listGroup" class="form-select-sm ml-auto d-inline-flex text-center" item-value="last" style="padding: 0" placeholder="Choissisez une division" label="wording" v-model="groupChoice">
													<template v-slot:option="option">
														{{ option.wording }}
													</template>
													<template v-slot:no-options="{ search, searching }">
														<template v-if="searching">
															Pas de résultats pour
															<em>{{ search }}</em>.
														</template>
														<em v-else style="opacity: 0.5">Ecrivez pour rechercher un groupe</em>
													</template>
												</v-select>
											</div>
											<div class="col-lg-2 col-md-3 col-sm-12 col-12" style="margin-top: 0.75em">
												<div class="row">
													<label for="refereeChoice" style="font-weight: bold;"><small>Arbitre</small></label>
												</div>

												<v-select :options="listReferee" class="form-select-sm ml-auto d-inline-flex text-center" :filter-by="myFilter" label="firstName" style="padding: 0" placeholder="Choissisez une division" v-model="refereeChoice">
													<template v-slot:option="option">
														{{ option.firstName }} {{ option.lastName }}
													</template>
													<template v-slot:no-options="{ search, searching }">
														<template v-if="searching">
															Pas de résultats pour
															<em>{{ search }}</em>.
														</template>
														<em v-else style="opacity: 0.5">Ecrivez pour rechercher un arbitre</em>
													</template>
													<template slot="selected-option" slot-scope="option">
														{{ option.firstName }} {{ option.lastName }}
													</template>
												</v-select>
											</div>
											<div class="col-lg-2  col-md-3 col-sm-12 col-12" style="margin-top: 0.75em">
												<div class="row">
													<label for="clubChoice" style="font-weight: bold;"><small>Club</small></label>
												</div>

												<v-select :options="listClub" class="form-select-sm ml-auto d-inline-flex text-center" style="padding: 0"  placeholder="Choissisez un club" label="name" v-model="clubChoice">
													<template v-slot:option="option">
														{{ option.name }}
													</template>
													<template v-slot:no-options="{ search, searching }">
														<template v-if="searching">
															Pas de résultats pour
															<em>{{ search }}</em>.
														</template>
														<em v-else style="opacity: 0.5">Ecrivez pour rechercher un club</em>
													</template>
												</v-select>
											</div>
										</div>
									</div>
									<div class="row">
										<div class="col-12">
											<div style="text-align: left;">
												<b-button class="btn app-btn-primary" style=" width: 220px; margin: 0.25em" v-on:click="datesSelected()">Afficher les matchs</b-button>
											</div>
										</div>
									</div>
									<hr style="background-color:rgb(255 106 0); height: 2.5px" />
									<div class="row align-items-center">
										<div class="row">
											<label for="week" style="font-weight: bold;"><small>Lancer la désignation automatisée</small></label>
										</div>
										<div class="col-lg-2">
											<div class="align-items-center" style="text-align: center;">
												<select v-model="week" class="form-select form-select-sm ml-auto d-inline-flex">
													<option value="">
														Choisissez la semaine
													</option>
													<option v-for="item in listWeek" :key="item.wording" :value="item.wording">
														{{ item.wording }}
													</option>
												</select>
											</div>
										</div>
										<div class="col-xs-12 col-sm-12 col-md-10 justify-content-evenly" style="margin-top: 0.5em">
											<div class="align-items-center" style="text-align: left;">
												<b-button class="btn app-btn-primary" style="background: rgb(42, 87, 136);color: white; width: 200px; margin: 0.5em;" v-on:click="generationAssignement()">Générer les désignations de la semaine</b-button>
												<b-button class="btn app-btn-secondary" style="border-color: rgb(42, 87, 136); color: rgb(42, 87, 136); width: 150px; margin: 0.5em;" v-on:click="ValiderToutesLesDesignations()">Valider toutes les désignations</b-button>
												<b-button class="btn app-btn-secondary" style="border-color: rgb(42, 87, 136); color: rgb(42, 87, 136); width: 150px; margin: 0.5em;" v-on:click="SupprimerToutesLesDesignations()">Supprimer toutes les désignations</b-button>
											</div>
										</div>
									</div>

								</div>
							</div><!--//col-->
							<div class="col-12 col-lg-2 align-center align-items-middle" style="text-align: center;">
								<a class="btn app-btn-secondary" style="color: #fa7507; border: 1px solid #fa7507; border-radius: .25rem;" v-on:click="downloadCSV()">
									<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-download mr-1" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
										<path fill-rule="evenodd" d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
										<path fill-rule="evenodd" d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
									</svg>
									Extraction CSV
								</a>
							</div>
						</div>
					</b-collapse>
				</div>
			</div>
		</div>

		<div v-if="assignmentVisible && hidden == false" class="tab-content" id="orders-table-tab-content">
			<form class="app-search-form">
				<input name="search" type="text" id="myInput" @keyup="FiltreTableau()" placeholder="Recherchez un nom, un prénom, un club" title="Recherche textuelle" class="form-control search-input" style="height: 50px; margin-bottom: 1em">
				<button disabled class="btn search-btn btn-primary" value="Search" style="padding-top: 1em">
					<svg class="svg-inline--fa fa-search fa-w-16" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="search" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
						<path fill="currentColor" d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"></path>
					</svg>
				</button>
			</form>
			<div class="tab-pane fade show active" id="orders-all" role="tabpanel" aria-labelledby="orders-all-tab">
				<div class="app-card app-card-orders-table shadow-sm mb-5">
					<div class="app-card-body">
						<div class="table-responsive table-stiped">
							<table id="myTable2" class="table table-striped table-hover mb-0 text-center">
								<thead>
									<tr>
										<th class="mb-2 text-primary" v-on:click="TriTableau(0)">
											Division
											<span v-if="etatTriColumn == 1 && etatTriTableau == true">
												<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-down-short" viewBox="0 0 16 16">
													<path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L7.5 10.293V4.5A.5.5 0 0 1 8 4z" />
												</svg>
											</span>
											<span v-if="etatTriColumn == 1 && etatTriTableau == false">
												<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-up-short" viewBox="0 0 16 16">
													<path fill-rule="evenodd" d="M8 12a.5.5 0 0 0 .5-.5V5.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 5.707V11.5a.5.5 0 0 0 .5.5z" />
												</svg>
											</span>
										</th>
										<th class="mb-2 text-primary" v-on:click="TriTableau(0)">
											Poule
										</th>
										<th class="mb-2 text-primary" v-on:click="TriTableau(1)">
											Numéro
											<span v-if="etatTriColumn == 2 && etatTriTableau == true">
												<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-down-short" viewBox="0 0 16 16">
													<path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L7.5 10.293V4.5A.5.5 0 0 1 8 4z" />
												</svg>
											</span>
											<span v-if="etatTriColumn == 2 && etatTriTableau == false">
												<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-up-short" viewBox="0 0 16 16">
													<path fill-rule="evenodd" d="M8 12a.5.5 0 0 0 .5-.5V5.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 5.707V11.5a.5.5 0 0 0 .5.5z" />
												</svg>
											</span>
										</th>
										<th class="mb-2 text-primary" v-on:click="TriTableau(2)">
											Horaire
											<span v-if="etatTriColumn == 3 && etatTriTableau == true">
												<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-down-short" viewBox="0 0 16 16">
													<path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L7.5 10.293V4.5A.5.5 0 0 1 8 4z" />
												</svg>
											</span>
											<span v-if="etatTriColumn == 3 && etatTriTableau == false">
												<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-up-short" viewBox="0 0 16 16">
													<path fill-rule="evenodd" d="M8 12a.5.5 0 0 0 .5-.5V5.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 5.707V11.5a.5.5 0 0 0 .5.5z" />
												</svg>
											</span>
										</th>
										<th class="mb-2 text-primary" v-on:click="TriTableau(3)">
											Salle de sports
											<span v-if="etatTriColumn == 4 && etatTriTableau == true">
												<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-down-short" viewBox="0 0 16 16">
													<path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L7.5 10.293V4.5A.5.5 0 0 1 8 4z" />
												</svg>
											</span>
											<span v-if="etatTriColumn == 4 && etatTriTableau == false">
												<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-up-short" viewBox="0 0 16 16">
													<path fill-rule="evenodd" d="M8 12a.5.5 0 0 0 .5-.5V5.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 5.707V11.5a.5.5 0 0 0 .5.5z" />
												</svg>
											</span>
										</th>
										<th class="mb-2 text-primary" v-on:click="TriTableau(4)">
											Locaux
											<span v-if="etatTriColumn == 5 && etatTriTableau == true">
												<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-down-short" viewBox="0 0 16 16">
													<path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L7.5 10.293V4.5A.5.5 0 0 1 8 4z" />
												</svg>
											</span>
											<span v-if="etatTriColumn == 5 && etatTriTableau == false">
												<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-up-short" viewBox="0 0 16 16">
													<path fill-rule="evenodd" d="M8 12a.5.5 0 0 0 .5-.5V5.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 5.707V11.5a.5.5 0 0 0 .5.5z" />
												</svg>
											</span>
										</th>
										<th class="mb-2 text-primary" v-on:click="TriTableau(5)">
											Visiteur
											<span v-if="etatTriColumn == 6 && etatTriTableau == true">
												<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-down-short" viewBox="0 0 16 16">
													<path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L7.5 10.293V4.5A.5.5 0 0 1 8 4z" />
												</svg>
											</span>
											<span v-if="etatTriColumn == 6 && etatTriTableau == false">
												<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-up-short" viewBox="0 0 16 16">
													<path fill-rule="evenodd" d="M8 12a.5.5 0 0 0 .5-.5V5.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 5.707V11.5a.5.5 0 0 0 .5.5z" />
												</svg>
											</span>
										</th>
										<th class="mb-2 text-primary">Arbitre n°1</th>
										<th class="mb-2 text-primary">Arbitre n°2</th>
										<th class="mb-2 text-primary">Actions</th>
									</tr>
								</thead>
								<tbody v-if="!noResult">
									<tr v-for="(item,index) in assignments" :key="index" :href="item.match.id">
										<td class="cell">{{ item.match.divisionId }}</td>
										<td class="cell">{{ item.match.poule.nomLong }}</td>
										<td class="cell">{{ item.match.id }}</td>
										<td class="cell">{{ item.match.date}}</td>
										<td class="cell">{{ item.match.sportsHall.name }}</td>
										<td class="cell">{{ item.match.homeClub.name }}</td>
										<td class="cell">{{ item.match.visitorClub.name }}</td>
										<td class="cell" v-if="item.historique !== undefined && item.historique[0].status !== 2">{{ item.historique[0].referee1.lastName }} {{ item.historique[0].referee1.firstName }}</td>
										<td class="cell" v-if="item.historique !== undefined && item.historique[0].status !== 2">{{ item.historique[0].referee2.lastName }} {{ item.historique[0].referee2.firstName }}</td>
										<td class="cell" v-if="item.historique == undefined || item.historique[0].status == 2" colspan="2"></td>
										<td class="cell" v-if="item.historique !== undefined">
											<b-button class="btn app-btn-secondary" style="margin: 3px" v-on:click="InfoDesignationModal(item)">
												<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentcolor" class="bi bi-info-lg" viewBox="0 0 16 16">
													<path d="m10.277 5.433-4.031.505-.145.67.794.145c.516.123.619.309.505.824L6.101 13.68c-.34 1.578.186 2.32 1.423 2.32.959 0 2.072-.443 2.577-1.052l.155-.732c-.35.31-.866.434-1.206.434-.485 0-.66-.34-.536-.939l1.763-8.278zm.122-3.673a1.76 1.76 0 1 1-3.52 0 1.76 1.76 0 0 1 3.52 0z" />
												</svg>
											</b-button>
											<b-button class="btn app-btn-secondary" style="margin: 3px" v-on:click="AjouterDesignationModal(item)">
												<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentcolor" class="bi bi-plus-lg" viewBox="0 0 16 16">
													<path d="M8 0a1 1 0 0 1 1 1v6h6a1 1 0 1 1 0 2H9v6a1 1 0 1 1-2 0V9H1a1 1 0 0 1 0-2h6V1a1 1 0 0 1 1-1z" />
												</svg>
											</b-button>
											<b-button class="btn app-btn-secondary" style="margin: 3px" v-on:click="ValidationDesignationModal(item)" v-if="item.historique[0].status == 0">
												<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentcolor" class="bi bi-check-lg" viewBox="0 0 16 16">
													<path d="M13.485 1.431a1.473 1.473 0 0 1 2.104 2.062l-7.84 9.801a1.473 1.473 0 0 1-2.12.04L.431 8.138a1.473 1.473 0 0 1 2.084-2.083l4.111 4.112 6.82-8.69a.486.486 0 0 1 .04-.045z" />
												</svg>
											</b-button>
											<b-button class="btn app-btn-secondary" style="margin: 3px" v-on:click="deleteAssignementModal(item)" v-if="item.historique[0].status == 1">
												<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentcolor" class="bi bi-trash" viewBox="0 0 16 16">
													<path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
													<path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
												</svg>
											</b-button>
											<b-button v-if="item.historique !== undefined && !item.historique[0].refereeAvailable" class="btn btn-warning" style="margin: 3px">
												/ ! \
											</b-button>
										</td>
										<td class="cell" v-else>
											<b-button class="btn app-btn-secondary" style="margin: 3px" v-on:click="InfoDesignationModal(item)">
												<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentcolor" class="bi bi-info-lg" viewBox="0 0 16 16">
													<path d="m10.277 5.433-4.031.505-.145.67.794.145c.516.123.619.309.505.824L6.101 13.68c-.34 1.578.186 2.32 1.423 2.32.959 0 2.072-.443 2.577-1.052l.155-.732c-.35.31-.866.434-1.206.434-.485 0-.66-.34-.536-.939l1.763-8.278zm.122-3.673a1.76 1.76 0 1 1-3.52 0 1.76 1.76 0 0 1 3.52 0z" />
												</svg>
											</b-button>
											<b-button class="btn app-btn-secondary" style="margin: 3px" v-on:click="AjouterDesignationModal(item)">
												<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentcolor" class="bi bi-plus-lg" viewBox="0 0 16 16">
													<path d="M8 0a1 1 0 0 1 1 1v6h6a1 1 0 1 1 0 2H9v6a1 1 0 1 1-2 0V9H1a1 1 0 0 1 0-2h6V1a1 1 0 0 1 1-1z" />
												</svg>
											</b-button>
											<b-button v-if="item.historique !== undefined && !item.historique[0].refereeAvailable" class="btn btn-warning" style="margin: 3px">
												/ ! \
											</b-button>
										</td>
									</tr>

								</tbody>
								<tbody v-if="noResult">
									<tr>
										<td colspan="9">Aucun résultat</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>


	</div>

</template>

<style>
	.listeInfoMatch{
		margin: 0.5em
	}
    .multiselect__content-wrapper /*, .multiselect__element */ {
        width: fit-content !important;
        white-space: nowrap !important;
        overflow-x: hidden !important;
        text-overflow: ellipsis !important;
    }
    .multiselect__single {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis !important;
    }
    .multiselect__above {
        white-space: nowrap !important;
        overflow: hidden !important;
        word-break: break-all !important;
        text-overflow: ellipsis !important;
    }
    .multiselect {
        box-sizing: border-box;
        position: relative;
        width: 100%;
        max-height: 30px !important;
        text-align: left;
        background: none !important;
        color: #35495e;
        text-overflow: ellipsis !important;
    }
    .multiselect__single {
        /*display: none !important;*/
        display: inline-block !important;
        margin-top: -0.1em !important;
        font-size: 0.875rem !important;
        color: #212529 !important;
        white-space: nowrap !important;
        overflow: hidden !important;
        box-sizing: border-box !important;
        word-break: break-all !important;
        max-width: 100px !important;
        box-sizing: border-box !important;
        text-overflow: ellipsis !important;
		text-align: center !important
    }
    .multiselect__input {
        /*display: none !important;*/
        display: inline-block !important;
        margin-top: -0.1em !important;
        font-size: 0.875rem !important;
        color: #212529 !important;
        white-space: nowrap !important;
        overflow: hidden !important;
        box-sizing: border-box !important;
        word-break: break-all !important;
        max-width: auto !important;
        box-sizing: border-box !important;
        text-overflow: ellipsis !important;
        text-align: center !important
    }
    .multiselect__select {
        height: 30px !important;
        text-overflow: ellipsis !important;
    }
    .multiselect__active > .multiselect__select {
        height: 30px !important;
        text-overflow: ellipsis !important;
    }
    .multiselect__current, .multiselect__select {
        /*display: none !important*/
        padding-bottom: 0.5em !important;
        text-overflow: ellipsis !important;
    }
    .multiselect__tags {
        margin-top: -0.1em !important;
        font-size: 0.875rem !important;
        min-height: 21px !important;
        max-height: 21px !important;
        display: inline-block !important;
        padding: 0 !important;
        border-radius: 0 !important;
        border: 0 !important;
        color: #212529 !important;
        text-overflow: ellipsis !important;
    }
    .multiselect__content {
        font-size: 0.875rem !important
    }


    .v-select {
        height: 30px !important;
		width: 100% !important
    }

	.vs__clear{
		display: block !important;
	}

    .vs__selected {
        word-wrap: normal !important;
        overflow: hidden !important;
        white-space: nowrap !important;
    }

    .vs__selected-options {
        word-wrap: normal !important;
        overflow: hidden !important;
        white-space: nowrap !important;
        width: 60% !important;
    }

    .vs__dropdown-toggle {
        height: 30px !important;
        width: 100% !important; overflow: hidden !important;
        white-space: nowrap !important;
    }
    .vs__dropdown-menu {
        width: auto !important;
    }

    .popover {
        width: 1000px;
        max-width: 1000px;
		min-width: 600px;
    }
</style>

<script>
import jwt_decode from "jwt-decode";

export default {
	name: 'Assignment',
	computed:
	{
        dateStartInputState: function () {
            var reDate = /^(20\d\d)-([1-9]|0\d|1[0-2])-([1-9]|[0-2]\d|3[0-1])$/;
            if (reDate.test(this.dateStartInput)) { return true; }
            else { return false; }
        },
		dateEndInputState: function () {
			var reDate = /^(20\d\d)-([1-9]|0\d|1[0-2])-([1-9]|[0-2]\d|3[0-1])$/;
			if (reDate.test(this.dateEndInput)) { return true; }
			else { return false; }
		},
		refereeDoubleInWeek1: function()
		{
			if (this.addAssignmentReferee1 != 0)
			{
				var hh = this.listRefereeAvailable.find(x => x.nationalNumber === this.addAssignmentReferee1);
				return hh.doubleInWeek;
			}
			return false;
		},
		refereeDoubleInDay1: function()
		{
			if (this.addAssignmentReferee1 != 0)
			{
				var hh = this.listRefereeAvailable.find(x => x.nationalNumber === this.addAssignmentReferee1);
				return hh.doubleInDay;
			}
			return false;
		},
		refereeDoubleInWeek2: function()
		{
			if (this.addAssignmentReferee2 != 0)
			{
				var hh = this.listRefereeAvailable.find(x => x.nationalNumber === this.addAssignmentReferee2);
				return hh.doubleInWeek;
			}
			return false;
		},
        refereeDoubleInDay2: function () {
            if (this.addAssignmentReferee2 != 0) {
                var hh = this.listRefereeAvailable.find(x => x.nationalNumber === this.addAssignmentReferee2);
                return hh.doubleInDay;
            }
            return false;
		},
	},
	data() {
		return {
			// Variable message alert
			dismissSecs: 4,
			alertSuccessCountDown: 0,
			alertWarningCountDown: 0,
			alertInfoCountDown: 0,
			info:'',
			error:'',

			listWeek:[],
			week:'',
			assignmentVisible:false,
			assignments:[],
			infoBeginDate: '',
			infoEndDate: '',
			previousDate: [],
			assignmentSelected:{
				"match":{
					"sportsHall":{},
					"homeClub":{},
					"visitorClub":{},
					"poule":{}
				},
				"historique": [{
					"referee1":{},
					"referee2":{}
				}]
			},
			assignmentSelectedBis:{
				"historique":[{
					"referee1":{},
					"referee2":{}
				}],
				"match":{
					"sportsHall":{},
					"homeClub":{},
					"visitorClub":{},
					"poule":{}
				}
			},
			noResult: false,

			etatTriTableau: true,
			etatTriColumn: 0,

            dateStartInput: '',
			dateEndInput: '',

			listInfoDivision: [],
			listClub: [],
			listReferee: [],
			listGroup: [],
            divisionChoice: {
                id: '',
                name: "Choisissez une division",},
			clubChoice: {
				id: '',
				name: "Choisissez un club",
			},
            refereeChoice: {
                nationalNumber: '',
                firstName: "Choisissez un arbitre",
            },
            groupChoice: {
                id: '',
                wording: "Choisissez un groupe",
            },

			listRefereeAvailable: [],
			filterListReferee1Pos1Available:[],
			filterListReferee1Pos2Available:[],
			filterListReferee2Pos1Available:[],
			filterListReferee2Pos2Available: [],
			listGroupAvailable:[],
			addAssignmentReferee1:0,
			groupAssignmentReferee1:"0",
			addAssignmentReferee2:0,
			groupAssignmentReferee2:"0",

			matchesUnavailabilityReferee1: [],
			previousAssignmentsReferee1: [],
			previousUnavailabilitiesReferee1: [],
			matchesUnavailabilityReferee2: [],
            previousAssignmentsReferee2: [],
            previousUnavailabilitiesReferee2: [],

			referee1: [],
			referee2: [],
			voirArbitreDejaDesigne: true,
			voirArbitreRegional: false,
			forceDesignation: false,
			
			hidden: true,
            myFilter: (option, label, search) => {
				let temp = search.toLowerCase();
				return option.firstName.toLowerCase().indexOf(temp) > -1 ||
                    option.lastName.toLowerCase().indexOf(temp) > -1
            }
		}
	},

	mounted()
	{
		const monthNames = ["Janv.", "Févr.", "Mars", "Avr.", "Mai", "Juin","Juill.", "Août", "Sept.", "Oct.", "Nov.", "Déc."];
		this.listWeek = [];
		var boucle = true;
		var i = 0;


		while (boucle)
		{
			let t = new Date(new Date().setDate(new Date().getDate() + 7 * i));
			var monday;
			var sunday;

            if (t.getDay() == 0) {
                monday = new Date(t.setDate(t.getDate() - t.getDay() - 6));
                sunday = new Date(t.setDate(t.getDate() + (7 - t.getDay())));
            }
            else {
                monday = new Date(t.setDate(t.getDate() - t.getDay() + 1));
                sunday = new Date(t.setDate(t.getDate() + (7 - t.getDay())));
			}

			var week = {
				wording: monday.getDate() + " " + monthNames[monday.getMonth()] + " - " + sunday.getDate() + " " + monthNames[sunday.getMonth()],
				start: monday,
				end:sunday
			}

			this.listWeek.push(week);
			i ++;

			if (t.getMonth() == 7)
				boucle = false;
		}

        this.checkToken();
        let config = {
            headers: {
                'Authorization': 'Bearer ' + localStorage.token
            }
        };

        this.$axios.get('https://api.clickandball.fr/division', config)
            .then(response => {
                if (response.status == 200 && response.data.error) {
                    this.showAlertWarning('Erreur : ' + response.data.error);
                }
                else if (response.status == 200) {
                    this.listInfoDivision = response.data;

                    if (this.listInfoDivision.length === 0) {
                        this.noResultDivision = true;
                    }
                }
                else {
                    this.showAlertWarning('Code erreur : ' + response.status);
                }
			})

        this.$axios.get('https://api.clickandball.fr/club', config)
            .then(response => {
                if (response.status == 200 && response.data.error) {
                    this.showAlertWarning('Erreur : ' + response.data.error);
                }
                else if (response.status == 200) {
                    this.listClub = response.data;
                }
                else {
                    this.showAlertWarning('Code erreur : ' + response.status);
                }
            })
            .catch(error => {
                this.showAlertWarning('Erreur : ' + error);
			});
        this.$axios.get('https://api.clickandball.fr/utilisateur/arbitre', config)
            .then(response => {
                if (response.status == 200 && response.data.error) {
                    this.showAlertWarning('Erreur : ' + response.data.error);
                }
                else if (response.status == 200) {
                    this.listReferee = response.data;
                }
                else {
                    this.showAlertWarning('Code erreur : ' + response.status);
                }
            })
            .catch(error => {
                this.showAlertWarning('Erreur : ' + error);
			});
        this.$axios.get('https://api.clickandball.fr/groupe', config)
            .then(response => {
                if (response.status == 200 && response.data.error) {
                    this.showAlertWarning('Erreur : ' + response.data.error);
                }
                else if (response.status == 200) {
                    this.listGroup = response.data;
                }
                else {
                    this.showAlertWarning('Code erreur : ' + response.status);
                }
            })
            .catch(error => {
                this.showAlertWarning('Erreur : ' + error);
			});
	},

	methods: {

        fistNameWithLastName(option) {
            return `${option.firstName} ${option.lastName}`;
        },
        // Méthodes message alert
		alertSuccessCountDownChanged(alertSuccessCountDown)
		{
			this.alertSuccessCountDown = alertSuccessCountDown
		},
		alertWarningCountDownChanged(alertWarningCountDown)
		{
			this.alertWarningCountDown = alertWarningCountDown
		},
        alertInfoCountDownChanged(alertInfoCountDown) {
            this.alertInfoCountDown = alertInfoCountDown
        },
		showAlertSuccess(info)
		{
			this.info = info;
			this.alertSuccessCountDown = this.dismissSecs
		},
		showAlertWarning(error)
		{
			this.error = error;
			this.alertWarningCountDown = this.dismissSecs
		},
        showInfoSuccess(info) {
            this.info = info;
            this.alertInfoCountDown = this.dismissSecs
        },
		checkToken()
		{
			if (localStorage.token)
			{
				var jsonToken = jwt_decode(localStorage.token);
				if ((jsonToken.exp - 60) < Math.round(Date.now()/1000))
				{
					localStorage.token = null;
					this.connected = false;
					this.$router.push({ path: '/login'})
				}
			}
		},

		// Méthodes de gestion de date
		getDateString(date)
		{
			// prints date & time in YYYY-MM-DD format
			return date.getFullYear() + "-" + ("0" + (date.getMonth() + 1)).slice(-2) + "-" + ("0" + date.getDate()).slice(-2);
		},
		datesSelected()
		{
			this.checkToken();
			
			this.assignments = [];
			this.assignmentVisible = true;
			this.noResult = false;
			
			let config = {
				headers: {
					'Authorization': 'Bearer ' + localStorage.token
				}
			};

			if (this.refereeChoice.nationalNumber == 0) {
				this.refereeChoice.nationalNumber = ''
			}
            if (this.clubChoice.id == 0) {
                this.clubChoice.id = ''
			}

			this.$axios.get('https://api.clickandball.fr/designation?start=' + this.dateStartInput + "&end=" + this.dateEndInput + "&club=" + this.clubChoice.id + "&division=" + this.divisionChoice.id + "&nationalNumber=" + this.refereeChoice.nationalNumber + "&group=" + this.groupChoice.id, config)
					.then(response => {
						if (response.status == 200 && response.data.error)
						{
							this.showAlertWarning('Erreur : ' + response.data.error);
                            this.hidden = true;
						}
						else if (response.status == 200)
						{
							this.assignments = response.data;
							this.$axios.get('https://api.clickandball.fr/designation/match?start=' + this.dateStartInput + "&end=" + this.dateEndInput + "&club=" + this.clubChoice.id + "&division=" + this.divisionChoice.id + "&nationalNumber=" + this.refereeChoice.nationalNumber + "&group=" + this.groupChoice.id, config)
								.then(response => {
									if (response.status == 200 && response.data.error)
									{
										this.showAlertWarning('Erreur : ' + response.data.error);
                                        this.hidden = true;
									}
									else if (response.status == 200)
									{
										for (var i = 0; i < response.data.length; i++)
										{
											if (!this.assignments.find(x => x.match.id === response.data[i].id && x.match.divisionId === response.data[i].divisionId))
											{
												this.assignments.push({"match": {
														id:response.data[i].id,
														poule:response.data[i].poule,
														divisionId:response.data[i].divisionId,
														date:response.data[i].date,
														sportsHall:response.data[i].sportsHall,
														homeClub:response.data[i].homeClub,
														visitorClub:response.data[i].visitorClub,
														address:response.data[i].address,
														played:response.data[i].played
													}});
											}
										}
										if (this.assignments.length === 0)
										{
											this.noResult = true;
										}

										this.hidden = false;
									}
									else
									{
										this.showAlertWarning('Code erreur : ' + response.status);
                                        this.hidden = true;
									}
								})
						}
						else
						{
							this.showAlertWarning('Code erreur : ' + response.status);
                            this.hidden = true;
						}
					})
					.catch(error => {
						this.showAlertWarning('Erreur : ' + error);
                        this.hidden = true;
					});
			//}
		},
		getMonday(d)
		{
			d = new Date(d);
			var day = d.getDay(),
			diff = d.getDate() - day + (day == 0 ? -6:1); // adjust when day is sunday
			return new Date(d.setDate(diff));
		},
		getSunday(d)
		{
			d = new Date(d);
			var day = d.getDay(),
			diff = d.getDate() - day + (day == 0 ? -6:1) + 6; // adjust when day is sunday
			return new Date(d.setDate(diff));
		},

		// Méthode d'information désignation
		InfoDesignationModal(info)
		{
			this.$refs['modalInfo'].show();
			this.assignmentSelected = info;
		},

		// Méthodes de validation désignation
		ValidationDesignationModal(info)
		{
			this.$refs['modalValidedAssignment'].show();
			this.assignmentSelectedBis = info
		},
		ValidationDesignation()
		{
			this.checkToken();
			this.$refs['modalValidedAssignment'].hide();
			let json = [{
					id : parseInt(this.assignmentSelectedBis.historique[0].id),
					status : 1
			}];
			let config = {
				headers: {
					'Authorization': 'Bearer ' + localStorage.token,
				}
			};

			this.$axios.put('https://api.clickandball.fr/designation', json, config)
				.then(response => {
					if (response.status == 200 && response.data.error)
					{
						this.showAlertWarning('Erreur : ' + response.data.error);
					}
					else if (response.status == 200)
					{
						this.showAlertSuccess("Désignation validée");
                        this.datesSelected();
					}
					else
					{
						this.showAlertWarning('Erreur : ' + response.statusText);
					}
				})
				.catch(error => {
					this.showAlertWarning('Erreur : ' + error);
				});
		},

		// Méthodes d'ajout désignation
		AjouterDesignationModal(info)
		{
			this.checkToken();

            this.referee1 = '';
            this.referee2 = '';

			this.addAssignmentReferee1 = 0;
			this.groupAssignmentReferee1 = "0";
			this.addAssignmentReferee2 = 0;
			this.groupAssignmentReferee2 = "0";
			this.filterListReferee1Pos1Available = [];
			this.filterListReferee1Pos2Available = [];
			this.filterListReferee2Pos1Available = [];
			this.filterListReferee2Pos2Available = [];
			this.showAllReferee = false;
			this.showRefereeSuperior = false;

			this.$refs['modalAddAssignment'].show();
			this.assignmentSelected = info;

			let config = {
				headers: {
					'Authorization': 'Bearer ' + localStorage.token
				}
			};

			this.infoBeginDate = '';
			this.infoEndDate = '';
			this.previousDate = [];
			this.infoEndDate = this.getSunday(new Date(info.match.date));
			this.infoBeginDate = this.getMonday(new Date(info.match.date));
			this.infoBeginDate.setDate(this.infoBeginDate.getDate()-30);
            var days = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'];
			
            var from = this.infoBeginDate
            while (from <= this.infoEndDate) {
                if (days[from.getDay()] == 'Samedi' | days[from.getDay()] == 'Dimanche') {
                    this.previousDate.push(this.getDateString(new Date(from)));
                }
                
                from = new Date(from.setDate(from.getDate() + 1));
			}

			this.$axios.get('https://api.clickandball.fr/utilisateur/arbitre?idMatch=' + info.match.id + "&divisionMatch=" + info.match.divisionId + "&pouleMatch=" + info.match.poule.id + "&voirTout=" + this.showAllReferee, config)
				.then(response => {
					if (response.status == 200 && response.data.error)
					{
						this.showAlertWarning('Erreur : ' + response.data.error);
					}
					else if (response.status == 200)
					{
						this.listRefereeAvailable = response.data;

						//Trier les arbitre par rapport à leur nom
						this.listRefereeAvailable.sort(function (a, b) {
                            if (a.lastName < b.lastName)
                                return -1;
                            if (a.lastName > b.lastName)
                                return 1;
                            return 0;
						});

						this.FiltreArbitreModalDesignation();
					}
					else
					{
						this.showAlertWarning('Code erreur: ' + response.status);
					}
				})
				.catch(error => {
					this.showAlertWarning('Erreur: ' + error);
				});

			this.$axios.get('https://api.clickandball.fr/groupe', config)
				.then(response => {
					if (response.status == 200 && response.data.error)
					{
						this.showAlertWarning('Erreur : ' + response.data.error);
					}
					else if (response.status == 200)
					{
						this.listGroupAvailable = response.data;
					}
					else
					{
						this.showAlertWarning('Code erreur : ' + response.status);
					}
				})
				.catch(error => {
					this.showAlertWarning('Erreur: ' + error);
				});
		},
		AjouterDesignation()
		{
			this.checkToken();
			this.$refs['modalAddAssignment'].hide();

			if (this.addAssignmentReferee1 == this.addAssignmentReferee2 && this.addAssignmentReferee1 != 0 && this.addAssignmentReferee2 != 0)
			{
				this.showAlertWarning('Problème sur la sélection des arbitres');
			}
			else
			{
				let json = {
					referee1: this.addAssignmentReferee1,
					referee2: this.addAssignmentReferee2,
					matchId: this.assignmentSelected.match.id,
					matchDivision: this.assignmentSelected.match.divisionId,
					matchPoule: this.assignmentSelected.match.poule.id,
					status: 1,
					forceDesignation: this.forceDesignation
				};
				let config = {
					headers: {
						'Authorization': 'Bearer ' + localStorage.token
					}
				};

				this.$axios.post('https://api.clickandball.fr/designation', json, config)
					.then(response => {
						if (response.status == 200 && response.data.error)
						{
							this.showAlertWarning('Erreur : ' + response.data.error);
						}
						else if (response.status == 200)
						{
							this.showAlertSuccess("Désignation sauvegardée");
                            this.datesSelected();
						}
						else
						{
							this.showAlertWarning('Erreur : ' + response.statusText);
						}
					})
					.catch(error => {
						this.showAlertWarning('Erreur: ' + error);
					});
			}
		},

		// Méthodes récupération informations arbitre sélectionné - modal sélection nouveaux arbitres
		GetUnavailabilityReferee1()
		{
			this.referee1 = this.listRefereeAvailable.find(x => x.nationalNumber === this.addAssignmentReferee1);

			let config = {
				headers: {
					'Authorization': 'Bearer ' + localStorage.token
				}
			};

			this.$axios.get('https://api.clickandball.fr/designation/match?nationalNumber=' + this.referee1.nationalNumber + '&start=' + this.getDateString(this.getMonday(new Date(this.assignmentSelected.match.date))) + "&end=" + this.getDateString(this.getSunday(new Date(this.assignmentSelected.match.date))), config)
				.then(response => {
					if (response.status == 200 && response.data.error)
					{
						this.showAlertWarning('Erreur : ' + response.data.error);
					}
					else if (response.status == 200)
					{
						this.matchesUnavailabilityReferee1 = response.data;
					}
					else
					{
						this.showAlertWarning('Code erreur : ' + response.status);
					}
				})
				.catch(error => {
					this.showAlertWarning('Erreur : ' + error);
				});
		},
		GetUnavailabilityReferee2()
		{
			this.referee2 = this.listRefereeAvailable.find(x => x.nationalNumber === this.addAssignmentReferee2);

			let config = {
				headers: {
					'Authorization': 'Bearer ' + localStorage.token
				}
			};

			this.$axios.get('https://api.clickandball.fr/designation/match?nationalNumber=' + this.referee2.nationalNumber + '&start=' + this.getDateString(this.getMonday(new Date(this.assignmentSelected.match.date))) + "&end=" + this.getDateString(this.getSunday(new Date(this.assignmentSelected.match.date))), config)
				.then(response => {
					if (response.status == 200 && response.data.error)
					{
						this.showAlertWarning('Erreur : ' + response.data.error);
					}
					else if (response.status == 200)
					{
						this.matchesUnavailabilityReferee2 = response.data;
					}
					else
					{
						this.showAlertWarning('Code erreur : ' + response.status);
					}
				})
				.catch(error => {
					this.showAlertWarning('Erreur : ' + error);
				});
		},
		GetPreviousAssignmentReferee1() {
			this.referee1 = this.listRefereeAvailable.find(x => x.nationalNumber === this.addAssignmentReferee1);

			var beginDate = '';
			beginDate = new Date(this.assignmentSelected.match.date)
			beginDate = beginDate.setDate(beginDate.getDate() - 30);
			var date = new Date(beginDate)

			let config = {
				headers: {
					'Authorization': 'Bearer ' + localStorage.token
				}
			};

			this.$axios.get('https://api.clickandball.fr/designation/match?nationalNumber=' + this.referee1.nationalNumber + '&start=' + this.getDateString(date) + "&last=6" + "&end=" + this.getDateString(this.getSunday(new Date(this.assignmentSelected.match.date))), config)
				.then(response => {
					if (response.status == 200 && response.data.error) {
						this.showAlertWarning('Erreur : ' + response.data.error);
					}
					else if (response.status == 200) {
						this.previousAssignmentsReferee1 = response.data;
					}
					else {
						this.showAlertWarning('Code erreur : ' + response.status);
					}
				})
				.catch(error => {
					this.showAlertWarning('Erreur : ' + error);
				});
		},
		GetPreviousAssignmentReferee2() {
			this.referee2 = this.listRefereeAvailable.find(x => x.nationalNumber === this.addAssignmentReferee2);

			var beginDate = '';
			beginDate = new Date(this.assignmentSelected.match.date)
			beginDate = beginDate.setDate(beginDate.getDate() - 30);
			var date = new Date(beginDate)

			let config = {
				headers: {
					'Authorization': 'Bearer ' + localStorage.token
				}
			};

			this.$axios.get('https://api.clickandball.fr/designation/match?nationalNumber=' + this.referee2.nationalNumber + '&start=' + this.getDateString(date) + "&last=6" +"&end=" + this.getDateString(this.getSunday(new Date(this.assignmentSelected.match.date))), config)
				.then(response => {
					if (response.status == 200 && response.data.error) {
						this.showAlertWarning('Erreur : ' + response.data.error);
					}
					else if (response.status == 200) {
						this.previousAssignmentsReferee2 = response.data;
					}
					else {
						this.showAlertWarning('Code erreur : ' + response.status);
					}
				})
				.catch(error => {
					this.showAlertWarning('Erreur : ' + error);
				});
		},
		GetPreviousUnavailabilitiesReferee1() {
			this.referee1 = this.listRefereeAvailable.find(x => x.nationalNumber === this.addAssignmentReferee1);

			let config = {
				headers: {
					'Authorization': 'Bearer ' + localStorage.token
				}
			};

			this.$axios.get('https://api.clickandball.fr/designation/indisponibilite?start=' + this.getDateString(this.getMonday(new Date(this.assignmentSelected.match.date))) + "&end=" + this.getDateString(this.getSunday(new Date(this.assignmentSelected.match.date))) + "&nationalNumber=" + this.referee1.nationalNumber, config)
				.then(response => {
					if (response.status == 200 && response.data.error) {
						this.showAlertWarning('Erreur : ' + response.data.error);
					}
					else if (response.status == 200) {
						this.previousUnavailabilitiesReferee1 = response.data;
					}
					else {
						this.showAlertWarning('Code erreur : ' + response.status);
					}
				})
				.catch(error => {
					this.showAlertWarning('Erreur : ' + error);
				});
		},
		GetPreviousUnavailabilitiesReferee2() {
			this.referee2 = this.listRefereeAvailable.find(x => x.nationalNumber === this.addAssignmentReferee2);

			let config = {
				headers: {
					'Authorization': 'Bearer ' + localStorage.token
				}
			};

			this.$axios.get('https://api.clickandball.fr/designation/indisponibilite?start=' + this.getDateString(this.getMonday(new Date(this.assignmentSelected.match.date))) + "&end=" + this.getDateString(this.getSunday(new Date(this.assignmentSelected.match.date))) + "&nationalNumber=" + this.referee2.nationalNumber, config)
				.then(response => {
					if (response.status == 200 && response.data.error) {
						this.showAlertWarning('Erreur : ' + response.data.error);
					}
					else if (response.status == 200) {
						this.previousUnavailabilitiesReferee2 = response.data;
					}
					else {
						this.showAlertWarning('Code erreur : ' + response.status);
					}
				})
				.catch(error => {
					this.showAlertWarning('Erreur : ' + error);
				});
		},

		// Méthodes de suppression désignation
		deleteAssignementModal(info)
		{
			this.$refs['modalDeleteAssignment'].show();
			this.assignmentSelectedBis = info
		},
		deleteAssignement(info)
		{
			this.checkToken();
			let id = 0;
			if(info === 1)
			{
				id = parseInt(this.assignmentSelected.historique[0].id);
			}
			else if (info === 2)
			{
				id = parseInt(this.assignmentSelectedBis.historique[0].id);
			}

			let json = [{
				id : id,
				status : 2
			}];
			this.$refs['modalDeleteAssignment'].hide();
			
			let config = {
				headers: {
					'Authorization': 'Bearer ' + localStorage.token,
				}
			};

			this.$axios.put('https://api.clickandball.fr/designation', json, config)
				.then(response => {
					if (response.status == 200 && response.data.error)
					{
						this.showAlertWarning('Erreur : ' + response.data.error);
					}
					else if (response.status == 200)
					{
						this.showAlertSuccess("Désignation supprimée");
						this.datesSelected();
					}
					else
					{
						this.showAlertWarning('Erreur : ' + response.statusText);
					}
				})
				.catch(error => {
					this.showAlertWarning('Erreur : ' + error);
				});
		},

		generationAssignement()
		{
			this.checkToken();
			if (this.week === '')
			{
				this.showAlertWarning('Aucun weekend sélectionné')
			}
			else
			{
				var week = this.listWeek.find(x => x.wording === this.week);

				let config = {
					headers: {
						'Authorization': 'Bearer ' + localStorage.token
					}
				};

				this.$axios.get('https://api.clickandball.fr/designation/algo/lancementdesignationauto?start=' + this.getDateString(week.start) + "&end=" + this.getDateString(week.end), config)
					.then(response => {
						if (response.status == 200 && response.data.error)
						{
							this.showAlertWarning('Erreur : ' + response.data.error);
						}
						else if (response.status == 200)
						{
							this.showInfoSuccess('Génération des désignation lancée');
							setTimeout(this.datesSelected, 4000);
                            
						}
						else
						{
							this.showAlertWarning('Code erreur : ' + response.status);
						}
					})
					.catch(error => {
						this.showAlertWarning('Erreur : ' + error);
					});
			}
		},
		downloadCSV()
		{
			this.checkToken();
			//if (this.week === '')
			//{
			//	this.showAlertWarning('Aucun weekend de sélectionné')
			//}
			//else
			//{
				//var week = this.listWeek.find(x => x.wording === this.week);

				let config = {
					headers: {
						'Authorization': 'Bearer ' + localStorage.token,
						'Content-Disposition': 'attachment'
					},
					responseType: 'blob'
				};

            this.$axios.get('https://api.clickandball.fr/designation/extraction?start=' + this.dateStartInput + "&end=" + this.dateEndInput + "&club=" + this.clubChoice + "&division=" + this.divisionChoice + "&nationalNumber=" + this.refereeChoice + "&group=" + this.groupChoice, config)
					.then(response => {
						if (response.status == 200 && response.data.error)
						{
							this.showAlertWarning('Erreur : ' + response.data.error);
						}
						else if (response.status == 200)
						{
							const url = window.URL.createObjectURL(new Blob([response.data]));
							const link = document.createElement('a');
							link.href = url;
							link.setAttribute('download', 'Désignations.csv');
							document.body.appendChild(link);
							link.click();
						}
						else
						{
							this.showAlertWarning('Code erreur : ' + response.status);
						}
					})
					.catch(error => {
						this.showAlertWarning('Erreur : ' + error);
					});
			//}
		},
		FiltreTableau()
		{
			var input, filter, table, tr;
			input = document.getElementById("myInput");
			filter = input.value.toUpperCase();
			table = document.getElementById("myTable2");
			tr = table.getElementsByTagName("tr");
			for (var i = 1; i < tr.length; i++) {
				var tds = tr[i].getElementsByTagName("td");
				var flag = false;
				for (var j = 0; j < tds.length; j++) {
					var td = tds[j];
					if (td.innerHTML.toUpperCase().indexOf(filter) > -1) {
						flag = true;
					}
				}
				if (flag) {
					tr[i].style.display = "";
				}
				else {
					tr[i].style.display = "none";
				}
			}
		},
		TriTableau(n)
		{
			let textA;
			let textB;
			let etatTri = this.etatTriTableau;
			let etatTriColumn = this.etatTriColumn;
		

			this.assignments.sort(function (a, b) {
				switch (n) {
					case 0:
						textA = a.match.divisionId.toUpperCase();
						textB = b.match.divisionId.toUpperCase();
						etatTriColumn = 1;
						break;
					case 1:
						textA = a.match.id;
						textB = b.match.id;
						etatTriColumn = 2;
						break;
					case 2:
						textA = a.match.date.toUpperCase();
						textB = b.match.date.toUpperCase();
						etatTriColumn = 3;
						break;
					case 3:
						textA = a.match.sportsHall.name.toUpperCase();
						textB = b.match.sportsHall.name.toUpperCase();
						etatTriColumn = 4;
						break;
					case 4:
						textA = a.match.homeClub.name.toUpperCase();
						textB = b.match.homeClub.name.toUpperCase();
						etatTriColumn = 5;
						break;
					case 5:
						textA = a.match.visitorClub.name.toUpperCase();
						textB = b.match.visitorClub.name.toUpperCase();
						etatTriColumn = 6;
						break;
					default:
						break;
				}

				if (etatTri) {
					return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
				}
				else {
					return (textA > textB) ? -1 : (textA > textB) ? 0 : 1;
				}
			});

			this.etatTriTableau = !etatTri;
			this.etatTriColumn = etatTriColumn;
		},
		ValiderToutesLesDesignations()
		{
			if (this.dateStartInput && this.dateEndInput) {
				let json = [];
				let config = {
					headers: {
						'Authorization': 'Bearer ' + localStorage.token
					}
				};

				for (var i = 0; i < this.assignments.length; i++) {
					if (this.assignments[i].historique != null && this.assignments[i].historique[0].status == 0) {
						json.push({
							id: this.assignments[i].historique[0].id,
							status: 1
						});
					}
				}

				this.$axios.put('https://api.clickandball.fr/designation', json, config)
					.then(response => {
						if (response.status == 200 && response.data.error) {
							this.showAlertWarning('Erreur : ' + response.data.error);
						}
						else if (response.status == 200) {
							this.showAlertSuccess("Désignations sauvegardées");
							this.datesSelected();
						}
						else {
							this.showAlertWarning('Erreur : ' + response.statusText);
						}
					})
					.catch(error => {
						this.showAlertWarning('Erreur: ' + error);
					});
			}
			else {
                this.showAlertWarning('Erreur : Veuillez saisir un intervalle pour afficher les désignations');
            }
			
		},
		SupprimerToutesLesDesignations()
		{
			if (this.dateStartInput && this.dateEndInput) {
                let json = [];
                let config = {
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.token
                    }
                };

                for (var i = 0; i < this.assignments.length; i++) {
                    if (this.assignments[i].historique != null && (this.assignments[i].historique[0].status == 0 || this.assignments[i].historique[0].status == 1)) {
                        json.push({
                            id: this.assignments[i].historique[0].id,
                            status: 2
                        });
                    }
                }

                this.$axios.put('https://api.clickandball.fr/designation', json, config)
                    .then(response => {
                        if (response.status == 200 && response.data.error) {
                            this.showAlertWarning('Erreur : ' + response.data.error);
                        }
                        else if (response.status == 200) {
                            this.showAlertSuccess("Désignations effacées");
                            this.datesSelected();
                        }
                        else {
                            this.showAlertWarning('Erreur : ' + response.statusText);
                        }
                    })
                    .catch(error => {
                        this.showAlertWarning('Erreur: ' + error);
                    });
			}
            else {
                this.showAlertWarning('Erreur : Veuillez saisir un intervalle pour afficher les désignations');
            }
            
		},
		FiltreArbitreModalDesignation()
		{
			this.filterListReferee1Pos1Available = [];
			this.filterListReferee2Pos1Available = [];
			this.filterListReferee1Pos2Available = [];
			this.filterListReferee2Pos2Available = [];
			let listRefereeAvailable2 = [];

			// Trie en fonction des groupes de niveau des arbitres
			this.listRefereeAvailable.forEach(x => {
				if ((x.refereeLevel.id == this.groupAssignmentReferee1) || (0 == this.groupAssignmentReferee1))
				{
					listRefereeAvailable2.push(x);
				}
			});

			listRefereeAvailable2.forEach(x => {
				let voirArbitreRegion = document.getElementById("voirArbitreRegional").checked;
				let voirArbitreDesigne = document.getElementById("voirArbitreDejaDesigne").checked;
				let forceDesignation = document.getElementById("forceDesignation").checked;

				// Visualisation des arbitres disponible
				if (((x.nbMatchArbitre == 0 && !x.canDouble) || 
				(x.nbMatchArbitre < 2 && x.canDouble)) 
				&& x.status != 3 && x.refereePosition == 1 && x.arbitreDispo &&
				!voirArbitreRegion && !voirArbitreDesigne && !forceDesignation)
				{
					this.filterListReferee1Pos1Available.push(x);
					this.filterListReferee2Pos1Available.push(x);
				}
				else if (((x.nbMatchArbitre == 0 && !x.canDouble) || 
				(x.nbMatchArbitre < 2 && x.canDouble)) &&
				x.status != 3 && x.refereePosition == 2 && x.arbitreDispo &&
				!voirArbitreRegion && !voirArbitreDesigne && !forceDesignation)
				{
					this.filterListReferee1Pos2Available.push(x);
					this.filterListReferee2Pos2Available.push(x);
				}

				// Visualisation déjà désigné
				else if (voirArbitreDesigne && x.refereePosition == 1 && x.status != 3 && x.arbitreDispo)
				{
					this.filterListReferee1Pos1Available.push(x);
					this.filterListReferee2Pos1Available.push(x);
				}
				else if (voirArbitreDesigne && x.refereePosition == 2 && x.status != 3 && x.arbitreDispo)
				{
					this.filterListReferee1Pos2Available.push(x);
					this.filterListReferee2Pos2Available.push(x);
				}

				// Visualisation des arbritres régional
				else if (voirArbitreRegion && x.status == 3 && x.refereePosition == 1 && x.arbitreDispo)
				{
					this.filterListReferee1Pos1Available.push(x);
					this.filterListReferee2Pos1Available.push(x);
				}
				else if (voirArbitreRegion && x.status == 3 && x.refereePosition == 2 && x.arbitreDispo)
				{
					this.filterListReferee1Pos2Available.push(x);
					this.filterListReferee2Pos2Available.push(x);
				}

				// Visualisation de tous les arbitres - Forcer l'enregistrement
				else if (forceDesignation && x.refereePosition == 1 )
				{
					this.filterListReferee1Pos1Available.push(x);
					this.filterListReferee2Pos1Available.push(x);
				}
				else if (forceDesignation && x.refereePosition == 2)
				{
					this.filterListReferee1Pos2Available.push(x);
					this.filterListReferee2Pos2Available.push(x);
				}
			});
		}
	}
}
</script>
