<template>
	<div class="Document">
		<h1 class="app-page-title"><img src="https://img.icons8.com/ios-glyphs/50/000000/basketball--v1.png"/> Documentation</h1>

		<div class="app-card shadow-sm mb-4 border-left-decoration">
			<div class="inner">
				<div class="card-header align-middle">
					<h5 class="title align-middle"><img src="https://img.icons8.com/ios/64/000000/basketball-jersey.png" style="height: 30px;" /> Manuels d'utilisation</h5>
				</div>
				<div class="app-card-body p-4">
					<div class="row g-2 justify-content-center justify-content-md-end">
						<div class="col-12 col-sm-12 col-lg-10">
							<ul>
								<li>
								
										Gestion du profil
									
								</li>
								<li>
									
										Gestion du calendrier
									
								</li>
								<li>
									
										Gestion des indisponibilités
									
								</li>
								<li>
									
										Gestion des retours
									
								</li>
								<li>
									
										Manuel d'administration
									
								</li>
							</ul>
						</div>
						<div class="col-12 col-lg-2 align-items-right" style="text-align: left; vertical-align: middle;">
						</div><!--//col-->
					</div><!--//row-->
				</div><!--//app-card-body-->
			</div><!--//inner-->
		</div>

	<div class="col-md-6 offset-md-3">
		<div class="card" style="border-color: darkorange;">
			<div class="card-body">
				<h5 class="card-title">Remontée d'anomalie(s)</h5>
				<p class="card-text">Si vous rencontrez des bugs, fautes de frappe, ou tout autre genre d'anomalie lors de l'utilisation de l'outil merci d'utiliser le formulaire suivant pour nous remonter directement les informations.</p>
				<div class="text-center">
					<a href="https://forms.gle/QLD6C5vDCVrnceoH7" style="color: white;" class="btn btn-primary">Lien du formulaire</a>
				</div>
			</div>
		</div>
	</div>

	</div>

</template>

<script>


export default {
	name: 'Document'
}
</script>
