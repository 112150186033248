<template>
    <div class="Statistique">
        <h1 class="app-page-title"><img src="https://img.icons8.com/ios-glyphs/50/000000/basketball--v1.png" /> Statistiques ���</h1>

        <div>
            <h3>Line Chart Example in Vue</h3>
            <line-chart></line-chart>
        </div>

        <div>
            <h3>Bar Chart Example in Vue</h3>
            <RefereeAllDistance></RefereeAllDistance>
        </div>

    </div>

</template>

<script>
    import LineChart from '@/components/LineChart'
    import RefereeAllDistance from '@/components/RefereeAllDistance'

    export default {
        name: 'Statistique',
        components: {
            LineChart,
            RefereeAllDistance
      }
    }
</script>
