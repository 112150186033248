<template>
	<div style="position: absolute; width: 100%;height: 100%; margin: 0;">
		<div style="
		background-image: url('https://wallery.app/dufovot/basketball-court-wallpaper.webp');
		min-height: 100%;
		background-repeat: no-repeat;
		background-attachment: fixed;
		background-position: center;
		background-size: cover; ">
			<div style="  
			position: fixed;
			background-image: linear-gradient(rgba(0,0,0, 0.4),rgba(0,0,0, 0.4)); /* Black w/opacity/see-through */
			height: 100%;
			width: 100%;
			top: 0;
			bottom: 0;
			background-repeat: no-repeat;
			background-attachment: fixed;
			background-position: center;
			background-size: cover;
			overflow-y: auto;
			font-weight: bold;
			text-align: center;">

				<div style="margin-top: 2.5em; min-height: 85%;">
					<div class="text-center" style="margin-bottom: 1em;">
						<img src="../../assets/images/cab_logo.png" alt="Click&Ball" class="d-none d-sm-inline" style="height: auto; width: 125px;text-align: center;filter: drop-shadow(10px 5px 4px #000000);">
						<h2 style="padding-top: 0.3em; text-align: center; color: white; font-weight: bold;filter: drop-shadow(10px 5px 4px #000000);">Click & Ball</h2>
						<small style="color: whitesmoke;">Beta - Version 1.2.0</small>
					</div>
					<slot />
				</div>


				<footer class="app-auth-footer" style="position: relative;">
					<div class="container-fluid text-center py-3">
						<div class=" container-fluid" style="color: white; text-align: center;">
							<div class="row justify-content-between">
								<div class="col-md ">
									<div class="copyright">
										<div class="container">
											<div class="row">
												<div class="col">
													<a href="https://basket44.com" style="color: white;">
														Basket44
													</a>
													<span style="margin-left: 0.5em;margin-right: 0.5em;">|</span>
													<a href="https://extranet.ffbb.com/fbi/identification.do" style="color: white;">
														FBI V2
													</a>
													<span style="margin-left: 0.5em;margin-right: 0.5em;">|</span>
													<a href="\about" style="color: white;">
														A propos
													</a>
													<span style="margin-left: 0.5em;margin-right: 0.5em;">|</span>
													<a href="mailto:outildesignation@loireatlantiquebasketball.org" style="color: white;">
														Contact
													</a>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="col-md offset-md-4">
									<div class="copyright" id="copyright" style="text-align: center;" >
									<p>
										{{currentYear}}&copy; Click & Ball
									</p>
							</div>
								</div>
							</div>

							

						</div>
					</div>
				</footer>
			</div>
		</div>
	</div>	
</template>

<script>

export default {
	name: "ExternLayout",
  data() {
    return {
      currentYear: new Date().getFullYear(), // 2020    
  };
  }
};
</script>