<template>
	<div class="About">
		<div class="container">
			<div class="shadow-lg p-3 mb-5 bg-body rounded">

				<h4 class="mb-2 text-primary">
					Hébergeur
				</h4>
				<small style="text-align: left;">
					<strong>OVH</strong><br>
					Siège social : 2 rue Kellermann 59100 Roubaix - France.<br>
					SAS au capital de 10 000 000 €<br>
					RCS Roubaix – Tourcoing 424 761 419 00045<br>
					Code APE 6202A - N° TVA : FR 22 424 761 419<br>
					L'ensemble de ce site relève des législations française et internationale sur le droit d'auteur et la propriété intellectuelle. Tous les droits de reproduction sont réservés, y compris pour les documents iconographiques et photographiques.
				</small><br /><br />
				
					Responsable de traitement pour le Site<br>
					<small style="text-align: left;">
						Le responsable du traitement des données dépend du Comité de Basket-Ball auquelle l'utilisateur est rattaché.
					</small>
				<p style="text-align: left;">
					<h5 class="mb-2 text-primary">Comité Départemental de Basket-Ball DE LOIRE-ATLANTIQUE</h5>
					5, rue Christophe Colomb<br>
					BP 98413<br>
					44984 STE LUCE SUR LOIRE CEDEX
				</p>
				<small style="text-align: left;">
					L'ensemble des données personnelles sont collectées et administées par le votre comité de Basket-Ball dans le cadre de la gestion des arbitres.
Conformément aux dispositions de la loi n° 78-17 du 6 janvier 1978 relative à l'informatique, aux fichiers et aux libertés, vous disposez d'un droit d'accès, de modification, de rectification et de suppression des données qui vous concernent. Pour demander une modification, rectification ou suppression des données vous concernant, il vous suffit d'envoyer un courrier par voie électronique ou postale en justifiant de votre identité.
				</small>
				

			</div>

			<hr style="height: 5px;">

			<a @click="$router.go(-1)" style="color: white; text-align: center;">
				<span>
					Revenir à la page précédente
				</span>
			</a>
						
		</div>
	</div>

</template>

<script>
export default {
	name: 'About'
}
</script>