<template>
	<div class="Administration">
		<h1 class="app-page-title"><img src="https://img.icons8.com/ios-glyphs/50/000000/basketball--v1.png" /> Administration de l'outil</h1>

		<b-alert :show="alertSuccessCountDown" variant="success" @dismissed="alertSuccessCountDown=0" @dismiss-count-down="alertSuccessCountDownChanged">
			<p>{{ info }}</p>
			<b-progress variant="success" :max="dismissSecs" :value="alertSuccessCountDown" height="4px"></b-progress>
		</b-alert>
		<b-alert :show="alertWarningCountDown" variant="warning" @dismissed="alertWarningCountDown=0" @dismiss-count-down="alertWarningCountDownChanged">
			<p>{{ error }}</p>
			<b-progress variant="warning" :max="dismissSecs" :value="alertWarningCountDown" height="4px"></b-progress>
		</b-alert>

		<div class="accordion2" role="tablist">
			<div class="app-card shadow-sm mb-4 border-left-decoration">
				<div class="inner">
					<div block v-b-toggle.actions id="title-legende" type="button" data-toggle="" class="card-header align-middle" style="background-color: rgba(0,0,0,.05) !important; border-bottom: 2px solid rgb(47 87 136)">
						<h5 class="title align-middle" style="color: rgb(47 87 136)">
							<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-pin-map" viewBox="0 0 16 16">
								<path fill-rule="evenodd" d="M3.1 11.2a.5.5 0 0 1 .4-.2H6a.5.5 0 0 1 0 1H3.75L1.5 15h13l-2.25-3H10a.5.5 0 0 1 0-1h2.5a.5.5 0 0 1 .4.2l3 4a.5.5 0 0 1-.4.8H.5a.5.5 0 0 1-.4-.8l3-4z" />
								<path fill-rule="evenodd" d="M8 1a3 3 0 1 0 0 6 3 3 0 0 0 0-6zM4 4a4 4 0 1 1 4.5 3.969V13.5a.5.5 0 0 1-1 0V7.97A4 4 0 0 1 4 3.999z" />
							</svg>
							Mise à jour des distances
						</h5>
					</div>
					<b-collapse visible id="actions" accordion="my-accordion2" role="tabpanel" class="app-card-body p-4">
						<div class="row g-2 justify-content-center justify-content-md-end align-items-center" style="margin-top: -1.5em">
							<div class="col-12 col-sm-12 col-lg-8">
								<small>
									<ul style="list-style: none; display: block; padding: 0;">
										<li style="float: left; display: block; margin-right: 0.75em; text-align: center;">
											<input type="radio" class="form-check-input" v-model="forceUpdateMAJDistanceRefereeSportsHall" :value='0' id="radio1" style="margin: 0.4em" /><label class="form-check-label" for="radio1">M.a.J des distances entres les salles de sports et <span style="font-weight:bold">les arbitres avec un changement d'adresse</span></label>
										</li>
										<li style="float: left; display: block; margin-right: 0.75em; text-align: center;">
											<input type="radio" class="form-check-input" v-model="forceUpdateMAJDistanceRefereeSportsHall" :value='1' id="radio2" style="margin: 0.4em" /><label class="form-check-label" for="radio2">M.a.J des jour les distances entres tous les arbitres et <span style="font-weight:bold">les salles de sport avec un changement d'adresse</span></label>
										</li>
										<li style="float: left; display: block; margin-right: 0.75em; text-align: center;">
											<input type="radio" class="form-check-input" v-model="forceUpdateMAJDistanceRefereeSportsHall" :value='2' id="radio3" style="margin: 0.4em" /><label class="form-check-label" for="radio3">M.a.J des des distances entre <span style="font-weight: bold">tous les arbitres et les salles de sports</span></label>
										</li>
									</ul>
								</small>
								
							</div>
							<div class="col-12 col-lg-4 text-center">
								<b-button style="width: 200px; margin: 0.5em" class="btn app-btn-primary" v-on:click="launchMAJDistanceRefereeSportsHall()">Lancer la mise à jour</b-button>
							</div>
						</div><!--//row-->
					</b-collapse><!--//app-card-body-->
				</div><!--//inner-->
			</div>
		</div>

		<div class="row g-4 mb-4">
			<div class="col-12 col-lg-4">
				<div class="app-card app-card-basic d-flex flex-column align-items-start shadow-sm">
					<div class="app-card-header p-3 border-bottom-0">
						<div class="row align-items-center gx-3">
							<div class="col-auto">
								<div class="app-icon-holder" style="background-color: #fa7507 !important">
									<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-receipt" fill="white" xmlns="http://www.w3.org/2000/svg">
										<path fill-rule="evenodd" d="M11.5 2a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM9.05 3a2.5 2.5 0 0 1 4.9 0H16v1h-2.05a2.5 2.5 0 0 1-4.9 0H0V3h9.05zM4.5 7a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM2.05 8a2.5 2.5 0 0 1 4.9 0H16v1H6.95a2.5 2.5 0 0 1-4.9 0H0V8h2.05zm9.45 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zm-2.45 1a2.5 2.5 0 0 1 4.9 0H16v1h-2.05a2.5 2.5 0 0 1-4.9 0H0v-1h9.05z" />
									</svg>
								</div>
							</div>
							<div class="col-auto">
								<h4 class="app-card-title">Limite kilométrique de l'entité</h4>
							</div>
						</div>
					</div>
					<div class="app-card-body px-4">
						<div class="intro">
							Fixe la distance maximale qu'un arbitre pourra parcourir pour aller à une rencontre désignée par l'entité
						</div>
						<div class="text-center" style="margin-top: 0.75em">
							<b-form-input class="form-select-sm ml-auto d-inline-flex text-center" style="height: 30px; border: 1px solid #ced4da; border-radius: .25rem;" type="number" min="0" step="5" v-model="limitKM"></b-form-input>
						</div>
						<hr />
						<div class="text-center" style="margin-bottom: 0.75em">
							<b-button class="btn app-btn-secondary" v-on:click="updateLimitKM()">Enregistrer</b-button>
						</div>
					</div>
				</div>
			</div>
			<div class="col-12 col-lg-4">
				<div class="app-card app-card-basic d-flex flex-column align-items-start shadow-sm">
					<div class="app-card-header p-3 border-bottom-0">
						<div class="row align-items-center gx-3">
							<div class="col-auto">
								<div class="app-icon-holder" style="background-color: #fa7507 !important">
									<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-code-square" fill="white" xmlns="http://www.w3.org/2000/svg">
										<path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2z"></path>
										<path fill-rule="evenodd" d="M8 1.918l-.797.161A4.002 4.002 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4.002 4.002 0 0 0-3.203-3.92L8 1.917zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5.002 5.002 0 0 1 13 6c0 .88.32 4.2 1.22 6z"></path>
									</svg>
								</div>
							</div>
							<div class="col-auto">
								<h4 class="app-card-title">Délai d'indisponibilité</h4>
							</div>
						</div>
					</div>
					<div class="app-card-body px-4">
						<div class="intro">
							Fixe la durée selon laquelle un arbitre ne peut poser d'indisponibilité
						</div>
						<div class="text-center" style="margin-top: 0.75em">
							<b-form-input class="form-select-sm ml-auto d-inline-flex text-center" style="height: 30px; border: 1px solid #ced4da; border-radius: .25rem;" type="number" min="0" v-model="timeIndispo"></b-form-input>
						</div>
						<hr />
						<div class="text-center" style="margin-bottom: 0.75em">
							<b-button class="btn app-btn-secondary" v-on:click="updateTimeIndispo()()">Enregistrer</b-button>
						</div>
					</div>
				</div>
			</div>
			<div class="col-12 col-lg-4">
				<div class="app-card app-card-basic d-flex flex-column align-items-start shadow-sm">
					<div class="app-card-header p-3 border-bottom-0">
						<div class="row align-items-center gx-3">
							<div class="col-auto">
								<div class="app-icon-holder" style="background-color: #fa7507 !important">
									<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-tools" fill="white" xmlns="http://www.w3.org/2000/svg">
										<path fill-rule="evenodd" d="M0 1l1-1 3.081 2.2a1 1 0 0 1 .419.815v.07a1 1 0 0 0 .293.708L10.5 9.5l.914-.305a1 1 0 0 1 1.023.242l3.356 3.356a1 1 0 0 1 0 1.414l-1.586 1.586a1 1 0 0 1-1.414 0l-3.356-3.356a1 1 0 0 1-.242-1.023L9.5 10.5 3.793 4.793a1 1 0 0 0-.707-.293h-.071a1 1 0 0 1-.814-.419L0 1zm11.354 9.646a.5.5 0 0 0-.708.708l3 3a.5.5 0 0 0 .708-.708l-3-3z" />
										<path fill-rule="evenodd" d="M15.898 2.223a3.003 3.003 0 0 1-3.679 3.674L5.878 12.15a3 3 0 1 1-2.027-2.027l6.252-6.341A3 3 0 0 1 13.778.1l-2.142 2.142L12 4l1.757.364 2.141-2.141zm-13.37 9.019L3.001 11l.471.242.529.026.287.445.445.287.026.529L5 13l-.242.471-.026.529-.445.287-.287.445-.529.026L3 15l-.471-.242L2 14.732l-.287-.445L1.268 14l-.026-.529L1 13l.242-.471.026-.529.445-.287.287-.445.529-.026z" />
									</svg>
								</div>
							</div>
							<div class="col-auto">
								<h4 class="app-card-title">Import des rencontres</h4>
							</div>
						</div>
					</div>
					<div class="app-card-body px-4">
						<div class="intro">
							Permet de forcer l'importation des rencontres depuis FBI des rencontres des XX jours à venir
						</div>
						<hr />
						<div class="text-center" style="margin-bottom: 0.75em">
							<b-button class="btn app-btn-secondary" v-on:click="launchSeleniumFBI()">Lancer l'import</b-button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

</template>

<script>
import jwt_decode from "jwt-decode";

export default {
	name: 'Administration',
	data() {
		return {
			// Variable message alert
			dismissSecs: 10,
			alertSuccessCountDown: 0,
			alertWarningCountDown: 0,
			info:'',
			error:'',

			limitKM:0,
			timeIndispo:0,
			forceUpdateMAJDistanceRefereeSportsHall:0,
		}
	},
	mounted()
	{
		this.loadingPage();

		this.checkToken();
		let config = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.token
			}
		};

		this.$axios.get('https://api.clickandball.fr/designation/parametre/limitedistancesalledesportsarbitre', config)
			.then(response => {
				if (response.status == 200 && response.data.error)
				{
					this.showAlertWarning('Erreur : ' + response.data.error);
				}
				else if (response.status == 200)
				{
					this.limitKM = response.data.distance;
				}
				else
				{
					this.showAlertWarning('Code erreur : ' + response.status);
				}
			})
		
		this.$axios.get('https://api.clickandball.fr/designation/parametre/dureeindisponibilitemaximum', config)
			.then(response => {
				if (response.status == 200 && response.data.error)
				{
					this.showAlertWarning('Erreur : ' + response.data.error);
				}
				else if (response.status == 200)
				{
					this.timeIndispo = response.data.time;
				}
				else
				{
					this.showAlertWarning('Code erreur : ' + response.status);
				}
			})
	},
	methods: {
        loadingPage() {
            this.checkToken();
            let config = {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.token
                }
            };

            this.$axios.get('https://api.clickandball.fr/designation/parametre/limitedistancesalledesportsarbitre', config)
                .then(response => {
                    if (response.status == 200 && response.data.error) {
                        this.showAlertWarning('Erreur : ' + response.data.error);
                    }
                    else if (response.status == 200) {
                        this.limitKM = response.data.distance;
                    }
                    else {
                        this.showAlertWarning('Code erreur : ' + response.status);
                    }
                })

            this.$axios.get('https://api.clickandball.fr/designation/parametre/dureeindisponibilitemaximum', config)
                .then(response => {
                    if (response.status == 200 && response.data.error) {
                        this.showAlertWarning('Erreur : ' + response.data.error);
                    }
                    else if (response.status == 200) {
                        this.timeIndispo = response.data.time;
                    }
                    else {
                        this.showAlertWarning('Code erreur : ' + response.status);
                    }
                })
        },

		// Méthodes message alert
		alertSuccessCountDownChanged(alertSuccessCountDown)
		{
			this.alertSuccessCountDown = alertSuccessCountDown
		},
		alertWarningCountDownChanged(alertWarningCountDown)
		{
			this.alertWarningCountDown = alertWarningCountDown
		},
		showAlertSuccess(info)
		{
			this.info = info;
			this.alertSuccessCountDown = this.dismissSecs
		},
		showAlertWarning(error)
		{
			this.error = error;
			this.alertWarningCountDown = this.dismissSecs
		},
		checkToken()
		{
			if (localStorage.token)
			{
				var jsonToken = jwt_decode(localStorage.token);
				if ((jsonToken.exp - 60) < Math.round(Date.now()/1000))
				{
					localStorage.token = null;
					this.connected = false;
					this.$router.push({ path: '/login'})
				}
			}
		},

		launchMAJDistanceRefereeSportsHall()
		{
			let params = "";
			if (this.forceUpdateMAJDistanceRefereeSportsHall === 1)
			{
				params = "?sportshall=true"
			}
			else if (this.forceUpdateMAJDistanceRefereeSportsHall === 2)
			{
				params = "?forceupdate=true"
			}
			let config = {
				headers: {
					'Authorization': 'Bearer ' + localStorage.token
				}
			};

			this.$axios.get('https://api.clickandball.fr/designation/algo/calculdistancesalledesportarbitre' + params, config)
				.then(response => {
					if (response.status == 200 && response.data.error)
					{
						this.showAlertWarning('Erreur : ' + response.data.error);
					}
					else if (response.status == 200)
					{
						this.showAlertSuccess("Lancement du traitement");
					}
					else
					{
						this.showAlertWarning('Code erreur : ' + response.status);
					}
				})
				.catch(error => {
					this.showAlertWarning('Erreur : ' + error);
				});
		},
		updateLimitKM()
		{
			this.checkToken();
			let json = {
				distance: parseInt(this.limitKM)
			};
			let config = {
				headers: {
					'Authorization': 'Bearer ' + localStorage.token
				}
			};

			this.$axios.post('https://api.clickandball.fr/designation/parametre/limitedistancesalledesportsarbitre', json, config)
				.then(response => {
					if (response.status == 200 && response.data.error)
					{
						this.showAlertWarning('Erreur : ' + response.data.error);
					}
					else if (response.status == 200)
					{
						this.showAlertSuccess("Limite de distance sauvegardée");
                        this.$axios.get('https://api.clickandball.fr/designation/parametre/reinitialiservariable', config)
                            .then(response => {
                                if (response.status == 200 && response.data.error) {
                                    this.showAlertWarning('Erreur : ' + response.data.error);
                                }
                                else if (response.status == 200) {
                                    this.showAlertSuccess("Mise à jour de la base faite");
                                    this.loadingPage();
                                }
                                else {
                                    this.showAlertWarning('Erreur : ' + response.statusText);
                                }
                            })
                            .catch(error => {
                                this.showAlertWarning('Erreur : ' + error);
                            });
					}
					else
					{
						this.showAlertWarning('Erreur : ' + response.statusText);
					}
				})
				.catch(error => {
					this.showAlertWarning('Erreur : ' + error);
				});
		},
		updateTimeIndispo()
		{
			this.checkToken();
			let json = {
				time: parseInt(this.timeIndispo)
			};
			let config = {
				headers: {
					'Authorization': 'Bearer ' + localStorage.token
				}
			};

			this.$axios.post('https://api.clickandball.fr/designation/parametre/dureeindisponibilitemaximum', json, config)
				.then(response => {
					if (response.status == 200 && response.data.error)
					{
						this.showAlertWarning('Erreur : ' + response.data.error);
					}
					else if (response.status == 200)
					{
						this.showAlertSuccess("Durée indisponibilité sauvegardée");
                        this.$axios.get('https://api.clickandball.fr/designation/parametre/reinitialiservariable', config)
                            .then(response => {
                                if (response.status == 200 && response.data.error) {
                                    this.showAlertWarning('Erreur : ' + response.data.error);
                                }
                                else if (response.status == 200) {
                                    this.showAlertSuccess("Mise à jour de la base faite");
                                    this.loadingPage();
                                }
                                else {
                                    this.showAlertWarning('Erreur : ' + response.statusText);
                                }
                            })
                            .catch(error => {
                                this.showAlertWarning('Erreur : ' + error);
                            });
					}
					else
					{
						this.showAlertWarning('Erreur : ' + response.statusText);
					}
				})
				.catch(error => {
					this.showAlertWarning('Erreur : ' + error);
				});
		},
		launchSeleniumFBI()
		{
			let config = {
				headers: {
					'Authorization': 'Bearer ' + localStorage.token
				}
			};

			this.$axios.get('https://api.clickandball.fr/designation/algo/lancerimportationfbi', config)
				.then(response => {
					if (response.status == 200 && response.data.error)
					{
						this.showAlertWarning('Erreur : ' + response.data.error);
					}
					else if (response.status == 200)
					{
						this.showAlertSuccess("Lancement du traitement");
					}
					else
					{
						this.showAlertWarning('Code erreur : ' + response.status);
					}
				})
				.catch(error => {
					this.showAlertWarning('Erreur : ' + error);
				});
		}
	}
}
</script>
