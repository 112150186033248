<template>
	<div class="404">
		<div class="container">

			<div>

				<hr style="height: 5px;">
				<h3 class="" style="margin:  0.5em;text-align: center;color: #fa7507;filter: drop-shadow(10px 5px 4px #000000);">La page demandée est introuvable</h3>
				<hr style="height: 5px;">

				<p class="small" style="color: white; text-align: center;">
					En attendant de retrouver votre chemin, faites une pause pour relire votre code de jeu :
					<ul style="list-style-type: none;padding: 0;margin: 0;">
					<li>
						<a href="http://www.ffbb.com/sites/default/files/otm_regelement_jeu/1a._reglement_officiel_du_basketball_2020_-_version_francaise_-_bvr_-2021-06-07_-v24.pdf">Règlement FIBA | Juin 2021</a>
					</li>
					<li>
						<a href="http://www.ffbb.com/sites/default/files/otm_regelement_jeu/2a._2021-06-30_interpretations_officielles_fiba_2020_v3.0_-_juin_2021_-_v.francaise_-_bvr-i.pdf">Interprétations FIBA | Décembre 2021</a>
					</li>
				</ul>
				</p>

				<hr style="height: 5px;">

				<a @click="$router.go(-1)" style="color: white; text-align: center;">
					<span>
						Revenir à la page précédente
					</span>
				</a>
						
			</div>

		</div>
	</div>

</template>

<script>
export default {
	name: 'NotFound'
}
</script>