<template>
	<div class="Unavailability">
		<h1 class="app-page-title"><img src="https://img.icons8.com/ios-glyphs/50/000000/basketball--v1.png" /> Gestion des indisponibilités</h1>

		<!-- Modal -->

		<b-modal ref="modalDeleteUnavailability" hide-footer hide-header>
			<div class="app-card " style="margin-top: -1em; margin-left: -1em; margin-right: -1em">
				<a style="float: right; z-index: 9999; margin-right: 0.5em; top: 0" href="#" block @click="$refs['modalDeleteUnavailability'].hide()">
					<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
						<path fill-rule="evenodd" d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z" />
						<path fill-rule="evenodd" d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z" />
					</svg>
				</a>
				<div class="app-card-header p-3 border-bottom-0">
					<div class="row align-items-center gx-3">
						<div class="col-auto">
							<div class="app-icon-holder" style="background-color: rgb(42, 87, 136)">
								<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-shield-check" fill="white" xmlns="http://www.w3.org/2000/svg">
									<path d="M6.854 7.146a.5.5 0 1 0-.708.708L7.293 9l-1.147 1.146a.5.5 0 0 0 .708.708L8 9.707l1.146 1.147a.5.5 0 0 0 .708-.708L8.707 9l1.147-1.146a.5.5 0 0 0-.708-.708L8 8.293 6.854 7.146z" />
									<path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z" />
								</svg>
							</div>
						</div>
						<div class="col-auto">
							<h4 class="app-card-title" style="color: rgb(42, 87, 136) !important">Supprimer l'indisponibilité</h4>
						</div>
					</div>
				</div>
			</div>
			<div class="app-card app-card-basic d-flex flex-column align-items-center shadow-sm">
				<div class="app-card-body">
					<div class="intro mb-6">
						<small>
							<span style="font-weight: bold;">Arbitre : </span>{{ unavailabilitySelected.referee.firstName }} {{ unavailabilitySelected.referee.lastName }}
							<hr>
							<span style="font-weight: bold;">Date de début : </span>{{ unavailabilitySelected.unavailability.start }}<br>
							<span style="font-weight: bold;">Date de fin : </span>{{ unavailabilitySelected.unavailability.end }}
							<hr>
						</small>
						<div class="text-center">
							<b-button class="btn app-btn-primary" style="background: rgb(42, 87, 136);color: white; width: 200px; margin: 0.5em;" v-on:click="deleteUnavailabilities()">Confirmer la supression</b-button>
						</div>
					</div>
				</div>
			</div>
	</b-modal>

	<b-modal ref="modalUpdateUnavailability" hide-footer hide-header size="lg">

		<div class="app-card " style="margin-top: -1em; margin-left: -1em; margin-right: -1em">
			<a style="float: right; z-index: 9999; margin-right: 0.5em; top: 0" href="#" block @click="$refs['modalUpdateUnavailability'].hide()">
				<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
					<path fill-rule="evenodd" d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z" />
					<path fill-rule="evenodd" d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z" />
				</svg>
			</a>
			<div class="app-card-header p-3 border-bottom-0">
				<div class="row align-items-center gx-3">
					<div class="col-auto">
						<div class="app-icon-holder" style="background-color: rgb(42, 87, 136)">
							<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-shield-check" fill="white" xmlns="http://www.w3.org/2000/svg">
								<path d="M8 7a.5.5 0 0 1 .5.5V9H10a.5.5 0 0 1 0 1H8.5v1.5a.5.5 0 0 1-1 0V10H6a.5.5 0 0 1 0-1h1.5V7.5A.5.5 0 0 1 8 7z" />
								<path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z" />
							</svg>
						</div>
					</div>
					<div class="col-auto">
						<h4 class="app-card-title" style="color: rgb(42, 87, 136) !important">Modifier une indisponibilité</h4>
					</div>
				</div>
			</div>
			<div class="app-card app-card-notification mb-4" style="margin-top: -0.5em; margin-bottom: -1em">
				<div class="app-card-header px-4 py-3">
					<div class="row g-3 align-items-center">
						<div class="col-12 col-lg-auto text-lg-left">
							<div class="notification-type mb-2">
								<span class="badge bg-danger">
									Attention
								</span>
								<ul class="notification-meta list-inline mb-0" style="margin-top: 0.25em;">
									<li class="list-inline-item">
										Vous serez désignable jusqu’au début de votre indisponibilité et dés la fin de celle-ci
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="app-card-body p-4">
				<div class="notification-content" style="margin-bottom: -2em">
					<div class="row">
						<div class="col-10 offset-md-1">
							<ul class="list-unstyled" style="margin-bottom: -0.25em; margin-top: -0.5em">
								<li>
									<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2 text-primary mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg" style="margin: 0.4em">
										<path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
									</svg>
									<strong>Arbitre : </strong>{{ unavailabilitySelected.referee.firstName }} {{ unavailabilitySelected.referee.lastName }}
								</li>
								<li>
									<small>
										<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2 text-primary mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg" style="margin: 0.4em">
											<path d="M6.445 11.688V6.354h-.633A12.6 12.6 0 0 0 4.5 7.16v.695c.375-.257.969-.62 1.258-.777h.012v4.61h.675zm1.188-1.305c.047.64.594 1.406 1.703 1.406 1.258 0 2-1.066 2-2.871 0-1.934-.781-2.668-1.953-2.668-.926 0-1.797.672-1.797 1.809 0 1.16.824 1.77 1.676 1.77.746 0 1.23-.376 1.383-.79h.027c-.004 1.316-.461 2.164-1.305 2.164-.664 0-1.008-.45-1.05-.82h-.684zm2.953-2.317c0 .696-.559 1.18-1.184 1.18-.601 0-1.144-.383-1.144-1.2 0-.823.582-1.21 1.168-1.21.633 0 1.16.398 1.16 1.23z" />
											<path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z" />
										</svg>
										<strong>Date de création : </strong>{{ unavailabilitySelected.unavailability.creationDate }}<br>
									</small>
								</li>
								<li>
									<small>
										<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-check-fill text-primary mr-2" style="margin: 0.4em">
											<path d="M8 7a.5.5 0 0 1 .5.5V9H10a.5.5 0 0 1 0 1H8.5v1.5a.5.5 0 0 1-1 0V10H6a.5.5 0 0 1 0-1h1.5V7.5A.5.5 0 0 1 8 7z" />
											<path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z" />
										</svg>
										<strong>Date de modification : </strong>{{ unavailabilitySelected.unavailability.updateDate }}<br>
									</small>
								</li>
							</ul>
						</div>
					</div>
					<hr />
					<div class="">
						<input type="checkbox" class="form-check-input" id="allDay" name="allDay" :state="allDay" v-model="allDay" style="margin: 0.4em;">
						<label for="allDay">L'arbitre est indisponible toute la journée ?</label>
					</div>
					<hr />
					<div class="row">
						<div v-if="allDay == true" class="col-md-3">
						</div>
						<div class="col-md-6">
							<div class="form-group">
								<label for="dateStartInput" style="font-weight: bold;" class="form-label">Date<span v-if="allDay == false"> de début : </span></label>
								<b-form-input class="form-select-sm ml-auto d-inline-flex text-center" style="padding: .375rem 2.25rem .375rem .75rem; font-weight: 400; line-height: 1.5; height: 30px; border: 1px solid #ced4da; border-radius: .25rem; " :state="dateStartInputState" name="dateStartInput" v-model="dateStartInput" type="date" required></b-form-input>
							</div>
						</div>
						<div class="col-md-6" v-if="allDay == false">
							<div class="form-group">
								<label for="hourStartInput" style="font-weight: bold;" class="form-label">Heure de début : </label>
								<b-form-input class="form-select-sm ml-auto d-inline-flex text-center" style="padding: .375rem 2.25rem .375rem .75rem; font-weight: 400; line-height: 1.5; height: 30px; border: 1px solid #ced4da; border-radius: .25rem; " :state="hourStartInputState" name="hourStartInput" v-model="hourStartInput" type="time" required></b-form-input>
							</div>
						</div>
					</div>
					<div class="row" style="margin-top: 0.5em">
						<div class="col-md-6" v-if="allDay == false">
							<div class="form-group">
								<label for="dateEndInput" style="font-weight: bold;" class="form-label">Date de fin : </label>
								<b-form-input class="form-select-sm ml-auto d-inline-flex text-center" style="padding: .375rem 2.25rem .375rem .75rem; font-weight: 400; line-height: 1.5; height: 30px; border: 1px solid #ced4da; border-radius: .25rem; " :state="dateEndInputState" name="dateEndInput" v-model="dateEndInput" type="date" required></b-form-input>
							</div>
						</div>
						<div class="col-md-6" v-if="allDay == false">
							<div class="form-group tex">
								<label for="hourEndInput" style="font-weight: bold;" class="form-label">Heure de fin : </label>
								<b-form-input class="form-select-sm ml-auto d-inline-flex text-center" style="padding: .375rem 2.25rem .375rem .75rem; font-weight: 400; line-height: 1.5; height: 30px; border: 1px solid #ced4da; border-radius: .25rem; " :state="hourEndInputState" name="hourEndInput" v-model="hourEndInput" type="time" required></b-form-input>
							</div>
						</div>
					</div>
					<hr />
					<div class="text-center">
						<b-button v-on:click="updateUnavailabilities()" class="btn app-btn-primary" style="background: rgb(42, 87, 136);color: white; width: 250px; margin: 0.5em;">Mettre à jour</b-button>
					</div>
				</div>
			</div>
		</div>
	</b-modal>

	<b-modal ref="modalAddUnavailability" hide-footer hide-header size="lg">
		
		<div class="app-card app-card-notification shadow-sm mb-4" style="margin: -1em">
			<a style="float: right; z-index: 9999; margin-right: 0.5em; top: 0" href="#" block @click="$refs['modalAddUnavailability'].hide()">
				<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
					<path fill-rule="evenodd" d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z" />
					<path fill-rule="evenodd" d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z" />
				</svg>
			</a>
			<div class="app-card-header p-3 border-bottom-0">
				<div class="row align-items-center gx-3">
					<div class="col-auto">
						<div class="app-icon-holder" style="background-color: rgb(42, 87, 136)">
							<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-shield-check" fill="white" xmlns="http://www.w3.org/2000/svg">
								<path d="M8 7a.5.5 0 0 1 .5.5V9H10a.5.5 0 0 1 0 1H8.5v1.5a.5.5 0 0 1-1 0V10H6a.5.5 0 0 1 0-1h1.5V7.5A.5.5 0 0 1 8 7z" />
								<path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z" />
							</svg>
						</div>
					</div>
					<div class="col-auto">
						<h4 class="app-card-title" style="color: rgb(42, 87, 136) !important">Ajouter une indisponibilité</h4>
					</div>
				</div>
			</div>
			<div class="app-card app-card-notification mb-4" style="margin-top: -0.5em; margin-bottom: -1em">
				<div class="app-card-header px-4 py-3">
					<div class="row g-3 align-items-center">
						<div class="col-12 col-lg-auto text-lg-left">
							<div class="notification-type mb-2">
								<span class="badge bg-danger">
									Attention
								</span>
								<ul class="notification-meta list-inline mb-0" style="margin-top: 0.25em;">
									<li class="list-inline-item">
										Vous serez désignable jusqu’au début de votre indisponibilité et dés la fin de celle-ci
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="app-card-body p-4" style="margin-bottom: -2em; margin-top: -2em">
				<div class="notification-content">
					<div class="text-center">
						<p style="font-weight: bold;">Choisir un arbitre:</p>
						<div class="row">
							<div class="col-md-5 offset-md-1">
								<b-form-select v-model="groupReferee" @change="filterReferee()" class="form-select form-select-sm ml-auto d-inline-flex text-center">
									<b-form-select-option :value="0">Choisir un groupe</b-form-select-option>
									<b-form-select-option v-for="r in listGroupAvailable" :key="r.id" :value="r.id">{{ r.wording }}</b-form-select-option>
								</b-form-select>
							</div>

							<div class="col-md-5">
								<b-form-select v-model="addUnavailabilityReferee" class="form-select form-select-sm ml-auto d-inline-flex text-center">
									<b-form-select-option disabled :value='0'>Choisir un arbitre</b-form-select-option>
									<b-form-select-option disabled :value='0'>--- Arbitre position 1 ---</b-form-select-option>
									<b-form-select-option v-for="r in filterListRefereePos1Available" :key="r.nationalNumber" :value="r.nationalNumber">{{ r.firstName }} {{ r.lastName }}</b-form-select-option>
									<b-form-select-option disabled :value='0'>--- Arbitre position 2 ---</b-form-select-option>
									<b-form-select-option v-for="r in filterListRefereePos2Available" :key="r.nationalNumber" :value="r.nationalNumber">{{ r.firstName }} {{ r.lastName }}</b-form-select-option>
								</b-form-select>
							</div>
						</div>


					</div>
					<div class="align-items-center text-center" style="margin-top: 0.5em">
						<input type="checkbox" class="form-check-input" id="allDay" name="allDay" :state="allDay" v-model="allDay" style="margin: 0.4em;">
						<label for="allDay" style="margin-top: 0.15em">L'arbitre est indisponible toute la journée ?</label>
					</div>
					<hr />
					<div class="row">
						<div v-if="allDay == true" class="col-md-3">
						</div>
						<div class="col-md-6">
							<div class="form-group">
								<label for="dateStartInput" style="font-weight: bold;" class="form-label">Date<span v-if="allDay == false"> de début : </span></label>
								<b-form-input class="form-select-sm ml-auto d-inline-flex text-center" style="padding: .375rem 2.25rem .375rem .75rem; font-weight: 400; line-height: 1.5; height: 30px; border: 1px solid #ced4da; border-radius: .25rem; " :state="dateStartInputState" name="dateStartInput" v-model="dateStartInput" type="date" required></b-form-input>
							</div>
						</div>
						<div class="col-md-6" v-if="allDay == false">
							<div class="form-group">
								<label for="hourStartInput" style="font-weight: bold;" class="form-label">Heure de début : </label>
								<b-form-input class="form-select-sm ml-auto d-inline-flex text-center" style="padding: .375rem 2.25rem .375rem .75rem; font-weight: 400; line-height: 1.5; height: 30px; border: 1px solid #ced4da; border-radius: .25rem; " :state="hourStartInputState" name="hourStartInput" v-model="hourStartInput" type="time" required></b-form-input>
							</div>
						</div>
					</div>
					<div class="row" style="margin-top: 0.5em">
						<div class="col-md-6" v-if="allDay == false">
							<div class="form-group">
								<label for="dateEndInput" style="font-weight: bold;" class="form-label">Date de fin : </label>
								<b-form-input class="form-select-sm ml-auto d-inline-flex text-center" style="padding: .375rem 2.25rem .375rem .75rem; font-weight: 400; line-height: 1.5; height: 30px; border: 1px solid #ced4da; border-radius: .25rem; " :state="dateEndInputState" name="dateEndInput" v-model="dateEndInput" type="date" required></b-form-input>
							</div>
						</div>
						<div class="col-md-6" v-if="allDay == false">
							<div class="form-group tex">
								<label for="hourEndInput" style="font-weight: bold;" class="form-label">Heure de fin : </label>
								<b-form-input class="form-select-sm ml-auto d-inline-flex text-center" style="padding: .375rem 2.25rem .375rem .75rem; font-weight: 400; line-height: 1.5; height: 30px; border: 1px solid #ced4da; border-radius: .25rem; " :state="hourEndInputState" name="hourEndInput" v-model="hourEndInput" type="time" required></b-form-input>
							</div>
						</div>
					</div>
					<hr />
					<div style="margin-top: 0.5em">
						<div class="col-lg-12">
							<input type="checkbox" class="form-check-input" id="recursive" name="recursive" :state="recursive" v-model="recursive" style="margin: 0.4em; margin-bottom: 1em">
							<label for="recursive" style="margin-top: 0.15em">Souhaitez vous ajouter une indisponibilité récurrente ?</label>
						</div>
					</div>
					<div v-if="recursive == true" class="col-lg-12" style="margin-top: 0.5em">

						<div class="col-12">
							<div class="app-card shadow-sm mb-4 border-left-decoration">
								<div class="inner">
									<div class="app-card-body p-4">
										<div style="margin-bottom: 0.5em;">
											<label class="form-label" for="selectedPeriodicity" style="font-weight: bold; margin-bottom: 0.5em;">
												<small>Répéter l'indisponibilité tous les :</small>
											</label>

											<div class="mb-3 col-12 text-center" style="margin-top: 0.5em">
												<div class="form-check form-check-inline">
													<ul style="list-style: none; display: block; padding: 0;">
														<li style="float: left; display: block; margin-right: 0.75em; text-align: center;">
															<input class="form-check-input" type="radio" name="selectedPeriodicity" v-model="selectedPeriodicity" id="radio-1" :value='1' />
															<label for="radio-1" style="display: block;">1 jour</label>
														</li>
														<li style="float: left; display: block; margin-left: 0.75em; margin-right: 0.75em; text-align: center;">
															<input class="form-check-input" type="radio" name="selectedPeriodicity" v-model="selectedPeriodicity" id="radio-2" :value='7' />
															<label for="radio-2" style="display: block;">7 jours</label>
														</li>
														<li style="float: left; display: block; margin-left: 0.75em; margin-right: 0.75em; text-align: center;">
															<input class="form-check-input" type="radio" name="selectedPeriodicity" v-model="selectedPeriodicity" id="radio-3" :value='14' />
															<label for="radio-3" style="display: block;">14 jours</label>
														</li>
														<li style="float: left; display: block; margin-left: 0.75em; margin-right: 0.75em; text-align: center;">
															<input class="form-check-input" type="radio" name="selectedPeriodicity" v-model="selectedPeriodicity" id="radio-4" :value='21' />
															<label for="radio-4" style="display: block;">21 jours</label>
														</li>
														<li style="float: left; display: block; margin-left: 0.75em; text-align: center;">
															<input class="form-check-input" type="radio" name="selectedPeriodicity" v-model="selectedPeriodicity" id="radio-5" :value='28' />
															<label for="radio-5" style="display: block;">28 jours</label>
														</li>
													</ul>
												</div>
											</div>

										</div>
										<div class="form-group">
											<label class="form-label" for="endPeriodicity" style="font-weight: bold;">
												<small>Répéter l'indisponibilité jusqu'au :</small>
											</label>
											<b-form-input class="form-select-sm ml-auto d-inline-flex text-center" style="padding: .375rem 2.25rem .375rem .75rem; font-weight: 400; line-height: 1.5; height: 30px; border: 1px solid #ced4da; border-radius: .25rem; " :state="endPeriodicityState" name="endPeriodicity" v-model="endPeriodicity" type="date"></b-form-input>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="text-center" style="margin-bottom: -1em">
						<b-button v-on:click="addUnavailability()" class="btn app-btn-primary" style="background: rgb(42, 87, 136);color: white; width: 250px; margin: 0.5em;">Enregistrer</b-button>
					</div>
				</div>
			</div>

		</div>
		
	</b-modal>

	<!--Modal-->
	<b-alert :show="alertSuccessCountDown" variant="success" @dismissed="alertSuccessCountDown=0" @dismiss-count-down="alertSuccessCountDownChanged">
		<p>{{ info }}</p>
		<b-progress variant="success" :max="dismissSecs" :value="alertSuccessCountDown" height="4px"></b-progress>
	</b-alert>
	<b-alert :show="alertWarningCountDown" variant="warning" @dismissed="alertWarningCountDown=0" @dismiss-count-down="alertWarningCountDownChanged">
		<p>{{ error }}</p>
		<b-progress variant="warning" :max="dismissSecs" :value="alertWarningCountDown" height="4px"></b-progress>
	</b-alert>

	<div class="accordion2" role="tablist">
		<div class="app-card shadow-sm mb-4 border-left-decoration">
			<div class="inner">
				<div block v-b-toggle.actions id="title-legende" type="button" data-toggle="" class="card-header align-middle" style="background-color: rgba(0,0,0,.05) !important; border-bottom: 2px solid rgb(47 87 136)">
					<h5 class="title align-middle" style="color: rgb(47 87 136)">
						<i class="fas fa-search-plus"></i>
						Actions
					</h5>
				</div>
				<b-collapse visible id="actions" accordion="my-accordion2" role="tabpanel" class="app-card-body p-4">
					<div class="row g-2 justify-content-center justify-content-md-end">
						<div class="col-12 col-sm-12 col-lg-10" style="margin-top: -1em; margin-bottom: -1em">
							<div class="col-lg-12">
								<div class="row" style="margin-bottom: 1em">
									<div class="col-lg-2 col-md-3 col-sm-12 col-12">
										<label for="dateStartInput2" style="font-weight: bold; margin-top: 0.5em"><small>Date de début</small></label>
										<b-form-input class="form-select-sm ml-auto d-inline-flex text-center" style="padding: .375rem 2.25rem .375rem .75rem; font-weight: 400; line-height: 1.5; height: 30px; border: 1px solid #ced4da; border-radius: .25rem; " :state="dateStartInputState2" name="dateStartInput2" v-model="dateStartInput2" type="date" required></b-form-input>
									</div>

									<div class="col-lg-2 col-md-3 col-sm-12 col-12" style="margin-top: 0.5em">
										<label for="dateEndInput2" style="font-weight: bold;"><small>Date de fin</small></label>
										<b-form-input class="form-select-sm ml-auto d-inline-flex text-center" style="padding: .375rem 2.25rem .375rem .75rem; font-weight: 400; line-height: 1.5; height: 30px; border: 1px solid #ced4da; border-radius: .25rem; " :state="dateEndInputState2" name="dateEndInput2" v-model="dateEndInput2" type="date" required></b-form-input>
									</div>
									<div class="row align-items-center">
										<div class="col-lg-2 col-md-3 col-sm-12 col-12" style="margin-top: 0.75em">
											<div class="row">
												<label for="groupChoice" style="font-weight: bold;"><small>Groupe</small></label>
											</div>

											<b-form-select v-model="groupChoice" name="divisionChoice" class="form-select form-select-sm ml-auto d-inline-flex text-center">
												<b-form-select-option value="">Choisissez un groupe</b-form-select-option>
												<b-form-select-option v-for="r in listGroup" :key="r.wording" :value="r.id">{{ r.wording }}</b-form-select-option>
											</b-form-select>
										</div>
										<div class="col-lg-2 col-md-3 col-sm-12 col-12" style="margin-top: 0.5em">
											<div class="row">
												<label for="refereeChoice" style="font-weight: bold;"><small>Arbitre</small></label>
											</div>

											<div name="refereeChoice" class="form-select-sm ml-auto d-inline-flex text-center" style="box-sizing: border-box; height: 30px; width: 100%;

													font-size: 1rem;
													font-weight: 400;
													line-height: 1.5;
													color: #212529;
													background-color: #fff;
													background-repeat: no-repeat;
													background-position: right .75rem center;
													background-size: 16px 12px;
													border: 1px solid #ced4da;
													border-radius: .25rem;
													-webkit-appearance: none;
													-moz-appearance: none;
													appearance: none;">
												<multiselect style="box-sizing: border-box;" v-model="refereeChoice" :custom-label="fistNameWithLastName" :maxHeight="300" :options="listReferee" placeholder="Choisissez un arbitre" :showLabels="false" label="firstName" track-by="nationalNumber">
													<template slot="singleLabel" slot-scope="{ option }">
														{{ option.firstName }}
														<strong>{{ option.lastName }}</strong>
													</template>
													<template slot="option" slot-scope="{ option }">
														{{ option.firstName }}
														<strong>{{ option.lastName }}</strong>
													</template>
												</multiselect>
											</div>
										</div>
										<div class="col-lg-3 col-sm-12 col-xs-12 offset-lg-2">
											<div class="align-items-center" style="text-align: center; margin-top: 0.5em">
												<b-button class="btn app-btn-primary" style=" max-width: 200px; margin-top: 1em;" v-on:click="showUnavailabilitiesInDateSelected()">Afficher les indisponibilités</b-button>
											</div>
										</div>
										<div class="col-lg-3 col-sm-12 col-xs-12">
											<div class="align-items-center" style="text-align: center; margin-top: 0.5em">
												<b-button class="btn app-btn-secondary" style=" max-width: 200px; margin-top: 1em;" v-on:click="addUnavailabilityModal()">Ajouter une indisponibilité</b-button>
											</div>
										</div>
									</div>

								</div>

							</div>
						</div><!--//col-->
						<div class="col-12 col-lg-2 align-center align-items-middle" style="text-align: center;">
							<a class="btn app-btn-secondary" style="color: #fa7507; border: 1px solid #fa7507; border-radius: .25rem;" v-on:click="downloadCSV()">
								<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-download mr-1" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
									<path fill-rule="evenodd" d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
									<path fill-rule="evenodd" d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
								</svg>
								Extraction CSV
							</a>
						</div><!--//col-->
					</div><!--//row-->
				</b-collapse><!--//app-card-body-->
			</div><!--//inner-->
		</div>
	</div>

	<div v-if="unavailabilityVisible && hidden == false" class="tab-content" id="orders-table-tab-content">
		<form class="app-search-form">
			<input name="search" type="text" id="myInput" @keyup="myFunction()" placeholder="Recherchez un nom, un prénom, une date" title="Recherche textuelle" class="form-control search-input" style="height: 50px; margin-bottom: 1em">
			<button disabled class="btn search-btn btn-primary" value="Search" style="padding-top: 1em">
				<svg class="svg-inline--fa fa-search fa-w-16" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="search" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
					<path fill="currentColor" d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"></path>
				</svg><!-- <i class="fas fa-search"></i> -->
			</button>
		</form>
		<div class="tab-panel fade show active" id="orders-all" role="tabpanel" aria-labelledby="orders-all-tab">
			<div class="app-card app-card-orders-table shadow-sm mb-5">
				<div class="app-card-body">
					<div class="table-responsive table-stiped">
						<table id="myTable2" class="table table-striped table-hover mb-0 text-center">
							<thead>
								<tr>
									<th class="mb-2 text-primary" v-on:click="sortTable(0)">
										Date de création
										<span v-if="etatTriColumn == 1 && etatTriTableau == true">
											<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-down-short" viewBox="0 0 16 16">
												<path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L7.5 10.293V4.5A.5.5 0 0 1 8 4z" />
											</svg>
										</span>
										<span v-if="etatTriColumn == 1 && etatTriTableau == false">
											<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-up-short" viewBox="0 0 16 16">
												<path fill-rule="evenodd" d="M8 12a.5.5 0 0 0 .5-.5V5.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 5.707V11.5a.5.5 0 0 0 .5.5z" />
											</svg>
										</span>
									</th>
									<th class="mb-2 text-primary" v-on:click="sortTable(1)">
										Prénom
										<span v-if="etatTriColumn == 2 && etatTriTableau == true">
											<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-down-short" viewBox="0 0 16 16">
												<path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L7.5 10.293V4.5A.5.5 0 0 1 8 4z" />
											</svg>
										</span>
										<span v-if="etatTriColumn == 2 && etatTriTableau == false">
											<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-up-short" viewBox="0 0 16 16">
												<path fill-rule="evenodd" d="M8 12a.5.5 0 0 0 .5-.5V5.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 5.707V11.5a.5.5 0 0 0 .5.5z" />
											</svg>
										</span>
									</th>
									<th class="mb-2 text-primary" v-on:click="sortTable(2)">
										Nom
										<span v-if="etatTriColumn == 3 && etatTriTableau == true">
											<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-down-short" viewBox="0 0 16 16">
												<path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L7.5 10.293V4.5A.5.5 0 0 1 8 4z" />
											</svg>
										</span>
										<span v-if="etatTriColumn == 3 && etatTriTableau == false">
											<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-up-short" viewBox="0 0 16 16">
												<path fill-rule="evenodd" d="M8 12a.5.5 0 0 0 .5-.5V5.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 5.707V11.5a.5.5 0 0 0 .5.5z" />
											</svg>
										</span>
									</th>
									<th class="mb-2 text-primary" v-on:click="sortTable(3)">
										Date de début
										<span v-if="etatTriColumn == 4 && etatTriTableau == true">
											<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-down-short" viewBox="0 0 16 16">
												<path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L7.5 10.293V4.5A.5.5 0 0 1 8 4z" />
											</svg>
										</span>
										<span v-if="etatTriColumn == 4 && etatTriTableau == false">
											<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-up-short" viewBox="0 0 16 16">
												<path fill-rule="evenodd" d="M8 12a.5.5 0 0 0 .5-.5V5.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 5.707V11.5a.5.5 0 0 0 .5.5z" />
											</svg>
										</span>
									</th>
									<th class="mb-2 text-primary" v-on:click="sortTable(4)">
										Date de fin
										<span v-if="etatTriColumn == 5 && etatTriTableau == true">
											<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-down-short" viewBox="0 0 16 16">
												<path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L7.5 10.293V4.5A.5.5 0 0 1 8 4z" />
											</svg>
										</span>
										<span v-if="etatTriColumn == 5 && etatTriTableau == false">
											<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-up-short" viewBox="0 0 16 16">
												<path fill-rule="evenodd" d="M8 12a.5.5 0 0 0 .5-.5V5.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 5.707V11.5a.5.5 0 0 0 .5.5z" />
											</svg>
										</span>
									</th>
									<th class="mb-2 text-primary">Actions</th>
								</tr>
							</thead>
							<tbody v-if="!noResult">
								<tr v-for="item in unavailabilities" :key="item.id" style="text-align: center;">
									<td class="cell">{{ item.unavailability.creationDate }}</td>
									<td class="cell">{{ item.referee.firstName }}</td>
									<td class="cell">{{ item.referee.lastName }}</td>
									<td class="cell">{{ item.unavailability.start }}</td>
									<td class="cell">{{ item.unavailability.end }}</td>
									<td class="cell">
										<b-button class="btn app-btn-secondary" style="margin: 3px" v-on:click="updateUnavailabilitiesModal(item)">
											<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil" viewBox="0 0 16 16">
												<path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
											</svg>
										</b-button>

										<b-button class="btn app-btn-secondary" style="margin: 3px" v-on:click="deleteUnavailabilitiesModal(item)">
											<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
												<path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
												<path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
											</svg>
										</b-button>
									</td>
								</tr>
							</tbody>
							<tbody v-if="noResult">
								<tr>
									<td colspan="6">Aucun résultat</td>
								</tr>
							</tbody>

						</table>
					</div>

				</div>

			</div>
		</div>

	</div>
	</div>

</template>

<style>
	.listeInfoMatch {
		margin: 0.5em
	}

	.multiselect__content-wrapper /*, .multiselect__element */ {
		width: fit-content !important;
		white-space: nowrap !important;
		overflow-x: hidden !important;
		text-overflow: ellipsis !important;
	}

	.multiselect__content {
		font-size: 0.875rem !important
	}

	.multiselect__single {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis !important;
	}

	.multiselect__above {
		white-space: nowrap !important;
		overflow: hidden !important;
		word-break: break-all !important;
		text-overflow: ellipsis !important;
	}

	.multiselect {
		box-sizing: border-box;
		position: relative;
		width: 100%;
		max-height: 30px !important;
		text-align: left;
		background: none !important;
		color: #35495e;
		text-overflow: ellipsis !important;
	}
	.multiselect__single {
		/*display: none !important;*/
		display: inline-block !important;
		margin-top: -0.1em !important;
		font-size: 0.875rem !important;
		color: #212529 !important;
		white-space: nowrap !important;
		overflow: hidden !important;
		box-sizing: border-box !important;
		word-break: break-all !important;
		max-width: 100px !important;
		box-sizing: border-box !important;
		text-overflow: ellipsis !important;
		text-align: center !important
	}

	.multiselect__input {
		/*display: none !important;*/
		display: inline-block !important;
		margin-top: -0.1em !important;
		font-size: 0.875rem !important;
		color: #212529 !important;
		white-space: nowrap !important;
		overflow: hidden !important;
		box-sizing: border-box !important;
		word-break: break-all !important;
		max-width: auto !important;
		box-sizing: border-box !important;
		text-overflow: ellipsis !important;
		text-align: center !important
	}

	.multiselect__select {
		height: 30px !important;
		text-overflow: ellipsis !important;
	}

	.multiselect__active > .multiselect__select {
		height: 30px !important;
		text-overflow: ellipsis !important;
	}

	.multiselect__current, .multiselect__select {
		/*display: none !important*/
		padding-bottom: 0.5em !important;
		text-overflow: ellipsis !important;
	}

	.multiselect__tags {
		margin-top: -0.1em !important;
		font-size: 0.875rem !important;
		min-height: 21px !important;
		max-height: 21px !important;
		display: inline-block !important;
		padding: 0 !important;
		border-radius: 0 !important;
		border: 0 !important;
		color: #212529 !important;
		text-overflow: ellipsis !important;
	}
</style>

<script>
import jwt_decode from "jwt-decode";
import Multiselect from "vue-multiselect";

export default {
	name: 'Unavailability',
    components: {
		Multiselect
    },
	computed:
	{
        dateStartInputState2: function () {
            var reDate = /^(20\d\d)-([1-9]|0\d|1[0-2])-([1-9]|[0-2]\d|3[0-1])$/;
            if (reDate.test(this.dateStartInput2)) { return true; }
            else { return false; }
        },
        dateEndInputState2: function () {
            var reDate = /^(20\d\d)-([1-9]|0\d|1[0-2])-([1-9]|[0-2]\d|3[0-1])$/;
            if (reDate.test(this.dateEndInput2)) { return true; }
            else { return false; }
		},

		dateStartInputState: function()
		{
			var reDate = /^(20\d\d)-([1-9]|0\d|1[0-2])-([1-9]|[0-2]\d|3[0-1])$/;
			if (reDate.test(this.dateStartInput)) { return true; }
			else { return false; }
		},
		dateEndInputState: function()
		{
			var reDate = /^(20\d\d)-([1-9]|0\d|1[0-2])-([1-9]|[0-2]\d|3[0-1])$/;
			if (reDate.test(this.dateEndInput)) { return true; }
			else { return false; }
		},
		hourStartInputState: function()
		{
			var reHour = /^([0-1]?\d|2[0-3]):([0-9]|[0-5]\d)$/;
			if (reHour.test(this.hourStartInput)) { return true; }
			else { return false; }
		},
		hourEndInputState: function()
		{
			var reHour = /^([0-1]?\d|2[0-3]):([0-9]|[0-5]\d)$/;
			if (reHour.test(this.hourEndInput)) { return true; }
			else { return false; }
		},
        endPeriodicityState: function () {
            var reDate = /^(20\d\d)-([1-9]|0\d|1[0-2])-([1-9]|[0-2]\d|3[0-1])$/;
            if (reDate.test(this.endPeriodicity)) { return true; }
            else { return false; }
        }

	},
	data() {
		return {
			// Variable message alert
			dismissSecs: 4,
			alertSuccessCountDown: 0,
			alertWarningCountDown: 0,
			info:'',
			error: '',

			etatTriTableau: true,
			etatTriColumn: 0,

			listWeek:[],
			week:'',
			unavailabilityVisible:false,
			unavailabilities: [],
			unavailabilitySelected:{
				"referee": {},
				"unavailability": {}
			},
			noResult:false,

			dateStartInput:'',
			hourStartInput:'',
			dateEndInput:'',
			hourEndInput: '',

			listReferee: [],
			listGroup: [],
			refereeChoice: {
				nationalNumber: 0,
				firstName: "Choisissez un arbitre"
			},
            dateStartInput2: '',
			dateEndInput2: '',
			groupChoice: '',

			addUnavailabilityReferee: 0,
			groupReferee: "0",
			filterListRefereePos1Available:[],
			filterListRefereePos2Available:[],
			listGroupAvailable:[],
			listRefereeAvailable: [],
			hidden: true,
			allDay: false,
			recursive: false,
            selectedPeriodicity: '',
			endPeriodicity: '',
			newUnavailibilites: [],
		}
	},

	mounted()
	{
		const wordings = ["Semaine dernière", "Semaine actuelle", "14 jours à venir", "30 jours à venir", "De 31 à 90 jours à venir"];
		this.listWeek = [];

		wordings.forEach((item) => {
			let t = new Date(new Date().setDate(new Date().getDate()));
			
			if (item == "Semaine dernière")
			{
				t = new Date(new Date().setDate(new Date().getDate() - 7));
			}

			var monday = new Date(t.setDate(t.getDate() - t.getDay() + 1));
			var sunday = new Date(t.setDate(t.getDate() + (8 - t.getDay())));
			t = new Date(new Date().setDate(new Date().getDate()));

			if (item == "14 jours à venir")
			{
				sunday = new Date(t.setDate(t.getDate() + 14));
			}
			if (item == "30 jours à venir")
			{
				sunday = new Date(t.setDate(t.getDate() + 30));
			}
			if (item == "De 31 à 90 jours à venir")
			{
				monday = new Date(t.setDate(t.getDate() + 30));
				sunday = new Date(t.setDate(t.getDate() + 90));
			}

			var week = {
				wording: item,
				start: monday,
				end:sunday
			}

			this.listWeek.push(week);
		});
        this.checkToken();
        let config = {
            headers: {
                'Authorization': 'Bearer ' + localStorage.token
            }
		};
        this.$axios.get('https://api.clickandball.fr/utilisateur/arbitre', config)
            .then(response => {
                if (response.status == 200 && response.data.error) {
                    this.showAlertWarning('Erreur : ' + response.data.error);
                }
                else if (response.status == 200) {
                    this.listReferee = response.data;
                }
                else {
                    this.showAlertWarning('Code erreur : ' + response.status);
                }
            })
            .catch(error => {
                this.showAlertWarning('Erreur : ' + error);
			});
        this.$axios.get('https://api.clickandball.fr/groupe', config)
            .then(response => {
                if (response.status == 200 && response.data.error) {
                    this.showAlertWarning('Erreur : ' + response.data.error);
                }
                else if (response.status == 200) {
                    this.listGroup = response.data;
                }
                else {
                    this.showAlertWarning('Code erreur : ' + response.status);
                }
            })
            .catch(error => {
                this.showAlertWarning('Erreur : ' + error);
            });
	},

		methods: {
			fistNameWithLastName(option) {
                return `${option.firstName} ${option.lastName }`;
            },
            myFunction: function () {
                var input, filter, table, tr;
                input = document.getElementById("myInput");
                filter = input.value.toUpperCase();
                table = document.getElementById("myTable2");
                tr = table.getElementsByTagName("tr");
                for (var i = 1; i < tr.length; i++) {
                    var tds = tr[i].getElementsByTagName("td");
                    var flag = false;
                    for (var j = 0; j < tds.length; j++) {
                        var td = tds[j];
                        if (td.innerHTML.toUpperCase().indexOf(filter) > -1) {
                            flag = true;
                        }
                    }
                    if (flag) {
                        tr[i].style.display = "";
                    }
                    else {
                        tr[i].style.display = "none";
                    }
                }
            },
            sortTable(n) {
                let textA;
                let textB;
				let etatTri = this.etatTriTableau;
				let etatTriColumn = this.etatTriColumn;

                this.unavailabilities.sort(function (a, b) {
                    switch (n) {
                        case 0:
                            textA = a.unavailability.creationDate.toUpperCase();
							textB = b.unavailability.creationDate.toUpperCase();
							etatTriColumn = 1;
                            break;
                        case 1:
                            textA = a.referee.firstName.toUpperCase();
							textB = b.referee.firstName.toUpperCase();
                            etatTriColumn = 2;
                            break;
                        case 2:
                            textA = a.referee.lastName.toUpperCase();
							textB = b.referee.lastName.toUpperCase();
                            etatTriColumn = 3;
                            break;
                        case 3:
                            textA = a.unavailability.start.toUpperCase();
							textB = b.unavailability.start.toUpperCase();
                            etatTriColumn = 4;
                            break;
                        case 4:
                            textA = a.unavailability.end.toUpperCase();
							textB = b.unavailability.end.toUpperCase();
                            etatTriColumn = 5;
                            break;
                        default:
                            break;
                    }

                    if (etatTri) {
                        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                    }
                    else {
                        return (textA > textB) ? -1 : (textA > textB) ? 0 : 1;
                    }
                });

				this.etatTriTableau = !etatTri;
                this.etatTriColumn = etatTriColumn;
            },

		// Méthodes message alert
		alertSuccessCountDownChanged(alertSuccessCountDown)
		{
			this.alertSuccessCountDown = alertSuccessCountDown
		},
		alertWarningCountDownChanged(alertWarningCountDown)
		{
			this.alertWarningCountDown = alertWarningCountDown
		},
		showAlertSuccess(info)
		{
			this.info = info;
			this.alertSuccessCountDown = this.dismissSecs
		},
		showAlertWarning(error)
		{
			this.error = error;
			this.alertWarningCountDown = this.dismissSecs
		},
		checkToken()
		{
			if (localStorage.token)
			{
				var jsonToken = jwt_decode(localStorage.token);
				if ((jsonToken.exp - 60) < Math.round(Date.now()/1000))
				{
					localStorage.token = null;
					this.connected = false;
					this.$router.push({ path: '/login'})
				}
			}
		},
		
		getDateHourString(date)
		{
			// prints date & time in YYYY-MM-DD HH:MM:SS format
			return date.getFullYear() + "-" + ("0" + (date.getMonth() + 1)).slice(-2) + "-" + ("0" + date.getDate()).slice(-2) + " " + date.getHours() + ":" + date.getMinutes() + ":" + ("0" + date.getSeconds()).slice(-2);
		},
		getDateString(date)
		{
			// prints date & time in YYYY-MM-DD format
			return date.getFullYear() + "-" + ("0" + (date.getMonth() + 1)).slice(-2) + "-" + ("0" + date.getDate()).slice(-2);
		},
		showUnavailabilitiesInDateSelected()
		{
			this.checkToken();
			//if (this.week === '')
			//{
			//	this.showAlertWarning('Aucune date de sélectionnée')
			//}
			//else
			//{

            if (this.refereeChoice.nationalNumber == 0) {
                this.refereeChoice.nationalNumber = ''
			}

				this.unavailabilities = [];
				this.unavailabilityVisible = true;
				this.noResult = false;
				//var week = this.listWeek.find(x => x.wording === this.week);

				let config = {
					headers: {
						'Authorization': 'Bearer ' + localStorage.token
					}
				};

			this.$axios.get('https://api.clickandball.fr/designation/indisponibilite?start=' + this.dateStartInput2 + "&end=" + this.dateEndInput2 + "&nationalNumber=" + this.refereeChoice.nationalNumber + "&group=" + this.groupChoice, config)
					.then(response => {
						if (response.status == 200 && response.data.error)
						{
							this.showAlertWarning('Erreur : ' + response.data.error);
                            this.hidden = true;
						}
						else if (response.status == 200)
						{
							this.unavailabilities = response.data;
							if (this.unavailabilities.length === 0)
							{
								this.noResult = true;
							}
                            this.hidden = false;
						}
						else
						{
							this.showAlertWarning('Code erreur : ' + response.status);
                            this.hidden = true;
						}
					})
					.catch(error => {
						this.showAlertWarning('Erreur : ' + error);
                        this.hidden = true;
					});
			//}
		},
		updateUnavailabilitiesModal(info)
		{
			this.checkToken();
			this.unavailabilitySelected = info;

			this.dateStartInput = new Date(info.unavailability.start).getFullYear() + "-" + ("0" + (new Date(info.unavailability.start).getMonth() + 1)).slice(-2) + "-" + ("0" + (new Date(info.unavailability.start).getDate())).slice(-2);
			this.hourStartInput = ("0" + (new Date(info.unavailability.start).getHours())).slice(-2) + ":" + ("0" + (new Date(info.unavailability.start).getMinutes())).slice(-2);
			this.dateEndInput = new Date(info.unavailability.end).getFullYear() + "-" + ("0" + (new Date(info.unavailability.end).getMonth() + 1)).slice(-2) + "-" + ("0" + (new Date(info.unavailability.end).getDate())).slice(-2);
			this.hourEndInput = ("0" + (new Date(info.unavailability.end).getHours())).slice(-2) + ":" + ("0" + (new Date(info.unavailability.end).getMinutes())).slice(-2);

			this.$refs['modalUpdateUnavailability'].show();
		},
		updateUnavailabilities()
		{
			this.checkToken();
			const dateStartInput = document.querySelector('input[name="dateStartInput"]');
			const hourStartInput = document.querySelector('input[name="hourStartInput"]');
			const dateEndInput = document.querySelector('input[name="dateEndInput"]');
			const hourEndInput = document.querySelector('input[name="hourEndInput"]');
			
			var reDate = /^(20\d\d)-([1-9]|0\d|1[0-2])-([1-9]|[0-2]\d|3[0-1])$/;
			var reHour = /^([0-1]?\d|2[0-3]):([0-9]|[0-5]\d)$/;

            if (this.allDay == true) {
                this.hourStartInput = '00:00',
                    this.dateEndInput = this.dateStartInput,
                    this.hourEndInput = '23:59'
            }

			if (!reDate.test(this.dateStartInput))
			{
				dateStartInput.setCustomValidity("Veuillez saisir une date de début");
			}
			if (!reDate.test(this.dateEndInput))
			{
				dateEndInput.setCustomValidity("Veuillez saisir une date de fin");
			}
			if (!reHour.test(this.hourStartInput))
			{
				hourStartInput.setCustomValidity("Veuillez saisir une heure de début");
			}
			if (!reHour.test(this.hourEndInput))
			{
				hourEndInput.setCustomValidity("Veuillez saisir une heure de fin");
			}

			if(reDate.test(this.dateStartInput) && reDate.test(this.dateEndInput) && reHour.test(this.hourStartInput) && reHour.test(this.hourEndInput))
			{
				this.$refs['modalUpdateUnavailability'].hide();
				let data = {
					id: this.unavailabilitySelected.unavailability.id,
					start: this.getDateHourString(new Date(this.dateStartInput + "T" + this.hourStartInput)), 
					end: this.getDateHourString(new Date(this.dateEndInput + "T" + this.hourEndInput))
				};
				let config = {
					headers: {
						'Authorization': 'Bearer ' + localStorage.token
					}
				};

				this.$axios.put('https://api.clickandball.fr/designation/indisponibilite', data, config)
					.then(response => {
						if (response.status == 200 && response.data.error)
						{
							this.showAlertWarning('Erreur : ' + response.data.error);
						}
						else if (response.status == 200)
						{
							this.showAlertSuccess("Indisponibilité mise à jour");
                            this.showUnavailabilitiesInDateSelected();
						}
						else
						{
							this.showAlertWarning('Erreur : ' + response.statusText);
						}
					})
					.catch(error => {
						this.showAlertWarning('Erreur : ' + error); 
					});
			}
		},
		deleteUnavailabilitiesModal(info)
		{
			this.checkToken();
			this.$refs['modalDeleteUnavailability'].show();
			this.unavailabilitySelected = info;
		},
		deleteUnavailabilities()
		{
			this.checkToken();
			this.$refs['modalDeleteUnavailability'].hide();
			let config = {
				headers: {
					'Authorization': 'Bearer ' + localStorage.token,
				},
				data: { "id" : [parseInt(this.unavailabilitySelected.unavailability.id)] }
			};

			this.$axios.delete('https://api.clickandball.fr/designation/indisponibilite', config)
				.then(response => {
					if (response.status == 200 && response.data.error)
					{
						this.showAlertWarning('Erreur : ' + response.data.error);
					}
					else if (response.status == 200)
					{
						this.showAlertSuccess("Indisponibilité supprimée");
						this.showUnavailabilitiesInDateSelected();
					}
					else
					{
						this.showAlertWarning('Code erreur : ' + response.status);
					}
				})
				.catch(error => {
					this.showAlertWarning('Erreur : ' + error);
				});
		},
		addUnavailabilityModal()
		{
			this.checkToken();
			this.$refs['modalAddUnavailability'].show();

			this.addUnavailabilityReferee = 0;
			this.groupReferee = 0;
			this.filterListRefereePos1Available = [];
			this.filterListRefereePos2Available = [];

			let config = {
				headers: {
					'Authorization': 'Bearer ' + localStorage.token
				}
			};

			this.$axios.get('https://api.clickandball.fr/utilisateur/arbitre', config)
				.then(response => {
					if (response.status == 200 && response.data.error)
					{
						this.showAlertWarning('Erreur : ' + response.data.error);
					}
					else if (response.status == 200)
					{
						this.listRefereeAvailable = response.data;
						
						this.listRefereeAvailable.forEach(x => {
							if (x.refereePosition === 1)
							{
								this.filterListRefereePos1Available.push(x);
							}
							else if (x.refereePosition === 2)
							{
								this.filterListRefereePos2Available.push(x);
							}
						})
					}
					else
					{
						this.showAlertWarning('Code erreur : ' + response.status);
					}
				})
				.catch(error => {
					this.showAlertWarning('Erreur : ' + error);
				});

			this.$axios.get('https://api.clickandball.fr/groupe', config)
				.then(response => {
					if (response.status == 200 && response.data.error)
					{
						this.showAlertWarning('Erreur : ' + response.data.error);
					}
					else if (response.status == 200)
					{
						this.listGroupAvailable = response.data;
					}
					else
					{
						this.showAlertWarning('Code erreur : ' + response.status);
					}
				})
				.catch(error => {
					this.showAlertWarning('Erreur: ' + error);
				});
		},
		addUnavailability()
		{
			this.checkToken();
			const dateStartInput = document.querySelector('input[name="dateStartInput"]');
			const hourStartInput = document.querySelector('input[name="hourStartInput"]');
			const dateEndInput = document.querySelector('input[name="dateEndInput"]');
			const hourEndInput = document.querySelector('input[name="hourEndInput"]');
			
			var reDate = /^(20\d\d)-([1-9]|0\d|1[0-2])-([1-9]|[0-2]\d|3[0-1])$/;
			var reHour = /^([0-1]?\d|2[0-3]):([0-9]|[0-5]\d)$/;

            if (this.allDay == true) {
                this.hourStartInput = '00:00',
                    this.dateEndInput = this.dateStartInput,
                    this.hourEndInput = '23:59'
            }

			if (!reDate.test(this.dateStartInput))
			{
				dateStartInput.setCustomValidity("Veuillez saisir une date de début");
			}
			if (!reDate.test(this.dateEndInput))
			{
				dateEndInput.setCustomValidity("Veuillez saisir une date de fin");
			}
			if (!reHour.test(this.hourStartInput))
			{
				hourStartInput.setCustomValidity("Veuillez saisir une heure de début");
			}
			if (!reHour.test(this.hourEndInput))
			{
				hourEndInput.setCustomValidity("Veuillez saisir une heure de fin");
			}

            if (reDate.test(this.dateStartInput) && reDate.test(this.dateEndInput) && reHour.test(this.hourStartInput) && reHour.test(this.hourEndInput)) {

                if (this.recursive) {
                    if (this.endPeriodicity) {
                        if (this.dateStartInput < this.endPeriodicity && this.dateEndInput < this.endPeriodicity) {
                            if (this.selectedPeriodicity != 0) {
                                let n = 0;
                                this.newUnavailabilities = [];

                                var fullDateStart = this.getDateHourString(new Date(this.dateStartInput + "T" + this.hourStartInput));
                                var fullDateEnd = this.getDateHourString(new Date(this.dateEndInput + "T" + this.hourEndInput));
                                this.newUnavailabilities.push(n, fullDateStart, fullDateEnd);

                                n++;


                                while (this.dateStartInput < this.endPeriodicity) {
                                    var start = new Date(this.dateStartInput);
                                    var end = new Date(this.dateEndInput);
                                    start.setDate(start.getDate() + this.selectedPeriodicity);
                                    start = this.getDateString(start),
                                        end.setDate(end.getDate() + this.selectedPeriodicity);
                                    end = this.getDateString(end),
                                        this.dateStartInput = start;
                                    this.dateEndInput = end;
                                    fullDateStart = this.getDateHourString(new Date(this.dateStartInput + "T" + this.hourStartInput));
                                    fullDateEnd = this.getDateHourString(new Date(this.dateEndInput + "T" + this.hourEndInput));
                                    this.newUnavailabilities.push(n, fullDateStart, fullDateEnd);
                                    n++;
                                }

                                this.$refs['modalAddUnavailability'].hide();
                                let data = [];
                                let config = {
                                    headers: {
                                        'Authorization': 'Bearer ' + localStorage.token
                                    }
                                };

                                for (var i = 0; i < this.newUnavailabilities.length; i += 3) {
                                    var y = i + 1;
                                    var z = i + 2;
									data.push({
                                        nationalNumber: this.addUnavailabilityReferee,
                                        start: this.newUnavailabilities[y],
                                        end: this.newUnavailabilities[z]
                                    });
                                }

                                this.$axios.post('https://api.clickandball.fr/designation/indisponibilite', data, config)
                                    .then(response => {
                                        if (response.status == 200 && response.data.error) {
                                            this.showAlertWarning('Erreur: ' + response.data.error);
                                        }
                                        else if (response.status == 200) {
                                            this.showAlertSuccess("Indisponibilité récurrente sauvegardée");
                                            this.newUnavailabilities = [];
                                            if (this.hidden == false) {
                                                this.showUnavailabilitiesInDateSelected();
                                            }
                                        }
                                        else {
                                            this.showAlertWarning('Erreur : ' + response.statusText);
                                        }
                                    })
                                    .catch(error => {
                                        this.showAlertWarning('Erreur : ' + error);
                                    });
                            }
                            else {
                                this.$refs['modalUnavailabilities'].hide();
                                this.showAlertWarning('Erreur: Aucune périodicité n\'a été choisie');
                            }
                        }
                        else {
                            this.$refs['modalUnavailabilities'].hide();
                            this.showAlertWarning('Erreur: La date de début de l\'indisponibilité est inférieure à la date de fin de la périodicité de l\'indisponibilité');
                        }
                    }

                }
                else {
                    this.$refs['modalAddUnavailability'].hide();
                    let data = [];
                    data.push({
						nationalNumber: this.addUnavailabilityReferee,
						start: this.getDateHourString(new Date(this.dateStartInput + "T" + this.hourStartInput)),
                        end: this.getDateHourString(new Date(this.dateEndInput + "T" + this.hourEndInput))
                    });
                    let config = {
                        headers: {
                            'Authorization': 'Bearer ' + localStorage.token
                        }
                    };
                    this.$axios.post('https://api.clickandball.fr/designation/indisponibilite', data, config)
                        .then(response => {
                            if (response.status == 200 && response.data.error) {
                                this.showAlertWarning('Erreur: ' + response.data.error);
                            }
                            else if (response.status == 200) {
								this.showAlertSuccess("Indisponibilité sauvegardée");
								if (this.hidden == false) {
                                    this.showUnavailabilitiesInDateSelected();
                                }
                            }
                            else {
                                this.showAlertWarning('Erreur : ' + response.statusText);
                            }
                        })
                        .catch(error => {
                            this.showAlertWarning('Erreur : ' + error);
                        });
                }
            }

		},
		filterReferee()
		{
			this.filterListRefereePos1Available = [];
			this.filterListRefereePos2Available = [];
			this.listRefereeAvailable.forEach(x => {
				if ((x.refereeLevel.id == this.groupReferee && x.refereePosition === 1) || (0 == this.groupReferee && x.refereePosition === 1))
				{
					this.filterListRefereePos1Available.push(x);
				}
				else if ((x.refereeLevel.id == this.groupReferee && x.refereePosition === 2) || (0 == this.groupReferee && x.refereePosition === 2))
				{
					this.filterListRefereePos2Available.push(x);
				}
			})
		},
		downloadCSV()
		{
			this.checkToken();
			//if (this.week === '')
			//{
			//	this.showAlertWarning('Aucune date de sélectionnée')
			//}
			//else
			//{
				//var week = this.listWeek.find(x => x.wording === this.week);

				let config = {
					headers: {
						'Authorization': 'Bearer ' + localStorage.token,
						'Content-Disposition': 'attachment'
					},
					responseType: 'blob'
				};

			this.$axios.get('https://api.clickandball.fr/designation/extraction/indisponibilite?start=' + this.dateStartInput2 + "&end=" + this.dateEndInput2 + "&nationalNumber=" + this.refereeChoice, config)
					.then(response => {
						if (response.status == 200 && response.data.error)
						{
							this.showAlertWarning('Erreur : ' + response.data.error);
						}
						else if (response.status == 200)
						{
							const url = window.URL.createObjectURL(new Blob([response.data]));
							const link = document.createElement('a');
							link.href = url;
							link.setAttribute('download', 'Indisponibilités.csv');
							document.body.appendChild(link);
							link.click();
						}
						else
						{
							this.showAlertWarning('Code erreur : ' + response.status);
						}
					})
					.catch(error => {
						this.showAlertWarning('Erreur : ' + error);
					});
			//}
		}
	}
}
</script>

