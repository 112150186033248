<template>
	<div class="Match">
		<h1 class="app-page-title"><img src="https://img.icons8.com/ios-glyphs/50/000000/basketball--v1.png" /> Gestion des matchs</h1>
		<!--Modal-->

		<b-modal id="modal-xl" size="lg" ref="modalMatch" hide-footer hide-header>
			<div class="app-card " style="margin-top: -1em; margin: -1em">
				<a style="float: right; z-index: 9999; margin-right: 0.5em; top: 0" href="#" block @click="$refs['modalMatch'].hide()">
					<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
						<path fill-rule="evenodd" d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z" />
						<path fill-rule="evenodd" d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z" />
					</svg>
				</a>
				<div class="app-card-header p-3 border-bottom-0">
					<div class="row align-items-center gx-3">
						<div class="col-auto">
							<div class="app-icon-holder" style="background-color: rgb(42, 87, 136)">
								<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-shield-check" fill="white" xmlns="http://www.w3.org/2000/svg">
									<path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z" />
									<path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z" />
								</svg>
							</div>
						</div>
						<div class="col-auto">
							<h4 v-if="modalUpdateMatch" class="app-card-title" style="color: rgb(42, 87, 136) !important">Modifier un match</h4>
							<h4 v-else class="app-card-title" style="color: rgb(42, 87, 136) !important">Ajouter un match</h4>
						</div>
					</div>
				</div>
			</div>
			<div class="app-card app-card-notification mb-4" style="margin-top: -0.5em; margin-bottom: -1em">
				<div class="app-card-header px-4 py-3">
					<div class="row g-3 align-items-center">
						<div class="col-12 col-lg-auto text-lg-left">
							<div class="notification-type mb-2">
								<span class="badge bg-info">
									Information
								</span>
								<ul class="notification-meta list-inline mb-0" style="margin-top: 0.25em;">
									<li class="list-inline-item">
										Les matchs sont importés depuis FBI, une modification sur Click & Ball ne modifie pas les informations du match sur FBI
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="app-card-body p-4" style="margin-top: -2em">
				<div class="notification-content" style="margin-bottom: -2em">
					<div class="row">
						<div class="col-md-4 offset-md-1">
							<div class="form-group">
								<label for="divisionId" style="font-weight: bold;" class="form-label">Division :</label>
								<select :disabled="modalUpdateMatch" :state="true" class="form-select form-select-sm ml-auto d-inline-flex text-center" v-model="divisionId">
									<option disabled value="">Choisissez une division</option>
									<option v-for="item in listdivisionId" :key="item.id" :value="item.id">
										{{ item.name }}
									</option>
								</select>
							</div>
						</div>
						<div class="col-md-4">
							<div class="form-group">
								<label for="idMatch" style="font-weight: bold;" class="form-label">N° de rencontre :</label>
								<b-form-input :disabled="modalUpdateMatch" type="number" min="0" v-model="idMatch" class="form-select-sm ml-auto d-inline-flex text-center" style="padding: .375rem 2.25rem .375rem .75rem; font-weight: 400; line-height: 1.5; height: 30px; border: 1px solid #ced4da; border-radius: .25rem; "></b-form-input>
							</div>
						</div>
						<div class="col-md-4 offset-md-1">
							<div class="form-group">
								<label for="divisionId" style="font-weight: bold;" class="form-label">Poule :</label>
								<select :disabled="modalUpdateMatch" :state="true" class="form-select form-select-sm ml-auto d-inline-flex text-center" v-model="poule">
									<option disabled value="">Choisissez une poule</option>
									<option v-for="item in listPoule" :key="item.id" :value="item.id">
										{{ item.nomLong }}
									</option>
								</select>
							</div>
						</div>
					</div>
					<hr />
					<div class="row">
						<div class="col-md-5 offset-md-1">
							<div class="form-group">
								<label for="dateMatch" style="font-weight: bold;" class="form-label">Date :</label>
								<b-form-input :state="dateState" v-model="dateMatch" type="date" class="form-select-sm ml-auto d-inline-flex text-center" style="padding: .375rem 2.25rem .375rem .75rem; font-weight: 400; line-height: 1.5; height: 30px; border: 1px solid #ced4da; border-radius: .25rem; "></b-form-input>
							</div>
						</div>
						<div class="col-md-5">
							<div class="form-group">
								<label for="hourMatch" style="font-weight: bold;" class="form-label">Heure :</label>
								<b-form-input :state="hourState" v-model="hourMatch" type="time" class="form-select-sm ml-auto d-inline-flex text-center" style="padding: .375rem 2.25rem .375rem .75rem; font-weight: 400; line-height: 1.5; height: 30px; border: 1px solid #ced4da; border-radius: .25rem; "></b-form-input>
							</div>
						</div>
						<div class="col-md-10 offset-md-1">
							<div class="form-group">
								<label for="divisionId" style="font-weight: bold;" class="form-label">Salle : </label>
								<select class="form-select form-select-sm ml-auto d-inline-flex text-center" v-model="sportsHall">
									<option disabled value="">Choisissez une salle</option>
									<option v-for="item in listSportsHall" :key="item.id" :value="item.id">
										{{ item.name }} - {{ item.city }}
									</option>
								</select>
							</div>
						</div>
					</div>
					<hr />
					<div class="row">
						<div class="col-md-5 offset-md-1">
							<div class="form-group">
								<label style="font-weight: bold;" class="form-label">Equipe A : </label>
								<select :disabled="modalUpdateMatch" class="form-select form-select-sm ml-auto d-inline-flex text-center" v-model="homeClub">
									<option disabled value="">Choisissez une équipe</option>
									<option v-for="item in listClub" :key="item.id" :value="item.id">
										{{ item.name }}
									</option>
								</select>
							</div>
						</div>
						<div class="col-md-5">
							<div class="form-group">
								<label style="font-weight: bold;" class="form-label">Suffixe équipe A : </label>
								<b-form-input :disabled="modalUpdateMatch" type="number" min="0" v-model="suffixHomeClub" class="form-select-sm ml-auto d-inline-flex text-center" style="padding: .375rem 2.25rem .375rem .75rem; font-weight: 400; line-height: 1.5; height: 30px; border: 1px solid #ced4da; border-radius: .25rem; "></b-form-input>
							</div>
						</div>
					</div>
					<hr />
					<div class="row">
						<div class="col-md-10 offset-md-1">
							<div class="form-group">
								<div class="form-check form-switch mb-3">
									<span style="font-weight:bold;">Equipe visiteuse : </span><br />
									<input class="form-check-input" type="checkbox" v-model="visitorClubSameEntity" :disabled="modalUpdateMatch" name="check-button" style="margin: 0.4em; margin-left: 0 !important" /> L'équipe B est elle dans la même entitée ?
								</div>
							</div>
						</div>
						<div class="col-md-5 offset-md-1">
							<div class="form-group">
								<label style="font-weight: bold;" class="form-label">Equipe B : </label>
								<select v-if="visitorClubSameEntity" :disabled="modalUpdateMatch" class="form-select form-select-sm ml-auto d-inline-flex text-center" v-model="visitorClub">
									<option disabled value="">Choisissez une équipe</option>
									<option v-for="item in listClub" :key="item.id" :value="item.id">
										{{ item.name }}
									</option>
								</select>
								<input v-if="!visitorClubSameEntity" :disabled="modalUpdateMatch" type="text" class="col-12 form-select-sm ml-auto d-inline-flex text-center" style="padding: .375rem 2.25rem .375rem .75rem; font-weight: 400; line-height: 1.5; height: 30px; border: 1px solid #ced4da; border-radius: .25rem; " id="address" v-model="visitorClub" placeholder="Nom de l'équipe">
							</div>
						</div>
						<div v-if="visitorClubSameEntity" class="col-md-5">
							<div class="form-group">
								<label style="font-weight: bold;" class="form-label">Suffixe B : </label>
								<b-form-input :disabled="modalUpdateMatch" type="number" min="0" v-model="suffixVisitorClub" class="form-select-sm ml-auto d-inline-flex text-center" style="padding: .375rem 2.25rem .375rem .75rem; font-weight: 400; line-height: 1.5; height: 30px; border: 1px solid #ced4da; border-radius: .25rem; "></b-form-input>
							</div>
						</div>
					</div>
				</div>
			</div>
			<hr>

			<div class="text-center" style="margin-bottom: -0.5em">
				<b-button v-if="modalUpdateMatch" class="btn app-btn-primary" style="background: rgb(42, 87, 136);color: white; width: 250px; margin: 0.5em;" v-on:click="updateMatch()">Enregistrer</b-button>
				<b-button v-else class="btn app-btn-primary" style="background: rgb(42, 87, 136);color: white; width: 250px; margin: 0.5em;" v-on:click="AjouterMatch()">Enregistrer</b-button>
			</div>
		</b-modal>


		<b-modal ref="modalInfoMatch" hide-footer hide-header size="lg">
			<div class="app-card " style="margin-top: -1em; margin-left: -1em; margin-right: -1em">
				<a style="float: right; z-index: 9999; margin-right: 0.5em; top: 0" href="#" block @click="$refs['modalInfoMatch'].hide()">
					<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
						<path fill-rule="evenodd" d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z" />
						<path fill-rule="evenodd" d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z" />
					</svg>
				</a>
				<div class="app-card-header p-3 border-bottom-0">
					<div class="row align-items-center gx-3">
						<div class="col-auto">
							<div class="app-icon-holder" style="background-color: rgb(42, 87, 136)">
								<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-shield-check" fill="white" xmlns="http://www.w3.org/2000/svg">
									<path d="M4 11a1 1 0 1 1 2 0v1a1 1 0 1 1-2 0v-1zm6-4a1 1 0 1 1 2 0v5a1 1 0 1 1-2 0V7zM7 9a1 1 0 0 1 2 0v3a1 1 0 1 1-2 0V9z" />
									<path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z" />
									<path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z" />
								</svg>
							</div>
						</div>
						<div class="col-auto">
							<h4 class="app-card-title" style="color: rgb(42, 87, 136) !important">Informations du match</h4>
						</div>
					</div>
				</div>
			</div>
			<div class="app-card app-card-notification shadow-sm mb-4" >
				<div class="app-card-body" style="margin-bottom: -1em">
					<div class="app-card-body p-4">
						<div class="notification-content" style="margin-bottom: -2em; margin-top: -1em">
							<ul class="list-unstyled">
								<li class="listeInfoMatch">
									<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2 text-primary mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
										<path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
									</svg>
									<strong>
										Division :
									</strong>
									{{ matchesSelected.divisionId }}
								</li>
								<li class="listeInfoMatch">
									<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2 text-primary mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
										<path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
									</svg>
									<strong>
										N° de rencontre :
									</strong>
									{{ matchesSelected.id }}
								</li>
								<li class="listeInfoMatch">
									<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2 text-primary mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
										<path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
									</svg>
									<strong>
										Poule :
									</strong>
									{{ matchesSelected.poule.id }}
								</li>
								<li class="listeInfoMatch">
									<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2 text-primary mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
										<path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
									</svg>
									<strong>
										Date :
									</strong>
									{{ matchesSelected.date }}
								</li>
							</ul>
							<hr />
							<ul class="list-unstyled">
								<li class="listeInfoMatch">
									<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2 text-primary mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
										<path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
									</svg>
									<strong>
										Salle :
									</strong>
									{{ matchesSelected.sportsHall.name }}
								</li>
								<li class="listeInfoMatch">
									<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2 text-primary mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
										<path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
									</svg>
									<strong>
										Ville :
									</strong>
									{{ matchesSelected.sportsHall.city }}
								</li>
							</ul>
							<hr />
							<ul class="list-unstyled">
								<li class="listeInfoMatch">
									<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2 text-primary mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
										<path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
									</svg>
									<strong>
										Equipe A :
									</strong>
									{{ matchesSelected.homeClub.name }}
								</li>
								<li class="listeInfoMatch">
									<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2 text-primary mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
										<path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
									</svg>
									<strong>
										Equipe B :
									</strong>
									{{ matchesSelected.visitorClub.name }}
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</b-modal>

		<b-modal ref="modalDeleteMatch" hide-footer hide-header>
			<div class="app-card " style="margin: -1em">
				<a style="float: right; z-index: 9999; margin-right: 0.5em; top: 0" href="#" block @click="$refs['modalDeleteMatch'].hide()">
					<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
						<path fill-rule="evenodd" d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z" />
						<path fill-rule="evenodd" d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z" />
					</svg>
				</a>
				<div class="app-card-header p-3 border-bottom-0">
					<div class="row align-items-center gx-3">
						<div class="col-auto">
							<div class="app-icon-holder" style="background-color: rgb(42, 87, 136)">
								<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-shield-check" fill="white" xmlns="http://www.w3.org/2000/svg">
									<path fill-rule="evenodd" d="M6.146 7.146a.5.5 0 0 1 .708 0L8 8.293l1.146-1.147a.5.5 0 1 1 .708.708L8.707 9l1.147 1.146a.5.5 0 0 1-.708.708L8 9.707l-1.146 1.147a.5.5 0 0 1-.708-.708L7.293 9 6.146 7.854a.5.5 0 0 1 0-.708z" />
									<path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z" />
									<path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z" />
								</svg>
							</div>
						</div>
						<div class="col-auto">
							<h4 class="app-card-title" style="color: rgb(42, 87, 136) !important">Supprimer un match</h4>
						</div>
					</div>
				</div>
			</div>
			<div class="app-card app-card-notification shadow-sm mb-4">
				<div class="app-card-body">
					<div class="app-card-body p-4">
							<div class="notification-content" style="margin-bottom: -2em; margin-top: -1em">
								<ul class="list-unstyled">
									<li class="listeInfoMatch">
										<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2 text-primary mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
											<path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
										</svg>
										<strong>
											Division :
										</strong>
										{{ matchesSelected.divisionId }}

										<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2 text-primary mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
											<path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
										</svg>
										<strong>
											N° de rencontre :
										</strong>
										{{ matchesSelected.id }}
									</li>
									<li class="listeInfoMatch">
										<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2 text-primary mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
											<path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
										</svg>
										<strong>
											Date :
										</strong>
										{{ matchesSelected.date }}
									</li>
								</ul>
								<hr />
								<ul class="list-unstyled">
									<li class="listeInfoMatch">
										<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2 text-primary mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
											<path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
										</svg>
										<strong>
											Equipe A :
										</strong>
										{{ matchesSelected.homeClub.name }}
									</li>
									<li class="listeInfoMatch">
										<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-check2 text-primary mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
											<path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" />
										</svg>
										<strong>
											Equipe B :
										</strong>
										{{ matchesSelected.visitorClub.name }}
									</li>
								</ul>
							</div>
						</div>
				</div>
			</div>
			<div class="text-center">
				<b-button v-if="modalUpdateMatch" class="btn app-btn-primary" style="background: rgb(42, 87, 136);color: white; width: 200px; margin: 0.5em;" v-on:click="SupprimerMatch()">Confirmer la suppression</b-button>
			</div>
		</b-modal>


		<!--Modal-->
		<b-alert :show="alertSuccessCountDown" variant="success" @dismissed="alertSuccessCountDown=0" @dismiss-count-down="alertSuccessCountDownChanged">
			<p>{{ info }}</p>
			<b-progress variant="success" :max="dismissSecs" :value="alertSuccessCountDown" height="4px"></b-progress>
		</b-alert>
		<b-alert :show="alertWarningCountDown" variant="warning" @dismissed="alertWarningCountDown=0" @dismiss-count-down="alertWarningCountDownChanged">
			<p>{{ error }}</p>
			<b-progress variant="warning" :max="dismissSecs" :value="alertWarningCountDown" height="4px"></b-progress>
		</b-alert>

		<div class="accordion2" role="tablist">
			<div class="app-card shadow-sm mb-4 border-left-decoration">
				<div class="inner">
					<div block v-b-toggle.actions id="title-legende" type="button" data-toggle="" class="card-header align-middle" style="background-color: rgba(0,0,0,.05) !important; border-bottom: 2px solid rgb(47 87 136)">
						<h5 class="title align-middle" style="color: rgb(47 87 136)">
							<i class="fas fa-search-plus"></i>
							Actions
						</h5>
					</div>
					<b-collapse visible id="actions" accordion="my-accordion2" role="tabpanel" class="app-card-body p-4">
						<div class="row g-2 justify-content-center justify-content-md-end">
							<div class="col-12 col-sm-12 col-lg-10" style="margin-top: -1em; margin-bottom: -1em">
								<div class="col-lg-12">
									<div class="row" style="margin-bottom: 1em">
										<div class="col-lg-2 col-md-3 col-sm-12 col-12">
											<label for="dateStartInput" style="font-weight: bold; margin-top: 0.5em"><small>Date de début</small></label>
											<b-form-input class="form-select-sm ml-auto d-inline-flex text-center" style="padding: .375rem 2.25rem .375rem .75rem; font-weight: 400; line-height: 1.5; height: 30px; border: 1px solid #ced4da; border-radius: .25rem; " :state="dateStartInputState" name="dateStartInput" v-model="dateStartInput" type="date" required></b-form-input>
										</div>

										<div class="col-lg-2 col-md-3 col-sm-12 col-12" style="margin-top: 0.5em">
											<label for="dateEndInput" style="font-weight: bold;"><small>Date de fin</small></label>
											<b-form-input class="form-select-sm ml-auto d-inline-flex text-center" style="padding: .375rem 2.25rem .375rem .75rem; font-weight: 400; line-height: 1.5; height: 30px; border: 1px solid #ced4da; border-radius: .25rem; " :state="dateEndInputState" name="dateEndInput" v-model="dateEndInput" type="date" required></b-form-input>
										</div>
										<div class="row align-items-center">
											<div class="col-lg-2 col-md-3 col-sm-12 col-12" style="margin-top: 0.75em">
												<div class="row">
													<label for="divisionChoice" style="font-weight: bold;"><small>Divisions</small></label>
												</div>

												<b-form-select v-model="divisionChoice" name="divisionChoice" class="form-select form-select-sm ml-auto d-inline-flex text-center">
													<b-form-select-option value="">Choisissez une division</b-form-select-option>
													<b-form-select-option v-for="r in listInfoDivision" :key="r.name" :value="r.id">{{ r.id }}</b-form-select-option>
												</b-form-select>
											</div>
											<div class="col-lg-2 col-md-3 col-sm-12 col-12" style="margin-top: 0.75em">
												<div class="row">
													<label for="groupChoice" style="font-weight: bold;"><small>Groupe</small></label>
												</div>

												<b-form-select v-model="groupChoice" name="divisionChoice" class="form-select form-select-sm ml-auto d-inline-flex text-center">
													<b-form-select-option value="">Choisissez un groupe</b-form-select-option>
													<b-form-select-option v-for="r in listGroup" :key="r.wording" :value="r.id">{{ r.wording }}</b-form-select-option>
												</b-form-select>
											</div>
											<div class="col-lg-2  col-sm-12 col-12" style="margin-top: 0.75em">
												<div class="row">
													<label for="clubChoice" style="font-weight: bold;"><small>Club</small></label>
												</div>

												<b-form-select v-model="clubChoice" name="clubChoice" class="form-select form-select-sm ml-auto d-inline-flex">
													<b-form-select-option value="">Choisissez un club</b-form-select-option>
													<b-form-select-option v-for="item in listClub2" :key="item.id" :value="item.id">{{ item.name }}</b-form-select-option>
												</b-form-select>
											</div>
											<div class="col-xs-12 col-sm-12 col-md-12s col-lg-6" style="margin-top: 0.75em">
												<div class="row " style="text-align: left;">
													<div class="col-12 col-md-12 d-flex justify-content-evenly" style="text-align: center;">
														<b-button class="btn app-btn-primary" style="width: 175px; margin-top: 1em;" v-on:click="RecuperationMatchs()">Afficher les matchs</b-button>
				
														<b-button class="btn app-btn-secondary" style="width: 175px; margin-top: 1em;" v-on:click="AjouterMatchModal()">Ajouter un match</b-button>
													</div>
													</div>
											</div>
										
										</div>

									</div>
								</div>
							</div><!--//col-->
							<div class="col-12 col-lg-2 align-center align-items-middle" style="text-align: center;">
								<a class="btn app-btn-secondary" style="color: #fa7507; border: 1px solid #fa7507; border-radius: .25rem;" v-on:click="TelechargementCSV()">
									<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-download mr-1" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
										<path fill-rule="evenodd" d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
										<path fill-rule="evenodd" d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
									</svg>
									Extraction CSV
								</a>
							</div><!--//col-->
						</div><!--//row-->
					</b-collapse><!--//app-card-body-->
				</div><!--//inner-->
			</div>
		</div>

		<div v-if="matchesVisible && hidden == false" class="tab-content" id="orders-table-tab-content">
			<form class="app-search-form">
				<input name="search" type="text" id="myInput" @keyup="myFunction()" placeholder="Recherchez une date, un club, une salle" title="Recherche textuelle" class="form-control search-input" style="height: 50px; margin-bottom: 1em">
				<button disabled class="btn search-btn btn-primary" value="Search" style="padding-top: 1em">
					<svg class="svg-inline--fa fa-search fa-w-16" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="search" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
						<path fill="currentColor" d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"></path>
					</svg><!-- <i class="fas fa-search"></i> -->
				</button>
			</form>
			<div class="tab-pane fade show active" id="orders-all" role="tabpanel" aria-labelledby="orders-all-tab">
				<div class="app-card app-card-orders-table shadow-sm mb-5">
					<div class="app-card-body">
						<div class="table-responsive table-stiped">
							<table id="myTable2" class="table table-striped table-hover mb-0 text-center">
								<thead class="align-items-center">
									<tr>
										<th class="mb-2 text-primary" v-on:click="TriTableau(0)">
											Id
											<span v-if="etatTriColumn == 1 && etatTriTableau == true">
												<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-down-short" viewBox="0 0 16 16">
													<path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L7.5 10.293V4.5A.5.5 0 0 1 8 4z" />
												</svg>
											</span>
											<span v-if="etatTriColumn == 1 && etatTriTableau == false">
												<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-up-short" viewBox="0 0 16 16">
													<path fill-rule="evenodd" d="M8 12a.5.5 0 0 0 .5-.5V5.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 5.707V11.5a.5.5 0 0 0 .5.5z" />
												</svg>
											</span>
										</th>
										<th class="mb-2 text-primary" v-on:click="TriTableau(1)">
											Divisions
											<span v-if="etatTriColumn == 2 && etatTriTableau == true">
												<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-down-short" viewBox="0 0 16 16">
													<path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L7.5 10.293V4.5A.5.5 0 0 1 8 4z" />
												</svg>
											</span>
											<span v-if="etatTriColumn == 2 && etatTriTableau == false">
												<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-up-short" viewBox="0 0 16 16">
													<path fill-rule="evenodd" d="M8 12a.5.5 0 0 0 .5-.5V5.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 5.707V11.5a.5.5 0 0 0 .5.5z" />
												</svg>
											</span>
										</th>
										<th class="mb-2 text-primary" v-on:click="TriTableau(2)">
											Horaire
											<span v-if="etatTriColumn == 3 && etatTriTableau == true">
												<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-down-short" viewBox="0 0 16 16">
													<path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L7.5 10.293V4.5A.5.5 0 0 1 8 4z" />
												</svg>
											</span>
											<span v-if="etatTriColumn == 3 && etatTriTableau == false">
												<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-up-short" viewBox="0 0 16 16">
													<path fill-rule="evenodd" d="M8 12a.5.5 0 0 0 .5-.5V5.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 5.707V11.5a.5.5 0 0 0 .5.5z" />
												</svg>
											</span>
										</th>
										<th class="mb-2 text-primary" v-on:click="TriTableau(3)">
											Salle
											<span v-if="etatTriColumn == 4 && etatTriTableau == true">
												<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-down-short" viewBox="0 0 16 16">
													<path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L7.5 10.293V4.5A.5.5 0 0 1 8 4z" />
												</svg>
											</span>
											<span v-if="etatTriColumn == 4 && etatTriTableau == false">
												<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-up-short" viewBox="0 0 16 16">
													<path fill-rule="evenodd" d="M8 12a.5.5 0 0 0 .5-.5V5.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 5.707V11.5a.5.5 0 0 0 .5.5z" />
												</svg>
											</span>
										</th>
										<th class="mb-2 text-primary" v-on:click="TriTableau(4)">
											Locaux
											<span v-if="etatTriColumn == 5 && etatTriTableau == true">
												<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-down-short" viewBox="0 0 16 16">
													<path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L7.5 10.293V4.5A.5.5 0 0 1 8 4z" />
												</svg>
											</span>
											<span v-if="etatTriColumn == 5 && etatTriTableau == false">
												<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-up-short" viewBox="0 0 16 16">
													<path fill-rule="evenodd" d="M8 12a.5.5 0 0 0 .5-.5V5.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 5.707V11.5a.5.5 0 0 0 .5.5z" />
												</svg>
											</span>
										</th>
										<th class="mb-2 text-primary" v-on:click="TriTableau(5)">
											Visiteurs
											<span v-if="etatTriColumn == 6 && etatTriTableau == true">
												<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-down-short" viewBox="0 0 16 16">
													<path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L7.5 10.293V4.5A.5.5 0 0 1 8 4z" />
												</svg>
											</span>
											<span v-if="etatTriColumn == 6 && etatTriTableau == false">
												<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-up-short" viewBox="0 0 16 16">
													<path fill-rule="evenodd" d="M8 12a.5.5 0 0 0 .5-.5V5.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 5.707V11.5a.5.5 0 0 0 .5.5z" />
												</svg>
											</span>
										</th>
										<th class="mb-2 text-primary">Actions</th>
									</tr>
								</thead>
								<tbody v-if="!noResult">
									<tr v-for="(item,index) in matches" :key="index" :href="item.id">
										<td class="cell">{{ item.id }}</td>
										<td class="cell">{{ item.divisionId }}</td>
										<td class="cell">{{ item.date }}</td>
										<td class="cell">{{ item.sportsHall.name }}</td>
										<td class="cell">{{ item.homeClub.name }}</td>
										<td class="cell">{{ item.visitorClub.name }}</td>
										<td class="cell">
											<b-button class="btn app-btn-secondary" style="margin: 3px" v-on:click="infoMatchModal(item)">
												<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-lg" viewBox="0 0 16 16">
													<path d="m10.277 5.433-4.031.505-.145.67.794.145c.516.123.619.309.505.824L6.101 13.68c-.34 1.578.186 2.32 1.423 2.32.959 0 2.072-.443 2.577-1.052l.155-.732c-.35.31-.866.434-1.206.434-.485 0-.66-.34-.536-.939l1.763-8.278zm.122-3.673a1.76 1.76 0 1 1-3.52 0 1.76 1.76 0 0 1 3.52 0z" />
												</svg>
											</b-button>
											<b-button class="btn app-btn-secondary" style="margin: 3px" v-on:click="MajMatchModal(item)">
												<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil" viewBox="0 0 16 16">
													<path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
												</svg>
											</b-button>
											<b-button class="btn app-btn-secondary" style="margin: 3px" v-on:click="SupprimerMatchModal(item)">
												<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
													<path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
													<path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
												</svg>
											</b-button>
											<b-button v-if="item.maj" class="btn app-btn-secondary" data-toggle="tooltip" data-placement="top" title="Nouveau match ou informations nouvelles  depuis le dernier import" style="margin: 3px">
												<svg xmlns="http://www.w3.org/2000/svg" stroke="red" stroke-width="1" width="16" height="16" fill="currentColor" class="bi bi-bell" viewBox="0 0 16 16">
													<path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zM8 1.918l-.797.161A4.002 4.002 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4.002 4.002 0 0 0-3.203-3.92L8 1.917zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5.002 5.002 0 0 1 13 6c0 .88.32 4.2 1.22 6z" />
												</svg>
											</b-button>
										</td>
									</tr>
								</tbody>
								<tbody v-if="noResult">
									<tr>
										<td colspan="7">Aucun résultat</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

</template>

<style>
	.listeInfoMatch {
		margin: 0.5em
	}
</style>

<script>
import jwt_decode from "jwt-decode";

export default {
	name: 'Match',
	computed:
	{
        dateStartInputState: function () {
            var reDate = /^(20\d\d)-([1-9]|0\d|1[0-2])-([1-9]|[0-2]\d|3[0-1])$/;
            if (reDate.test(this.dateStartInput)) { return true; }
            else { return false; }
        },
        dateEndInputState: function () {
            var reDate = /^(20\d\d)-([1-9]|0\d|1[0-2])-([1-9]|[0-2]\d|3[0-1])$/;
            if (reDate.test(this.dateEndInput)) { return true; }
            else { return false; }
        },
		dateState: function()
		{
			var reDate = /^(20\d\d)-([1-9]|0\d|1[0-2])-([1-9]|[0-2]\d|3[0-1])$/;
			if (reDate.test(this.dateMatch))
			{
				return true;
			}
			else 
			{
				return false;
			}
		},
		hourState: function()
		{
			var reHour = /^([0-1]?\d|2[0-3]):([0-9]|[0-5]\d)$/;
			if (reHour.test(this.hourMatch))
			{
				return true;
			}
			else 
			{
				return false;
			}
		}
	},
	data() {
		return {
			// Variable message alert
			dismissSecs: 10,
			alertSuccessCountDown: 0,
			alertWarningCountDown: 0,
			info:'',
			error: '',

			etatTriTableau: true,
			etatTriColumn: 0,

			listWeek:[],
			noResult: false,
			matchesVisible:false,
			matches: [],
			matchesSelected: {
				"sportsHall":{},
				"homeClub":{},
				"visitorClub":{},
				"poule":{}
			},

            dateStartInput: '',
            dateEndInput: '',

			listInfoDivision: [],
			listClub2: [],
			listGroup: [],
            divisionChoice: '',
			clubChoice: '',
			groupChoice: '',

			modalUpdateMatch: true,
			idMatch: 0,
			divisionId: "",
			poule: 0,
			dateMatch: "",
			hourMatch: "",
			sportsHall: 0,
			homeClub: "",
			suffixHomeClub: 0,
			visitorClubSameEntity: true,
			visitorClub: "",
			suffixVisitorClub: 0,
			played: false,

			listdivisionId: [],
			listClub: [],
			listSportsHall: [],
			listPoule: [],

			hidden: true,
		}
	},

	mounted()
	{
        this.VerifToken();
        let config = {
            headers: {
                'Authorization': 'Bearer ' + localStorage.token
            }
        };

        this.$axios.get('https://api.clickandball.fr/division', config)
            .then(response => {
                if (response.status == 200 && response.data.error) {
                    this.showAlertWarning('Erreur : ' + response.data.error);
                }
                else if (response.status == 200) {
                    this.listInfoDivision = response.data;

                    if (this.listInfoDivision.length === 0) {
                        this.noResultDivision = true;
                    }
                }
                else {
                    this.showAlertWarning('Code erreur : ' + response.status);
                }
            })

        this.$axios.get('https://api.clickandball.fr/club', config)
            .then(response => {
                if (response.status == 200 && response.data.error) {
                    this.showAlertWarning('Erreur : ' + response.data.error);
                }
                else if (response.status == 200) {
                    this.listClub2 = response.data;
                }
                else {
                    this.showAlertWarning('Code erreur : ' + response.status);
                }
            })
            .catch(error => {
                this.showAlertWarning('Erreur : ' + error);
			});
        this.$axios.get('https://api.clickandball.fr/groupe', config)
            .then(response => {
                if (response.status == 200 && response.data.error) {
                    this.showAlertWarning('Erreur : ' + response.data.error);
                }
                else if (response.status == 200) {
                    this.listGroup = response.data;
                }
                else {
                    this.showAlertWarning('Code erreur : ' + response.status);
                }
            })
            .catch(error => {
                this.showAlertWarning('Erreur : ' + error);
            });
	},

	methods: {
		// Méthodes message alert
		alertSuccessCountDownChanged(alertSuccessCountDown)
		{
			this.alertSuccessCountDown = alertSuccessCountDown
		},
		alertWarningCountDownChanged(alertWarningCountDown)
		{
			this.alertWarningCountDown = alertWarningCountDown
		},
		showAlertSuccess(info)
		{
			this.info = info;
			this.alertSuccessCountDown = this.dismissSecs
		},
		showAlertWarning(error)
		{
			this.error = error;
			this.alertWarningCountDown = this.dismissSecs
		},
		VerifToken()
		{
			if (localStorage.token)
			{
				var jsonToken = jwt_decode(localStorage.token);
				if ((jsonToken.exp - 60) < Math.round(Date.now()/1000))
				{
					localStorage.token = null;
					this.connected = false;
					this.$router.push({ path: '/login'})
				}
			}
		},
        TriTableau(n) {
            let textA;
            let textB;
			let etatTri = this.etatTriTableau;
			let etatTriColumn = this.etatTriColumn;

            this.matches.sort(function (a, b) {
                switch (n) {
                    case 0:
                        textA = a.id;
						textB = b.id;
						etatTriColumn = 1;
                        break;
                    case 1:
                        textA = a.divisionId.toUpperCase();
						textB = b.divisionId.toUpperCase();
                        etatTriColumn = 2;
                        break;
                    case 2:
                        textA = a.date.toUpperCase();
						textB = b.date.toUpperCase();
                        etatTriColumn = 3;
                        break;
                    case 3:
                        textA = a.sportsHall.name.toUpperCase();
						textB = b.sportsHall.name.toUpperCase();
                        etatTriColumn = 4;
                        break;
                    case 4:
                        textA = a.homeClub.name.toUpperCase();
						textB = b.homeClub.name.toUpperCase();
                        etatTriColumn = 5;
						break;
                    case 5:
                        textA = a.visitorClub.name.toUpperCase();
						textB = b.visitorClub.name.toUpperCase();
                        etatTriColumn = 6;
                        break;
                    default:
                        break;
                }

                if (etatTri) {
                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                }
                else {
                    return (textA > textB) ? -1 : (textA > textB) ? 0 : 1;
                }
            });

			this.etatTriTableau = !etatTri;
			this.etatTriColumn = etatTriColumn;
        },
		getHourString(date)
		{
			// prints date & time in HH:MM:SS format
			return ("0" + date.getHours()).slice(-2)+ ":" + ("0" + date.getMinutes()).slice(-2);
		},
		getDateHourString(date)
		{
			// prints date & time in YYYY-MM-DD HH:MM:SS format
			return date.getFullYear() + "-" + ("0" + (date.getMonth() + 1)).slice(-2) + "-" + ("0" + date.getDate()).slice(-2) + " " + date.getHours() + ":" + date.getMinutes() + ":" + ("0" + date.getSeconds()).slice(-2);
		},
		getDateString(date)
		{
			// prints date & time in YYYY-MM-DD format
			return date.getFullYear() + "-" + ("0" + (date.getMonth() + 1)).slice(-2) + "-" + ("0" + date.getDate()).slice(-2);
		},
		RecuperationMatchs()
		{
			if (!this.dateStartInputState || !this.dateEndInputState)
			{
				this.showAlertWarning('Date non renseignées');
			}
			else
			{
				this.assignments = [];
				this.matchesVisible = true;
				this.noResult = false;

				let config = {
					headers: {
						'Authorization': 'Bearer ' + localStorage.token
					}
				};

				this.$axios.get('https://api.clickandball.fr/designation/match?start=' + this.dateStartInput + "&end=" + this.dateEndInput + "&division=" + this.divisionChoice + "&club=" + this.clubChoice + "&group=" + this.groupChoice, config)
					.then(response => {
						if (response.status == 200 && response.data.error)
						{
							this.showAlertWarning('Erreur : ' + response.data.error);
							this.hidden = true;
						}
						else if (response.status == 200)
						{
							this.matches = response.data;


							if (this.matches.length === 0)
							{
								this.noResult = true;
							}

							this.hidden = false;
						}
						else
						{
							this.showAlertWarning('Code erreur : ' + response.status);
							this.hidden = true;
						}
					})
			}
		},
		AjouterMatchModal()
		{
			this.VerifToken();
			this.$refs['modalMatch'].show();
			this.modalUpdateMatch = false;
			this.idMatch = 0;
			this.divisionId = "";
			this.poule = 0;
			this.dateMatch = "";
			this.hourMatch = "";
			this.sportsHall = 0;
			this.homeClub = "";
			this.suffixHomeClub = 0;
			this.visitorClubSameEntity = true;
			this.visitorClub = "";
			this.suffixVisitorClub = 0;
			this.played = false;

			this.apiModalMatch();
		},
		AjouterMatch()
		{
			this.VerifToken();
			this.$refs['modalMatch'].hide();

			let json = {
				id: parseInt(this.idMatch),
				divisionId: this.divisionId,
				poule: this.poule.id,
				date: this.getDateHourString(new Date(this.dateMatch + ' ' + this.hourMatch)),
				sportsHall: this.sportsHall,
				homeClub: this.homeClub,
				suffixHomeClub: "" + this.suffixHomeClub,
				visitorClub: this.visitorClub,
				suffixVisitorClub: "" + this.suffixVisitorClub,
				played: this.played
			};
			let config = {
				headers: {
					'Authorization': 'Bearer ' + localStorage.token
				}
			};

			this.$axios.post('https://api.clickandball.fr/designation/match', json, config)
				.then(response => {
					if (response.status == 200 && response.data.error)
					{
						this.showAlertWarning('Erreur : ' + response.data.error);
					}
					else if (response.status == 200)
					{
						this.showAlertSuccess("Match sauvegardé");
						if (this.hidden == false) {
							this.RecuperationMatchs();
						}
						
					}
					else
					{
						this.showAlertWarning('Erreur : ' + response.statusText);
					}
				})
				.catch(error => {
					this.showAlertWarning('Erreur : ' + error);
				});

		},
		MajMatchModal(info)
		{
			this.VerifToken();
			this.apiModalMatch();

			this.$refs['modalMatch'].show();
			this.modalUpdateMatch = true;
			this.idMatch = info.id;
			this.divisionId = info.divisionId;
			this.poule = info.poule.id;
			this.dateMatch = this.getDateString(new Date(info.date));
			this.hourMatch = this.getHourString(new Date(info.date));
			this.sportsHall = info.sportsHall.id;
			this.homeClub = info.homeClub.id;
			this.suffixHomeClub = info.suffixHomeClub;
			if (info.visitorClub.id)
			{
				this.visitorClubSameEntity = true;
				this.visitorClub = info.visitorClub.id;
				this.suffixVisitorClub = info.suffixVisitorClub;			}
			else
			{
				this.visitorClubSameEntity = false;
				this.visitorClub = info.visitorClub.name;
			}
			this.played = info.played;
		},
		updateMatch()
		{
			this.VerifToken();

			//var reDate = /^(20\d\d)-([1-9]|0\d|1[0-2])-([1-9]|[0-2]\d|3[0-1])$/;
			//var reHour = /^([0-1]?\d|2[0-3]):([0-9]|[0-5]\d)$/;

			this.$refs['modalMatch'].hide();

			let json = {
				id: parseInt(this.idMatch),
				divisionId: this.divisionId,
				poule: this.poule,
				date: this.getDateHourString(new Date(this.dateMatch + ' ' + this.hourMatch)),
				sportsHall: this.sportsHall,
				played: this.played
			};
			let config = {
				headers: {
					'Authorization': 'Bearer ' + localStorage.token
				}
			};

			this.$axios.put('https://api.clickandball.fr/designation/match', json, config)
				.then(response => {
					if (response.status == 200 && response.data.error)
					{
						this.showAlertWarning('Erreur : ' + response.data.error);
					}
					else if (response.status == 200)
					{
						this.showAlertSuccess("Match mis à jour");
						if (this.hidden == false) {
							this.RecuperationMatchs();
						}
					}
					else
					{
						this.showAlertWarning('Erreur : ' + response.statusText);
					}
				})
				.catch(error => {
					this.showAlertWarning('Erreur : ' + error);
				});

		},
		infoMatchModal(info)
		{
			this.VerifToken();
			this.matchesSelected = info;
			this.$refs['modalInfoMatch'].show();
		},
		SupprimerMatchModal(info)
		{
			this.VerifToken();
			this.matchesSelected = info;
			this.$refs['modalDeleteMatch'].show();
		},
		SupprimerMatch()
		{
			this.VerifToken();
			this.$refs['modalDeleteMatch'].hide();

			let config = {
				headers: {
					'Authorization': 'Bearer ' + localStorage.token,
				},
				data: {
					id: parseInt(this.matchesSelected.id),
					divisionId: this.matchesSelected.divisionId,
					poule: this.matchesSelected.poule.id
				}
			};

			this.$axios.delete('https://api.clickandball.fr/designation/match', config)
				.then(response => {
					if (response.status == 200 && response.data.error)
					{
						this.showAlertWarning('Erreur : ' + response.data.error);
					}
					else if (response.status == 200)
					{
						this.showAlertSuccess("Match supprimé");
                        if (this.hidden == false) {
                            this.RecuperationMatchs();
                        }
					}
					else
					{
						this.showAlertWarning('Erreur : ' + response.statusText);
					}
				})
				.catch(error => {
					this.showAlertWarning('Erreur : ' + error);
				});
		},
		apiModalMatch()
		{
			let config = {
				headers: {
					'Authorization': 'Bearer ' + localStorage.token
				}
			};

			this.$axios.get('https://api.clickandball.fr/division', config)
				.then(response => {
					if (response.status == 200 && response.data.error)
					{
						this.showAlertWarning('Erreur : ' + response.data.error);
					}
					else if (response.status == 200)
					{
						this.listdivisionId = response.data;
					}
					else
					{
						this.showAlertWarning('Code erreur : ' + response.status);
					}
				})

			this.$axios.get('https://api.clickandball.fr/club', config)
				.then(response => {
					if (response.status == 200 && response.data.error)
					{
						this.showAlertWarning('Erreur : ' + response.data.error);
					}
					else if (response.status == 200)
					{
						this.listClub = response.data;
					}
					else
					{
						this.showAlertWarning('Code erreur : ' + response.status);
					}
				})

			this.$axios.get('https://api.clickandball.fr/salledesport', config)
				.then(response => {
					if (response.status == 200 && response.data.error)
					{
						this.showAlertWarning('Erreur : ' + response.data.error);
					}
					else if (response.status == 200)
					{
						this.listSportsHall = response.data;
					}
					else
					{
						this.showAlertWarning('Code erreur : ' + response.status);
					}
				})

			this.$axios.get('https://api.clickandball.fr/poule', config)
				.then(response => {
					if (response.status == 200 && response.data.error)
					{
						this.showAlertWarning('Erreur : ' + response.data.error);
					}
					else if (response.status == 200)
					{
						this.listPoule = response.data;
					}
					else
					{
						this.showAlertWarning('Code erreur : ' + response.status);
					}
				})
		},
		TelechargementCSV()
		{
			this.VerifToken();
			if (!this.dateStartInputState || !this.dateEndInputState)
			{
				this.showAlertWarning('Date non renseignées');
			}
			else
			{
				let config = {
					headers: {
						'Authorization': 'Bearer ' + localStorage.token,
						'Content-Disposition': 'attachment'
					},
					responseType: 'blob'
				};

				this.$axios.get('https://api.clickandball.fr/designation/extraction/match?start=' + this.dateStartInput + "&end=" + this.dateEndInput, config)
					.then(response => {
						if (response.status == 200 && response.data.error)
						{
							this.showAlertWarning('Erreur : ' + response.data.error);
						}
						else if (response.status == 200)
						{
							const url = window.URL.createObjectURL(new Blob([response.data]));
							const link = document.createElement('a');
							link.href = url;
							link.setAttribute('download', 'Matchs.csv');
							document.body.appendChild(link);
							link.click();
						}
						else
						{
							this.showAlertWarning('Code erreur : ' + response.status);
						}
					})
					.catch(error => {
						this.showAlertWarning('Erreur : ' + error);
					});
			}
		},
        myFunction: function () {
            var input, filter, table, tr;
            input = document.getElementById("myInput");
            filter = input.value.toUpperCase();
            table = document.getElementById("myTable2");
            tr = table.getElementsByTagName("tr");
            for (var i = 1; i < tr.length; i++) {
                var tds = tr[i].getElementsByTagName("td");
                var flag = false;
                for (var j = 0; j < tds.length; j++) {
                    var td = tds[j];
                    if (td.innerHTML.toUpperCase().indexOf(filter) > -1) {
                        flag = true;
                    }
                }
                if (flag) {
                    tr[i].style.display = "";
                }
                else {
                    tr[i].style.display = "none";
                }
            }
        },
	}
}
</script>
