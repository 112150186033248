<template>
	<div class="Profile">

		<h1 class="app-page-title"><img src="https://img.icons8.com/ios-glyphs/50/000000/basketball--v1.png"/> Mon profil</h1>

		<b-alert
		:show="alertSuccessCountDown"
		variant="success"
		@dismissed="alertSuccessCountDown=0"
		@dismiss-count-down="alertSuccessCountDownChanged"
		>
			<p>{{ info }}</p>
		<b-progress
			variant="success"
			:max="dismissSecs"
			:value="alertSuccessCountDown"
			height="4px"
			></b-progress>
		</b-alert>
		<b-alert
			:show="alertWarningCountDown"
			variant="warning"
			@dismissed="alertWarningCountDown=0"
			@dismiss-count-down="alertWarningCountDownChanged">
				<p>{{ error }}</p>
			<b-progress
				variant="warning"
				:max="dismissSecs"
				:value="alertWarningCountDown"
				height="4px"></b-progress>
		</b-alert>

		<!-- Modal -->
		<b-modal ref="modalUnavailabilities" hide-footer hide-header>
			<div class="row" style="text-align:right; margin: -1em;">
				<a style="float: right; z-index: 9999; margin-right: 0.5em; top: 0" href="#" block @click="$refs['modalUnavailabilities'].hide()">
					<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
						<path fill-rule="evenodd" d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z" />
						<path fill-rule="evenodd" d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z" />
					</svg>
				</a>
			</div>
			<div class="app-card app-card-account shadow-sm d-flex flex-column align-items-start">
				
				<div class="app-card-header p-3 border-bottom-0">
					<div class="row align-items-center gx-3">
						<div class="col-auto">
							<div class="app-icon-holder" style="background-color: rgb(42, 87, 136)">
								<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-shield-check" fill="white" xmlns="http://www.w3.org/2000/svg">
									<path fill-rule="evenodd" d="M5.443 1.991a60.17 60.17 0 0 0-2.725.802.454.454 0 0 0-.315.366C1.87 7.056 3.1 9.9 4.567 11.773c.736.94 1.533 1.636 2.197 2.093.333.228.626.394.857.5.116.053.21.089.282.11A.73.73 0 0 0 8 14.5c.007-.001.038-.005.097-.023.072-.022.166-.058.282-.111.23-.106.525-.272.857-.5a10.197 10.197 0 0 0 2.197-2.093C12.9 9.9 14.13 7.056 13.597 3.159a.454.454 0 0 0-.315-.366c-.626-.2-1.682-.526-2.725-.802C9.491 1.71 8.51 1.5 8 1.5c-.51 0-1.49.21-2.557.491zm-.256-.966C6.23.749 7.337.5 8 .5c.662 0 1.77.249 2.813.525a61.09 61.09 0 0 1 2.772.815c.528.168.926.623 1.003 1.184.573 4.197-.756 7.307-2.367 9.365a11.191 11.191 0 0 1-2.418 2.3 6.942 6.942 0 0 1-1.007.586c-.27.124-.558.225-.796.225s-.526-.101-.796-.225a6.908 6.908 0 0 1-1.007-.586 11.192 11.192 0 0 1-2.417-2.3C2.167 10.331.839 7.221 1.412 3.024A1.454 1.454 0 0 1 2.415 1.84a61.11 61.11 0 0 1 2.772-.815z" />
									<path fill-rule="evenodd" d="M10.854 6.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 8.793l2.646-2.647a.5.5 0 0 1 .708 0z" />
								</svg>
							</div>
						</div>
						<div class="col-auto">
							<h4 class="app-card-title">Changement de mot de passe</h4>
						</div>
					</div>
				</div>
				<div class="app-card-body px-4 w-100">
					<div class="item border-bottom py-3">
						<div class="row justify-content-between align-items-center text-center">
							<div class="col-md-10 offset-md-1 align-items-center" style="margin-bottom: 1em">
								<label class="form-label"><strong>Ancien mot de passe</strong></label>
								<b-form-input class="form-select-sm ml-auto d-inline-flex text-center" style="height: 30px; border: 1px solid #ced4da; border-radius: .25rem;" type="password" name="odlPwdInput" v-model="odlPwdInput" aria-describedby="password-help-block"></b-form-input>
							</div>
							<hr />
							<div class="col-md-10 offset-md-1 align-items-center" style="margin-bottom: 1em">
								<label class="form-label" style="font-weight: bold;">Nouveau mot de passe </label>
								<b-form-input class="form-select-sm ml-auto d-inline-flex text-center" style="height: 30px; border: 1px solid #ced4da; border-radius: .25rem; margin-bottom: 0.5em" :state="newPwdInputState" type="password" name="newPwdInput" v-model="newPwdInput" aria-describedby="password-help-block"></b-form-input>
								<b-form-checkbox-group style="text-align: left !important" v-model="testPwd" :options="options" :state="checkPwdState" disabled name="checkbox-validation">
									<b-form-invalid-feedback :state="checkPwdState" style="text-align: center !important; margin-top: 0.5em">Merci de respecter au moins 4 critères sur 5</b-form-invalid-feedback>
									<b-form-valid-feedback :state="checkPwdState" style="text-align: center !important; margin-top: 0.5em">Le mot de passe satisfait aux conditions de sécurité</b-form-valid-feedback>
								</b-form-checkbox-group>
							</div>
							<hr />
							<div class="col-md-10 offset-md-1 align-items-center">
								<label class="form-label" style="font-weight: bold;">Confirmation du mot de passe </label>
								<b-form-input :state="newPwdConfInputState" class="form-select-sm ml-auto d-inline-flex text-center" style="height: 30px; border: 1px solid #ced4da; border-radius: .25rem;" type="password" name="newPwdConfInput" v-model="newPwdConfInput" aria-describedby="password-help-block"></b-form-input>
							</div>
						</div>
					</div>
					<div class="text-center" style="margin: 0.5em">
						<b-button v-on:click="updatePwd()" class="btn app-btn-primary" style="background: rgb(42, 87, 136);color: white; width: 200px; margin: 0.5em;">Enregistrer</b-button>
					</div>
				</div><!--//app-card-body-->
			</div>		
		</b-modal>

		<div class="row">
			<div class="col-sm-12 col-md-8 col-lg-8">
				<div class="app-card app-card-account shadow-sm d-flex flex-column">
					<div class="app-card-header px-4 py-3" style="background-color: rgba(0,0,0,.05) !important; border-bottom: 2px solid #fa7507">
						<div class="row align-items-center gx-3">
							<div class="col-12 col-lg-auto  text-center  text-lg-left">
								<div class="app-icon-holder" style="background-color: #fa7507">
									<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-person" fill="white" xmlns="http://www.w3.org/2000/svg">
										<path fill-rule="evenodd" d="M10 5a2 2 0 1 1-4 0 2 2 0 0 1 4 0zM8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm6 5c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z" />
									</svg>
								</div><!--//icon-holder-->

							</div><!--//col-->
							<div class="col-12 col-lg-auto text-lg-left text-center">
								<h4 class="app-card-title">Mes informations</h4>
								<ul class="notification-meta list-inline mb-0">
									<li class="list-inline-item">
										<small>
											En cas d'erreur de saisie, merci de nous en informer :
										</small>
									</li>
									<li class="list-inline-item">
										<small>
											<a href="mailto:cdo@loireatlantiquebasketball.org">cdo@loireatlantiquebasketball.org</a>
										</small>
									</li>
								</ul>
							</div><!--//col-->
						</div><!--//row-->
					</div><!--//app-card-header-->
					<div class="app-card-body px-4 w-100">
						<div style="margin-top: 0.5em; margin-left: 0.5em; margin-bottom: -1em">
							<h6 class="mb-2 text-primary">Informations administratives</h6>
						</div>
						<div class="item border-bottom py-3">
							<div class="row justify-content-center align-items-center">

								<div class="col-md-4">
									<div class="item-label mb-2"><strong>Nom :</strong></div>
									<b-form-input type="text" class="form-select-sm ml-auto d-inline-flex text-center" style="height: 30px; border: 1px solid #ced4da; border-radius: .25rem;" id="fullName" v-model="infoProfil.lastName" disabled=""></b-form-input>
								</div>
								<div class="col-md-4">
									<div class="item-label mb-2"><strong>Prénom :</strong></div>
									<b-form-input type="text" class="form-select-sm ml-auto d-inline-flex text-center" style="height: 30px; border: 1px solid #ced4da; border-radius: .25rem;" id="firstName" v-model="infoProfil.firstName" disabled=""></b-form-input>
								</div>
							</div>
						</div>
						<div class="item border-bottom py-3" style="margin-top: -0.5em">
							<div class="row justify-content-center align-items-center">
								<div class="col-md-4">
									<div class="item-label mb-2"><strong>Date de naissance :</strong></div>
									<b-form-input type="text" class="form-select-sm ml-auto d-inline-flex text-center" style="height: 30px; border: 1px solid #ced4da; border-radius: .25rem;" id="birthday" v-model="birthday" disabled=""></b-form-input>
								</div>
								<div class="col-md-4">
									<div class="item-label mb-2"><strong>Club :</strong></div>
									<b-form-input type="text" class="form-select-sm ml-auto d-inline-flex text-center" style="height: 30px; border: 1px solid #ced4da; border-radius: .25rem;" id="cluib" v-model="infoProfil.club.name" disabled=""></b-form-input>
								</div>
							</div>
						</div>
						<div class="item border-bottom py-3" style="margin-top: -0.5em">
							<div class="row justify-content-center align-items-center">
								<div class="col-md-8">
									<div class="item-label mb-2"><strong>Adresse :</strong></div>
									<b-form-input type="text" class="form-select-sm ml-auto d-inline-flex text-center" style="height: 30px; border: 1px solid #ced4da; border-radius: .25rem;" v-model="address" disabled=""></b-form-input>
								</div>
							</div>
						</div>
						<div style="margin-top: 0.5em; margin-left: 0.5em; margin-bottom: -1em">
							<h6 class="mb-2 text-primary">Informations de contact</h6>
						</div>
						<div class="item border-bottom py-3">
							<div class="row justify-content-center align-items-center">

								<div class="col-md-4">
									<div class="item-label mb-2"><strong>Adresse mail :</strong></div>
									<b-form-input type="text" class="form-select-sm ml-auto d-inline-flex text-center" style="height: 30px; border: 1px solid #ced4da; border-radius: .25rem;" v-model="infoProfil.email" id="email" disabled=""></b-form-input>
								</div>
								<div class="col-md-4">
									<div class="item-label mb-2"><strong>N° de téléphone :</strong></div>
									<b-form-input type="text" class="form-select-sm ml-auto d-inline-flex text-center" style="height: 30px; border: 1px solid #ced4da; border-radius: .25rem;" v-model="infoProfil.phone" id="phone" disabled=""></b-form-input>
								</div>
							</div>
						</div>
						<div style="margin-top: 0.5em; margin-left: 0.5em; margin-bottom: -1em">
							<h6 class="mb-2 text-primary">Informations de désignation</h6>
						</div>
						<div class="item border-bottom py-3">
							<div class="row justify-content-center align-items-center">

								<div class="col-md-6">
									<div class="item-label mb-2"><strong>Entité de rattachement :</strong></div>
									<b-form-input type="text" class="form-select-sm ml-auto d-inline-flex text-center" style="height: 30px; border: 1px solid #ced4da; border-radius: .25rem;" v-model="infoProfil.entityName" disabled=""></b-form-input>
								</div>
							</div>
						</div>
						<div class="item border-bottom py-3">
							<div class="row justify-content-center align-items-center">

								<div class="col-md-4">
									<div class="item-label mb-2"><strong>Groupe :</strong></div>
									<b-form-input type="text" class="form-select-sm ml-auto d-inline-flex text-center" style="height: 30px; border: 1px solid #ced4da; border-radius: .25rem;" v-model="infoProfil.refereeLevel.wording" id="group" disabled=""></b-form-input>
								</div>
								<div class="col-md-4">
									<div class="item-label mb-2"><strong>Position :</strong></div>
									<b-form-input type="text" class="form-select-sm ml-auto d-inline-flex text-center" style="height: 30px; border: 1px solid #ced4da; border-radius: .25rem;" v-model="infoProfil.refereePosition" id="position" disabled=""></b-form-input>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-sm-12 col-md-4 col-lg-4">
				<div class="app-card app-card-notification shadow-sm mb-4">
					<div block v-b-toggle.preferences type="button" data-toggle="" class="app-card-header px-4 py-3" header-tag="header" role="tab" style="background-color: rgba(0,0,0,.05) !important;border-bottom: 2px solid rgb(47 87 136)">
						<div class="row g-3 align-items-center">
							<div class="col-12 col-lg-auto text-center text-lg-left">
								<div class="app-icon-holder" style="background-color: rgb(42, 87, 136)">
									<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-sliders" fill="white" xmlns="http://www.w3.org/2000/svg">
										<path fill-rule="evenodd" d="M11.5 2a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM9.05 3a2.5 2.5 0 0 1 4.9 0H16v1h-2.05a2.5 2.5 0 0 1-4.9 0H0V3h9.05zM4.5 7a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM2.05 8a2.5 2.5 0 0 1 4.9 0H16v1H6.95a2.5 2.5 0 0 1-4.9 0H0V8h2.05zm9.45 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zm-2.45 1a2.5 2.5 0 0 1 4.9 0H16v1h-2.05a2.5 2.5 0 0 1-4.9 0H0v-1h9.05z" />
									</svg>
								</div>

							</div>
							<div class="col-12 col-lg-auto  text-center  text-lg-left">
								<h4 class="notification-title mb-1">Mes préférences</h4>
							</div>
						</div>
					</div>
					<b-collapse visible id="preferences" accordion="my-accordion2" role="tabpanel" class="app-card-body p-4">
						<div class="notification-content" style="margin-bottom: -1em">
							<form class="form-horizontal" style="text-align: center;">
								<div class="row">
									<label class="form-label" style="font-weight: bold; margin-bottom: 0.5em;">Je souhaite doubler ?</label>
									<div class="col-6 offset-3 col-md-6 offset-md-3 col-lg-6 offset-lg-3 ml-auto mr-auto">
										<div class="form-group">
											<select disabled class="form-select form-select-sm ml-auto d-inline-flex text-center" v-model="infoProfil.canDouble" required style="text-align: center;">
												<option disabled value="">Choisissez</option>
												<option v-bind:value="true">Oui</option>
												<option v-bind:value="false">Non</option>
											</select>
										</div>
									</div>
								</div>
							</form>
							<hr>
							<ul class="notification-meta list-inline mb-0">
									<li class="list-inline-item">
										<small>
											Si vous souhaitez doubler (ou ne plus doubler), envoyez un mail à :
										</small>
									</li>
									<li class="list-inline-item">
										<small>
											<a href="mailto:cdo@loireatlantiquebasketball.org">cdo@loireatlantiquebasketball.org</a>
										</small>
									</li>
								</ul>
							<!-- <div class="col-10 offset-1 col-md-10 offset-md-1 col-lg-8 offset-lg-2 ml-auto mr-auto" style="text-align: center;">
								<button v-on:click="updateProfile()" class="btn app-btn-primary">
									<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2-square" viewBox="0 0 16 16">
										<path d="M3 14.5A1.5 1.5 0 0 1 1.5 13V3A1.5 1.5 0 0 1 3 1.5h8a.5.5 0 0 1 0 1H3a.5.5 0 0 0-.5.5v10a.5.5 0 0 0 .5.5h10a.5.5 0 0 0 .5-.5V8a.5.5 0 0 1 1 0v5a1.5 1.5 0 0 1-1.5 1.5H3z" />
										<path d="m8.354 10.354 7-7a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0z" />
									</svg>
									Sauvegarder mes préférences
								</button>
							</div> -->
						</div>
					</b-collapse>
				</div>
				<!--
				<div class="app-card app-card-notification shadow-sm mb-4">
					<div block v-b-toggle.notifications type="button" data-toggle="" class="app-card-header px-4 py-3" header-tag="header" role="tab" style="background-color: rgba(0,0,0,.05) !important;border-bottom: 2px solid rgb(47 87 136)">
						<div class="row g-3 align-items-center">
							<div class="col-12 col-lg-auto text-center text-lg-left">
								<div class="app-icon-holder" style="background-color: rgb(42, 87, 136)">
									<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-sliders" fill="white" xmlns="http://www.w3.org/2000/svg">
										<path fill-rule="evenodd" d="M11.5 2a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM9.05 3a2.5 2.5 0 0 1 4.9 0H16v1h-2.05a2.5 2.5 0 0 1-4.9 0H0V3h9.05zM4.5 7a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM2.05 8a2.5 2.5 0 0 1 4.9 0H16v1H6.95a2.5 2.5 0 0 1-4.9 0H0V8h2.05zm9.45 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zm-2.45 1a2.5 2.5 0 0 1 4.9 0H16v1h-2.05a2.5 2.5 0 0 1-4.9 0H0v-1h9.05z" />
									</svg>
								</div>

							</div>
							<div class="col-12 col-lg-auto  text-center  text-lg-left">
								<h4 class="notification-title mb-1">Notifications</h4>
							</div>
						</div>
					</div>
					<b-collapse visible id="notifications" accordion="my-accordion2" role="tabpanel" class="app-card-body p-4">
						<div class="notification-content" style="margin-bottom: -1em">
							<form class="form-horizontal" style="text-align: center;">
								<div class="row">
									<h4 class="notification-title mb-1">Notification par mail</h4>
									<tr v-for="item in listNotifMail" :key="'mail-' + item.id">
										<input class="form-check-input" type="checkbox" v-model="item.statut" style="margin: 0.4em" />
										<td class="cell">{{ item.libelle }}</td>
									</tr>

									<h4 class="notification-title mb-1">Notification sur le site</h4>
									<tr v-for="item in listNotifSite" :key="'site-' + item.id">
										<input class="form-check-input" type="checkbox" v-model="item.statut" style="margin: 0.4em" />
										<td class="cell">{{ item.libelle }}</td>
									</tr>

								</div>
							</form>
							<hr>
							<div class="col-10 offset-1 col-md-10 offset-md-1 col-lg-8 offset-lg-2 ml-auto mr-auto" style="text-align: center;">
								<button v-on:click="MajNotification()" class="btn app-btn-primary">
									<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2-square" viewBox="0 0 16 16">
										<path d="M3 14.5A1.5 1.5 0 0 1 1.5 13V3A1.5 1.5 0 0 1 3 1.5h8a.5.5 0 0 1 0 1H3a.5.5 0 0 0-.5.5v10a.5.5 0 0 0 .5.5h10a.5.5 0 0 0 .5-.5V8a.5.5 0 0 1 1 0v5a1.5 1.5 0 0 1-1.5 1.5H3z" />
										<path d="m8.354 10.354 7-7a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0z" />
									</svg>
									Sauvegarder
								</button>
							</div>
						</div>
					</b-collapse>
				</div>-->
				<div class="app-card app-card-notification shadow-sm mb-4">
					<div block v-b-toggle.password type="button" data-toggle="" class="app-card-header px-4 py-3" header-tag="header" role="tab" style="background-color: rgba(0,0,0,.05) !important;border-bottom: 2px solid rgb(47 87 136)">
						<div class="row g-3 align-items-center">
							<div class="col-12 col-lg-auto text-center text-lg-left">
								<div class="app-icon-holder" style="background-color: rgb(42, 87, 136)">
									<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" class="bi bi-key" viewBox="0 0 16 16">
										<path d="M0 8a4 4 0 0 1 7.465-2H14a.5.5 0 0 1 .354.146l1.5 1.5a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0L13 9.207l-.646.647a.5.5 0 0 1-.708 0L11 9.207l-.646.647a.5.5 0 0 1-.708 0L9 9.207l-.646.647A.5.5 0 0 1 8 10h-.535A4 4 0 0 1 0 8zm4-3a3 3 0 1 0 2.712 4.285A.5.5 0 0 1 7.163 9h.63l.853-.854a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.793-.793-1-1h-6.63a.5.5 0 0 1-.451-.285A3 3 0 0 0 4 5z" />
										<path d="M4 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
									</svg>
								</div>

							</div>
							<div class="col-12 col-lg-auto  text-center  text-lg-left">
								<h4 class="notification-title mb-1">Changement de mot de passe</h4>
							</div>
						</div>
					</div>
					<b-collapse visible id="password" accordion="my-accordion3" role="tabpanel" class="app-card-body p-4">
						<div class="notification-content" style="margin-bottom: -1em">
							<div class="col-10 offset-1 col-md-10 offset-md-1 col-lg-8 offset-lg-2 ml-auto mr-auto" style="text-align: center;">
								<button v-on:click="showChangePwd()" class="btn app-btn-secondary" style="border-color: rgb(42, 87, 136); color: rgb(42, 87, 136); margin: 0.5em;">
									<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-cursor" viewBox="0 0 16 16">
										<path d="M14.082 2.182a.5.5 0 0 1 .103.557L8.528 15.467a.5.5 0 0 1-.917-.007L5.57 10.694.803 8.652a.5.5 0 0 1-.006-.916l12.728-5.657a.5.5 0 0 1 .556.103zM2.25 8.184l3.897 1.67a.5.5 0 0 1 .262.263l1.67 3.897L12.743 3.52 2.25 8.184z" />
									</svg>
									Changer de mot de passe
								</button>
							</div>
						</div>
					</b-collapse>
				</div>
			</div>
		</div>
	</div>

</template>

<style>
    .custom-control-input {
        margin: 0.5em !important;
    }
    @media (min-width: 992px) {
        .app-card-title{
			text-align: left !important;
        }
    }
</style>

<script>
import jwt_decode from "jwt-decode";
export default {
	name: 'Profile',
	computed:
	{
		checkPwdState: function()
		{
			var reMdp = /^.{8,32}$/;
			var reInt = /[0-9]+/;
			var reMin = /[a-z]+/;
			var reMaj = /[A-Z]+/;
			var reSym = /[,.;:?!£$@#%*()=+-]/;

			if (reMdp.test(this.newPwdInput) && this.testPwd.indexOf(0) === -1)
			{
				// eslint-disable-next-line vue/no-side-effects-in-computed-properties
				this.testPwd.push(0);
			}
			else if (!reMdp.test(this.newPwdInput))
			{
				const index = this.testPwd.indexOf(0);
				if (index > -1)
				{
					// eslint-disable-next-line vue/no-side-effects-in-computed-properties
					this.testPwd.splice(index, 1);
				}
			}
			
			if (reInt.test(this.newPwdInput) && this.testPwd.indexOf(1) === -1)
			{
				// eslint-disable-next-line vue/no-side-effects-in-computed-properties
				this.testPwd.push(1);
			}
			else if (!reInt.test(this.newPwdInput))
			{
				const index = this.testPwd.indexOf(1);
				if (index > -1)
				{
					// eslint-disable-next-line vue/no-side-effects-in-computed-properties
					this.testPwd.splice(index, 1);
				}
			}

			if (reMin.test(this.newPwdInput) && this.testPwd.indexOf(2) === -1)
			{
				// eslint-disable-next-line vue/no-side-effects-in-computed-properties
				this.testPwd.push(2);
			}
			else if (!reMin.test(this.newPwdInput))
			{
				const index = this.testPwd.indexOf(2);
				if (index > -1)
				{
					// eslint-disable-next-line vue/no-side-effects-in-computed-properties
					this.testPwd.splice(index, 1);
				}
			}

			if (reMaj.test(this.newPwdInput) && this.testPwd.indexOf(3) === -1)
			{
				// eslint-disable-next-line vue/no-side-effects-in-computed-properties
				this.testPwd.push(3);
			}
			else if (!reMaj.test(this.newPwdInput))
			{
				const index = this.testPwd.indexOf(3);
				if (index > -1)
				{
					// eslint-disable-next-line vue/no-side-effects-in-computed-properties
					this.testPwd.splice(index, 1);
				}
			}

			if (reSym.test(this.newPwdInput) && this.testPwd.indexOf(4) === -1)
			{
				// eslint-disable-next-line vue/no-side-effects-in-computed-properties
				this.testPwd.push(4);
			}
			else if (!reSym.test(this.newPwdInput))
			{
				const index = this.testPwd.indexOf(4);
				if (index > -1)
				{
					// eslint-disable-next-line vue/no-side-effects-in-computed-properties
					this.testPwd.splice(index, 1);
				}
			}

			return (this.testPwd.length >= 4 && reMdp.test(this.newPwdInput))
		},
		newPwdInputState: function()
		{
			var remdp = /^.{8,32}$/;
			if (remdp.test(this.newPwdInput)) { return true; }
			else { return false; }
		},
		newPwdConfInputState: function()
		{
			var remdp = /^.{8,32}$/;
			if (remdp.test(this.newPwdConfInput) && this.newPwdInput == this.newPwdConfInput) { return true; }
			else { return false; }
		}
	},
	data() {
		return {
			// Variable message alert
			dismissSecs: 10,
			alertSuccessCountDown: 0,
			alertWarningCountDown: 0,
			info:'',
			error: '',

			infoProfil:{
				"club": {},
				"refereeLevel": {}
			},
			address: "",
			birthday: "",
			canDouble: '',
			odlPwdInput: '',
			
			newPwdInput:'',
			newPwdConfInput:'',
			options: [
				{ text: 'Longueur comprise entre 8 et 32 caractères', value: 0 },
				{ text: 'Un nombre', value: 1 },
				{ text: 'Une minuscule', value: 2 },
				{ text: 'Une majuscule', value: 3 },
				{ text: 'Une symbole (@$!#?...)', value: 4 }
			],
			testPwd:[],

			listNotifMail:[],
			listNotifSite:[],
		}
	},
	mounted()
	{
		this.loadingPage();
	},

	methods: {
		loadingPage() {
			this.checkToken();
			let config = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.token
				}
			};

			this.$axios.get('https://api.clickandball.fr/profil', config)
				.then(response => {
					if (response.status == 200 && response.data.error) {
						this.showAlertWarning('Erreur : ' + response.data.error);
					}
					else if (response.status == 200) {
						this.infoProfil = response.data;
						this.address = this.infoProfil.address + ", " + this.infoProfil.city;
						this.birthday = this.getDateString(new Date(this.infoProfil.birthday));
					}
					else {
						this.showAlertWarning('Code erreur : ' + response.status);
					}
				})
				.catch(error => {
					this.showAlertWarning('Erreur : ' + error);
				});

			this.$axios.get('https://api.clickandball.fr/profil/notification', config)
				.then(response => {
					if (response.status == 200 && response.data.error) {
						this.showAlertWarning('Erreur : ' + response.data.error);
					}
					else if (response.status == 200) {
						this.listNotifMail = response.data.mail;
						this.listNotifSite = response.data.site;

						if (this.listInfoDivision.length === 0) {
							this.noResultDivision = true;
						}
					}
					else {
						this.showAlertWarning('Code erreur : ' + response.status);
					}
				})
		},
		// Méthodes message alert
		alertSuccessCountDownChanged(alertSuccessCountDown)
		{
			this.alertSuccessCountDown = alertSuccessCountDown
		},
		alertWarningCountDownChanged(alertWarningCountDown)
		{
			this.alertWarningCountDown = alertWarningCountDown
		},
		showAlertSuccess(info)
		{
			this.info = info;
			this.alertSuccessCountDown = this.dismissSecs
		},
		showAlertWarning(error)
		{
			this.error = error;
			this.alertWarningCountDown = this.dismissSecs
		},
		checkToken()
		{
			if (localStorage.token)
			{
				var jsonToken = jwt_decode(localStorage.token);
				if ((jsonToken.exp - 60) < Math.round(Date.now()/1000))
				{
					localStorage.token = null;
					this.connected = false;
					this.$router.push({ path: '/login'})
				}
			}
		},

		// Méthode de gestion de date
		getDateString(date)
		{
			// prints date & time in DD-MM-YYYY format
			return ("0" + date.getDate()).slice(-2) + "-" + ("0" + (date.getMonth() + 1)).slice(-2) + "-" + date.getFullYear();
		},

		// Méthodes de maj profile
		updateProfile()
		{
			
			let jsonUpdateProfile = {
				canDouble: this.infoProfil.canDouble
			};
			let config = {
				headers: {
					'Authorization': 'Bearer ' + localStorage.token
				}
			};

			this.$axios
				.put('https://api.clickandball.fr/profil', jsonUpdateProfile, config)
				.then(response => {
					if (response.status == 200)
					{
						this.showAlertSuccess("Informations sauvegardées");
						this.loadingPage();
					}
					else
					{
						this.showAlertWarning('Code erreur : ' + response.statusText);
					}
				})
				.catch(error => {
					this.showAlertWarning('Erreur : ' + error);
				});
		},
		updatePwd()
		{
			this.checkToken();
			const newPwdInput = document.querySelector('input[name="newPwdInput"]');
			const newPwdConfInput = document.querySelector('input[name="newPwdConfInput"]');

			var remdp = /^.{8,32}$/;

			if (!remdp.test(this.newPwdInput))
			{
				newPwdInput.setCustomValidity("Veuillez choisir un mot de passe correct");
			}
			if (this.newPwdInput != this.newPwdConfInput)
			{
				newPwdInput.setCustomValidity("Veuillez saisir un mot de passe identique");
				newPwdConfInput.setCustomValidity("Veuillez saisir un mot de passe identique");
			}

			if ((remdp.test(this.newPwdInput)) && (this.newPwdInput == this.newPwdConfInput))
			{
				this.$refs['modalUnavailabilities'].hide();
				let jsonUpdatePwd = {
					odlPassword: this.odlPwdInput,
					newPassword: this.newPwdInput
				};
				let config = {
					headers: {
						'Authorization': 'Bearer ' + localStorage.token
					}
				};

				this.$axios.put('https://api.clickandball.fr/profil/changementmdp', jsonUpdatePwd, config)
					.then(response => {
						if (response.status == 200 && response.data.error)
						{
							this.showAlertWarning('Erreur : ' + response.data.error);
						}
						else if (response.status == 200)
						{
							this.showAlertSuccess("Informations sauvegardées");
						}
						else
						{
							this.showAlertWarning('Code erreur : ' + response.statusText);
						}
					})
					.catch(error => {
						this.showAlertWarning('Erreur : ' + error);
					});
			}
		},
		MajNotification()
		{
			console.log("tutu")
			this.checkToken();
			let jsonPOST = [];
			for (let i = 0; i < this.listNotifMail.length; i++)
			{
				console.log("mail: " + i)
				jsonPOST.push({
					"id": this.listNotifMail[i].id,
					"mail": this.listNotifMail[i].statut
				})
			}
			for (let i = 0; i < this.listNotifSite.length; i++)
			{
				console.log("site: " + i)
				jsonPOST.push({
					"id": this.listNotifSite[i].id,
					"site": this.listNotifSite[i].statut
				})
			}

			console.log(jsonPOST)
			let config = {
				headers: {
					'Authorization': 'Bearer ' + localStorage.token
				}
			};

			this.$axios
				.put('https://api.clickandball.fr/profil/notification', jsonPOST, config)
				.then(response => {
					if (response.status == 200)
					{
						this.showAlertSuccess("Informations sauvegardées");
						this.loadingPage();
					}
					else
					{
						this.showAlertWarning('Code erreur : ' + response.statusText);
					}
				})
				.catch(error => {
					this.showAlertWarning('Erreur : ' + error);
				});
		},

		showChangePwd()
		{
			this.$refs['modalUnavailabilities'].show();
		}
	}
}
</script>
