import Vue from 'vue'
import VueRouter from 'vue-router'

//Import des layouts

import AuthLayout from '../views/layouts/AuthLayout.vue'
import ExternLayout from '../views/layouts/ExternLayout.vue'
import ConnectedLayout from '../views/layouts/ConnectedLayout.vue'

//Import des pages


import NotFound from '../views/404.vue'
import About from '../views/About.vue'
import Administration from '../views/Administration.vue'
import Designation from '../views/Designation.vue'
import Calendar from '../views/Calendar.vue'
import Club from '../views/Club.vue'
import Division from '../views/Division.vue'
import Exclusion from '../views/Exclusion.vue'
import Statistique from '../views/GlobalStats.vue'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Match from '../views/Match.vue'
import NewPassword from '../views/NewPassword.vue'
import Profile from '../views/Profile.vue'
import Return from '../views/Return.vue'
import Sporthall from '../views/Sporthall.vue'
import Unavailability from '../views/Unavailability.vue'
import User from '../views/User.vue'
import Document from '../views/Document.vue'
import Information from '../views/Information.vue'
import Autre from '../views/Autre.vue'
import RefereeAvailable from '../views/RefereeAvailable'
import NouveauMdpObligatoire from '../views/NouveauMdpObligatoire'
import QuestionnaireApresMatch from '../views/QuestionnaireApresMatch'
import QuestionnaireApresMatchReponse from '../views/QuestionnaireApresMatchReponse'
import QuestionnaireDebutSaison from '../views/QuestionnaireDebutSaison'
import RecapDesignation from '../views/RecapDesignation'


Vue.use(VueRouter)

const routes = [
  {
    path: '/about',
    name: 'About',
    component: About,
    meta: {
      title: 'Click&Ball | A propos',
      layout: ExternLayout
    }
  },
  {
    path: '/administration',
    name: 'Administration',
    component: Administration,
    meta: {
      title: 'Click&Ball | Paramétrages',
      layout: ConnectedLayout
    }
  },
  {
    path: '/designation',
    name: 'Designation',
    component: Designation,
    meta: {
      title: 'Click&Ball | Désignations',
      layout: ConnectedLayout
    }
  },
    {
    path: '/autre',
    name: 'Autre',
    component: Autre,
    meta: {
      title: 'Click&Ball | Autres',
      layout: ConnectedLayout
    }
  },
  {
    path: '/calendar',
    name: 'Calendar',
    component: Calendar,
    meta: {
      title: 'Click&Ball | Calendrier',
      layout: ConnectedLayout
    }
  },
  {
    path: '/club',
    name: 'Club',
    component: Club,
    meta: {
      title: 'Click&Ball | Clubs',
      layout: ConnectedLayout
    }
  },
    {
    path: '/document',
    name: 'Document',
    component: Document,
    meta: {
      title: 'Click&Ball | Documents',
      layout: ConnectedLayout
    }
  },
  {
    path: '/division',
    name: 'Division',
    component: Division,
    meta: {
      title: 'Click&Ball | Groupes & Divisions',
      layout: ConnectedLayout
    }
  },
  {
    path: '/exclusion',
    name: 'Exclusion',
    component: Exclusion,
    meta: {
      title: 'Click&Ball | Exclusions',
      layout: ConnectedLayout
    }
  },
  {
    path: '/globalstatistique',
    name: 'Statistiques',
    component: Statistique,
    meta: {
      title: 'Click&Ball | Statistiques globales',
      layout: ConnectedLayout
    }
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: 'Click&Ball',
      layout: ConnectedLayout
    }
  },
    {
    path: '/information',
    name: 'Information',
    component: Information,
    meta: {
      title: 'Click&Ball | Informations',
      layout: ConnectedLayout
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      title: 'Click&Ball',
      layout: AuthLayout
    }
  },
  {
    path: '/match',
    name: 'Match',
    component: Match,
    meta: {
      title: 'Click&Ball | Matchs',
      layout: ConnectedLayout
    }
  },
  {
    path: '/newpassword',
    name: 'NewPassword',
    component: NewPassword,
    meta: {
      title: 'Click&Ball | Renouvellement du mot de passe',
      layout: AuthLayout
    }
  },  
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
    meta: {
      title: 'Click&Ball | Profil',
      layout: ConnectedLayout
    }
  },
  {
    path: '/refereeAvailable',
    name: 'RefereeAvailable',
    component: RefereeAvailable,
    meta: {
      title: 'Click&Ball | Arbitres disponibles',
      layout: ConnectedLayout
    }
  },
  {
    path: '/return',
    name: 'Return',
    component: Return,
    meta: {
      title: 'Click&Ball | Retours',
      layout: ConnectedLayout
    }
  },
  {
    path: '/sporthall',
    name: 'Sporthall',
    component: Sporthall,
    meta: {
      title: 'Click&Ball | Salles',
      layout: ConnectedLayout
    }
  },
  {
    path: '/unavailability',
    name: 'Unavailability',
    component: Unavailability,
    meta: {
      title: 'Click&Ball | Indisponibilités',
      layout: ConnectedLayout
    }
  },
  {
    path: '/user',
    name: 'Users',
    component: User,
    meta: {
      title: 'Click&Ball | Utilisateurs',
      layout: ConnectedLayout
    }
  },
  {
    path: '/questionnaireapresmatch',
    name: 'QuestionnaireApresMatch',
    component: QuestionnaireApresMatch,
    meta: {
      title: 'Click&Ball | Questionnaire Après Match - question',
      layout: ConnectedLayout
    }
  },
  {
    path: '/questionnaireapresmatchreponse',
    name: 'QuestionnaireApresMatchReponse',
    component: QuestionnaireApresMatchReponse,
    meta: {
      title: 'Click&Ball | Questionnaire Après Match - réponse',
      layout: ConnectedLayout
    }
  },
  {
    path: '/questionnairedebutsaison',
    name: 'QuestionnaireDebutSaison',
    component: QuestionnaireDebutSaison,
    meta: {
      title: 'Click&Ball | Questionnaire Début Saison',
      layout: ConnectedLayout
    }
  },
  {
    path: '/recapdesignation',
    name: 'RecapDesignation',
    component: RecapDesignation,
    meta: {
      title: 'Click&Ball | Récap désignation',
      layout: ConnectedLayout
    }
  },
  {
    path: '/changementmdpobligatoire',
    name: 'NouveauMdpObligatoire',
    component: NouveauMdpObligatoire,
    meta: {
      title: 'Click&Ball | Changement mot de passe obligatoire',
      layout: AuthLayout
    }
  },
  {
    path: '*',
    name: 'Not Found',
    component: NotFound,
    meta: {
      title: 'Click&Ball | Page introuvable',
      layout: ExternLayout
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((toRoute, fromRoute, next) => {
    window.document.title = toRoute.meta && toRoute.meta.title ? toRoute.meta.title : 'Home';

    next();
})

export default router
