<template>
	<div class="app" style="background-color: #e9eaef;">

		<header class="app-header fixed-top" style="background: linear-gradient(to right, #0c2646 0%, #204065 60%, #2a5788 100%)">
			<div class="app-header-inner">
				<div class="container-fluid py-2">
					<div class="app-header-content">
						<div class="row justify-content-between align-items-center">

							<div class="col-auto">
								<a id="sidepanel-toggler" class="sidepanel-toggler d-inline-block d-xl-none" href="#">
									<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" role="img"><title>Menu</title><path stroke="currentColor" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2" d="M4 7h22M4 15h22M4 23h22"></path></svg>
								</a>
							</div>

							<div class="app-search-box col col-xs">
								<form class="app-search-form">
									<span style="color: white;padding-top: .5rem; padding-bottom: .6rem; height: 2.5rem; display: block;	width: 100%; padding-right .75rem; font-size: 1rem;font-weight: 400;line-height: 1.5;">
										Click&Ball | CDO44
									</span>
								</form>
							</div>

							<div class="app-utilities col-auto">
								<div class="app-utility-item app-user-dropdown dropdown">
									<a class="dropdown-toggle align-middle" id="user-dropdown-toggle" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false" style="color: white">
										{{firstName}} {{lastName}}
									</a>
									<ul class="dropdown-menu" aria-labelledby="user-dropdown-toggle">
										<li><a class="dropdown-item" disabled>Vous êtes connecté en tant que {{firstName}} {{lastName}}</a></li>
										<li><hr class="dropdown-divider"></li>
										<li><a class="dropdown-item" style="text-align: right;" v-on:click="callDisconnection()">Déconnexion</a></li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div id="app-sidepanel" class="app-sidepanel sidepanel" >
				<div id="sidepanel-drop" class="sidepanel-drop"></div>
				<div class="sidepanel-inner d-flex flex-column" style="background-color: #ff6633" >
					<a href="#" id="sidepanel-close" class="sidepanel-close d-xl-none">&times;</a>
					<div class="app-branding text-center" style="padding-left: 0 !important; height: auto !important">
						<router-link to="/">
							<img style="width: 150px;height: auto;" src="../../assets/images/comite-basket-44_logo.svg" />
							<hr style="height:2px; width: 80%; margin-left: 10%; background-color: white;">
							<span class="logo-text" style="padding-top: -1em !important; font-size: 18px !important; color: white !important">Click&Ball</span>
						</router-link>		
					</div>
					<nav id="app-nav-main" class="app-nav app-nav-main flex-grow-1" >
						<ul class="app-menu list-unstyled accordion" id="menu-accordion" style="border-top: 2px solid rgba(255, 255, 255, 0.60); border-bottom: 2px solid rgba(255, 255, 255, 0.60);">
							<li class="nav-item">
								<router-link to="/" class="nav-link" style="color: white">
									<span class="nav-icon">
										<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-house-door" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
											<path fill-rule="evenodd" d="M7.646 1.146a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 .146.354v7a.5.5 0 0 1-.5.5H9.5a.5.5 0 0 1-.5-.5v-4H7v4a.5.5 0 0 1-.5.5H2a.5.5 0 0 1-.5-.5v-7a.5.5 0 0 1 .146-.354l6-6zM2.5 7.707V14H6v-4a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 .5.5v4h3.5V7.707L8 2.207l-5.5 5.5z" />
											<path fill-rule="evenodd" d="M13 2.5V6l-2-2V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5z" />
										</svg>
									</span>
									<span class="nav-link-text">Accueil</span>
								</router-link>
							</li>
							<li class="nav-item">
								<router-link class="nav-link" style="color: white;" to="/profile">
									<span class="nav-icon">
										<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-fill" viewBox="0 0 16 16">
											<path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
										</svg>
									</span>
									<span class="nav-link-text">Profil</span>
								</router-link>
							</li>
							<li v-if="arbitreDesignation || arbitreRetour" class="nav-item">
								<router-link class="nav-link" style="color: white;" to="/calendar">
									<span class="nav-icon">
										<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calendar3" viewBox="0 0 16 16">
											<path d="M14 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zM1 3.857C1 3.384 1.448 3 2 3h12c.552 0 1 .384 1 .857v10.286c0 .473-.448.857-1 .857H2c-.552 0-1-.384-1-.857V3.857z" />
											<path d="M6.5 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
										</svg>
									</span>
									<span class="nav-link-text">Calendrier</span>
								</router-link>
							</li>
							<li v-if="repartiteur || retour || adminRetour" class="nav-item has-submenu" style="background-color: #ff6633">
								<b-link v-b-toggle.submenu-1 class="nav-link submenu-toggle" style="color: white;">
									<span class="nav-icon">
										<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-clipboard-check" viewBox="0 0 16 16">
											<path fill-rule="evenodd" d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0z" />
											<path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z" />
											<path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z" />
										</svg>
									</span>
									<span class="nav-link-text">Répartition</span>
									<span class="submenu-arrow" style="color: white;">
										<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-chevron-down" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
											<path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
										</svg>
									</span>
								</b-link>
								<b-collapse id="submenu-1" class="collapse submenu submenu-1" data-parent="#menu-accordion">
									<ul class="submenu-list list-unstyled">
										<li v-if="repartiteur" class="submenu-item"><router-link class="submenu-link" style="color: white;" to="/designation">Désignations</router-link></li>
										<li v-if="repartiteur" class="submenu-item"><router-link class="submenu-link" style="color: white;" to="/recapdesignation">Recap désignations</router-link></li>
										<li v-if="repartiteur" class="submenu-item"><router-link class="submenu-link" style="color: white;" to="/exclusion">Exclusions</router-link></li>
										<li v-if="repartiteur" class="submenu-item"><router-link class="submenu-link" style="color: white;" to="/unavailability">Indisponibilités</router-link></li>
										<li v-if="repartiteur" class="submenu-item"><router-link class="submenu-link" style="color: white;" to="/match">Matchs</router-link></li>
										<li v-if="retour || adminRetour" class="submenu-item"><router-link class="submenu-link" style="color: white;" to="/return">Retours</router-link></li>
									</ul>
								</b-collapse>
							</li>
							<li v-if="adminCB || repartiteur || adminUtilisateur || adminEntite || adminRepartiteur || utilisateur" class="nav-item has-submenu" style="background-color: #ff6633">
								<b-link v-b-toggle.submenu-2 class="nav-link submenu-toggle" style="color: white;">
									<span class="nav-icon">
										<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-tools" viewBox="0 0 16 16">
											<path d="M1 0 0 1l2.2 3.081a1 1 0 0 0 .815.419h.07a1 1 0 0 1 .708.293l2.675 2.675-2.617 2.654A3.003 3.003 0 0 0 0 13a3 3 0 1 0 5.878-.851l2.654-2.617.968.968-.305.914a1 1 0 0 0 .242 1.023l3.356 3.356a1 1 0 0 0 1.414 0l1.586-1.586a1 1 0 0 0 0-1.414l-3.356-3.356a1 1 0 0 0-1.023-.242L10.5 9.5l-.96-.96 2.68-2.643A3.005 3.005 0 0 0 16 3c0-.269-.035-.53-.102-.777l-2.14 2.141L12 4l-.364-1.757L13.777.102a3 3 0 0 0-3.675 3.68L7.462 6.46 4.793 3.793a1 1 0 0 1-.293-.707v-.071a1 1 0 0 0-.419-.814L1 0zm9.646 10.646a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708zM3 11l.471.242.529.026.287.445.445.287.026.529L5 13l-.242.471-.026.529-.445.287-.287.445-.529.026L3 15l-.471-.242L2 14.732l-.287-.445L1.268 14l-.026-.529L1 13l.242-.471.026-.529.445-.287.287-.445.529-.026L3 11z" />
										</svg>
									</span>
									<span class="nav-link-text">Administration</span>
									<span class="submenu-arrow" style="color: white;">
										<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-chevron-down" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
											<path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
										</svg>
									</span>
								</b-link>
								<b-collapse id="submenu-2" class="collapse submenu submenu-1" data-parent="#menu-accordion">
									<ul class="submenu-list list-unstyled">

										<li v-if="adminCB" class="submenu-item"><router-link class="submenu-link" style="color: white;" to="/club">Clubs</router-link></li>
										<li v-if="adminEntite" class="submenu-item"><router-link class="submenu-link" style="color: white;" to="/division">Groupes & Divisions</router-link></li>
										<li v-if="adminRepartiteur" class="submenu-item"><router-link class="submenu-link" style="color: white;" to="/administration">Paramétrages</router-link></li>
										<li v-if="adminCB || repartiteur" class="submenu-item"><router-link class="submenu-link" style="color: white;" to="/sporthall">Salles</router-link></li>
										<li v-if="utilisateur || adminUtilisateur" class="submenu-item"><router-link class="submenu-link" style="color: white;" to="/user"><span v-if="adminUtilisateur">Utilisateurs</span><span v-if="utilisateur & !adminUtilisateur">Arbitres</span></router-link></li>

									</ul>
								</b-collapse>
							</li>
							<li v-if="adminQuestionDebutSaison || adminReponseDebutSaison" class="nav-item has-submenu" style="background-color: #ff6633">
								<b-link v-b-toggle.submenu-3 class="nav-link submenu-toggle" style="color: white;">
									<span class="nav-icon">
										<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-ui-checks" viewBox="0 0 16 16">
											<path d="M7 2.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5v-1zM2 1a2 2 0 0 0-2 2v2a2 2 0 0 0 2 2h2a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2H2zm0 8a2 2 0 0 0-2 2v2a2 2 0 0 0 2 2h2a2 2 0 0 0 2-2v-2a2 2 0 0 0-2-2H2zm.854-3.646a.5.5 0 0 1-.708 0l-1-1a.5.5 0 1 1 .708-.708l.646.647 1.646-1.647a.5.5 0 1 1 .708.708l-2 2zm0 8a.5.5 0 0 1-.708 0l-1-1a.5.5 0 0 1 .708-.708l.646.647 1.646-1.647a.5.5 0 0 1 .708.708l-2 2zM7 10.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5v-1zm0-5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0 8a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z"/>
										</svg>
									</span>
									<span class="nav-link-text">Formulaire</span>
									<span class="submenu-arrow" style="color: white;">
										<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-chevron-down" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
											<path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
										</svg>
									</span>
								</b-link>
								<b-collapse id="submenu-3" class="collapse submenu submenu-1" data-parent="#menu-accordion">
									<ul class="submenu-list list-unstyled">

										<li v-if="adminQuestionDebutSaison" class="submenu-item"><router-link class="submenu-link" style="color: white;" to="/questionnaireapresmatch">Après match - Question</router-link></li>
										<li v-if="adminReponseDebutSaison" class="submenu-item"><router-link class="submenu-link" style="color: white;" to="/questionnaireapresmatchreponse">Après match - Réponse</router-link></li>
										<li class="submenu-item"><router-link class="submenu-link" style="color: white;" to="/questionnairedebutsaison">Début de saison</router-link></li>
									</ul>
								</b-collapse>
							</li>
							<!-- <li class="nav-item has-submenu" style="background-color: #ff6633">
								<b-link v-b-toggle.submenu-5 class="nav-link submenu-toggle" style="color: white;">
									<span class="nav-icon">
										<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-tools" viewBox="0 0 16 16">
											<path d="M11 2a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v12h.5a.5.5 0 0 1 0 1H.5a.5.5 0 0 1 0-1H1v-3a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3h1V7a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v7h1V2zm1 12h2V2h-2v12zm-3 0V7H7v7h2zm-5 0v-3H2v3h2z" />
										</svg>
									</span>
									<span class="nav-link-text">Statistiques</span>
									<span class="submenu-arrow" style="color: white;">
										<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-chevron-down" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
											<path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
										</svg>
									</span>
								</b-link>
								<b-collapse id="submenu-5" class="collapse submenu submenu-1" data-parent="#menu-accordion">
									<ul class="submenu-list list-unstyled">
										<li class="submenu-item"><a class="submenu-link" style="color: white;" href="\club">Mes statistiques</a></li>
										<li v-if="admin" class="submenu-item"><a class="submenu-link" style="color: white;" href="\division">Statistiques</a></li>
									</ul>
								</b-collapse>
							</li> -->
							<li lass="nav-item has-submenu" style="background-color: #ff6633">
								<b-link v-b-toggle.submenu-6 class="nav-link submenu-toggle" style="color: white;">
									<span class="nav-icon">
										<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-book" viewBox="0 0 16 16">
											<path d="M9.828 4a3 3 0 0 1-2.12-.879l-.83-.828A1 1 0 0 0 6.173 2H2.5a1 1 0 0 0-1 .981L1.546 4h-1L.5 3a2 2 0 0 1 2-2h3.672a2 2 0 0 1 1.414.586l.828.828A2 2 0 0 0 9.828 3v1z" />
											<path fill-rule="evenodd" d="M13.81 4H2.19a1 1 0 0 0-.996 1.09l.637 7a1 1 0 0 0 .995.91h10.348a1 1 0 0 0 .995-.91l.637-7A1 1 0 0 0 13.81 4zM2.19 3A2 2 0 0 0 .198 5.181l.637 7A2 2 0 0 0 2.826 14h10.348a2 2 0 0 0 1.991-1.819l.637-7A2 2 0 0 0 13.81 3H2.19z" />
										</svg>
									</span>
									<span class="nav-link-text">Documents</span>
									<span class="submenu-arrow" style="color: white;">
										<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-chevron-down" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
											<path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
										</svg>
									</span>
								</b-link>
								<b-collapse id="submenu-6" class="collapse submenu submenu-1" data-parent="#menu-accordion">
									<ul class="submenu-list list-unstyled">
										<!--<li class="submenu-item"><a class="submenu-link" style="color: white;" href="\document">Documentation de l'outil</a></li>-->
										<li class="submenu-item"><a class="submenu-link" style="color: white;" href="https://www.loireatlantiquebasketball.org/les-commissions/commission-arbitrage.html">Informations CDO</a></li>
										<li class="submenu-item"><a class="submenu-link" style="color: white;" href="https://www.loireatlantiquebasketball.org/documents/documents-cdo.html">Autres documents de l'arbitrage</a></li>
									</ul>
								</b-collapse>
							</li>
						</ul>
					</nav>
					<div class="app-sidepanel-footer">
						<nav class="app-nav app-nav-footer text-center">
							<div class="app-menu footer-menu list-unstyled text-center" style="padding: 0 !important ">
								<div class="nav-item" style="padding: 0 !important">
									<a class="nav-link" href="https://basket44.com" style="color: white !important;padding-left: 0 !important; padding-right: 0 important">
										<span class="nav-link-text">CDO Basket 44</span>
									</a>
								</div>
							</div>
						</nav>
					</div>
				</div>
			</div>
		</header>
			<div class="app-wrapper">
				<div class="app-content pt-3 p-md-3 p-lg-4">
					<div class="container-fluid">

						<slot />


					</div>
				</div>
				<footer>
					<div class="container text-center py-3">
						<small class="copyright">
							<div class="row justify-content-between">
								<div class="col" style="margin-bottom: 0.5em">
									<a href="https://basket44.com" class="footer-link">
										Basket44
									</a>
									<span style="margin-left: 0.5em;margin-right: 0.5em;">|</span>
									<a href="https://extranet.ffbb.com/fbi/identification.do" class="footer-link">
										FBI V2
									</a>
									<span style="margin-left: 0.5em;margin-right: 0.5em;">|</span>
									<a href="\about" class="footer-link">
										A propos
									</a>
									<span style="margin-left: 0.5em;margin-right: 0.5em;">|</span>
									<a href="mailto:outildesignation@loireatlantiquebasketball.org" class="footer-link">
										Contact
									</a>
								</div>
								<hr>
								<span style="margin-top: -0.5em; font-size: 12px">
									{{currentYear}}&copy; Click & Ball
								</span>
								<span style="font-size: 10px; color: #2a5788">
									1.4.0 - API 1.5.0
								</span>
							</div>
						</small>
					</div>
				</footer>
			</div>
	</div>	
</template>

<style>
    .footer-link:hover {
        color: #204065 !important;
    }
    .nav-link{
        background-color: #ff6633 ;
    }
    .nav-link:hover {
        background-color: #dd4b1a !important;
        color: white !important;
        border-right: 4px solid #204065 !important;
        text-shadow: 2px 2px 4px #000000;
        font-weight: bold;
    }
	.nav-link:hover .nav-icon svg {
		color: white !important;
		font-weight: bold !important;
		width: 105% !important;
		-webkit-filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, .7));
		filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, .7));
	}
    .submenu-link:hover {
        background-color: #dd4b1a !important;
        color: white !important;
        border-right: 2px solid #204065 !important;
        text-shadow: 2px 2px 4px #000000;
        font-weight: bold;
    }
    .submenu-link:hover .nav-icon svg {
        color: white !important;
        font-weight: bold !important;
        -webkit-filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, .7));
        filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, .7));
    }
    .submenu-item:hover {
        border-right: 2px solid #204065 !important;
    }
    #sidepanel-toggler:hover{
		color: white !important;
    }
    #user-dropdown-toggle:hover {
        color: #ff6633 !important;

    }
    .app-nav .submenu-link::before {
        background-color: #e7e9edb0 !important;
    }
    .app-nav .submenu-link:hover::before {
        background-color: white !important;
    }
</style>

<script>
import jwt_decode from "jwt-decode";

export default {
	name: "ExternalLayout",
	data() {
		return {
			currentYear: new Date().getFullYear(), // 2020    
			connected: false,

			//Nouveau rôles
			arbitreDesignation: false,
			arbitreRetour: false,
			utilisateur: false,
			adminUtilisateur: false,
			repartiteur: false,
			adminRepartiteur: false,
			retour: false,
			adminRetour: false,
			adminEntite: false,
			adminCB: false,
			adminQuestionDebutSaison: false,
			adminReponseDebutSaison: false,

			lastName:'',
			firstName:'',
			roles:[]
		}
	},

	mounted()
	{
		this.checkConnection();
		var sidePanelToggler = document.getElementById('sidepanel-toggler');
		var sidePanel = document.getElementById('app-sidepanel');
		var sidePanelDrop = document.getElementById('sidepanel-drop');
		var sidePanelClose = document.getElementById('sidepanel-close');
		window.addEventListener('load', function () {
			responsiveSidePanel();
		});
		window.addEventListener('resize', function () {
			responsiveSidePanel();
		});

		function responsiveSidePanel()
		{
			var w = window.innerWidth;

			if (w >= 1200)
			{
				// if larger 
				//console.log('larger');
				sidePanel.classList.remove('sidepanel-hidden');
				sidePanel.classList.add('sidepanel-visible');
			}
			else
			{
				// if smaller
				//console.log('smaller');
				sidePanel.classList.remove('sidepanel-visible');
				sidePanel.classList.add('sidepanel-hidden');
			}
		}

		sidePanelToggler.addEventListener('click', function ()
		{
			if (sidePanel.classList.contains('sidepanel-visible'))
			{
				sidePanel.classList.remove('sidepanel-visible');
				sidePanel.classList.add('sidepanel-hidden');
			}
			else
			{
				sidePanel.classList.remove('sidepanel-hidden');
				sidePanel.classList.add('sidepanel-visible');
			}
		});
		sidePanelClose.addEventListener('click', function (e) {
			e.preventDefault();
			sidePanelToggler.click();
		});
		sidePanelDrop.addEventListener('click', function () {
			sidePanelToggler.click();
		});
	},

	updated() {
		this.checkConnection();
	},

	methods: {
		checkConnection()
		{
			var rgToken = /^[A-Za-z0-9-_=]+\.[A-Za-z0-9-_=]+\.?[A-Za-z0-9-_.+/=]*$/;

			if (rgToken.test(localStorage.token))
			{
				var jsonToken = jwt_decode(localStorage.token);
				if (jsonToken.exp < Math.round(Date.now()/1000))
				{
					localStorage.token = null;
					this.connected = false;
					this.$router.push({ path: '/login'})
				}
				else
				{
					this.lastName = jsonToken.lastName;
					this.firstName = jsonToken.firstName;
					this.roles = jsonToken.role;

					//Nouveau rôles
					if (this.roles.indexOf('ArbitreDesignation') != -1)
					{
						this.arbitreDesignation = true;
						this.connected = true;
					}
					if (this.roles.indexOf('ArbitreRetour') != -1)
					{
						this.arbitreRetour = true;
						this.connected = true;
					}
					if (this.roles.indexOf('Utilisateur') != -1)
					{
						this.utilisateur = true;
						this.connected = true;
					}
					if (this.roles.indexOf('AdminUtilisateur') != -1)
					{
						this.adminUtilisateur = true;
						this.connected = true;
					}
					if (this.roles.indexOf('Repartiteur') != -1)
					{
						this.repartiteur = true;
						this.connected = true;
					}
					if (this.roles.indexOf('AdminRepartiteur') != -1)
					{
						this.adminRepartiteur = true;
						this.connected = true;
					}
					if (this.roles.indexOf('Retour') != -1)
					{
						this.retour = true;
						this.connected = true;
					}
					if (this.roles.indexOf('AdminRetour') != -1)
					{
						this.adminRetour = true;
						this.connected = true;
					}
					if (this.roles.indexOf('AdminEntite') != -1)
					{
						this.adminEntite = true;
						this.connected = true;
					}
					if (this.roles.indexOf('AdminCB') != -1)
					{
						this.adminCB = true;
						this.connected = true;
					}
					if (this.roles.indexOf('AdminQuestionDebutSaison') != -1)
					{
						this.adminQuestionDebutSaison = true;
						this.connected = true;
					}
					if (this.roles.indexOf('AdminReponseDebutSaison') != -1)
					{
						this.adminReponseDebutSaison = true;
						this.connected = true;
					}
					
					if (this.$router.history.current.name == "Login" || this.$router.history.current.name == "NewPassword")
					{
						this.$router.push({ path: '/'})
					}
				}
			}
			else if ((!this.$router.history.pending || (this.$router.history.pending.name != "NewPassword" && this.$router.history.pending.name != "Login")) &&
					(!this.$router.history.current || (this.$router.history.current.name != "NewPassword" && this.$router.history.current.name != "Login")))
			{
				localStorage.token = null;
				this.connected = false;
				this.$router.push({ path: '/login'})
			}
		},
		callDisconnection()
		{
			this.connected = false;
			localStorage.token = null;
			this.$router.push({ path: '/login'})
		}
	}
}
</script>
