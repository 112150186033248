<template>
	<div class="Login">
		<b-modal ref="modalForgetPwd" hide-footer hide-header>
			<div class="app-card app-card-account shadow-sm d-flex flex-column align-items-start">
				<div class="app-card-header p-3 border-bottom-0">
					<div class="row align-items-center gx-3">
						<div class="col-auto">
							<div class="app-icon-holder" style="background-color: rgb(42, 87, 136)">
								<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-shield-check" fill="white" xmlns="http://www.w3.org/2000/svg">
									<path fill-rule="evenodd" d="M5.443 1.991a60.17 60.17 0 0 0-2.725.802.454.454 0 0 0-.315.366C1.87 7.056 3.1 9.9 4.567 11.773c.736.94 1.533 1.636 2.197 2.093.333.228.626.394.857.5.116.053.21.089.282.11A.73.73 0 0 0 8 14.5c.007-.001.038-.005.097-.023.072-.022.166-.058.282-.111.23-.106.525-.272.857-.5a10.197 10.197 0 0 0 2.197-2.093C12.9 9.9 14.13 7.056 13.597 3.159a.454.454 0 0 0-.315-.366c-.626-.2-1.682-.526-2.725-.802C9.491 1.71 8.51 1.5 8 1.5c-.51 0-1.49.21-2.557.491zm-.256-.966C6.23.749 7.337.5 8 .5c.662 0 1.77.249 2.813.525a61.09 61.09 0 0 1 2.772.815c.528.168.926.623 1.003 1.184.573 4.197-.756 7.307-2.367 9.365a11.191 11.191 0 0 1-2.418 2.3 6.942 6.942 0 0 1-1.007.586c-.27.124-.558.225-.796.225s-.526-.101-.796-.225a6.908 6.908 0 0 1-1.007-.586 11.192 11.192 0 0 1-2.417-2.3C2.167 10.331.839 7.221 1.412 3.024A1.454 1.454 0 0 1 2.415 1.84a61.11 61.11 0 0 1 2.772-.815z" />
									<path fill-rule="evenodd" d="M10.854 6.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 8.793l2.646-2.647a.5.5 0 0 1 .708 0z" />
								</svg>
							</div>
						</div>
						<div class="col-auto">
							<h4 class="app-card-title">Mot de passe oublié</h4>
						</div>
					</div>
				</div>
				<div class="app-card-body px-4 w-100">
					<div class="item border-bottom py-3">
						<div class="row justify-content-between align-items-center text-center">
							<div class="text-center">
								<div class="item-label"><strong>Renseignez votre email</strong></div>
								<b-form-input class="form-select-sm ml-auto d-inline-flex text-center" style="margin: 0.5em; height: 30px; border: 1px solid #ced4da; border-radius: .25rem;" :state="emailState" type="email" name="emailPwdForgetInput" v-model="emailPwdForgetInput" aria-describedby="password-help-block"></b-form-input>
							</div>
							<div class="col-auto">
								<small>Si votre adresse mail correspond à un compte enregistré sur Click&Ball , vous recevrez un lien pour réinitialiser votre mot de passe.</small>
							</div>
						</div>
					</div>
					<div class="text-center" style="margin: 0.5em">
						<b-button v-on:keyup.enter="forgetPwd()" v-on:click="forgetPwd()" class="btn app-btn-secondary">Envoyer la demande de renouvellement</b-button>
					</div>
				</div><!--//app-card-body-->

			</div>
			

		</b-modal>


		<div class="container">
			<div class="row justify-content-md-center ">
				<div class="col-md-6">
					
					<div class="logo-container" style="margin-top: 2.5em;">
						<img src="../assets/images/logo-comite-basket-44-white.png" alt="CDO44" class="d-xs-inline" style="height: auto; width: 175px;text-align: center;filter: drop-shadow(10px 5px 4px #000000);">
						<h2 style="padding-top: 0.3em; text-align: center; color: #f96332; font-weight: bold;filter: drop-shadow(10px 5px 4px #000000);">Outil de désignation</h2>
						<small style="color: whitesmoke;">Version 1.4.0</small>
					</div>
					<div v-if="visibleMessageAlerte">

						<div class="accordion3" role="tablist">
							<div class="app-card app-card-notification shadow-sm mb-4" style="border: 1px solid rgb(47 87 136); border-radius: 0.25rem">
								<div block v-b-toggle.bug type="button" data-toggle="" class="app-card-header px-4 py-3" header-tag="header" role="tab" style="background-color: rgba(0,0,0,.05) !important;border-bottom: 2px solid rgb(47 87 136)">
									{{ titreMessageAlerte }}
								</div>
								<b-collapse visible id="bug" accordion="my-accordion4" role="tabpanel" class="app-card-body p-4">
									<div class="notification-content" style="margin-bottom: -2em">
										{{ messageAlerte }}
									</div>
								</b-collapse>
								<div class="px-4 py-3">
								</div>
							</div>
						</div>

					</div>
					<div class="auth-form-container text-start" style="margin: 1.5em">
						<form class="auth-form login-form">
							<div class="email mb-3">
								<label class="sr-only" for="emailInput">Email</label>
								<input id="emailInput" name="emailInput" v-on:keyup.enter="callAuthentication()" v-model="emailInput" type="email" class="form-control emailInput" placeholder="Adresse email" required="required">
							</div>
							<div class="password mb-3">
								<label class="sr-only" for="passwordInput">Mot de passe</label>
								<input id="passwordInput" name="passwordInput" v-on:keyup.enter="callAuthentication()" v-model="passwordInput" type="password" class="form-control signin-password" placeholder="Mot de passe" required="required">
							</div>
							<div class="text-center">
								<b-button v-on:click="callAuthentication()"  class="btn app-btn-primary w-100 theme-btn mx-auto">Connexion</b-button>
							</div>
							<div class="text-center">
								<small style="color:white; text-decoration: none">En cas de problème d'accès merci de contacter l'administrateur de votre entité</small>
							</div>
							<div class="extra mt-3 row justify-content-between">
								<div class="col-5">
									<div class="form-check">
										
									</div>
								</div>
								<div class="col-7">
									<div class="forgot-password text-end">
										<b-link v-on:click="showPwdForget()" class="link footer-link" style="color: white !important;filter: drop-shadow(5px 3px 3px #000000);">Mot de passe oublié ?</b-link>
									</div>
								</div>
							</div>
						</form>
					</div>

					<b-alert :show="alertSuccessCountDown"
						variant="success"
						@dismissed="alertSuccessCountDown=0"
						@dismiss-count-down="alertSuccessCountDownChanged">
					<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-exclamation-circle-fill" viewBox="0 0 16 16">
						<path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/>
					</svg>

					<p>{{ info }}</p>

					<b-progress variant="success"
						:max="dismissSecs"
						:value="alertSuccessCountDown"
						height="4px"
						></b-progress>
					</b-alert>
					<b-alert :show="alertWarningCountDown"
						variant="warning"
						@dismissed="alertWarningCountDown=0"
						@dismiss-count-down="alertWarningCountDownChanged">

						<p>{{ error }}</p>

						<b-progress
							variant="warning"
							:max="dismissSecs"
							:value="alertWarningCountDown"
							height="4px"
							></b-progress>
					</b-alert>

				</div>
			</div>
		</div>
	</div>
</template>

<script>
import jwt_decode from "jwt-decode";

export default {
	name: 'Login',
	components: {
		//'app-footer': Footer,
	},
	computed:
	{
		emailState: function()
		{
			var reEmail = /^[\w.-]+@[a-zA-Z0-9-.]+.[a-zA-Z]{2,4}$/;
			if (reEmail.test(this.email))
			{
				return true;
			}
			else 
			{
				return false;
			}
		}
	},
	data() {
		return {
			// Variable message alert
			dismissSecs: 4,
			alertSuccessCountDown: 0,
			alertWarningCountDown: 0,
			info:'',
			error:'',

			//Message Alerte
			visibleMessageAlerte: false,
			titreMessageAlerte: "",
			messageAlerte: "",

			emailInput: '',
			passwordInput: '',
			emailPwdForgetInput:'',
		}
	},
	mounted()
	{
		this.$axios.get('https://api.clickandball.fr/cab/authentification/alerte')
                .then(response => {
                    if (response.status == 200 && response.data.error) {
                        this.showAlertWarning('Erreur : ' + response.data.error);
                    }
                    else if (response.status == 200) {
                        this.titreMessageAlerte = response.data.titreTexteAuthentification;
                        this.messageAlerte = response.data.texteAuthentification;
						if (this.titreMessageAlerte !== undefined && this.titreMessageAlerte.length > 1)
						{
							this.visibleMessageAlerte = true;
						}
						else
						{
							this.visibleMessageAlerte = false;
						}
                    }
                    else {
                        this.showAlertWarning('Code erreur : ' + response.status);
                    }
                })
                .catch(error => {
                    this.showAlertWarning('Erreur : ' + error);
				});
	},

	methods: {
		// Méthodes message alert
		alertSuccessCountDownChanged(alertSuccessCountDown)
		{
			this.alertSuccessCountDown = alertSuccessCountDown
		},
		alertWarningCountDownChanged(alertWarningCountDown)
		{
			this.alertWarningCountDown = alertWarningCountDown
		},
		showAlertSuccess(info)
		{
			this.info = info;
			this.alertSuccessCountDown = this.dismissSecs
		},
		showAlertWarning(error)
		{
			this.error = error;
			this.alertWarningCountDown = this.dismissSecs
		},
		callAuthentication()
		{
			var reEmail = /^[\w.-]+@[a-zA-Z0-9-.]+.[a-zA-Z]{2,4}$/;

			let json = {
				email : this.emailInput, 
				password : this.passwordInput
			};

			if(reEmail.test(this.emailInput) && this.passwordInput != "")
			{
				this.$axios.post('https://api.clickandball.fr/authentification/connexion', json)
					.then(response => {
						if (response.status == 200 && response.data.error)
						{
							this.showAlertWarning(response.data.error);
						}
						else if (response.status == 200)
						{
							var jsonToken = jwt_decode(response.data.token);
							if (jsonToken.changementMdpObligatoire)
							{
								localStorage.token = response.data.token;
								this.$router.push({ path: '/changementmdpobligatoire'});
							}
							else
							{
								localStorage.token = response.data.token;
								this.$router.push({ path: '/'});
							}
						}
					})
					.catch(error => {
						this.showAlertWarning('Erreur: ' + error);	
					});
			}
		},

		showPwdForget()
		{
			this.$refs['modalForgetPwd'].show();
		},
		forgetPwd()
		{
			var reEmail = /^[\w.-]+@[a-zA-Z0-9-.]+.[a-zA-Z]{2,4}$/;

			if(reEmail.test(this.emailPwdForgetInput))
			{
				this.$refs['modalForgetPwd'].hide();
				let json = {
					email : this.emailPwdForgetInput
				};

				this.$axios.post('https://api.clickandball.fr/authentification/oublimdp', json)
					.then(response => {
						if (response.status == 200 && response.data.error)
						{
							this.showAlertWarning('Erreur : ' + response.data.error);
						}
						else if (response.status == 200)
						{
							this.showAlertSuccess("Email envoyé");
						}
					})
					.catch(error => {
						this.showAlertWarning('Erreur : ' + error);
					});
			}	
		}
	}
}
</script>