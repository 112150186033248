<template>
	<div class="Exclusion">
		<h1 class="app-page-title"><img src="https://img.icons8.com/ios-glyphs/50/000000/basketball--v1.png" /> Gestion des exclusions</h1>

		<b-modal ref="addExclusion" hide-footer hide-header size="lg" >
			<div class="app-card " style="margin: -1em">
				
				<div class="app-card-header p-3 border-bottom-0">
					<a style="float: right; z-index: 9999; margin-right: 0.5em; top: 0" href="#" block @click="$refs['addExclusion'].hide()">
						<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
							<path fill-rule="evenodd" d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z" />
							<path fill-rule="evenodd" d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z" />
						</svg>
					</a>
					<div class="row align-items-center gx-3">
						<div class="col-auto">
							<div class="app-icon-holder" style="background-color: rgb(42, 87, 136)">
								<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-shield-check" fill="white" xmlns="http://www.w3.org/2000/svg">
									<path d="M4.54.146A.5.5 0 0 1 4.893 0h6.214a.5.5 0 0 1 .353.146l4.394 4.394a.5.5 0 0 1 .146.353v6.214a.5.5 0 0 1-.146.353l-4.394 4.394a.5.5 0 0 1-.353.146H4.893a.5.5 0 0 1-.353-.146L.146 11.46A.5.5 0 0 1 0 11.107V4.893a.5.5 0 0 1 .146-.353L4.54.146zM5.1 1 1 5.1v5.8L5.1 15h5.8l4.1-4.1V5.1L10.9 1H5.1z" />
									<path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
								</svg>
							</div>
						</div>
						<div class="col-auto">
							<h4 class="app-card-title" style="color: rgb(42, 87, 136) !important">Ajouter une exclusion</h4>
						</div>
					</div>
				</div>
			</div>
			<div class="app-card app-card-basic d-flex flex-column align-items-center shadow-sm">
				<div class="app-card-body">
					<div class="intro mb-6">
						<small class="text-center">
							<div style="margin-top: -1em; margin-bottom: 0.75em">
								<strong>
									Arbitre :
								</strong>
							</div>
							<div class="row">
								<div class="col-md-5 offset-md-1">
									<b-form-select v-model="groupReferee" @change="filterReferee()" class="form-select form-select-sm ml-auto d-inline-flex text-center">
										<b-form-select-option :value="0">Choisir un groupe</b-form-select-option>
										<b-form-select-option v-for="r in listGroup" :key="r.id" :value="r.id">{{ r.wording }}</b-form-select-option>
									</b-form-select>
								</div>
								<div class="col-md-5">
									<b-form-select v-model="refereeExcluded" class="form-select form-select-sm ml-auto d-inline-flex text-center">
										<b-form-select-option :value='0' disabled>Choisir un arbitre</b-form-select-option>
										<b-form-select-option :value='0' disabled>--- Arbitre position 1 ---</b-form-select-option>
										<b-form-select-option v-for="r in filterListRefereePos1" :key="r.nationalNumber" :value="r.nationalNumber">{{ r.firstName }} {{ r.lastName }}</b-form-select-option>
										<b-form-select-option :value='0' disabled>--- Arbitre position 2 ---</b-form-select-option>
										<b-form-select-option v-for="r in filterListRefereePos2" :key="r.nationalNumber" :value="r.nationalNumber">{{ r.firstName }} {{ r.lastName }}</b-form-select-option>
									</b-form-select>
								</div>
							</div>
							<hr />
							<div style="margin-top: 0.75em; margin-bottom: 0.75em">
								<strong>
									Club a exclure :
								</strong>
							</div>
							<div class="row">
								<div class="col-md-8 offset-md-2">
									<select  class="form-select form-select-sm ml-auto d-inline-flex text-center" v-model="clubId">
										<option disabled value="">Choisissez une équipe</option>
										<option v-for="item in listClub" :key="item.id" :value="item.id">
											{{ item.name }}
										</option>
									</select>
								</div>
							</div>
							<div class="align-items-center text-center" style="margin-top: 0.5em">
								<input type="checkbox" class="form-check-input" id="checkClubRelated" name="checkClubRelated" :state="checkClubRelated" v-model="checkClubRelated" style="margin: 0.4em;">
								<label for="allDay" style="margin-top: 0.15em">Ajouter les clubs liés (CTC, Entente) à l'exclusion?</label>
							</div>
							<hr />
						</small>
						<div class="text-center">
							<b-button class="btn app-btn-primary" style="background: rgb(42, 87, 136);color: white; width: 200px; margin: 0.5em;" v-on:click="addExclusion()">Ajouter l'exclusion</b-button>
						</div>
					</div>
				</div>
			</div>
		</b-modal>

		<b-modal ref="deleteExclusion" hide-footer hide-header size="lg">
			<div class="app-card " style="margin-top: -1em; margin: -1em">
				<a style="float: right; z-index: 9999; margin-right: 0.5em; top: 0" href="#" block @click="$refs['deleteExclusion'].hide()">
					<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
						<path fill-rule="evenodd" d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z" />
						<path fill-rule="evenodd" d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z" />
					</svg>
				</a>
				<div class="app-card-header p-3 border-bottom-0">
					<div class="row align-items-center gx-3">
						<div class="col-auto">
							<div class="app-icon-holder" style="background-color: rgb(42, 87, 136)">
								<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-shield-check" fill="white" xmlns="http://www.w3.org/2000/svg">
									<path d="M4.54.146A.5.5 0 0 1 4.893 0h6.214a.5.5 0 0 1 .353.146l4.394 4.394a.5.5 0 0 1 .146.353v6.214a.5.5 0 0 1-.146.353l-4.394 4.394a.5.5 0 0 1-.353.146H4.893a.5.5 0 0 1-.353-.146L.146 11.46A.5.5 0 0 1 0 11.107V4.893a.5.5 0 0 1 .146-.353L4.54.146zM5.1 1 1 5.1v5.8L5.1 15h5.8l4.1-4.1V5.1L10.9 1H5.1z" />
									<path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
								</svg>
							</div>
						</div>
						<div class="col-auto">
							<h4 class="app-card-title" style="color: rgb(42, 87, 136) !important">Supprimer une exclusion</h4>
						</div>
					</div>
				</div>
				<div class="app-card app-card-basic d-flex flex-column align-items-center shadow-sm">
					<div class="app-card-body">
						<div class="intro mb-6">
							<small class="text-center">
								<div style="margin-top: -1em; margin-bottom: 0.75em">
									<strong>
										Choisir une exclusion :
									</strong>
								</div>
								<div class="row">
									<div class="col-md-12">
										<select class="form-select form-select-sm ml-auto d-inline-flex text-center" v-model="exclusionIdToDelete">
											<option disabled value="">Choisissez une exclusion</option>
											<option v-for="item in listExclusionToDelete" :key="item.idExclusion" :value="item.idExclusion">
												{{ item.club.name }}
											</option>
										</select>
									</div>
								</div>
								<hr />
								<div class="text-center">
									<b-button class="btn app-btn-primary" style="background: rgb(42, 87, 136);color: white; width: 200px; margin: 0.5em;" v-on:click="deleteExclusion()">Supprimer l'exclusion</b-button>
								</div>
							</small>
						</div>
					</div>
				</div>
			</div>
		</b-modal>

		<b-alert :show="alertSuccessCountDown" variant="success" @dismissed="alertSuccessCountDown=0" @dismiss-count-down="alertSuccessCountDownChanged">
			<p>{{ info }}</p>
			<b-progress variant="success" :max="dismissSecs" :value="alertSuccessCountDown" height="4px"></b-progress>
		</b-alert>
		<b-alert :show="alertWarningCountDown" variant="warning" @dismissed="alertWarningCountDown=0" @dismiss-count-down="alertWarningCountDownChanged">
			<p>{{ error }}</p>
			<b-progress variant="warning"
						:max="dismissSecs"
						:value="alertWarningCountDown"
						height="4px"></b-progress>
		</b-alert>

		<div class="accordion2" role="tablist">
			<div class="app-card shadow-sm mb-4 border-left-decoration">
				<div class="inner">
					<div block v-b-toggle.actions id="title-legende" type="button" data-toggle="" class="card-header align-middle" style="background-color: rgba(0,0,0,.05) !important; border-bottom: 2px solid rgb(47 87 136)">
						<h5 class="title align-middle" style="color: rgb(47 87 136)">
							<i class="fas fa-search-plus"></i>
							Actions
						</h5>
					</div>
					<b-collapse visible id="actions" accordion="my-accordion2" role="tabpanel" class="app-card-body p-4">
						<div class="row g-2 justify-content-center justify-content-md-end" style="margin-bottom: -1em">
							<div class="col-12 col-sm-12 col-lg-10">
								<div class="col-lg-12">
									<div class="row align-center align-items-middle" style="margin-bottom: 1em">
										<div class="col-lg-2 col-md-3 col-sm-12 col-12 text-center">
											<b-button class="btn app-btn-primary" style="width: 200px;" v-on:click="addExclusionModal()">
												Ajouter une exclusion
											</b-button>
										</div>

									</div>

								</div>
							</div><!--//col-->
							<div class="col-12 col-lg-2 align-center align-items-middle" style="text-align: center;">
								<a class="btn app-btn-secondary" style="color: #fa7507; border: 1px solid #fa7507; border-radius: .25rem;" v-on:click="downloadCSV()">
									<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-download mr-1" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
										<path fill-rule="evenodd" d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
										<path fill-rule="evenodd" d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
									</svg>
									Extraction CSV
								</a>
							</div><!--//col-->
						</div><!--//row-->
					</b-collapse><!--//app-card-body-->
				</div><!--//inner-->
			</div>
		</div>

		<div class="tab-content" id="orders-table-tab-content">
			<div class="tab-panel fade show active" id="orders-all" role="tabpanel" aria-labelledby="orders-all-tab">
				<div class="app-card app-card-orders-table shadow-sm mb-5">
					<div class="app-card-body">
						<div class="table-responsive table-stiped">
							<table class="table table-striped table-hover mb-0 text-center">
								<thead>
									<tr>
										<th class="mb-2 text-primary">Numéro de licence</th>
										<th class="mb-2 text-primary">Prénom</th>
										<th class="mb-2 text-primary">Nom</th>
										<th class="mb-2 text-primary">Club</th>
										<th class="mb-2 text-primary">Date d'ajout</th>
										<th class="mb-2 text-primary">Actions</th>
									</tr>
								</thead>
								<tbody v-if="!noResult">
									<tr v-for="item in listExclusion" :key="item.id">
										<td class="cell">{{ item.user.permitNumber }}</td>
										<td class="cell">{{ item.user.firstName }}</td>
										<td class="cell">{{ item.user.lastName }}</td>
										<td class="cell">
											<div v-for="excluion in item.exclusion" :key="excluion.id">
												{{ excluion.club.id }} - {{ excluion.club.name }}
											</div>
										</td>
										<td class="cell">
											<div v-for="excluion in item.exclusion" :key="excluion.id">
												{{ excluion.creationDate}}
											</div>
										</td>
										<td class="cell">
											<b-button class="btn app-btn-secondary" style="margin: 3px" v-on:click="deleteExclusionModal(item)">
												<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
													<path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
													<path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
												</svg>
											</b-button>
										</td>
									</tr>
								</tbody>
								<tbody v-if="noResult">
									<tr>
										<td colspan="6">Aucun résultat</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

</template>

<script>
import jwt_decode from "jwt-decode";

export default {
	name: 'Exclusion',
	data() {
		return {
			// Variable message alert
			dismissSecs: 10,
			alertSuccessCountDown: 0,
			alertWarningCountDown: 0,
			info:'',
			error:'',

			listExclusion:[],
			noResult:false,

			listGroup:[],
			groupReferee:"0",
			refereeExcluded:0,
			filterListRefereePos1:[],
			filterListRefereePos2:[],
			listRefereeAvailable:[],
			listClub:[],
			clubId:"",
			checkClubRelated:true,

			listExclusionToDelete:[],
			exclusionIdToDelete:0,
		}
	},

	mounted()
	{
		this.loadingPage();
	},

	methods: {
		loadingPage() {
            this.checkToken();
            let config = {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.token
                }
            };

            this.$axios.get('https://api.clickandball.fr/designation/exclusion', config)
                .then(response => {
                    if (response.status == 200 && response.data.error) {
                        this.showAlertWarning('Erreur : ' + response.data.error);
                    }
                    else if (response.status == 200) {
                        this.listExclusion = response.data;
                        this.listExclusion.forEach(x => x.exclusion.forEach(y => y.creationDate = this.getDateString(new Date(y.creationDate))))

                        if (this.listExclusion.length === 0) {
                            this.noResult = true;
                        }
                    }
                    else {
                        this.showAlertWarning('Code erreur : ' + response.status);
                    }
                })
		},
		// Méthodes message alert
		alertSuccessCountDownChanged(alertSuccessCountDown)
		{
			this.alertSuccessCountDown = alertSuccessCountDown
		},
		alertWarningCountDownChanged(alertWarningCountDown)
		{
			this.alertWarningCountDown = alertWarningCountDown
		},
		showAlertSuccess(info)
		{
			this.info = info;
			this.alertSuccessCountDown = this.dismissSecs
		},
		showAlertWarning(error)
		{
			this.error = error;
			this.alertWarningCountDown = this.dismissSecs
		},
		checkToken()
		{
			if (localStorage.token)
			{
				var jsonToken = jwt_decode(localStorage.token);
				if ((jsonToken.exp - 60) < Math.round(Date.now()/1000))
				{
					localStorage.token = null;
					this.connected = false;
					this.$router.push({ path: '/login'})
				}
			}
		},

		getDateString(date)
		{
			// prints date & time in MM-YYYY format
			return ("0" + (date.getMonth() + 1)).slice(-2) + "-" + date.getFullYear();
		},
		addExclusionModal()
		{
			this.checkToken();

			this.groupReferee = "0";
			this.refereeExcluded = 0;
			this.filterListRefereePos1 = [];
			this.filterListRefereePos2 = [];

			this.$refs['addExclusion'].show();

			let config = {
				headers: {
					'Authorization': 'Bearer ' + localStorage.token
				}
			};

			this.$axios.get('https://api.clickandball.fr/utilisateur/arbitre', config)
				.then(response => {
					if (response.status == 200 && response.data.error)
					{
						this.showAlertWarning('Erreur : ' + response.data.error);
					}
					else if (response.status == 200)
					{
						this.listRefereeAvailable = response.data;
						
						this.listRefereeAvailable.forEach(x => {
							if (x.refereePosition === 1)
							{
								this.filterListRefereePos1.push(x);
							}
							else if (x.refereePosition === 2)
							{
								this.filterListRefereePos2.push(x);
							}
						})
					}
					else
					{
						this.showAlertWarning('Code erreur: ' + response.status);
					}
				})
				.catch(error => {
					this.showAlertWarning('Erreur: ' + error);
				});

			this.$axios.get('https://api.clickandball.fr/groupe', config)
				.then(response => {
					if (response.status == 200 && response.data.error)
					{
						this.showAlertWarning('Erreur : ' + response.data.error);
					}
					else if (response.status == 200)
					{
						this.listGroup = response.data;
					}
					else
					{
						this.showAlertWarning('Code erreur : ' + response.status);
					}
				})
				.catch(error => {
					this.showAlertWarning('Erreur: ' + error);
				});

			this.$axios.get('https://api.clickandball.fr/club', config)
				.then(response => {
					if (response.status == 200 && response.data.error)
					{
						this.showAlertWarning('Erreur : ' + response.data.error);
					}
					else if (response.status == 200)
					{
						this.listClub = response.data;
					}
					else
					{
						this.showAlertWarning('Code erreur : ' + response.status);
					}
				})
		},
		addExclusion()
		{
			this.checkToken();
			this.$refs['addExclusion'].hide();

			let json = {
				nationalNumber:this.refereeExcluded,
				clubId:this.clubId,
				checkClubRelated:this.checkClubRelated
			};
			let config = {
				headers: {
					'Authorization': 'Bearer ' + localStorage.token,
				}
			};

			this.$axios.post('https://api.clickandball.fr/designation/exclusion', json, config)
				.then(response => {
					if (response.status == 200 && response.data.error)
					{
						this.showAlertWarning('Erreur : ' + response.data.error);
					}
					else if (response.status == 200)
					{
						this.showAlertSuccess("Exclusion ajoutée");
						this.apiGetInfoIsUpdate = false;
						this.loadingPage();
					}
					else
					{
						this.showAlertWarning('Erreur : ' + response.statusText);
					}
				})
				.catch(error => {
					this.showAlertWarning('Erreur : ' + error);
				});
		},
		deleteExclusionModal(info)
		{
			this.checkToken();
			this.exclusionIdToDelete = info.exclusion[0].idExclusion;
			this.listExclusionToDelete = info.exclusion;
			this.$refs['deleteExclusion'].show();
		},
		deleteExclusion()
		{
			this.checkToken();
			this.$refs['deleteExclusion'].hide();

			let config = {
				headers: {
					'Authorization': 'Bearer ' + localStorage.token,
				},
				data: {
					id: this.exclusionIdToDelete,
				}
			};

			this.$axios.delete('https://api.clickandball.fr/designation/exclusion', config)
				.then(response => {
					if (response.status == 200 && response.data.error)
					{
						this.showAlertWarning('Erreur : ' + response.data.error);
					}
					else if (response.status == 200)
					{
						this.showAlertSuccess("Exclusion supprimée");
						this.loadingPage();
					}
					else
					{
						this.showAlertWarning('Erreur : ' + response.statusText);
					}
				})
				.catch(error => {
					this.showAlertWarning('Erreur : ' + error);
				});
		},
		filterReferee()
		{
			this.filterListRefereePos1 = [];
			this.filterListRefereePos2 = [];
			this.listRefereeAvailable.forEach(x => {
				if ((x.refereeLevel.id == this.groupReferee && x.refereePosition === 1) || (0 == this.groupReferee && x.refereePosition === 1))
				{
					this.filterListRefereePos1.push(x);
				}
				else if ((x.refereeLevel.id == this.groupReferee && x.refereePosition === 2) || (0 == this.groupReferee && x.refereePosition === 2))
				{
					this.filterListRefereePos2.push(x);
				}
			})
		},
		downloadCSV()
		{
			this.checkToken();
			let config = {
				headers: {
					'Authorization': 'Bearer ' + localStorage.token,
					'Content-Disposition': 'attachment'
				},
				responseType: 'blob'
			};

			this.$axios.get('https://api.clickandball.fr/designation/extraction/exclusion', config)
				.then(response => {
					if (response.status == 200 && response.data.error)
					{
						this.showAlertWarning('Erreur : ' + response.data.error);
					}
					else if (response.status == 200)
					{
						const url = window.URL.createObjectURL(new Blob([response.data]));
						const link = document.createElement('a');
						link.href = url;
						link.setAttribute('download', 'Exclusions.csv');
						document.body.appendChild(link);
						link.click();
					}
					else
					{
						this.showAlertWarning('Code erreur : ' + response.status);
					}
				})
				.catch(error => {
					this.showAlertWarning('Erreur : ' + error);
				});
			
		}
	}
}
</script>
