<template>

	<div class="RefereeAvailable">

		<h1 class="app-page-title"><img src="https://img.icons8.com/ios-glyphs/50/000000/basketball--v1.png" /> Arbitres disponibles</h1>

		<div class="row">
			<div class="col-lg-10 offset-lg-1 col-md-12">
				<div v-for="item in listWeek" :key="item.wording" :value="item.wording">
					<div class="accordion" role="tablist" style="margin-bottom: -0.5em">
						<div class="app-card app-card-notification shadow-sm mb-4">
							<div class="app-card-header px-4 py-3" style="background-color: rgba(0,0,0,.05) !important; border-bottom: 2px solid rgb(47 87 136)">
								<div class="row g-3 align-items-center">
									<div class="col-12 col-lg-auto text-lg-left">
										<h4 class="notification-title mb-1" id="title-legende">
											<span style="color: rgb(47 87 136);"> {{ item.wording }}</span>
										</h4>
									</div>
								</div>
							</div>
							<div visible id="freeReferee" accordion="my-accordion" role="tabpanel">
								<div class="app-card-body p-4">
									<div class="notification-content" style="margin-bottom: -1.5em; margin-top: -1em">
										<span>
											<strong>
												Liste des arbitres disponibles : <br />
											</strong>
										</span>
										<span v-for="ref in listReferee" :key="ref.nationalNumber">
											[{{ ref.nationalNumber }}]
										</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>


	</div>

</template>



<script>
	import jwt_decode from "jwt-decode";

	export default {
		name: 'RefereeAvailable',
		data() {
			return {
				// Variable message alert
				dismissSecs: 4,
				alertSuccessCountDown: 0,
				alertWarningCountDown: 0,
				info: '',
				error: '',

				listWeek: [],
				listReferee: [],
				week: '',
			}
		},
		mounted() {
			const monthNames = ["Janvier", "F\u00e9vrier", "Mars", "Avril", "Mai", "Juin", "Juillet", "Ao\u00fbt", "Septembre", "Octobre", "Novembre", "D\u00e9cembre"];
			const weekday = ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"];
			this.listWeek = [];
			var boucle = true;
			var i = 0;


			while (boucle) {
				let t = new Date(new Date().setDate(new Date().getDate() + 7 * i));
				var saturday;
				var sunday;

				if (t.getDay() == 0) {
					saturday = new Date(t.setDate(t.getDate() - t.getDay() - 1));
					sunday = new Date(t.setDate(t.getDate() + (7 - t.getDay())));
				}
				else {
					saturday = new Date(t.setDate(t.getDate() - t.getDay() + 6));
					sunday = new Date(t.setDate(t.getDate() + (7 - t.getDay())));
				}

				var week = {
					wording: weekday[saturday.getDay()] + " " + saturday.getDate() + " " + monthNames[saturday.getMonth()].toString() + " - " + weekday[sunday.getDay()] + " " + sunday.getDate() + " " + monthNames[sunday.getMonth()],
					start: saturday,
					end: sunday
				}

				this.listWeek.push(week);
				i++;

				if (t.getMonth() == 7)
					boucle = false;
			}
			this.checkToken();
			let config = {
				headers: {
					'Authorization': 'Bearer ' + localStorage.token
				}
			};
			this.$axios.get('https://api.clickandball.fr/utilisateur/arbitre', config)
				.then(response => {
					if (response.status == 200) {
						for (var i = 0; i < response.data.length; i++) {
							this.listReferee.push(response.data[i]);
						}
					}
					else {
						this.showAlertWarning("Code erreur : " + response.status);
					}
				})
				.catch(error => {
					this.showAlertWarning('Erreur : ' + error);
				});
		},
		methods:
		{
			// Méthodes message alert
			alertSuccessCountDownChanged(alertSuccessCountDown) {
				this.alertSuccessCountDown = alertSuccessCountDown
			},
			alertWarningCountDownChanged(alertWarningCountDown) {
				this.alertWarningCountDown = alertWarningCountDown
			},
			showAlertSuccess(info) {
				this.info = info;
				this.alertSuccessCountDown = this.dismissSecs
			},
			showAlertWarning(error) {
				this.error = error;
				this.alertWarningCountDown = this.dismissSecs
			},

			checkToken() {
				if (localStorage.token) {
					var jsonToken = jwt_decode(localStorage.token);
					if ((jsonToken.exp - 60) < Math.round(Date.now() / 1000)) {
						localStorage.token = null;
						this.connected = false;
						this.$router.push({ path: '/login' })
					}
				}
			},
		}
	}
</script>
