<template>
	<div class="QuestionnaireApresMatch">
		<h1 class="app-page-title"><img src="https://img.icons8.com/ios-glyphs/50/000000/basketball--v1.png" /> Gestion des questionnaires d'après match</h1>

		<b-modal ref="modalQuestion" hide-footer hide-header size="lg">
			<div class="app-card " style="margin-top: -1em; margin-left: -1em; margin-right: -1em">
				<a style="float: right; z-index: 9999; margin-right: 0.5em; top: 0" href="#" block @click="$refs['modalQuestion'].hide()">
					<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
						<path fill-rule="evenodd" d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z" />
						<path fill-rule="evenodd" d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z" />
					</svg>
				</a>
				<div class="app-card-header p-3 border-bottom-0">
					<div class="row align-items-center gx-3">
						<div class="col-auto">
							<div class="app-icon-holder" style="background-color: rgb(42, 87, 136)">
								<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-question-octagon" viewBox="0 0 16 16">
									<path d="M4.54.146A.5.5 0 0 1 4.893 0h6.214a.5.5 0 0 1 .353.146l4.394 4.394a.5.5 0 0 1 .146.353v6.214a.5.5 0 0 1-.146.353l-4.394 4.394a.5.5 0 0 1-.353.146H4.893a.5.5 0 0 1-.353-.146L.146 11.46A.5.5 0 0 1 0 11.107V4.893a.5.5 0 0 1 .146-.353L4.54.146zM5.1 1 1 5.1v5.8L5.1 15h5.8l4.1-4.1V5.1L10.9 1H5.1z"/>
									<path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
								</svg>
							</div>
						</div>
						<div class="col-auto">
							<h4 class="app-card-title" style="color: rgb(42, 87, 136) !important">
								<div v-if="modalAjouterQuestion">Ajouter une question</div>
								<div v-if="modalModifierQuestion">Modifier une question</div>
								<div v-if="modalVisualiserQuestion">Visualiser une question</div>
								<div v-if="modalSupprimerQuestion">Supprimer une question</div>
							</h4>
						</div>
					</div>
				</div>
				<div class="app-card-body px-4">
					Veuillez remplir les champs pour ajouter une question.

					<div class="">
						<b-form-textarea name="modalQuestion" v-model="modalQuestion" placeholder="Saisir la question..." :maxlength="500" rows="3" max-rows="3" style="max-height: 175px"></b-form-textarea>
						<div class="d-flex justify-content-end" style="margin-bottom: 0.25em; padding-top: 0.25em; font-style: oblique; font-size: 12px">
							<span v-text="(500 - modalQuestion.length)"></span><span style="margin-left: 0.2em">caractère(s) restant(s)</span>
						</div>
					</div>
					<div class="">
						<input type="checkbox" class="form-check-input" id="modalQuestionObligatoire" name="modalQuestionObligatoire" :state="modalQuestionObligatoire" v-model="modalQuestionObligatoire" style="margin: 0.4em;">
						<label for="modalQuestionObligatoire">Réponse obligatoire ?</label>
					</div>

					Choisir le type de question
					<div class="">
						<small>
							<ul style="list-style: none; display: block; padding: 0;">
								<li style="float: left; display: block; margin-right: 0.75em; text-align: center;">
									<input type="radio" class="form-check-input" v-model="modalQuestionType" :value='1' id="radio1" style="margin: 0.4em" /><label class="form-check-label" for="radio1">Type 1: Texte </label>
								</li>
								<li style="float: left; display: block; margin-right: 0.75em; text-align: center;">
									<input type="radio" class="form-check-input" v-model="modalQuestionType" :value='2' id="radio2" style="margin: 0.4em" /><label class="form-check-label" for="radio2">Type 2: Choix multiple</label>
								</li>
								<li style="float: left; display: block; margin-right: 0.75em; text-align: center;">
									<input type="radio" class="form-check-input" v-model="modalQuestionType" :value='3' id="radio3" style="margin: 0.4em" /><label class="form-check-label" for="radio3">Type 3: Date</label>
								</li>
							</ul>
						</small>
					</div>

					<br>
					<div v-if="modalQuestionType == 2">
						Ajout des réponses possible
						<div class="">
							<b-form-textarea name="modalChoixMultiple" v-model="modalChoixMultiple" placeholder="Saisir la question..." :maxlength="500" rows="3" max-rows="3" style="max-height: 175px"></b-form-textarea>
							<div class="d-flex justify-content-end" style="margin-bottom: 0.25em; padding-top: 0.25em; font-style: oblique; font-size: 12px">
								<span v-text="(500 - modalQuestion.length)"></span><span style="margin-left: 0.2em">caractère(s) restant(s)</span>
							</div>
							<b-button v-if="modalAjouterQuestion" class="btn app-btn-primary" style="background: rgb(42, 87, 136);color: white;" v-on:click="AjoutModalChoixMultiple()">Ajouter</b-button>

							<!-- <ul v-for="item in modalListeChoixMultiple" style="list-style: none; display: block; padding: 0;">
								<li style="float: left; display: block; margin-right: 0.75em; text-align: center;">
									{{ item }}
								</li>
							</ul> -->
						</div>
					</div>

					<br>
					Choisir les arbitre concerné par la question
					<div class="">
						<input type="checkbox" class="form-check-input" id="modalQuestionPourTousLesArbitres" name="modalQuestionPourTousLesArbitres" :state="modalQuestionPourTousLesArbitres" v-model="modalQuestionPourTousLesArbitres" style="margin: 0.4em;">
						<label for="modalQuestionPourTousLesArbitres">Pour tous les arbitres</label>
					</div>

					<div v-if="!modalQuestionPourTousLesArbitres" class="form-group">
						<label for="divisionId" style="font-weight: bold;" class="form-label">Division :</label>
						<select :state="true" class="form-select form-select-sm ml-auto d-inline-flex text-center" v-model="divisionId">
							<option disabled value="">Choisissez une division</option>
							<option v-for="item in listdivisionId" :key="item.id" :value="item.id">
								{{ item.name }}
							</option>
						</select>
					</div>

					<div class="">
						<input type="checkbox" class="form-check-input" id="modalQuestionTuteur" name="modalQuestionTuteur" :state="modalQuestionTuteur" v-model="modalQuestionTuteur" style="margin: 0.4em;">
						<label for="modalQuestionTuteur">Arbitre tuteur</label>
					</div>
					<div class="">
						<input type="checkbox" class="form-check-input" id="modalQuestionTutore" name="modalQuestionTutore" :state="modalQuestionTutore" v-model="modalQuestionTutore" style="margin: 0.4em;">
						<label for="modalQuestionTutore">Arbitre tutoré</label>
					</div>

					<div class="text-center">
						<hr>
						<b-button v-if="modalAjouterQuestion" class="btn app-btn-primary" style="background: rgb(42, 87, 136);color: white;" v-on:click="AjoutQuestion()">Enregistrer</b-button>
						<b-button v-if="modalModifierQuestion" class="btn app-btn-primary" style="background: rgb(42, 87, 136);color: white;" v-on:click="AjoutQuestion()">Mettre à jour</b-button>
						<b-button v-if="modalSupprimerQuestion" class="btn app-btn-primary" style="background: rgb(42, 87, 136);color: white;" v-on:click="AjoutQuestion()">Valider</b-button>
					</div>
				</div>
			</div>
		</b-modal>

		<b-alert :show="alertSuccessCountDown" variant="success" @dismissed="alertSuccessCountDown=0" @dismiss-count-down="alertSuccessCountDownChanged">
			<p>{{ info }}</p>
			<b-progress variant="success" :max="dismissSecs" :value="alertSuccessCountDown" height="4px"></b-progress>
		</b-alert>
		<b-alert :show="alertWarningCountDown" variant="warning" @dismissed="alertWarningCountDown=0" @dismiss-count-down="alertWarningCountDownChanged">
			<p>{{ error }}</p>
			<b-progress variant="warning" :max="dismissSecs" :value="alertWarningCountDown" height="4px"></b-progress>
		</b-alert>

		<div class="accordion24" role="tablist">
			<div class="app-card shadow-sm mb-4 border-left-decoration">
				<div class="inner">
					<div block v-b-toggle.actions id="title-legende" type="button" data-toggle="" class="card-header align-middle" style="background-color: rgba(0,0,0,.05) !important; border-bottom: 2px solid rgb(47 87 136)">
						<h5 class="title align-middle" style="color: rgb(47 87 136)">
							<i class="fas fa-search-plus"></i>
							Actions
						</h5>
					</div>
					<b-collapse visible id="actions" accordion="my-accordion24" role="tabpanel" class="app-card-body p-4">
						<div class="row g-2 justify-content-center justify-content-md-end align-middle">
							<div class="col-12 col-sm-12 col-lg-10" style=" margin-bottom: -1em">
								<b-button class="btn app-btn-primary" style="background: rgb(42, 87, 136);color: white; width: 200px; margin-bottom: 1.5em" v-on:click="AjoutQuestionModal()">
									Ajouter une question
								</b-button>
							</div>
						</div>
					</b-collapse>
				</div>
			</div>
		</div>

		<div class="tab-content" id="orders-table-tab-content">
			<div class="tab-pane fade show active" id="orders-all" role="tabpanel" aria-labelledby="orders-all-tab">
				<div class="app-card app-card-orders-table shadow-sm mb-5">
					<div class="app-card-body">
						<div class="table-responsive table-stiped">
							<table id="myTable2" class="table table-striped table-hover mb-0 text-center">
								<thead>
									<tr>
										<th class="mb-2 text-primary" v-on:click="sortTable(0)">
											ID <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-down-up" viewBox="0 0 16 16">
												<path fill-rule="evenodd" d="M11.5 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L11 2.707V14.5a.5.5 0 0 0 .5.5zm-7-14a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L4 13.293V1.5a.5.5 0 0 1 .5-.5z" />
											</svg>
										</th>
										<th class="mb-2 text-primary" v-on:click="sortTable(1)">
											Question <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-down-up" viewBox="0 0 16 16">
												<path fill-rule="evenodd" d="M11.5 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L11 2.707V14.5a.5.5 0 0 0 .5.5zm-7-14a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L4 13.293V1.5a.5.5 0 0 1 .5-.5z" />
											</svg>
										</th>
										<th class="mb-2 text-primary" v-on:click="sortTable(2)">
											Type réponse <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-down-up" viewBox="0 0 16 16">
												<path fill-rule="evenodd" d="M11.5 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L11 2.707V14.5a.5.5 0 0 0 .5.5zm-7-14a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L4 13.293V1.5a.5.5 0 0 1 .5-.5z" />
											</svg>
										</th>
										<th class="mb-2 text-primary" v-on:click="sortTable(2)">
											Arbitre concerné <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-down-up" viewBox="0 0 16 16">
												<path fill-rule="evenodd" d="M11.5 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L11 2.707V14.5a.5.5 0 0 0 .5.5zm-7-14a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L4 13.293V1.5a.5.5 0 0 1 .5-.5z" />
											</svg>
										</th>
										<th class="mb-2 text-primary">Actions</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="item in listeQuestion" :key="item.id">
										<td class="cell">{{ item.id }}</td>
										<td class="cell">{{ item.question }}</td>
										<td class="cell" v-if="item.typeReponse == 1">
											<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-body-text" viewBox="0 0 16 16">
												<path fill-rule="evenodd" d="M0 .5A.5.5 0 0 1 .5 0h4a.5.5 0 0 1 0 1h-4A.5.5 0 0 1 0 .5Zm0 2A.5.5 0 0 1 .5 2h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5Zm9 0a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5Zm-9 2A.5.5 0 0 1 .5 4h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5Zm5 0a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5Zm7 0a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5Zm-12 2A.5.5 0 0 1 .5 6h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5Zm8 0a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5Zm-8 2A.5.5 0 0 1 .5 8h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5Zm7 0a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5Zm-7 2a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 0 1h-8a.5.5 0 0 1-.5-.5Zm0 2a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5Zm0 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5Z"/>
											</svg>
											Texte
										</td>
										<td class="cell" v-if="item.typeReponse == 2">
											<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2-square" viewBox="0 0 16 16">
												<path d="M3 14.5A1.5 1.5 0 0 1 1.5 13V3A1.5 1.5 0 0 1 3 1.5h8a.5.5 0 0 1 0 1H3a.5.5 0 0 0-.5.5v10a.5.5 0 0 0 .5.5h10a.5.5 0 0 0 .5-.5V8a.5.5 0 0 1 1 0v5a1.5 1.5 0 0 1-1.5 1.5H3z"/>
												<path d="m8.354 10.354 7-7a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0z"/>
											</svg>
											Choix multiple
										</td>
										<td class="cell" v-if="item.typeReponse == 3">
											<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calendar-date" viewBox="0 0 16 16">
												<path d="M6.445 11.688V6.354h-.633A12.6 12.6 0 0 0 4.5 7.16v.695c.375-.257.969-.62 1.258-.777h.012v4.61h.675zm1.188-1.305c.047.64.594 1.406 1.703 1.406 1.258 0 2-1.066 2-2.871 0-1.934-.781-2.668-1.953-2.668-.926 0-1.797.672-1.797 1.809 0 1.16.824 1.77 1.676 1.77.746 0 1.23-.376 1.383-.79h.027c-.004 1.316-.461 2.164-1.305 2.164-.664 0-1.008-.45-1.05-.82h-.684zm2.953-2.317c0 .696-.559 1.18-1.184 1.18-.601 0-1.144-.383-1.144-1.2 0-.823.582-1.21 1.168-1.21.633 0 1.16.398 1.16 1.23z"/>
												<path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z"/>
											</svg>
											Date
										</td>
										<td class="cell" v-if="item.toutLeMonde">Tous les arbitres</td>
										<td class="cell" v-else>{{ item.division}}</td>
										<td class="cell">
											<b-button class="btn app-btn-secondary" style="margin: 3px" v-on:click="updateClubModal(item)">
												<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil" viewBox="0 0 16 16">
													<path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
												</svg>
											</b-button>
											<b-button class="btn app-btn-secondary" style="margin: 3px" v-on:click="deleteClubModal(item)">
												<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
													<path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
													<path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
												</svg>
											</b-button>
										</td>
									</tr>
								</tbody>
								<tbody v-if="aucunResultat">
									<tr>
										<td colspan="5">Aucun résultat</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

</template>



<script>
// import jwt_decode from "jwt-decode";
// import Multiselect from 'vue-multiselect'

export default {
	name: 'Club',
	// components: {
	// 	Multiselect
	// },
	// computed:
	// {

	// },
	data() {
	// 	return {
	// 		// Variable message alert
	// 		dismissSecs:4,
	// 		alertSuccessCountDown:0,
	// 		alertWarningCountDown:0,
	// 		info:'',
	// 		error: '',
			
	// 		listeQuestion: [],
	// 		aucunResultat: true,
	// 		questionSelectionne: {},

	// 		//Modal division liste déroulante
	// 		listdivisionId: [],
	// 		divisionId: "",

	// 		// Affichage type modal
	// 		modalAjouterQuestion: false,
	// 		modalModifierQuestion: false,
	// 		modalVisualiserQuestion: false,
	// 		modalSupprimerQuestion: false,
	// 		// Modal variable
	// 		modalQuestion: "",
	// 		modalQuestionObligatoire: true,
	// 		modalQuestionType:1,
	// 		modalChoixMultiple:"",
	// 		modalListeChoixMultiple:[],
	// 		modalQuestionPourTousLesArbitres:true,
	// 		modalQuestionTuteur:false,
	// 		modalQuestionTutore:false,
	// 	}
	// },

	// mounted()
	// {
	// 	this.chargementDeLaPage();
	// },


	// methods: {
	// 	// Méthodes message alert
	// 	alertSuccessCountDownChanged(alertSuccessCountDown)
	// 	{
	// 		this.alertSuccessCountDown = alertSuccessCountDown
	// 	},
	// 	alertWarningCountDownChanged(alertWarningCountDown)
	// 	{
	// 		this.alertWarningCountDown = alertWarningCountDown
	// 	},
	// 	showAlertSuccess(info)
	// 	{
	// 		this.info = info;
	// 		this.alertSuccessCountDown = this.dismissSecs
	// 	},
	// 	showAlertWarning(error)
	// 	{
	// 		this.error = error;
	// 		this.alertWarningCountDown = this.dismissSecs
	// 	},
	// 	// Vérification de la validité du token
	// 	checkToken()
	// 	{
	// 		if (localStorage.token)
	// 		{
	// 			var jsonToken = jwt_decode(localStorage.token);
	// 			if ((jsonToken.exp - 60) < Math.round(Date.now()/1000))
	// 			{
	// 				localStorage.token = null;
	// 				this.connected = false;
	// 				this.$router.push({ path: '/login'})
	// 			}
	// 		}
	// 	},

	// 	chargementDeLaPage() {
	// 		this.checkToken();
	// 		let config = {
	// 			headers: {
	// 				'Authorization': 'Bearer ' + localStorage.token
	// 			}
	// 		};

	// 		this.$axios.get('https://api.clickandball.fr/apresmatch/admin/question', config)
	// 			.then(response => {
	// 				if (response.status == 200 && response.data.error) {
	// 					this.showAlertWarning('Erreur : ' + response.data.error);
	// 				}
	// 				else if (response.status == 200) {
	// 					this.listeQuestion = response.data;

	// 					if (this.listeQuestion.length === 0) {
	// 						this.aucunResultat = true;
	// 					}
	// 					else
	// 					{
	// 						this.aucunResultat = false;
	// 					}
	// 				}
	// 				else {
	// 					this.showAlertWarning('Code erreur : ' + response.status);
	// 				}
	// 			})
	// 	},
	// 	AjoutModalChoixMultiple() {
	// 		this.modalListeChoixMultiple.push(this.modalChoixMultiple);
	// 		this.modalChoixMultiple = "";
	// 	},
	// 	sortTable(n) {
	// 	},
	// 	AjoutQuestionModal() {
	// 		this.checkToken();

	// 		// Récupération des divisions
	// 		if (this.listdivisionId.length > 0)
	// 		{
	// 			let config = {
	// 				headers: {
	// 					'Authorization': 'Bearer ' + localStorage.token
	// 				}
	// 			};

	// 			this.$axios.get('https://api.clickandball.fr/division', config)
	// 				.then(response => {
	// 					if (response.status == 200 && response.data.error)
	// 					{
	// 						this.showAlertWarning('Erreur : ' + response.data.error);
	// 					}
	// 					else if (response.status == 200)
	// 					{
	// 						this.listdivisionId = response.data;
	// 					}
	// 					else
	// 					{
	// 						this.showAlertWarning('Code erreur : ' + response.status);
	// 					}
	// 				})
	// 		}
			
	// 		// Reset valeur de la modal
	// 		this.modalQuestion = "";
	// 		this.modalQuestionObligatoire = true;
	// 		this.modalQuestionType = 1;
	// 		this.modalChoixMultiple = "";
	// 		this.modalListeChoixMultiple = [];
	// 		this.modalQuestionPourTousLesArbitres = true;
	// 		this.modalQuestionTuteur = false;
	// 		this.modalQuestionTutore = false;

	// 		// Affichage de la modal
	// 		this.modalAjouterQuestion = true;
	// 		this.$refs['modalQuestion'].show();        
	// 	},
	// 	AjoutQuestion() {
	// 		this.checkToken();
	// 		this.modalAjouterQuestion = false;
	// 		this.$refs['modalQuestion'].hide();
	// 	}	
	}
}
</script>
