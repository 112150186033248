import Vue from 'vue'
import App from './App.vue'
import router from './router'

import VueResource from 'vue-resource'
Vue.use(VueResource)

import axios from 'axios'
Vue.prototype.$axios = axios

import VModal from 'vue-js-modal'
Vue.use(VModal, {
	componentName: 'modal'
})

import BootstrapVue from 'bootstrap-vue/dist/bootstrap-vue.esm'
import IconsPlugin from 'bootstrap-vue'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'bootstrap/dist/css/bootstrap.css'
import 'vue-multiselect/dist/vue-multiselect.min.css'

Vue.use(BootstrapVue);
Vue.use(IconsPlugin)

import AlertPlugin from 'bootstrap-vue'
Vue.use(AlertPlugin)

import 'vue-search-select/dist/VueSearchSelect.css'

import '@/assets/plugins/fontawesome/js/all.min.js'
import '@/assets/css/portal.css'

import '@/assets/plugins/popper.min.js'
import '@/assets/plugins/bootstrap/js/bootstrap.min.js'

import 'vue-multiselect/dist/vue-multiselect.min.css'



import DataTable from 'v-data-table'
Vue.use(DataTable)

Vue.config.productionTip = false

new Vue({
	router,
	render: h => h(App)
}).$mount('#app')
