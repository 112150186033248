<template>
  <div id="app">
    <component :is="this.$route.meta.layout || 'div'">
      <router-view />
    </component>
  </div>
</template>

<script>
//import Footer from '@/assets/footer.vue'

export default {
	name: 'App',
	components: {
//		'app-footer' : Footer
	}
}
</script>
