<script>
    import { Bar } from 'vue-chartjs';
    import jwt_decode from "jwt-decode";

  export default {
    extends: Bar,
    data() {
      return {
          listReferee: [],
          refereelabel: [],
        chartData: {
          labels: this.refereelabel,
          datasets: [{
            label: 'Bar Chart',
            borderWidth: 1,
          }]
        },
        options: {
          scales: {
            yAxes: [{
              ticks: {
                beginAtZero: true
              },
              gridLines: {
                display: true
              }
            }],
            xAxes: [{
              gridLines: {
                display: false
              }
            }]
          },
          legend: {
            display: true
          },
          responsive: true,
          maintainAspectRatio: false
        }
      }
    },
    mounted() {
        
        this.checkToken();


        this.renderChart(this.chartData, this.options);
    },
    methods: {

        checkToken()
        {
            if (localStorage.token) {
                var jsonToken = jwt_decode(localStorage.token);
                if ((jsonToken.exp - 60) < Math.round(Date.now() / 1000)) {
                    localStorage.token = null;
                    this.connected = false;
                    this.$router.push({ path: '/login' })
                }
            }
        }

    }
  }
</script>