<template>
	<div class="Autre">
		<h1 class="app-page-title"><img src="https://img.icons8.com/ios-glyphs/50/000000/basketball--v1.png"/> Documents de l'arbitrage</h1>

		<div class="app-card shadow-sm mb-4 border-left-decoration">
			<div class="inner">
				<div class="card-header align-middle">
					<h5 class="title align-middle"><img src="https://img.icons8.com/ios/64/000000/basketball-jersey.png" style="height: 30px;" /> Préambule</h5>
				</div>
				<div class="app-card-body p-4">
					<div class="row g-2 justify-content-center justify-content-md-end">
						<div class="col-12 col-sm-12 col-lg-10">
							<p>
								Ici vous trouverez tous les documents, formulaires et liens nécessaires à votre fonction d'arbitre.
							</p>
						</div>
						<div class="col-12 col-lg-2 align-items-right" style="text-align: left; vertical-align: middle;">
						</div><!--//col-->
					</div><!--//row-->
				</div><!--//app-card-body-->
			</div><!--//inner-->
		</div>

		<div class="row">
			<div class="col-12 col-sm-12 col-md-6 col-lg-3">
				<h3><img src="https://img.icons8.com/ios-glyphs/50/000000/basketball-net.png"/> Dossier administratif</h3>
			</div>
			<div class="col-12 col-sm-12 col-md-6 col-lg-3">
				<h3><img src="https://img.icons8.com/ios-glyphs/50/000000/basketball-net.png"/> Dossier médical</h3>
			</div>
			<div class="col-12 col-sm-12 col-md-6 col-lg-3">
				<h3><img src="https://img.icons8.com/ios-glyphs/50/000000/basketball-net.png"/> Guide de l'arbitre</h3>
			</div>
			<div class="col-12 col-sm-12 col-md-6 col-lg-3">
				<h3><img src="https://img.icons8.com/ios-glyphs/50/000000/basketball-net.png"/> Guide E-Marque</h3>
			</div>
		</div>
		<div class="row">
			<div class="col-12 col-sm-12 col-md-6 col-lg-4">
				<h3><img src="https://img.icons8.com/ios-glyphs/50/000000/basketball-net.png"/> Examen Arbitre Départemental</h3>
			</div>
			<div class="col-12 col-sm-12 col-md-6 col-lg-4">
				<h3><img src="https://img.icons8.com/ios-glyphs/50/000000/basketball-net.png"/> Consigne et réglements</h3>
			</div>
			<div class="col-12 col-sm-12 col-md-12 col-lg-4">
				<h3><img src="https://img.icons8.com/ios-glyphs/50/000000/basketball-net.png"/> Procédures et formulaires</h3>
			</div>
		</div>

	</div>

</template>

<script>
export default {
	name: 'Autre'
}
</script>
