<template>
	<div class="Exclusion">
		<h1 class="app-page-title"><img src="https://img.icons8.com/ios-glyphs/50/000000/basketball--v1.png" /> Gestion des salles de sport</h1>

		<b-modal ref="Sporthall" hide-footer hide-header size="lg">
			<div class="app-card " style="margin-top: -1em; margin-left: -1em; margin-right: -1em">
				<a style="float: right; z-index: 9999; margin-right: 0.5em; top: 0" href="#" block @click="$refs['Sporthall'].hide()">
					<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
						<path fill-rule="evenodd" d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z" />
						<path fill-rule="evenodd" d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z" />
					</svg>
				</a>
				<div class="app-card-header p-3 border-bottom-0">
					<div class="row align-items-center gx-3">
						<div class="col-auto">
							<div class="app-icon-holder" style="background-color: rgb(42, 87, 136)">
								<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-shield-check" fill="white" xmlns="http://www.w3.org/2000/svg">
									<path fill-rule="evenodd" d="M8 0C3.584 0 0 3.584 0 8s3.584 8 8 8c4.408 0 8-3.584 8-8s-3.592-8-8-8zm5.284 3.688a6.802 6.802 0 0 1 1.545 4.251c-.226-.043-2.482-.503-4.755-.217-.052-.112-.096-.234-.148-.355-.139-.33-.295-.668-.451-.99 2.516-1.023 3.662-2.498 3.81-2.69zM8 1.18c1.735 0 3.323.65 4.53 1.718-.122.174-1.155 1.553-3.584 2.464-1.12-2.056-2.36-3.74-2.551-4A6.95 6.95 0 0 1 8 1.18zm-2.907.642A43.123 43.123 0 0 1 7.627 5.77c-3.193.85-6.013.833-6.317.833a6.865 6.865 0 0 1 3.783-4.78zM1.163 8.01V7.8c.295.01 3.61.053 7.02-.971.199.381.381.772.555 1.162l-.27.078c-3.522 1.137-5.396 4.243-5.553 4.504a6.817 6.817 0 0 1-1.752-4.564zM8 14.837a6.785 6.785 0 0 1-4.19-1.44c.12-.252 1.509-2.924 5.361-4.269.018-.009.026-.009.044-.017a28.246 28.246 0 0 1 1.457 5.18A6.722 6.722 0 0 1 8 14.837zm3.81-1.171c-.07-.417-.435-2.412-1.328-4.868 2.143-.338 4.017.217 4.251.295a6.774 6.774 0 0 1-2.924 4.573z" />
								</svg>
							</div>
						</div>
						<div class="col-auto">
							<h4 class="app-card-title" style="color: rgb(42, 87, 136) !important">
								<div v-if="modalUpdateSporthall">Modifier une salle</div>
								<div v-else>Ajouter une salle</div>
							</h4>
						</div>
					</div>
				</div>
				<div class="app-card-body px-4">
					<ul class="list-unstyled">
						<div class="row">
							<div class="col-md-5 offset-md-1 pr-1">
								<div class="form-group">
									<svg width="1em" height="1em" style="margin-right: 0.25em" viewBox="0 0 16 16" class="bi bi-check2 text-primary mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
										<path fill-rule="evenodd" d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
									</svg>
									<label class="form-label" style="font-weight:bold;">Identifiant : </label>
									<b-form-input :disabled="modalUpdateSporthall" class="form-select-sm ml-auto d-inline-flex text-center" style="height: 30px; border: 1px solid #ced4da; border-radius: .25rem;" type="number" min="0" v-model="id"></b-form-input>
								</div>
							</div>
							<div class="col-md-5 pr-1">
								<div class="form-group">
									<svg width="1em" height="1em" style="margin-right: 0.25em" viewBox="0 0 16 16" class="bi bi-check2 text-primary mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
										<path fill-rule="evenodd" d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
									</svg>
									<label class="form-label" style="font-weight:bold;">Nom : </label>
									<b-form-input class="form-select-sm ml-auto d-inline-flex text-center" style="height: 30px; border: 1px solid #ced4da; border-radius: .25rem;" type="text" v-model="name"></b-form-input>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-md-10 offset-md-1 pr-1">
								<div class="form-group">
									<svg width="1em" height="1em" style="margin-right: 0.25em" viewBox="0 0 16 16" class="bi bi-check2 text-primary mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
										<path fill-rule="evenodd" d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
									</svg>
									<label class="form-label" style="font-weight:bold;">Adresse : </label>
									<b-form-input v-on:keyup="searchAddressAPIEtalab()" v-on:keydown="searchAddressAPIEtalab()" placeholder="Saisissez une adresse..." class="form-select-sm ml-auto d-inline-flex text-center" style="height: 30px; border: 1px solid #ced4da; border-radius: .25rem;" v-model="address"></b-form-input>
									<div v-if="listSuggestedAddress.length > 0">
										<ul>
											<li v-for="item in listSuggestedAddress" :key="item">
												<span @click="selectAddress(item)">{{ item }}</span>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
						<div class="row" v-if="modalUpdateSporthall">
							<div class="col-md-8 offset-md-2 pr-1">
								<div class="form-group">
									<svg width="1em" height="1em" style="margin-right: 0.25em" viewBox="0 0 16 16" class="bi bi-check2 text-primary mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
										<path fill-rule="evenodd" d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
									</svg>
									<label class="form-label" style="font-weight:bold;">Ville : </label>
									<b-form-input :disabled="modalUpdateSporthall" class="form-select-sm ml-auto d-inline-flex text-center" style="height: 30px; border: 1px solid #ced4da; border-radius: .25rem;" v-model="city"></b-form-input>

								</div>
							</div>
						</div>
						<div class="row" v-if="modalUpdateSporthall">
							<div class="col-md-5 offset-md-1 pr-1">
								<div class="form-group">
									<svg width="1em" height="1em" style="margin-right: 0.25em" viewBox="0 0 16 16" class="bi bi-check2 text-primary mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
										<path fill-rule="evenodd" d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
									</svg>
									<label class="form-label" style="font-weight:bold;">Latitude : </label>
									<b-form-input :disabled="modalUpdateSporthall" class="form-select-sm ml-auto d-inline-flex text-center" style="height: 30px; border: 1px solid #ced4da; border-radius: .25rem;" type="number" min="0" v-model="latitude"></b-form-input>
								</div>
							</div>
							<div class="col-md-5 pr-1">
								<div class="form-group">
									<svg width="1em" height="1em" style="margin-right: 0.25em" viewBox="0 0 16 16" class="bi bi-check2 text-primary mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
										<path fill-rule="evenodd" d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
									</svg>
									<label class="form-label" style="font-weight:bold;">Longitude : </label>
									<b-form-input :disabled="modalUpdateSporthall" class="form-select-sm ml-auto d-inline-flex text-center" style="height: 30px; border: 1px solid #ced4da; border-radius: .25rem;" type="number" min="0" v-model="longitude"></b-form-input>
								</div>
							</div>
						</div>

					</ul>
					<div class="text-center">
						<hr>
						<b-button v-if="modalUpdateSporthall" class="btn app-btn-primary" style="background: rgb(42, 87, 136);color: white;" v-on:click="updateSporthall()">Mettre à jour</b-button>
						<b-button v-else class="btn app-btn-primary" style="background: rgb(42, 87, 136);color: white;" v-on:click="addSporthall()">Enregistrer</b-button>
					</div>
				</div>
			</div>
		</b-modal>

		<b-modal ref="modalDeleteSportHall" hide-footer hide-header>
			<div class="app-card " style="margin-top: -1em; margin-left: -1em; margin-right: -1em">
				<a style="float: right; z-index: 9999; margin-right: 0.5em; top: 0" href="#" block @click="$refs['modalDeleteSportHall'].hide()">
					<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
						<path fill-rule="evenodd" d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z" />
						<path fill-rule="evenodd" d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z" />
					</svg>
				</a>
				<div class="app-card-header p-3 border-bottom-0">
					<div class="row align-items-center gx-3">
						<div class="col-auto">
							<div class="app-icon-holder" style="background-color: rgb(42, 87, 136)">
								<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-shield-check" fill="white" xmlns="http://www.w3.org/2000/svg">
									<path fill-rule="evenodd" d="M8 0C3.584 0 0 3.584 0 8s3.584 8 8 8c4.408 0 8-3.584 8-8s-3.592-8-8-8zm5.284 3.688a6.802 6.802 0 0 1 1.545 4.251c-.226-.043-2.482-.503-4.755-.217-.052-.112-.096-.234-.148-.355-.139-.33-.295-.668-.451-.99 2.516-1.023 3.662-2.498 3.81-2.69zM8 1.18c1.735 0 3.323.65 4.53 1.718-.122.174-1.155 1.553-3.584 2.464-1.12-2.056-2.36-3.74-2.551-4A6.95 6.95 0 0 1 8 1.18zm-2.907.642A43.123 43.123 0 0 1 7.627 5.77c-3.193.85-6.013.833-6.317.833a6.865 6.865 0 0 1 3.783-4.78zM1.163 8.01V7.8c.295.01 3.61.053 7.02-.971.199.381.381.772.555 1.162l-.27.078c-3.522 1.137-5.396 4.243-5.553 4.504a6.817 6.817 0 0 1-1.752-4.564zM8 14.837a6.785 6.785 0 0 1-4.19-1.44c.12-.252 1.509-2.924 5.361-4.269.018-.009.026-.009.044-.017a28.246 28.246 0 0 1 1.457 5.18A6.722 6.722 0 0 1 8 14.837zm3.81-1.171c-.07-.417-.435-2.412-1.328-4.868 2.143-.338 4.017.217 4.251.295a6.774 6.774 0 0 1-2.924 4.573z" />
								</svg>
							</div>
						</div>
						<div class="col-auto">
							<h4 class="app-card-title" style="color: rgb(42, 87, 136) !important">
								Supprimer une salle
							</h4>
						</div>
					</div>
				</div>
				<div class="app-card-body px-4">
					<small>
						<label>
							<svg width="1em" height="1em" style="margin-right: 0.25em" viewBox="0 0 16 16" class="bi bi-check2 text-primary mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
								<path fill-rule="evenodd" d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
							</svg>
							<span style="font-weight:bold;">Numéro de salle : </span>{{ sporthallSelected.id }}
						</label>
						<br>
						<label>
							<svg width="1em" height="1em" style="margin-right: 0.25em" viewBox="0 0 16 16" class="bi bi-check2 text-primary mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
								<path fill-rule="evenodd" d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
							</svg>
							<span style="font-weight:bold;">Nom : </span>{{ sporthallSelected.name }}
						</label>
						<hr>
						<label>
							<svg width="1em" height="1em" style="margin-right: 0.25em" viewBox="0 0 16 16" class="bi bi-check2 text-primary mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
								<path fill-rule="evenodd" d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
							</svg>
							<span style="font-weight:bold;">Adresse : </span>{{ sporthallSelected.address }}
						</label><br>
						<label>
							<svg width="1em" height="1em" style="margin-right: 0.25em" viewBox="0 0 16 16" class="bi bi-check2 text-primary mr-2" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
								<path fill-rule="evenodd" d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
							</svg>
							<span style="font-weight:bold;">Ville : </span>{{ sporthallSelected.city }}
						</label><br>
					</small>

				</div>
				<hr />
				<div class="text-center">
					<b-button class="btn app-btn-primary" style="background: rgb(42, 87, 136);color: white;" v-on:click="deleteSportHall()">Confirmer la suppression</b-button>
				</div>
			</div>
		</b-modal>

		<b-alert :show="alertSuccessCountDown" variant="success" @dismissed="alertSuccessCountDown=0" @dismiss-count-down="alertSuccessCountDownChanged">
			<p>{{ info }}</p>
			<b-progress variant="success" :max="dismissSecs" :value="alertSuccessCountDown" height="4px"></b-progress>
		</b-alert>
		<b-alert :show="alertWarningCountDown" variant="warning" @dismissed="alertWarningCountDown=0" @dismiss-count-down="alertWarningCountDownChanged">
			<p>{{ error }}</p>
			<b-progress variant="warning" :max="dismissSecs" :value="alertWarningCountDown" height="4px"></b-progress>
		</b-alert>

		<div class="accordion24" role="tablist">
			<div class="app-card shadow-sm mb-4 border-left-decoration">
				<div class="inner">
					<div block v-b-toggle.actions id="title-legende" type="button" data-toggle="" class="card-header align-middle" style="background-color: rgba(0,0,0,.05) !important; border-bottom: 2px solid rgb(47 87 136)">
						<h5 class="title align-middle" style="color: rgb(47 87 136)">
							<i class="fas fa-search-plus"></i>
							Actions
						</h5>
					</div>
					<b-collapse visible id="actions" accordion="my-accordion24" role="tabpanel" class="app-card-body p-4">
						<div class="row g-2 justify-content-center justify-content-md-end align-middle">
							<div class="col-12 col-sm-12 col-lg-10" style=" margin-bottom: -1em">
								<b-button class="btn app-btn-primary" style="background: rgb(42, 87, 136);color: white; width: 200px; margin-bottom: 1.5em" v-on:click="addSporthallModal()">
									Ajouter une salle
								</b-button>
							</div>
							<div class="col-12 col-lg-2 align-center align-items-middle" style="text-align: center;">
								<a class="btn app-btn-secondary" style="color: #fa7507; border: 1px solid #fa7507; border-radius: .25rem;" v-on:click="downloadCSV()">
									<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-download mr-1" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
										<path fill-rule="evenodd" d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
										<path fill-rule="evenodd" d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
									</svg>
									Extraction CSV
								</a>
							</div><!--//col-->
						</div><!--//row-->
					</b-collapse><!--//app-card-body-->
				</div><!--//inner-->
			</div>
		</div>

		<div class="tab-content" id="orders-table-tab-content">
			<form class="app-search-form">
				<input name="search" type="text" id="myInput" @keyup="myFunction()" placeholder="Recherchez un nom, un prénom, un club" title="Recherche textuelle" class="form-control search-input" style="height: 50px; margin-bottom: 1em">
				<button disabled class="btn search-btn btn-primary" value="Search" style="padding-top: 1em">
					<svg class="svg-inline--fa fa-search fa-w-16" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="search" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
						<path fill="currentColor" d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"></path>
					</svg><!-- <i class="fas fa-search"></i> -->
				</button>
			</form>
			<div class="tab-pane fade show active" id="orders-all" role="tabpanel" aria-labelledby="orders-all-tab">
				<div class="app-card app-card-orders-table shadow-sm mb-5">
					<div class="app-card-body">
						<div class="table-responsive table-stiped">
							<table id="myTable2" class="table table-striped table-hover mb-0 text-center">
								<thead>
									<tr>
										<th class="mb-2 text-primary" v-on:click="sortTable(0)">
											ID
											<span v-if="etatTriColumn == 1 && etatTriTableau == true">
												<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-down-short" viewBox="0 0 16 16">
													<path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L7.5 10.293V4.5A.5.5 0 0 1 8 4z" />
												</svg>
											</span>
											<span v-if="etatTriColumn == 1 && etatTriTableau == false">
												<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-up-short" viewBox="0 0 16 16">
													<path fill-rule="evenodd" d="M8 12a.5.5 0 0 0 .5-.5V5.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 5.707V11.5a.5.5 0 0 0 .5.5z" />
												</svg>
											</span>
										</th>
										<th class="mb-2 text-primary" v-on:click="sortTable(1)">
											Nom
											<span v-if="etatTriColumn == 2 && etatTriTableau == true">
												<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-down-short" viewBox="0 0 16 16">
													<path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L7.5 10.293V4.5A.5.5 0 0 1 8 4z" />
												</svg>
											</span>
											<span v-if="etatTriColumn == 2 && etatTriTableau == false">
												<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-up-short" viewBox="0 0 16 16">
													<path fill-rule="evenodd" d="M8 12a.5.5 0 0 0 .5-.5V5.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 5.707V11.5a.5.5 0 0 0 .5.5z" />
												</svg>
											</span>
										</th>
										<th class="mb-2 text-primary">Adresse</th>
										<th class="mb-2 text-primary" v-on:click="sortTable(2)">
											Ville
											<span v-if="etatTriColumn == 3 && etatTriTableau == true">
												<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-down-short" viewBox="0 0 16 16">
													<path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L7.5 10.293V4.5A.5.5 0 0 1 8 4z" />
												</svg>
											</span>
											<span v-if="etatTriColumn == 3 && etatTriTableau == false">
												<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-up-short" viewBox="0 0 16 16">
													<path fill-rule="evenodd" d="M8 12a.5.5 0 0 0 .5-.5V5.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 5.707V11.5a.5.5 0 0 0 .5.5z" />
												</svg>
											</span>
										</th>
										<th class="mb-2 text-primary" v-on:click="sortTable(3)">
											Latitude
											<span v-if="etatTriColumn == 4 && etatTriTableau == true">
												<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-down-short" viewBox="0 0 16 16">
													<path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L7.5 10.293V4.5A.5.5 0 0 1 8 4z" />
												</svg>
											</span>
											<span v-if="etatTriColumn == 4 && etatTriTableau == false">
												<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-up-short" viewBox="0 0 16 16">
													<path fill-rule="evenodd" d="M8 12a.5.5 0 0 0 .5-.5V5.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 5.707V11.5a.5.5 0 0 0 .5.5z" />
												</svg>
											</span>
										</th>
										<th class="mb-2 text-primary" v-on:click="sortTable(4)">
											Longitude
											<span v-if="etatTriColumn == 5 && etatTriTableau == true">
												<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-down-short" viewBox="0 0 16 16">
													<path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L7.5 10.293V4.5A.5.5 0 0 1 8 4z" />
												</svg>
											</span>
											<span v-if="etatTriColumn == 5 && etatTriTableau == false">
												<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-up-short" viewBox="0 0 16 16">
													<path fill-rule="evenodd" d="M8 12a.5.5 0 0 0 .5-.5V5.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 5.707V11.5a.5.5 0 0 0 .5.5z" />
												</svg>
											</span>
										</th>
										<th class="mb-2 text-primary">Actions</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="item in listSportsHall" :key="item.id">
										<td class="cell">{{ item.id }}</td>
										<td class="cell">{{ item.name }}</td>
										<td class="cell">{{ item.address }}</td>
										<td class="cell">{{ item.city }}</td>
										<td class="cell">{{ item.latitude }}</td>
										<td class="cell">{{ item.longitude }}</td>
										<td class="cell">

											<b-button class="btn app-btn-secondary" style="margin: 3px" v-on:click="updateSporthallModal(item)">
												<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil" viewBox="0 0 16 16">
													<path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
												</svg>
											</b-button>
											<b-button class="btn app-btn-secondary" style="margin: 3px" v-on:click="deleteSportHallModal(item)">
												<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
													<path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
													<path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" />
												</svg>
											</b-button>
										</td>
									</tr>
								</tbody>
								<tbody v-if="noResult">
									<tr>
										<td colspan="5">Aucun résultat</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

</template>

<script>
import jwt_decode from "jwt-decode";

export default {
	name: 'Sporthall',
	
	data() {
		return {
			// Variable message alert
			dismissSecs: 4,
			alertSuccessCountDown: 0,
			alertWarningCountDown: 0,
			info:'',
			error:'',

			etatTriTableau: true,
			etatTriColumn: 0,

			modalUpdateSporthall: true,

			listSportsHall:[],
			noResult: false,

			sporthallSelected: {},
            listSuggestedAddress: [],

            id: 0,
            name: "",
            address: "",
			city: "",
			latitude: 0,
            longitude: 0,

		}
	},

	mounted()
	{
        this.pageLoading();
	},

		methods: {
            pageLoading() {
                this.checkToken();
                let config = {
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.token
                    }
                };

                this.$axios.get('https://api.clickandball.fr/salledesport', config)
                    .then(response => {
                        if (response.status == 200 && response.data.error) {
                            this.showAlertWarning('Erreur : ' + response.data.error);
                        }
                        else if (response.status == 200) {
                            this.listSportsHall = response.data;

                            if (this.listSportsHall.length === 0) {
                                this.noResult = true;
                            }
                        }
                        else {
                            this.showAlertWarning('Code erreur : ' + response.status);
                        }
                    })
            },
            myFunction: function () {
                var input, filter, table, tr;
                input = document.getElementById("myInput");
                filter = input.value.toUpperCase();
                table = document.getElementById("myTable2");
                tr = table.getElementsByTagName("tr");
                for (var i = 1; i < tr.length; i++) {
                    var tds = tr[i].getElementsByTagName("td");
                    var flag = false;
                    for (var j = 0; j < tds.length; j++) {
                        var td = tds[j];
                        if (td.innerHTML.toUpperCase().indexOf(filter) > -1) {
                            flag = true;
                        }
                    }
                    if (flag) {
                        tr[i].style.display = "";
                    }
                    else {
                        tr[i].style.display = "none";
                    }
                }
            },
            sortTable(n) {
                let textA;
                let textB;
				let etatTri = this.etatTriTableau;
				let etatTriColumn = this.etatTriColumn;

				this.listSportsHall.sort(function (a, b) {
                    switch (n) {
                        case 0:
                            textA = a.id;
							textB = b.id;
							etatTriColumn = 1;
                            break;
                        case 1:
                            textA = a.name.toUpperCase();
							textB = b.name.toUpperCase();
                            etatTriColumn = 2;
                            break;
                        case 2:
                            textA = a.city.toUpperCase();
							textB = b.city.toUpperCase();
                            etatTriColumn = 3;
                            break;
                        case 3:
                            textA = a.latitude;
							textB = b.latitude;
                            etatTriColumn = 4;
                            break;
                        case 4:
                            textA = a.longitude;
							textB = b.longitude;
                            etatTriColumn = 5;
                            break;
                        default:
                            break;
                    }

                    if (etatTri) {
                        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                    }
                    else {
                        return (textA > textB) ? -1 : (textA > textB) ? 0 : 1;
                    }
                });

				this.etatTriTableau = !etatTri;
				this.etatTriColumn = etatTriColumn;
            },
		// Méthodes message alert
		alertSuccessCountDownChanged(alertSuccessCountDown)
		{
			this.alertSuccessCountDown = alertSuccessCountDown
		},
		alertWarningCountDownChanged(alertWarningCountDown)
		{
			this.alertWarningCountDown = alertWarningCountDown
		},
		showAlertSuccess(info)
		{
			this.info = info;
			this.alertSuccessCountDown = this.dismissSecs
		},
		showAlertWarning(error)
		{
			this.error = error;
			this.alertWarningCountDown = this.dismissSecs
		},
		checkToken()
		{
			if (localStorage.token)
			{
				var jsonToken = jwt_decode(localStorage.token);
				if ((jsonToken.exp - 60) < Math.round(Date.now()/1000))
				{
					localStorage.token = null;
					this.connected = false;
					this.$router.push({ path: '/login'})
				}
			}
		},
		searchAddressAPIEtalab()
		{
			var value = this.address;

			if (value != "")
			{
				this.$axios.get('https://api-adresse.data.gouv.fr/search/?q=' + value + '&type=housenumber&autocomplete=1')
					.then(response => {
						if (response.status == 200)
						{
							if (response.data.features.length != 0)
							{
								this.listSuggestedAddress = [];
								for (var i = 0; i < response.data.features.length; i++)
								{
									this.listSuggestedAddress.push(response.data.features[i].properties.label);
								}
							}
						}
					})
			}
		},
		selectAddress(info)
		{
			this.address = info;
			this.listSuggestedAddress = [];
		},
		addSporthallModal()
		{
            this.checkToken();
			this.$refs['Sporthall'].show();
			this.modalUpdateSporthall = false;
			},
		addSporthall()
		{
			this.checkToken();
			this.$refs['Sporthall'].hide();

			let json = [{
                id:parseInt(this.id),
				name:this.name,
                address: this.address
			}];
			let config = {
				headers: {
					'Authorization': 'Bearer ' + localStorage.token,
				}
			};

			this.$axios.post('https://api.clickandball.fr/salledesport', json, config)
				.then(response => {
					if (response.status == 200 && response.data.error)
					{
						this.showAlertWarning('Erreur : ' + response.data.error);
					}
					else if (response.status == 200)
					{
						this.showAlertSuccess("Salle ajoutée");
						this.apiGetInfoIsUpdate = false;
						this.pageLoading();
					}
					else
					{
						this.showAlertWarning('Erreur : ' + response.statusText);
					}
				})
				.catch(error => {
					this.showAlertWarning('Erreur : ' + error);
				});
		},
		updateSporthallModal(info)
		{
            this.checkToken();
            this.$refs['Sporthall'].show();
			this.modalUpdateSporthall = true;
			this.id = info.id;
			this.name = info.name;
			this.address = info.address;
			this.city = info.city;
			this.latitude = info.latitude;
			this.longitude = info.longitude;
		},
        updateSporthall() {
            this.checkToken();
            this.$refs['Sporthall'].hide();

            let json = {
                id: parseInt(this.id),
                name: this.name,
                address: this.address,
            };
            let config = {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.token,
                }
            };

            this.$axios.put('https://api.clickandball.fr/salledesport', json, config)
                .then(response => {
                    if (response.status == 200 && response.data.error) {
                        this.showAlertWarning('Erreur : ' + response.data.error);
                    }
                    else if (response.status == 200) {
                        this.showAlertSuccess("Salle mise à jour");
						this.apiGetInfoIsUpdate = false;
						this.pageLoading();
                    }
                    else {
                        this.showAlertWarning('Erreur : ' + response.statusText);
                    }
                })
                .catch(error => {
                    this.showAlertWarning('Erreur : ' + error);
                });
        },
		deleteSportHallModal(info) {
			this.checkToken();
			this.sporthallSelected = info;
			this.$refs['modalDeleteSportHall'].show();
        },
		deleteSportHall() {
            this.checkToken();
            this.$refs['modalDeleteSportHall'].hide();
            let config = {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.token,
				},
				data: { "id": this.sporthallSelected.id }
            };

            this.$axios.delete('https://api.clickandball.fr/salledesport', config)
                .then(response => {
                    if (response.status == 200 && response.data.error) {
                        this.showAlertWarning('Erreur : ' + response.data.error);
                    }
                    else if (response.status == 200) {
						this.showAlertSuccess("Salle supprimée");
						this.pageLoading();
                    }
                    else {
                        this.showAlertWarning('Code erreur : ' + response.status);
                    }
                })
                .catch(error => {
                    this.showAlertWarning('Erreur : ' + error);
                });
        },
	}
}
</script>
